import * as React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ITeacherApplication } from "../../types/classroomTypes";
import { Fragment } from "react";
import { arrayOfDatesToRanges } from "../UserProfileUpdate/convertTime";

interface AppProps {
  application: ITeacherApplication;
  setApplication: React.Dispatch<React.SetStateAction<ITeacherApplication>>;
}

function getDayOfWeek(date: Date) {
  // Accepts a Date object or date string that is recognized by the Date.parse() method
  const dayOfWeek = date.getDay();
  return isNaN(dayOfWeek)
    ? null
    : [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ][dayOfWeek];
}

const Review = ({ application, setApplication }: AppProps) => {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Summary
      </Typography>
      <List disablePadding>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText
            primary={"First and Last Name"}
            secondary={application.first_name + " " + application.last_name}
          />
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText
            primary={"Teaching Language"}
            secondary={application.teaching_languages.join(", ")}
          />
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText
            primary={"Introduction"}
            secondary={application.introduction}
          />
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText
            primary={"Availability"}
            secondary={arrayOfDatesToRanges(
              application.availability_template
            ).map((datePair, index) => {
              return (
                <Fragment key={index}>
                  {datePair[0] && getDayOfWeek(datePair[0])}{" "}
                  {datePair[0] && datePair[0].toLocaleTimeString()} -{" "}
                  {datePair[0] && datePair[1].toLocaleTimeString()}
                  <br />
                </Fragment>
              );
            })}
          />
        </ListItem>
      </List>
    </React.Fragment>
  );
};

export default Review;
