import React from "react";
import { Word } from "../../../../types/linguisticsTypes";
import { Chip } from "@mui/material";
import { borderColor } from "../../../theme/colors";

interface WordProps {
  word: Word;
  sentence: string;
  language: string;
  level: string;
  native_languages: string[];
  index: number;
  isInVocabulary: boolean;
}
const ModernTextDisplay: React.FC<WordProps> = ({
  word,
  sentence,
  language,
  level,
  native_languages,
  index,
  isInVocabulary,
}) => {
  const wordStyles = isInVocabulary
    ? {
        cursor: "pointer",
        backgroundColor: borderColor.pastel3, // Background color of your choice
        padding: "2px 4px", // Add a little padding to give it the chip feel
        borderRadius: "4px", // Round the corners a bit
      }
    : {
        cursor: "pointer",
      };

  return <span style={wordStyles}>{word.text}</span>;
};

export default ModernTextDisplay;
