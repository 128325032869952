import React, { useState } from "react";
import { IMarker } from "../../../types/languageTypes";

const Marker: React.FC<IMarker> = ({ text }) => {
  const [isHovered, setIsHovered] = useState(false);

  const markerStyle: React.CSSProperties = {
    color: "white",
    background: isHovered ? "black" : "red",
    padding: "10px 5px",
    width: "40px",
    height: "40px",
    display: "inline-flex",
    textAlign: "center" as "center",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    transform: "translate(-50%, -50%)",
  };

  return (
    <div
      style={markerStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {text}
    </div>
  );
};

export default Marker;
