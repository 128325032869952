import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import DashboardMany from "./components/Dashboard/DashboardMany/DashboardMany";
import NoPage from "./components/NoPage/NoPage";
import SignUp from "./components/Authentication/SignUp";
import SignIn from "./components/Authentication/SignIn";
import ProfileUpdateForm from "./components/UserProfileUpdate/ProfileUpdateForm";
import { PrivateRoute } from "./utils/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import { ROLE } from "./components/Dashboard/types";
import store from "./features/store";
import Chat from "./components/Chat/Chat";
import EmailSent from "./components/Authentication/EmailSent";
import UserProfile from "./components/UserProfile/UserProfileDashboard";
import Dashboard from "./components/Dashboard/Dashboard";
import LearningDashboard from "./components/Texts/TextsDashboard";
import Scheduler from "./components/Scheduler/SchedulerMany";
import TeacherApplicationForm from "./components/CreateTeacherProfile/CreateTeacherProfile";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Terms from "./Terms";
import Privacy from "./Privacy";
import Cookies from "./Cookies";
import Disclaimer from "./Disclaimer";
import SignedOut from "./components/Authentication/SignedOut";
import SignInSide from "./components/Authentication/SignInSide";
import DictionaryDashboard from "./components/Dictionary/DictionaryDashboard";
import OneDashboard from "./components/Groups/GroupsOneDashboard";
import CommunityDashboard from "./components/Community/CommunityDashboard";
import TextDisplay from "./components/Texts/Text/TextDisplay";
import GroupCreate from "./components/CreateGroup/CreateGroup";
import Groups from "./components/Groups/GroupsManyDashboard";
import ChatUser from "./components/Chat/ChatUser";
import { ThemeProvider } from "@emotion/react";
import theme from "./components/theme/theme";
import WordAdd from "./components/Dictionary/WordAdd/WordAdd";
import Categories from "./components/WikipediaCategories/CategoriesAll";
import CategoriesImportant from "./components/WikipediaCategories/CategoriesImportant";
// get OATH from env
const OAUTH_CLIENT_ID = process.env.REACT_APP_OAUTH_CLIENT_ID || "";
const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "";

if (REACT_APP_ENVIRONMENT == "development") {
  console.log(OAUTH_CLIENT_ID);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <Router>
      <ThemeProvider theme={theme}>
        <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
          <AuthProvider>
            <Routes>
              {/* Public Routes */}
              <Route index element={<SignInSide />} />
              <Route path="/terms/" element={<Terms />} />
              <Route path="/privacy/" element={<Privacy />} />
              <Route path="/cookies/" element={<Cookies />} />
              <Route path="/disclaimer/" element={<Disclaimer />} />
              <Route path="/signup/" element={<SignUp />} />
              <Route path="/signup/emailsent/" element={<EmailSent />} />
              <Route path="/signin/" element={<SignIn />} />
              <Route path="/signout/" element={<SignedOut />} />
              <Route path="/categories/" element={<CategoriesImportant />} />
              <Route path="*" element={<NoPage />} />

              {/* Private Routes */}
              <Route
                path="/dashboard/"
                element={<PrivateRoute component={Dashboard} />}
              />
              <Route
                path="/teacher/"
                element={<PrivateRoute component={Dashboard} />}
              />
              <Route
                path="/profile/update/"
                element={<PrivateRoute component={ProfileUpdateForm} />}
              />
              <Route
                path="/groups/create/"
                element={<PrivateRoute component={GroupCreate} />}
              />
              <Route
                path="/groups/"
                element={<PrivateRoute component={Groups} />}
              />
              <Route
                path="/groups/:uuid/"
                element={<PrivateRoute component={OneDashboard} />}
              />
              <Route
                path="/dictionary/"
                element={<PrivateRoute component={DictionaryDashboard} />}
              />
              <Route
                path="/texts/"
                element={<PrivateRoute component={LearningDashboard} />}
              />
              <Route
                path="/community/"
                element={<PrivateRoute component={CommunityDashboard} />}
              />
              <Route
                path="/chat/"
                element={<PrivateRoute component={Chat} />}
              />
              <Route
                path="/user/:id/"
                element={<PrivateRoute component={UserProfile} />}
              />
              <Route
                path="/user/chat/:userId"
                element={<PrivateRoute component={ChatUser} />}
              />
              <Route
                path="/chat/:channelId"
                element={<PrivateRoute component={Chat} />}
              />
              <Route
                path="/scheduler/"
                element={<PrivateRoute component={Scheduler} />}
              />
              <Route
                path="/user/:id/"
                element={<PrivateRoute component={UserProfile} />}
              />
              <Route
                path="/text/:uuid/"
                element={<PrivateRoute component={TextDisplay} />}
              />
              <Route
                path="/word/add/"
                element={<PrivateRoute component={WordAdd} />}
              />
              <Route
                path="/teachers/create/"
                element={<PrivateRoute component={TeacherApplicationForm} />}
              />
              <Route
                path="/teachers/accept-invite/:uuid"
                element={<PrivateRoute component={OneDashboard} />}
              />
              <Route path="*" element={<NoPage />} />
              <Route
                path="/test/"
                element={
                  <PrivateRoute
                    roles={[ROLE.ADMIN]}
                    component={DashboardMany}
                  />
                }
              />
            </Routes>
          </AuthProvider>
        </GoogleOAuthProvider>
      </ThemeProvider>
    </Router>
  </Provider>
);
