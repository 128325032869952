import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IUserProfilePlusEmail } from "../../types/userProfileTypes";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getTeacherProfile = createAsyncThunk(
  "userProfile/getTeacherProfile",
  async (
    data: { access_token: string; teacherProfileId: string },
    thunkApi
  ) => {
    try {
      const response = await axios.get<IUserProfilePlusEmail>(
        `${url}/profile/${data.teacherProfileId}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const getTeacherProfileSlice = createSlice({
  name: "getTeacherProfile",
  initialState: {
    getTeacherProfileLoading: false,
    getTeacherProfileError: null,
    getTeacherProfilePayload: {
      uuid: "",
      username: "",
      email: "",
      date_of_birth: "",
      about: "",
      native_languages_names: [],
      learning_languages_names: ["en-US"],
      learning_languages_levels: ["A1"],
      timezone: "",
      countries_from: [],
      countries_living: [],
      images: [],
      is_online: false,
      age: "",
      profile_image: "",
      all_image_urls: [],
      is_teacher: false,
    } as IUserProfilePlusEmail,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTeacherProfile.pending, (state) => {
        state.getTeacherProfileLoading = true;
      })
      .addCase(
        getTeacherProfile.fulfilled,
        (state, action: PayloadAction<IUserProfilePlusEmail>) => {
          state.getTeacherProfileLoading = false;
          state.getTeacherProfilePayload = action.payload;
        }
      )
      .addCase(getTeacherProfile.rejected, (state, action: any) => {
        state.getTeacherProfileLoading = false;
        state.getTeacherProfileError = action.payload;
      });
  },
});

export default getTeacherProfileSlice.reducer;
