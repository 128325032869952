import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IUserProfilePlusEmailPlusTeacher } from "../../types/userProfileTypes";
import { initialUserProfileState } from "../../initalStates/initialUserState";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const patchUserProfile = createAsyncThunk(
  "userProfile/patchUserProfile",
  async (
    data: { access_token: string; data: any; subscriberId: string },
    thunkApi
  ) => {
    try {
      const response = await fetch(`${url}/profile/${data.subscriberId}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        body: JSON.stringify(data.data),
      });
      // await and if not ok return error
      const json = await response.json();
      if (!response.ok) {
        return thunkApi.rejectWithValue(json);
      }
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const patchUserProfileSlice = createSlice({
  name: "patchUserProfile",
  initialState: {
    patchUserProfileLoading: false,
    patchUserProfileError: null,
    patchUserProfilePayload: [
      initialUserProfileState,
    ] as IUserProfilePlusEmailPlusTeacher[],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(patchUserProfile.pending, (state) => {
        state.patchUserProfileLoading = true;
      })
      .addCase(patchUserProfile.fulfilled, (state, action: any) => {
        state.patchUserProfileLoading = false;
        state.patchUserProfilePayload = action.payload;
      })
      .addCase(patchUserProfile.rejected, (state, action: any) => {
        state.patchUserProfileLoading = false;
        state.patchUserProfileError = action.payload;
      });
  },
});

export default patchUserProfileSlice.reducer;
