import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const putAdjustTimeZone = createAsyncThunk(
  "userProfile/putAdjustTimeZone",
  async (data: { access_token: string; timezone: any }, thunkApi) => {
    try {
      const response = await fetch(`${url}/adjusttimezone/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        body: JSON.stringify({ timezone: data.timezone }),
      });
      // await and if not ok return error
      if (!response.ok) {
        return thunkApi.rejectWithValue({ error: "Something went wrong" });
      } else {
        return { status: "OK" };
      }
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const putAdjustTimeZoneSlice = createSlice({
  name: "putAdjustTimeZone",
  initialState: {
    putAdjustTimeZoneLoading: false,
    putAdjustTimeZoneError: null,
    putAdjustTimeZonePayload: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(putAdjustTimeZone.pending, (state) => {
        state.putAdjustTimeZoneLoading = true;
      })
      .addCase(putAdjustTimeZone.fulfilled, (state, action: any) => {
        state.putAdjustTimeZoneLoading = false;
        state.putAdjustTimeZonePayload = action.payload;
      })
      .addCase(putAdjustTimeZone.rejected, (state, action: any) => {
        state.putAdjustTimeZoneLoading = false;
        state.putAdjustTimeZoneError = action.payload;
      });
  },
});

export default putAdjustTimeZoneSlice.reducer;
