import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IErrors, IMessage } from "../../types/MessageTypes";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getChannelMessages = createAsyncThunk(
  "user/getUsers",
  async (data: { access_token: string; channelId: string }, thunkApi) => {
    try {
      const response = await axios.get<IMessage[]>(
        `${url}/channels/` + data.channelId + "/",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
          data: data.channelId,
        }
      );
      //console.log("response.data: ", response.data)
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const ChannelMessagesSlice = createSlice({
  name: "getMessages",
  initialState: {
    getChannelMessagesLoading: false,
    getChannelMessagesErrors: {} as IErrors,
    getChannelMessagesPayload: [] as IMessage[],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getChannelMessages.pending, (state, action) => {
        state.getChannelMessagesLoading = true;
      })
      .addCase(
        getChannelMessages.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.getChannelMessagesLoading = false;
          state.getChannelMessagesPayload = action.payload;
        }
      )
      .addCase(
        getChannelMessages.rejected,
        (state, action: PayloadAction<any>) => {
          state.getChannelMessagesLoading = false;
          state.getChannelMessagesErrors = action.payload;
        }
      );
  },
});

export default ChannelMessagesSlice.reducer;
