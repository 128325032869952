import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ITeacherApplication } from "../../types/classroomTypes";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const postTeacherProfile = createAsyncThunk(
  "userProfile/postTeacherProfile",
  async (
    data: { access_token: string; application: ITeacherApplication },
    thunkApi
  ) => {
    try {
      const response = await fetch(`${url}/teacherprofile/create/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        body: JSON.stringify(data.application),
      });
      // await and if not ok return error
      const json = await response.json();
      if (!response.ok) {
        return thunkApi.rejectWithValue(json);
      }
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const postTeacherProfileSlice = createSlice({
  name: "postTeacherProfile",
  initialState: {
    postTeacherProfileLoading: false,
    postTeacherProfileErrors: null,
    postTeacherProfilePayload: {
      success: false,
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postTeacherProfile.pending, (state) => {
        state.postTeacherProfileLoading = true;
      })
      .addCase(postTeacherProfile.fulfilled, (state, action: any) => {
        state.postTeacherProfileLoading = false;
        state.postTeacherProfilePayload = action.payload;
      })
      .addCase(postTeacherProfile.rejected, (state, action: any) => {
        state.postTeacherProfileLoading = false;
        state.postTeacherProfileErrors = action.payload;
      });
  },
});

export default postTeacherProfileSlice.reducer;
