import React, { useState, useRef } from "react";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import { canvasPreview } from "../canvasPreview";
import { useDebounceEffect } from "../useDebounceEffect";
import { Box, Button, Fab, Grid } from "@mui/material";
import { useAppDispatch } from "../../../hooks/useTypedSelector";
import { putUserProfileImageList } from "../../../features/users/putUserProfileImageList";
import CloseIcon from "@mui/icons-material/Close";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AddIcon from "@mui/icons-material/Add";
import "react-image-crop/dist/ReactCrop.css";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

interface Props {
  profile: any;
  errors: any;
  state: any;
  setState: any;
}

function Images({ profile, errors, state, setState }: Props) {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const scale = 1;
  const rotate = 0;
  const aspect = 1;
  const [addButtonVisibility, setAddButtonVisibility] = useState(false);

  const dispatch = useAppDispatch();

  function deleteProfileImageHandler(image: string) {
    // if image is profile image alert user to change profile image first
    if (state.profile_images.profile_image === image) {
      alert(
        "You can't delete your profile image. Please change your profile image first."
      );
      return;
    }
    let profile_image = state.profile_images.profile_image;
    let images = state.profile_images.images.filter(
      (img: string) => img !== image
    );
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    if (profile_image === image) {
      profile_image = images[0];
    }
    dispatch(
      putUserProfileImageList({
        access_token: access_token,
        profile_images: {
          profile_image: profile_image,
          images: images,
        },
      })
    );
    setState({
      ...state,
      profile_images: {
        profile_image: profile_image,
        images: images,
      },
    });
  }

  function setProfileImageHandler(profile_image: string) {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      putUserProfileImageList({
        access_token: access_token,
        profile_images: {
          profile_image: profile_image,
          images: state.profile_images.images,
        },
      })
    );
    setState({
      ...state,
      profile_images: {
        profile_image: profile_image,
        images: state.profile_images.images,
      },
    });
  }

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
    setAddButtonVisibility(true);
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  return (
    <>
      <Box sx={{ mb: 3 }}>Photos</Box>

      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {state.profile_images.images.map((url: string, index: number) => (
          <Grid item xs={2} sm={4} md={4} key={index}>
            <div style={{ position: "relative" }}>
              <img
                src={url}
                style={{
                  width: "100%",
                }}
              />
              <CloseIcon
                style={{
                  position: "absolute",
                  top: "4px",
                  right: "5px",
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.cursor = "pointer";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.cursor = "default";
                }}
                onClick={(e) => {
                  deleteProfileImageHandler(url);
                }}
              />
              {url === state.profile_images.profile_image ? (
                <AccountBoxIcon
                  style={{
                    position: "absolute",
                    top: "4px",
                    left: "5px",
                    color: "green",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.cursor = "pointer";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.cursor = "default";
                  }}
                  onClick={(e) => {
                    setProfileImageHandler(url);
                  }}
                />
              ) : (
                <AccountBoxIcon
                  style={{
                    position: "absolute",
                    top: "4px",
                    left: "5px",
                    color: "gray",
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.cursor = "pointer";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.cursor = "default";
                  }}
                  onClick={(e) => {
                    setProfileImageHandler(url);
                  }}
                />
              )}
            </div>
          </Grid>
        ))}
        {state.profile_images.images.length < 7 &&
          Array.from(Array(6 - state.profile_images.images.length)).map(
            (_, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <Button
                  variant="outlined"
                  component="label"
                  sx={{
                    border: 1,
                    borderRadius: "16px",
                    borderStyle: "dotted",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <AddIcon />
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={onSelectFile}
                  />
                </Button>
              </Grid>
            )
          )}
      </Grid>

      <Box sx={{ mt: 3 }} />

      {!!imgSrc && (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={aspect}
        >
          <img
            ref={imgRef}
            alt="Crop me"
            src={imgSrc}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      )}
      <div>
        {!!completedCrop && (
          <canvas
            ref={previewCanvasRef}
            style={{
              border: "1px solid black",
              objectFit: "contain",
              width: completedCrop.width,
              height: completedCrop.height,
            }}
            hidden
          />
        )}
      </div>
      <div>
        {addButtonVisibility ? (
          <Fab
            variant="extended"
            size="small"
            color="primary"
            aria-label="add"
            onClick={() => {
              if (previewCanvasRef.current) {
                let authTokens = JSON.parse(
                  localStorage.getItem("authTokens") || "{}"
                );
                let access_token = authTokens.access;
                previewCanvasRef.current.toBlob((blob) => {
                  if (blob) {
                    const formData = new FormData();
                    formData.append("imageBlob", blob);
                    fetch(`${url}/userProfileImage/`, {
                      method: "POST",
                      body: formData,
                      headers: {
                        Authorization: `Bearer ${access_token}`,
                      },
                    })
                      .then((res) => res.json())
                      .then((data) => {
                        setState({
                          ...state,
                          profile_images: {
                            ...state.profile_images,
                            images: data.images,
                          },
                        });
                      });
                  }
                });
              }
              // make canvas disappear
              setImgSrc("");
              setAddButtonVisibility(false);
            }}
          >
            Add
          </Fab>
        ) : null}
      </div>
    </>
  );
}

export default Images;
