import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { IEvent } from "../../components/Dashboard/types";
import axios from "axios";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getEvents = createAsyncThunk(
  "classrooms/events",
  async (
    data: {
      access_token: string;
      from: string;
      to: string;
      classroom?: string;
    },
    thunkApi
  ) => {
    try {
      const response = await axios.get<any[]>(`${url}/events/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        params: {
          from: data.from,
          to: data.to,
          classroom: data.classroom,
        },
      });
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const eventsSlice = createSlice({
  name: "classrooms/events",
  initialState: {
    eventsLoading: false,
    eventsErrors: null,
    events: [] as IEvent[],
  },
  reducers: {
    pushChatMessage: (state, action: PayloadAction<IEvent[]>) => {
      action.payload.forEach((event: IEvent) => {
        state.events.push(event);
      });
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEvents.pending, (state) => {
        state.eventsLoading = true;
      })
      .addCase(getEvents.fulfilled, (state, action: any) => {
        state.eventsLoading = false;
        state.events = action.payload;
      })
      .addCase(getEvents.rejected, (state, action: any) => {
        state.eventsLoading = false;
        state.eventsErrors = action.payload;
      });
  },
});

export default eventsSlice.reducer;
