import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IUserNew } from "../../types/userTypes";
import { IUserProfile } from "../../types/userProfileTypes";
import { IErrors } from "../../types/MessageTypes";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getUsers = createAsyncThunk(
  "user/getUsers",
  async (data: { subscriberIds: string[]; access_token: string }, thunkApi) => {
    try {
      const response = await axios.get<IUserNew[]>(`${url}/users/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        params: {
          subscriberIds: data.subscriberIds,
        },
      });
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const getUsersSlice = createSlice({
  name: "getUsers",
  initialState: {
    getUsersLoading: false,
    getUsersError: {} as IErrors,
    getUsersPayload: [
      {
        email: "",
        role: "",
        all_image_urls: [""],
        images: [""],
        profile_details: {
          username: "",
          date_of_birth: "",
          about: "",
          native_languages: [],
          learning_languages: [],
          learning_languages_levels: [],
          countries_from: [],
          countries_living: [],
          timezone: "",
          images: [],
          age: "",
          profile_image: "",
          all_image_urls: [],
          is_online: false,
          is_teacher: false,
          is_bot: false,
          user_uuid: "",
          voted_by: "",
        } as IUserProfile,
        profile: {
          username: "",
          date_of_birth: "",
          about: "",
          native_languages: [],
          learning_languages: [],
          learning_languages_levels: [],
          countries_from: [],
          countries_living: [],
          timezone: "",
          images: [],
          age: "",
          profile_image: "",
          all_image_urls: [],
          is_online: false,
          is_teacher: false,
          is_bot: false,
          user_uuid: "",
          voted_by: "",
        } as IUserProfile,
        _id: "",
        id: 0,
        created_at: "",
        updated_at: "",
        __v: 0,
      } as IUserNew,
    ],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUsers.pending, (state, action) => {
        state.getUsersLoading = true;
      })
      .addCase(getUsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.getUsersLoading = false;
        state.getUsersPayload = action.payload;
      })
      .addCase(getUsers.rejected, (state, action: PayloadAction<any>) => {
        state.getUsersLoading = false;
        state.getUsersError = action.payload;
      });
  },
});

export default getUsersSlice.reducer;
