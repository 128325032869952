import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IUserNew } from "../../types/userTypes";
import { IErrors } from "../../types/MessageTypes";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getTeachers = createAsyncThunk(
  "user/getTeachers",
  async (
    data: {
      access_token: string;
      language: string;
      experience: string | number;
    },
    thunkApi
  ) => {
    try {
      const response = await axios.post<IUserNew[]>(
        `${url}/teachers/`,
        {
          language: data.language,
          experience: data.experience,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const getTeachersSlice = createSlice({
  name: "getTeachers",
  initialState: {
    getTeachersLoading: false,
    getTeachersErrors: {} as IErrors,
    getTeachersPayload: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTeachers.pending, (state, action) => {
        state.getTeachersLoading = true;
      })
      .addCase(getTeachers.fulfilled, (state, action: PayloadAction<any>) => {
        state.getTeachersLoading = false;
        state.getTeachersPayload = action.payload;
      })
      .addCase(getTeachers.rejected, (state, action: PayloadAction<any>) => {
        state.getTeachersLoading = false;
        state.getTeachersErrors = action.payload;
      });
  },
});

export default getTeachersSlice.reducer;
