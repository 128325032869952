import { useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../hooks/useTypedSelector";
import { useContext, useEffect, useState } from "react";
import { getUserProfile } from "../../../features/users/getUserProfileSlice";
import { ThemeProvider } from "@emotion/react";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Container,
  CssBaseline,
  Divider,
  IconButton,
  Modal,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import AuthContext from "../../../context/AuthContext";
import AppBar from "../../Header/AppBar";
import theme from "../../theme/theme";
import Copyright from "../../Copyright/Copyright";
import ListIcon from "@mui/icons-material/List";
import ShareIcon from "@mui/icons-material/Share";
import StyleIcon from "@mui/icons-material/Style";
import { getBodyOfTextByUuid } from "../../../features/linguistics/getBodyOfTextByUuidSlice";
import { BodyOfText, Paragraph, Word } from "../../../types/linguisticsTypes";
import React from "react";
import NormalView from "./NormalView/NormalView";
import KnowledgeView from "./KnowledgeView/KnowledgeView";
import GrammarView from "./GrammarView/GrammarView";
import Legend from "./GrammarView/Legend";
import { postWord } from "../../../features/linguistics/postWordSlice";
import { listVocabulary } from "../../../features/linguistics/listVocabularySlice";
import { borderColor } from "../../theme/colors";

var url = process.env.REACT_APP_URL;

function TextDisplay() {
  const navigate = useNavigate();
  let { uuid } = useParams();
  const [wordCache, setWordCache] = useState<Record<number, string>>({});

  const dispatch = useAppDispatch();
  const { getBodyOfTextByUuidPayload } = useAppSelector(
    (state) => state.getBodyOfTextByUuid
  );
  const { currentUser, isAuthenticated } = useContext(AuthContext);
  const { getUserProfilePayload } = useAppSelector(
    (state) => state.getUserProfile
  );
  const [isMobile, setIsMobile] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedViewIndex, setSelectedViewIndex] = useState(0);
  const [bodyOfText, setBodyOfText] = useState<BodyOfText>({
    uuid: "",
    is_deleted: false,
    title: "",
    paragraphs: [] as Paragraph[],
    language: "",
    level: "",
    created_at: "",
  });
  const views = ["text", "grammar", "knowledge"];
  const views_colors = ["#FCD9CF", "#DABDF6", "#A8DEFA"];

  const checkScreenSize = () => {
    setIsMobile(window.innerWidth <= 768); // You can adjust the breakpoint as needed
  };

  function setIsModalOpen(arg0: boolean) {
    throw new Error("Function not implemented.");
  }

  const handleSubmit = () => {
    // Handle form submission logic here
    console.log("Form submitted");
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function share
  const handleShare = (uuid: string) => {
    navigator.clipboard.writeText(url + "/groups/" + uuid);
    setOpen(true);
  };

  const handleViewClick = () => {
    // Cycle to the next language or loop back to the first language
    setSelectedViewIndex((prevIndex: number) =>
      prevIndex === views.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleClose = () => {
    // Cycle to the next language or loop back to the first language
    setOpen(false);
  };

  useEffect(() => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      getUserProfile({
        access_token: access_token,
        subscriberProfileId: currentUser.profile_uuid,
      })
    );
    if (uuid) {
      dispatch(
        getBodyOfTextByUuid({
          access_token: access_token,
          uuid: uuid,
        })
      );
    }

    checkScreenSize(); // Initial check
    window.addEventListener("resize", checkScreenSize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  useEffect(() => {
    const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    const access_token = authTokens.access;
    dispatch(
      listVocabulary({
        access_token: access_token,
      })
    );
  }, []);

  const { listVocabularyWordsPayload } = useAppSelector(
    (state) => state.listVocabulary
  );

  const isDuplicateWord = (wordToCheck: Word) => {
    return listVocabularyWordsPayload.some((vocabularyWord) => {
      return (
        vocabularyWord.word.text === wordToCheck.text &&
        JSON.stringify(vocabularyWord.word.parts_of_speech) ===
          JSON.stringify(wordToCheck.parts_of_speech)
      );
    });
  };

  useEffect(() => {
    if (getBodyOfTextByUuidPayload) setBodyOfText(getBodyOfTextByUuidPayload);
  }, [getBodyOfTextByUuidPayload]);

  // Function to open the modal

  const ComponentToRender: React.FC<{
    word: Word;
    sentence: string;
    index: number;
  }> = ({ word, sentence, index }) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const { postWordError, postWordLoading, postWordPayload } = useAppSelector(
      (state) => state.postWord
    );

    const handleDuplicateWordClick = (uuid: string) => {
      navigate(`/word/${uuid}`);
    };

    const handleNonDuplicateWordClick = (e: any) => {
      e.preventDefault();

      const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
      const access_token = authTokens.access;
      dispatch(
        postWord({
          access_token: access_token,
          word: word.text,
          context: sentence,
          language: bodyOfText.language,
          level: bodyOfText.level,
        })
      );

      setTooltipOpen(true);
    };

    const handleCloseTooltip = () => {
      setTooltipOpen(false);
    };

    const handleAddWordClick = () => {
      //console.log("postWordPayload.analysis", postWordPayload.analysis);
      navigate(
        "/word/add",
        { state: { data: postWordPayload.analysis } }
        // `/word/add?word=${word}&context=${context}&language=${language}&description=${description}&pos=${parts_of_speech}`
      );
    };

    //{'parts_of_speech': ['noun'], 'translations': ['Wege', 'Möglichkeiten'], 'translations_language': 'de-CH', 'context': 'Walking, jogging, or dancing are great ways to stay active.', 'description': "The word 'ways' is a noun that refers to different methods or options to achieve something. In this context, it means different activities that can help you stay active, such as walking, jogging, or dancing.", 'description_language': 'en-US', 'word': 'ways', 'word_language': 'en-US'}

    let modalSyled;
    if (postWordLoading) {
      modalSyled = <span style={{ fontSize: "1em" }}>Loading...</span>;
    } else {
      modalSyled = (
        <div>
          <div
            style={{ fontSize: "1em", marginBottom: "20px" }}
            dangerouslySetInnerHTML={{
              __html: postWordPayload
                ? postWordPayload.analysis.description
                : "",
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddWordClick}
          >
            Add Word
          </Button>
        </div>
      );
    }
    switch (selectedViewIndex) {
      case 0: // NormalView
        return (
          <>
            <span
              onClick={(e) => {
                if (isDuplicateWord(word)) {
                  handleDuplicateWordClick(word.uuid); // assuming word has a uuid property
                } else {
                  handleNonDuplicateWordClick(e);
                }
              }}
            >
              <NormalView
                word={word}
                sentence={sentence}
                language={bodyOfText.language}
                level={bodyOfText.level}
                native_languages={getUserProfilePayload.native_languages_names}
                index={index}
                isInVocabulary={isDuplicateWord(word)}
              />
            </span>

            <Modal open={tooltipOpen} onClose={handleCloseTooltip}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  padding: "20px",
                  backgroundColor: "white",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                {modalSyled}
              </div>
            </Modal>
          </>
        );

      case 1:
        return (
          <GrammarView
            word={word}
            sentence={sentence}
            native_languages={getUserProfilePayload.native_languages_names}
            index={index}
          />
        );
      case 2:
        return (
          <KnowledgeView
            word={word}
            sentence={sentence}
            native_languages={getUserProfilePayload.native_languages_names}
            index={index}
          />
        );
      default:
        return (
          <NormalView
            word={word}
            sentence={sentence}
            language={bodyOfText.language}
            level={bodyOfText.level}
            native_languages={getUserProfilePayload.native_languages_names}
            index={index}
            isInVocabulary={isDuplicateWord(word)}
          />
        );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar currentUser={currentUser} userProfile={getUserProfilePayload} />
      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
        {/* Add another navbar inside grid */}
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          alignItems="center"
        >
          <IconButton
            aria-label="Create Word Entry"
            color={"primary"}
            onClick={() => {
              navigate("/texts/");
            }}
          >
            <ListIcon />
          </IconButton>

          <Button
            aria-label="List"
            color={"primary"}
            onClick={() => {
              handleViewClick();
            }}
          >
            <ChangeCircleIcon
              sx={{
                mr: isMobile ? 0 : 1,
                color: views_colors[selectedViewIndex],
              }}
            />
            {!isMobile ? views[selectedViewIndex] : ""}
          </Button>

          <Button
            aria-label="List"
            color={"primary"}
            onClick={() => {
              navigate("/dictionary/");
            }}
          >
            <StyleIcon
              sx={{
                mr: isMobile ? 0 : 1,
              }}
            />
            {!isMobile ? " Show Words" : ""}
          </Button>
          <Chip label={`${bodyOfText.language}`} color="primary" size="small" />
        </Stack>
        {/* This is the textcards component */}
        <Box sx={{ mt: 2 }}>
          <Card
            sx={{ mb: 0, p: 2, borderColor: borderColor.color4 }}
            variant="outlined"
          >
            <CardContent>
              <div style={{ marginBottom: "10px" }}>{bodyOfText.title}</div>
              {bodyOfText.paragraphs.map((paragraph) => (
                <div key={paragraph.id}>
                  {paragraph.sentences.map((sentence) => (
                    <div key={sentence.id}>
                      {sentence.words.map((word, index) => (
                        <React.Fragment key={index}>
                          {
                            // Add a space if PUNCT is not in word.parts_of_speech,
                            // it's not the first word, and it's not a quotation mark
                            !word.parts_of_speech.includes("PUNCT") &&
                              index !== 0 &&
                              !["'", '"', "“", "”", "‘", "’"].includes(
                                word.text
                              ) &&
                              " "
                          }
                          <ComponentToRender
                            word={word}
                            sentence={sentence.text}
                            index={index}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                  ))}
                </div>
              ))}
              {selectedViewIndex === 1 && <Legend />}
            </CardContent>
            <CardActions>
              {" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "8px",
                }}
              >
                <IconButton
                  aria-label="Delete"
                  onClick={() => handleShare(bodyOfText.uuid)}
                >
                  <ShareIcon />
                </IconButton>
              </div>
            </CardActions>
          </Card>
        </Box>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Copied Link to Clipboard!
          </Alert>
        </Snackbar>
        <Copyright sx={{ pt: 4 }} />
      </Container>
    </ThemeProvider>
  );
}

export default TextDisplay;
