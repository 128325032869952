import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getLanguagesAndCountries = createAsyncThunk(
  "languages/getLanguagesAndCountries",
  async (data: { access_token: string }, thunkApi) => {
    try {
      const response = await axios.get<string[][]>(
        `${url}/languagesandcountries/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
          //data: data.subscriberIds
        }
      );
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const getLanguagesAndCountriesSlice = createSlice({
  name: "languages/getLanguagesAndCountries",
  initialState: {
    getLanguagesAndCountriesLoading: false,
    getLanguagesAndCountriesError: null,
    getLanguagesAndCountriesPayload: [["en-US", "English (United States)"]],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getLanguagesAndCountries.pending, (state) => {
        state.getLanguagesAndCountriesLoading = true;
      })
      .addCase(
        getLanguagesAndCountries.fulfilled,
        (state, action: PayloadAction<string[][]>) => {
          state.getLanguagesAndCountriesLoading = false;
          state.getLanguagesAndCountriesPayload = action.payload;
        }
      )
      .addCase(getLanguagesAndCountries.rejected, (state, action: any) => {
        state.getLanguagesAndCountriesLoading = false;
        state.getLanguagesAndCountriesError = action.payload;
      });
  },
});

export default getLanguagesAndCountriesSlice.reducer;
