import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import {
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Skeleton,
} from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { borderColor } from "../../theme/colors";

export default function UserProfileImages({ userPayload }: any) {
  console.log(userPayload);
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Container maxWidth="md" sx={{ mt: 0, mb: 4 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {!userPayload.profile_details ? (
              <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
            ) : (
              <Card
                sx={{ mb: 0, p: 2, borderColor: borderColor.logo }}
                variant="outlined"
              >
                <CardContent>
                  <Carousel>
                    {userPayload.profile_details.all_image_urls.map(
                      (image: string, index: number) => (
                        <div key={index}>
                          <img src={image} />
                        </div>
                      )
                    )}
                  </Carousel>
                </CardContent>
                <CardActions></CardActions>
              </Card>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
