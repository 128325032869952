import Typography from "@mui/material/Typography";
import {
  Box,
  CardContent,
  CardActions,
  AvatarGroup,
  Avatar,
  IconButton,
  Alert,
  Pagination,
  Stack,
  CardActionArea,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardSkeleton from "../../../Skeletons/CardSkeleton";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../hooks/useTypedSelector";
import { getClassroomsPaginated } from "../../../../features/classrooms/getClassroomsPaginatedSlice";
import { useState } from "react";
import ShareIcon from "@mui/icons-material/Share";
import Snackbar from "@mui/material/Snackbar";
import { IClassroomPaginated } from "../../../../types/classroomTypes";
import { backgroundColor, borderColor } from "../../../theme/colors";

var url = process.env.REACT_APP_URL;

interface GroupListUnfilteredProps {
  classroomsPaginated: IClassroomPaginated;
}

export const GroupListUnfiltered: React.FC<GroupListUnfilteredProps> = ({
  classroomsPaginated,
}) => {
  const [open, setOpen] = useState(false);
  const handleShare = (uuid: string) => {
    navigator.clipboard.writeText(url + "/groups/" + uuid);
    setOpen(true);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(1);

  const handlePaginationChange = (e: any, pageNumber: number) => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      getClassroomsPaginated({
        access_token: access_token,
        page: pageNumber,
      })
    );
    setPage(pageNumber);
  };

  return (
    <div>
      {classroomsPaginated.results &&
        classroomsPaginated.results.map((classroom, index) => (
          <Box sx={{ mb: 2 }} key={index}>
            <CardActionArea href={url + "/groups/" + classroom.uuid}>
              <Card
                sx={{
                  borderColor: borderColor.logo,
                  width: "100%",
                }}
                variant="outlined"
              >
                {" "}
                <CardContent>
                  <Box sx={{ m: 1 }}>
                    <Typography gutterBottom variant="h5" component="span">
                      {classroom.title}
                    </Typography>
                  </Box>
                  <Box sx={{ m: 1 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component="span"
                    >
                      {classroom.description}
                    </Typography>
                  </Box>
                  {/*<Box sx={{ mt: 2 }}>
                    <Divider />
                  </Box>*/}
                </CardContent>
                <CardActions>
                  <Box sx={{ ml: 1 }}>
                    <Snackbar
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="success"
                        sx={{ width: "100%" }}
                      >
                        Copied Link to Clipboard!
                      </Alert>
                    </Snackbar>
                  </Box>
                  <Box sx={{ flexGrow: 1, mr: 1 }}>
                    <AvatarGroup
                      max={4}
                      sx={{
                        "& .MuiAvatar-root": {
                          width: 24,
                          height: 24,
                          fontSize: "0.8rem",
                        },
                        "& .MuiAvatarGroup-avatar": {
                          width: 30,
                          height: 30,
                          fontSize: "0.8rem",
                          "&:last-child": {
                            fontSize: "0.5rem", // Adjusts the "+" size
                            border: "1px solid black", // Add a black circle around the last avatar
                          },
                        },
                      }}
                    >
                      {classroom.subscribers_details.map(
                        (subscriber: any, index) => (
                          <Avatar
                            key={index}
                            alt={subscriber.username}
                            src={subscriber.profile_image}
                            style={{
                              borderRadius: "50%",
                              border: "1px solid black",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "40px",
                              height: "40px",
                            }}
                          />
                        )
                      )}
                    </AvatarGroup>
                  </Box>
                </CardActions>
              </Card>
            </CardActionArea>
          </Box>
        ))}
      <Stack alignItems="center">
        <Pagination
          count={
            classroomsPaginated ? Math.round(classroomsPaginated.count / 5) : 0
          }
          siblingCount={5}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={(event, pageNumber) =>
            handlePaginationChange(event, pageNumber)
          }
        />
      </Stack>
    </div>
  );
};
