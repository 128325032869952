// TODO:
// 1. Turn into accordion
// 2. Add a button to add a new classroom

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "../Header/AppBar";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { useAppSelector } from "../../hooks/useTypedSelector";
import Many from "./GroupsMany/Many";
import AdjustTimeZone from "../AdjustTimeZone/AdjustTimeZone";
import theme from "../theme/theme";

function Groups() {
  const { currentUser } = useContext(AuthContext);
  const { getUserProfilePayload } = useAppSelector(
    (state) => state.getUserProfile
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar currentUser={currentUser} userProfile={getUserProfilePayload} />
      <Many />
      {getUserProfilePayload.timezone ? (
        <AdjustTimeZone timezone={getUserProfilePayload.timezone} />
      ) : null}
    </ThemeProvider>
  );
}

export default Groups;
