import React, { useState } from "react";
import { grammar_colors } from "./grammar";
import { Sentence, Word } from "../../../../types/linguisticsTypes";
import Legend from "./Legend";

const getColor = (pos: keyof typeof grammar_colors | string): string => {
  return grammar_colors[pos as keyof typeof grammar_colors] || "#e0e0e0"; // Using a light gray as the fallback color
};

interface WordProps {
  word: Word;
  sentence: string;
  native_languages: string[];
  index: number;
}

const GrammarView: React.FC<WordProps> = ({ word, sentence, index }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleToggle = (): void => {
    setIsHovered(!isHovered);
  };

  return (
    <span
      onClick={handleToggle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        cursor: "pointer",
        position: "relative",
        display: "inline-block",
      }}
    >
      <span
        style={{
          backgroundColor: getColor(word.parts_of_speech[0]),
          padding: "0px 0px",
          textDecoration: isHovered ? "underline" : "none",
        }}
      >
        {word.text}
      </span>

      {isHovered && (
        <span
          style={{
            position: "absolute",
            border: "1px solid black",
            padding: "5px",
            backgroundColor: "white",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            top: "100%",
            left: "0",
            marginTop: "5px",
            zIndex: 1000,
            display: "inline-block",
            whiteSpace: "nowrap",
          }}
        >
          Information about the word:
          <span>ID: {word.text}</span>
        </span>
      )}
    </span>
  );
};

export default GrammarView;
