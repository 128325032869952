import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IErrors, IMessage } from "../../types/MessageTypes";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getMessages = createAsyncThunk(
  "user/getUsers",
  async (data: { access_token: string }, thunkApi) => {
    try {
      const response = await axios.get<IMessage[]>(`${url}/messages/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        //data: data.subscriberIds
      });
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const messagesSlice = createSlice({
  name: "getMessages",
  initialState: {
    getMessagesLoading: false,
    getMessagesErrors: {} as IErrors,
    getMessagesPayload: [] as IMessage[],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getMessages.pending, (state, action) => {
        state.getMessagesLoading = true;
      })
      .addCase(getMessages.fulfilled, (state, action: PayloadAction<any>) => {
        state.getMessagesLoading = false;
        state.getMessagesPayload = action.payload;
      })
      .addCase(getMessages.rejected, (state, action: PayloadAction<any>) => {
        state.getMessagesLoading = false;
        state.getMessagesErrors = action.payload;
      });
  },
});

export default messagesSlice.reducer;
