// TODO:
// - return error if image is not uploaded successfully

import React, { useState, useRef } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Button,
} from "@mui/material";
import { useAppDispatch } from "../../../../hooks/useTypedSelector";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { postAvatarChange } from "../../../../features/users/postAvatarChangeSlice";

const url = process.env.REACT_APP_URL;
const api_url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

//import { v4 as uuidv4 } from "uuid";
//import { validate as isValidUUID } from "uuid";

//import styled from "styled-components";

// Create a function to dynamically import images

interface Props {
  profile: any;
  errors: any;
  state: any;
  setState: any;
  setSaveButtonDisabled: any;
}

const AVATAR_STYLES = {
  Eyes_kids: [
    "/media/images/character/Eyes_kids/32x32/Eyes_kids_32x32_2.png",
    "/media/images/character/Eyes_kids/32x32/Eyes_kids_32x32_3.png",
    "/media/images/character/Eyes_kids/32x32/Eyes_kids_32x32_1.png",
    "/media/images/character/Eyes_kids/32x32/Eyes_kids_32x32_4.png",
    "/media/images/character/Eyes_kids/32x32/Eyes_kids_32x32_5.png",
    "/media/images/character/Eyes_kids/32x32/Eyes_kids_32x32_6.png",
  ],
  Bodies: [
    "/media/images/character/Bodies/32x32/Body_32x32_08.png",
    "/media/images/character/Bodies/32x32/Body_32x32_09.png",
    "/media/images/character/Bodies/32x32/Body_32x32_04.png",
    "/media/images/character/Bodies/32x32/Body_32x32_05.png",
    "/media/images/character/Bodies/32x32/Body_32x32_07.png",
    "/media/images/character/Bodies/32x32/Body_32x32_06.png",
    "/media/images/character/Bodies/32x32/Body_32x32_02.png",
    "/media/images/character/Bodies/32x32/Body_32x32_03.png",
    "/media/images/character/Bodies/32x32/Body_32x32_01.png",
  ],
  Accessories: [
    "/media/images/character/Accessories/32x32/Accessory_05_Dino_Snapback_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_11_Beanie_32x32_04.png",
    "/media/images/character/Accessories/32x32/Accessory_12_Mustache_32x32_04.png",
    "/media/images/character/Accessories/32x32/Accessory_15_Glasses_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_15_Glasses_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_12_Mustache_32x32_05.png",
    "/media/images/character/Accessories/32x32/Accessory_11_Beanie_32x32_05.png",
    "/media/images/character/Accessories/32x32/Accessory_05_Dino_Snapback_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_05_Dino_Snapback_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_15_Glasses_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_12_Mustache_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_11_Beanie_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_15_Glasses_32x32_05.png",
    "/media/images/character/Accessories/32x32/Accessory_16_Monocle_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_16_Monocle_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_15_Glasses_32x32_04.png",
    "/media/images/character/Accessories/32x32/Accessory_11_Beanie_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_09_Zombie_Brain_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_12_Mustache_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_09_Zombie_Brain_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_11_Beanie_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_12_Mustache_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_03_Backpack_32x32_09.png",
    "/media/images/character/Accessories/32x32/Accessory_15_Glasses_32x32_06.png",
    "/media/images/character/Accessories/32x32/Accessory_16_Monocle_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_03_Backpack_32x32_08.png",
    "/media/images/character/Accessories/32x32/Accessory_09_Zombie_Brain_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_13_Beard_32x32_05.png",
    "/media/images/character/Accessories/32x32/Accessory_08_Detective_Hat_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_14_Gloves_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_14_Gloves_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_08_Detective_Hat_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_13_Beard_32x32_04.png",
    "/media/images/character/Accessories/32x32/Accessory_08_Detective_Hat_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_14_Gloves_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_18_Chef_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_13_Beard_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_14_Gloves_32x32_04.png",
    "/media/images/character/Accessories/32x32/Accessory_13_Beard_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_18_Chef_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_18_Chef_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_13_Beard_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_02_Bee_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_04_Snapback_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_17_Medical_Mask_32x32_04.png",
    "/media/images/character/Accessories/32x32/Accessory_17_Medical_Mask_32x32_05.png",
    "/media/images/character/Accessories/32x32/Accessory_04_Snapback_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_02_Bee_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_04_Snapback_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_02_Bee_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_04_Snapback_32x32_04.png",
    "/media/images/character/Accessories/32x32/Accessory_17_Medical_Mask_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_17_Medical_Mask_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_04_Snapback_32x32_05.png",
    "/media/images/character/Accessories/32x32/Accessory_17_Medical_Mask_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_04_Snapback_32x32_06.png",
    "/media/images/character/Accessories/32x32/Accessory_07_Bataclava_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_03_Backpack_32x32_05.png",
    "/media/images/character/Accessories/32x32/Accessory_01_Ladybug_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_06_Policeman_Hat_32x32_05.png",
    "/media/images/character/Accessories/32x32/Accessory_06_Policeman_Hat_32x32_04.png",
    "/media/images/character/Accessories/32x32/Accessory_01_Ladybug_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_03_Backpack_32x32_04.png",
    "/media/images/character/Accessories/32x32/Accessory_03_Backpack_32x32_10.png",
    "/media/images/character/Accessories/32x32/Accessory_19_Party_Cone_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_19_Party_Cone_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_07_Bataclava_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_03_Backpack_32x32_06.png",
    "/media/images/character/Accessories/32x32/Accessory_01_Ladybug_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_06_Policeman_Hat_32x32_06.png",
    "/media/images/character/Accessories/32x32/Accessory_03_Backpack_32x32_07.png",
    "/media/images/character/Accessories/32x32/Accessory_07_Bataclava_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_19_Party_Cone_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_03_Backpack_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_10_Bolt_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_06_Policeman_Hat_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_01_Ladybug_32x32_04.png",
    "/media/images/character/Accessories/32x32/Accessory_06_Policeman_Hat_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_03_Backpack_32x32_02.png",
    "/media/images/character/Accessories/32x32/Accessory_10_Bolt_32x32_03.png",
    "/media/images/character/Accessories/32x32/Accessory_10_Bolt_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_06_Policeman_Hat_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_03_Backpack_32x32_01.png",
    "/media/images/character/Accessories/32x32/Accessory_19_Party_Cone_32x32_04.png",
  ],
  Bodies_kids: [
    "/media/images/character/Bodies_kids/32x32/Body_1_kid_32x32.png",
    "/media/images/character/Bodies_kids/32x32/Body_2_kid_32x32.png",
    "/media/images/character/Bodies_kids/32x32/Body_3_kid_32x32.png",
    "/media/images/character/Bodies_kids/32x32/Body_4_kid_32x32.png",
  ],
  Outfits_kids: [
    "/media/images/character/Outfits_kids/32x32/Outfit_kid_2_32x32.png",
    "/media/images/character/Outfits_kids/32x32/Outfit_kid_4_32x32.png",
    "/media/images/character/Outfits_kids/32x32/Outfit_kid_3_32x32.png",
    "/media/images/character/Outfits_kids/32x32/Outfit_kid_5_32x32.png",
    "/media/images/character/Outfits_kids/32x32/Outfit_kid_7_pajama_tiger_32x32.png",
    "/media/images/character/Outfits_kids/32x32/Outfit_kid_6_pajama_frog_32x32.png",
    "/media/images/character/Outfits_kids/32x32/Outfit_kid_1_32x32.png",
  ],
  Books: [
    "/media/images/character/Books/32x32/Book_32x32_01.png",
    "/media/images/character/Books/32x32/Book_32x32_03.png",
    "/media/images/character/Books/32x32/Book_32x32_02.png",
    "/media/images/character/Books/32x32/Book_32x32_06.png",
    "/media/images/character/Books/32x32/Book_32x32_05.png",
    "/media/images/character/Books/32x32/Book_32x32_04.png",
  ],
  Outfits: [
    "/media/images/character/Outfits/32x32/Outfit_08_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_08_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_08_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_09_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_09_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_09_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_03_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_04_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_10_32x32_05.png",
    "/media/images/character/Outfits/32x32/Outfit_02_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_16_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_11_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_05_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_32_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_11_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_05_32x32_05.png",
    "/media/images/character/Outfits/32x32/Outfit_16_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_27_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_04_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_10_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_20_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_03_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_20_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_27_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_16_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_21_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_11_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_32_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_32_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_27_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_04_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_20_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_03_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_17_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_10_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_02_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_21_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_11_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_05_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_26_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_05_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_02_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_33_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_10_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_17_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_03_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_33_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_02_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_21_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_05_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_26_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_32_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_01_32x32_09.png",
    "/media/images/character/Outfits/32x32/Outfit_01_32x32_08.png",
    "/media/images/character/Outfits/32x32/Outfit_26_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_32_32x32_05.png",
    "/media/images/character/Outfits/32x32/Outfit_21_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_33_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_10_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_17_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_24_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_07_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_13_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_23_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_14_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_12_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_01_32x32_05.png",
    "/media/images/character/Outfits/32x32/Outfit_15_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_01_32x32_10.png",
    "/media/images/character/Outfits/32x32/Outfit_01_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_15_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_12_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_06_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_31_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_14_32x32_05.png",
    "/media/images/character/Outfits/32x32/Outfit_07_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_24_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_07_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_23_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_25_32x32_05.png",
    "/media/images/character/Outfits/32x32/Outfit_31_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_01_32x32_06.png",
    "/media/images/character/Outfits/32x32/Outfit_15_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_01_32x32_07.png",
    "/media/images/character/Outfits/32x32/Outfit_22_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_12_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_25_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_31_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_23_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_24_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_30_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_07_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_13_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_14_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_06_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_01_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_01_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_22_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_06_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_25_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_14_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_13_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_30_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_24_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_13_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_23_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_14_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_25_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_31_32x32_05.png",
    "/media/images/character/Outfits/32x32/Outfit_22_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_01_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_22_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_06_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_25_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_31_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_30_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_28_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_29_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_19_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_28_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_18_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_28_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_19_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_19_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_18_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_28_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_29_32x32_03.png",
    "/media/images/character/Outfits/32x32/Outfit_29_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_18_32x32_02.png",
    "/media/images/character/Outfits/32x32/Outfit_29_32x32_01.png",
    "/media/images/character/Outfits/32x32/Outfit_19_32x32_04.png",
    "/media/images/character/Outfits/32x32/Outfit_18_32x32_03.png",
  ],
  Hairstyles: [
    "/media/images/character/Hairstyles/32x32/Hairstyle_24_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_13_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_07_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_23_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_14_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_25_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_06_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_12_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_22_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_15_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_01_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_15_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_01_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_22_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_06_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_12_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_25_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_14_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_13_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_07_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_24_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_07_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_13_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_23_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_14_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_25_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_06_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_22_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_01_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_01_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_15_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_22_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_12_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_06_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_25_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_14_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_23_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_13_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_24_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_24_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_13_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_07_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_23_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_14_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_06_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_12_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_15_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_01_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_15_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_01_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_22_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_06_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_12_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_25_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_14_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_23_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_13_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_07_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_24_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_24_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_07_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_13_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_23_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_14_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_25_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_12_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_22_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_15_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_01_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_15_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_22_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_12_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_06_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_25_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_23_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_07_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_24_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_18_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_28_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_29_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_29_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_19_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_28_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_18_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_18_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_28_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_19_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_29_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_19_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_18_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_18_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_28_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_19_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_29_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_29_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_19_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_28_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_18_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_19_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_29_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_19_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_28_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_18_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_09_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_09_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_08_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_08_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_09_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_09_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_08_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_08_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_09_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_08_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_08_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_09_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_09_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_08_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_03_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_17_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_10_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_04_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_16_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_02_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_21_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_05_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_11_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_26_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_26_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_05_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_11_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_21_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_16_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_02_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_27_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_10_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_04_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_20_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_03_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_17_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_17_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_20_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_10_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_27_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_02_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_16_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_21_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_11_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_05_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_26_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_26_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_05_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_21_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_02_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_27_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_04_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_10_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_20_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_17_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_03_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_03_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_17_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_20_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_10_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_04_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_27_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_16_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_02_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_21_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_05_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_11_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_26_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_05_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_11_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_16_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_02_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_10_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_04_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_20_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_03_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_17_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_03_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_20_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_04_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_27_32x32_05.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_02_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_16_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_21_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_11_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_05_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_26_32x32_02.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_26_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_11_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_21_32x32_03.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_16_32x32_06.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_27_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_04_32x32_07.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_10_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_20_32x32_04.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_17_32x32_01.png",
    "/media/images/character/Hairstyles/32x32/Hairstyle_03_32x32_07.png",
  ],
  Smartphones: [
    "/media/images/character/Smartphones/32x32/Smartphone_32x32_1.png",
    "/media/images/character/Smartphones/32x32/Smartphone_32x32_2.png",
    "/media/images/character/Smartphones/32x32/Smartphone_32x32_3.png",
    "/media/images/character/Smartphones/32x32/Smartphone_32x32_4.png",
    "/media/images/character/Smartphones/32x32/Smartphone_32x32_5.png",
  ],
  "0_Premade_/media/images/characters": [
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_19.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_18.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_08.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_20.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_09.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_10.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_04.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_05.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_11.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_07.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_13.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_12.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_06.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_02.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_16.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_17.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_03.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_15.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_01.png",
    "/media/images/character/0_Premade_/media/images/characters/32x32/Premade_/media/images/character_32x32_14.png",
  ],
  Eyes: [
    "/media/images/character/Eyes/32x32/Eyes_32x32_03.png",
    "/media/images/character/Eyes/32x32/Eyes_32x32_02.png",
    "/media/images/character/Eyes/32x32/Eyes_32x32_01.png",
    "/media/images/character/Eyes/32x32/Eyes_32x32_05.png",
    "/media/images/character/Eyes/32x32/Eyes_32x32_04.png",
    "/media/images/character/Eyes/32x32/Eyes_32x32_06.png",
    "/media/images/character/Eyes/32x32/Eyes_32x32_07.png",
  ],
  Hairstyles_kids: [
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_2_32x32_5.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_2_32x32_4.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_2_32x32_3.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_2_32x32_2.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_2_32x32_1.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_4_32x32_4.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_1_32x32_1.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_4_32x32_5.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_1_32x32_2.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_1_32x32_3.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_4_32x32_2.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_4_32x32_3.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_4_32x32_1.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_1_32x32_4.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_1_32x32_5.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_3_32x32_1.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_6_32x32_4.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_6_32x32_5.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_3_32x32_2.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_3_32x32_3.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_6_32x32_2.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_6_32x32_3.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_3_32x32_4.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_6_32x32_1.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_3_32x32_5.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_5_32x32_1.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_5_32x32_3.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_5_32x32_2.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_5_32x32_5.png",
    "/media/images/character/Hairstyles_kids/32x32/Hairstyle_kid_5_32x32_4.png",
  ],
};

const CANVAS_HIDDEN = {
  width: 1854,
  height: 1312,
};

const CANVAS_COMPLETE_HIDDEN = {
  width: 1664,
  height: 48,
};

const CANVAS = {
  width: 300,
  height: 150,
};

const POSITIONS = {
  IDLE_RIGHT: {
    sx: 0,
    sy: 74,
    sWidth: 192,
    sHeight: 54,
    dx: 0,
    dy: 0,
    dWidth: 192,
    dHeight: 48,
  },
  IDLE_UP: {
    sx: 192,
    sy: 74,
    sWidth: 192,
    sHeight: 54,
    dx: 192,
    dy: 0,
    dWidth: 192,
    dHeight: 48,
  },
  IDLE_LEFT: {
    sx: 384,
    sy: 74,
    sWidth: 192,
    sHeight: 54,
    dx: 384,
    dy: 0,
    dWidth: 192,
    dHeight: 48,
  },
  IDLE_DOWN: {
    sx: 576,
    sy: 74,
    sWidth: 192,
    sHeight: 54,
    dx: 576,
    dy: 0,
    dWidth: 192,
    dHeight: 48,
  },
  RUN_RIGHT: {
    sx: 0,
    sy: 138,
    sWidth: 192,
    sHeight: 54,
    dx: 768,
    dy: 0,
    dWidth: 192,
    dHeight: 48,
  },
  RUN_UP: {
    sx: 192,
    sy: 138,
    sWidth: 192,
    sHeight: 54,
    dx: 960,
    dy: 0,
    dWidth: 192,
    dHeight: 48,
  },
  RUN_LEFT: {
    sx: 384,
    sy: 138,
    sWidth: 192,
    sHeight: 54,
    dx: 1152,
    dy: 0,
    dWidth: 192,
    dHeight: 48,
  },
  RUN_DOWN: {
    sx: 576,
    sy: 138,
    sWidth: 192,
    sHeight: 54,
    dx: 1344,
    dy: 0,
    dWidth: 192,
    dHeight: 48,
  },
  SIT_RIGHT: {
    sx: 0,
    sy: 268,
    sWidth: 32,
    sHeight: 54,
    dx: 1600,
    dy: 0,
    dWidth: 32,
    dHeight: 48,
  },
  SIT_LEFT: {
    sx: 192,
    sy: 268,
    sWidth: 32,
    sHeight: 54,
    dx: 1568,
    dy: 0,
    dWidth: 32,
    dHeight: 48,
  },
  SIT_UP: {
    sx: 192,
    sy: 524,
    sWidth: 32,
    sHeight: 54,
    dx: 1632,
    dy: 0,
    dWidth: 32,
    dHeight: 48,
  },
  SIT_DOWN: {
    sx: 576,
    sy: 524,
    sWidth: 32,
    sHeight: 54,
    dx: 1536,
    dy: 0,
    dWidth: 32,
    dHeight: 48,
  },
} as any;

const avatars = [
  { name: "adam", img: "Adam" },
  // { name: 'ash', img: 'Ash' },
  // { name: 'lucy', img: "Lucy" },
  // { name: 'nancy', img: "Nancy" },
];

// shuffle the avatars array
for (let i = avatars.length - 1; i > 0; i--) {
  const j = Math.floor(Math.random() * (i + 1));
  [avatars[i], avatars[j]] = [avatars[j], avatars[i]];
}

interface IAvatarIndex {
  Bodies: number;
  Accessories: number;
  Bodies_kids: number;
  Outfits_kids: number;
  Books: number;
  Outfits: number;
  Hairstyles: number;
  Smartphones: number;
  Eyes: number;
  Hairstyles_kids: number;
  Eyes_kids: number;
}

// shuffle the avatars array
for (let i = avatars.length - 1; i > 0; i--) {
  const j = Math.floor(Math.random() * (i + 1));
  [avatars[i], avatars[j]] = [avatars[j], avatars[i]];
}

export default function CharacterCreation({
  state,
  setState,
  setSaveButtonDisabled,
}: Props) {
  const handleDateChange = (newValue: {} | null) => {
    setState({
      ...state,
      avatar_data: avatarStyleIndex,
    });
  };

  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  // all
  const canvasHiddenRef = React.useRef<HTMLCanvasElement>(null);
  // complete
  const canvasCompleteHiddenRef = React.useRef<HTMLCanvasElement>(null);

  const [avatarStyleIndex, setAvatarStyleIndex] = useState<IAvatarIndex>(
    state.avatar_data
  );

  const dispatch = useAppDispatch();

  // draw avatar
  function drawAvatar() {
    if (canvasRef.current) {
      // draw visible canvas
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      // clear whats on the canvas
      context?.clearRect(0, 0, canvas.width, canvas.height);
      if (context) {
        const bodyImg = new Image();
        bodyImg.src = url + AVATAR_STYLES["Bodies"][avatarStyleIndex.Bodies];
        bodyImg.onload = function () {
          context.drawImage(
            bodyImg,
            0,
            0,
            bodyImg.width * 2.25,
            bodyImg.height * 2.25
          );
          // draw the head on top of the body
          const hairImg = new Image();
          hairImg.src =
            url + AVATAR_STYLES["Hairstyles"][avatarStyleIndex.Hairstyles];
          hairImg.onload = function () {
            context.drawImage(
              hairImg,
              0,
              0,
              hairImg.width * 2.25,
              hairImg.height * 2.25
            );
            // draw the eyes on top of the head
            const eyesImg = new Image();
            eyesImg.src = url + AVATAR_STYLES["Eyes"][avatarStyleIndex.Eyes];
            eyesImg.onload = function () {
              context.drawImage(
                eyesImg,
                0,
                0,
                eyesImg.width * 2.25,
                eyesImg.height * 2.25
              );
              // draw the accessories on top of the eyes
              const accessoriesImg = new Image();
              accessoriesImg.src =
                url +
                AVATAR_STYLES["Accessories"][avatarStyleIndex.Accessories];
              accessoriesImg.onload = function () {
                context.drawImage(
                  accessoriesImg,
                  0,
                  0,
                  accessoriesImg.width * 2.25,
                  accessoriesImg.height * 2.25
                );
                // draw the outfits on top of the accessories
                const outfitsImg = new Image();
                outfitsImg.src =
                  url + AVATAR_STYLES["Outfits"][avatarStyleIndex.Outfits];
                outfitsImg.onload = function () {
                  context.drawImage(
                    outfitsImg,
                    0,
                    0,
                    outfitsImg.width * 2.25,
                    outfitsImg.height * 2.25
                  );
                };
              };
            };
          };
        };
      }
    }
    if (canvasCompleteHiddenRef.current) {
      const canvas = canvasCompleteHiddenRef.current;
      const context = canvas.getContext("2d");
      // clear whats on the canvas
      context?.clearRect(0, 0, canvas.width, canvas.height);
      if (context) {
        [
          "IDLE_DOWN",
          "IDLE_UP",
          "IDLE_LEFT",
          "IDLE_RIGHT",
          "RUN_DOWN",
          "RUN_UP",
          "RUN_LEFT",
          "RUN_RIGHT",
          "SIT_RIGHT",
          "SIT_LEFT",
          "SIT_DOWN",
          "SIT_UP",
        ].forEach((position) => {
          const bodyImg = new Image();
          bodyImg.src = AVATAR_STYLES["Bodies"][avatarStyleIndex.Bodies];
          bodyImg.onload = function () {
            context.drawImage(
              bodyImg,
              POSITIONS[position].sx,
              POSITIONS[position].sy,
              POSITIONS[position].sWidth,
              POSITIONS[position].sHeight,
              POSITIONS[position].dx,
              POSITIONS[position].dy,
              POSITIONS[position].dWidth,
              POSITIONS[position].dHeight
            );
            // draw the head on top of the body
            const headImg = new Image();
            headImg.src =
              AVATAR_STYLES["Hairstyles"][avatarStyleIndex.Hairstyles];
            headImg.onload = function () {
              context.drawImage(
                headImg,
                POSITIONS[position].sx,
                POSITIONS[position].sy,
                POSITIONS[position].sWidth,
                POSITIONS[position].sHeight,
                POSITIONS[position].dx,
                POSITIONS[position].dy,
                POSITIONS[position].dWidth,
                POSITIONS[position].dHeight
              );
              // draw the eyes on top of the head
              const eyesImg = new Image();
              eyesImg.src = AVATAR_STYLES["Eyes"][avatarStyleIndex.Eyes];
              eyesImg.onload = function () {
                context.drawImage(
                  eyesImg,
                  POSITIONS[position].sx,
                  POSITIONS[position].sy,
                  POSITIONS[position].sWidth,
                  POSITIONS[position].sHeight,
                  POSITIONS[position].dx,
                  POSITIONS[position].dy,
                  POSITIONS[position].dWidth,
                  POSITIONS[position].dHeight
                );
                // draw the accessories on top of the eyes
                const accessoriesImg = new Image();
                accessoriesImg.src =
                  AVATAR_STYLES["Accessories"][avatarStyleIndex.Accessories];
                accessoriesImg.onload = function () {
                  context.drawImage(
                    accessoriesImg,
                    POSITIONS[position].sx,
                    POSITIONS[position].sy,
                    POSITIONS[position].sWidth,
                    POSITIONS[position].sHeight,
                    POSITIONS[position].dx,
                    POSITIONS[position].dy,
                    POSITIONS[position].dWidth,
                    POSITIONS[position].dHeight
                  );
                  // draw the outfits on top of the accessories
                  const outfitsImg = new Image();
                  outfitsImg.src =
                    AVATAR_STYLES["Outfits"][avatarStyleIndex.Outfits];
                  outfitsImg.onload = function () {
                    context.drawImage(
                      outfitsImg,
                      POSITIONS[position].sx,
                      POSITIONS[position].sy,
                      POSITIONS[position].sWidth,
                      POSITIONS[position].sHeight,
                      POSITIONS[position].dx,
                      POSITIONS[position].dy,
                      POSITIONS[position].dWidth,
                      POSITIONS[position].dHeight
                    );
                  };
                };
              };
            };
          };
        });
      }
    }
  }

  // backdrop
  const handleClose = () => {
    setIsLoading(false);
  };
  const [isLoading, setIsLoading] = useState<boolean>(false);

  React.useEffect(() => {
    // set user exists to false if avatar style index changes
    const initialValue = state.avatar_data;
    if (
      initialValue["Bodies"] == avatarStyleIndex["Bodies"] &&
      initialValue["Accessories"] == avatarStyleIndex["Accessories"] &&
      initialValue["Bodies_kids"] == avatarStyleIndex["Bodies_kids"] &&
      initialValue["Outfits_kids"] == avatarStyleIndex["Outfits_kids"] &&
      initialValue["Books"] == avatarStyleIndex["Books"] &&
      initialValue["Outfits"] == avatarStyleIndex["Outfits"] &&
      initialValue["Hairstyles"] == avatarStyleIndex["Hairstyles"] &&
      initialValue["Smartphones"] == avatarStyleIndex["Smartphones"] &&
      initialValue["Eyes"] == avatarStyleIndex["Eyes"] &&
      initialValue["Hairstyles_kids"] == avatarStyleIndex["Hairstyles_kids"] &&
      initialValue["Eyes_kids"] == avatarStyleIndex["Eyes_kids"]
    ) {
      console.log("avatarStyleIndex has not changed");
    } else {
      console.log("avatarStyleIndex has changed");
      setSaveButtonDisabled(false);
      setState({
        ...state,
        avatar_data: avatarStyleIndex,
      });
    }
    handleDateChange(avatarStyleIndex);
  }, [avatarStyleIndex]);

  React.useEffect(() => {
    drawAvatar();
  }, [avatarStyleIndex]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;

    // Dispatch the action to post avatar changes
    dispatch(
      postAvatarChange({
        access_token: access_token,
        avatar_data: avatarStyleIndex,
      })
    ).then(() => {
      setState({
        ...state,
        avatar_data: avatarStyleIndex,
      });

      //window.location.reload();
    });

    // // UPLOAD IMAGE, NOT SURE IF THIS IS NEEDED

    // // upload avatar, complete (which is the avatar put in a row of sprites)
    // const canvasComplete = canvasCompleteHiddenRef.current;
    // const context = canvasComplete?.getContext("2d");
    // if (context) {
    //   const formData = new FormData();
    //   // submit to django
    //   // all
    //   // if (canvas) {
    //   // formData.append("image", canvas?.toDataURL("image/png"))
    //   // }
    //   // complete
    //   if (canvasComplete) {
    //     formData.append("complete", canvasComplete?.toDataURL("image/png"));
    //   }
    //   // complete
    //   // set is loading to true after canvas is drawn
    //   setIsLoading(true);
    //   if (!userAlreadyExists) {
    //     formData.append(
    //       "title",
    //       avatarStyleIndex.Bodies.toString() +
    //         "_" +
    //         avatarStyleIndex.Accessories.toString() +
    //         "_" +
    //         avatarStyleIndex.Outfits.toString() +
    //         "_" +
    //         avatarStyleIndex.Hairstyles.toString() +
    //         "_" +
    //         avatarStyleIndex.Eyes.toString()
    //     );
    //     fetch(`${api_url}/avatarupload/`, {
    //       method: "POST",
    //       body: formData,
    //     })
    //       .then((response) => {
    //         if (response.status === 200) {
    //           //dispatch(setAvatar(res.data));
    //           setIsLoading(false);
    //           return response.json();
    //         } else {
    //           setIsLoading(false);
    //           throw new Error("Failed to upload image");
    //         }
    //       })
    //       .then((data) => {
    //         setIsLoading(false);
    //         // return error
    //       });
    //   }
    // }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 2,
        }}
      >
        <canvas ref={canvasRef} height={CANVAS.height} width={CANVAS.width} />
      </div>
      <canvas
        hidden={true}
        ref={canvasCompleteHiddenRef}
        height={CANVAS_COMPLETE_HIDDEN.height}
        width={CANVAS_COMPLETE_HIDDEN.width}
      />

      <Stack
        direction="row"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
        }}
      >
        <IconButton
          onClick={() => {
            if (avatarStyleIndex.Hairstyles > 0) {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Hairstyles: avatarStyleIndex.Hairstyles - 1,
              });
            } else {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Hairstyles: AVATAR_STYLES.Hairstyles.length - 1,
              });
            }
          }}
        >
          <ArrowLeftIcon fontSize="large" />{" "}
        </IconButton>
        <Box
          style={{
            fontSize: "14px",
            textTransform: "uppercase",
            width: "150px",
            textAlign: "center",
          }}
        >
          Hair
        </Box>
        <IconButton
          onClick={() => {
            if (
              avatarStyleIndex.Hairstyles <
              AVATAR_STYLES.Hairstyles.length - 1
            ) {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Hairstyles: avatarStyleIndex.Hairstyles + 1,
              });
            } else {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Hairstyles: 0,
              });
            }
          }}
        >
          <ArrowRightIcon fontSize="large" />{" "}
        </IconButton>
      </Stack>
      <Stack
        direction="row"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          mt: 0,
        }}
      >
        <IconButton
          onClick={() => {
            if (avatarStyleIndex.Accessories > 0) {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Accessories: avatarStyleIndex.Accessories - 1,
              });
            } else {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Accessories: AVATAR_STYLES.Accessories.length - 1,
              });
            }
          }}
        >
          <ArrowLeftIcon fontSize="large" />{" "}
        </IconButton>
        <Box
          style={{
            fontSize: "14px",

            textTransform: "uppercase",
            width: "150px",
            textAlign: "center",
          }}
        >
          Accessories
        </Box>
        <IconButton
          onClick={() => {
            if (
              avatarStyleIndex.Accessories <
              AVATAR_STYLES.Accessories.length - 1
            ) {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Accessories: avatarStyleIndex.Accessories + 1,
              });
            } else {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Accessories: 0,
              });
            }
          }}
        >
          <ArrowRightIcon fontSize="large" />{" "}
        </IconButton>
      </Stack>
      <Stack
        direction="row"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          mt: 0,
        }}
      >
        <IconButton
          onClick={() => {
            if (avatarStyleIndex.Eyes > 0) {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Eyes: avatarStyleIndex.Eyes - 1,
              });
            } else {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Eyes: AVATAR_STYLES.Eyes.length - 1,
              });
            }
          }}
        >
          <ArrowLeftIcon fontSize="large" />{" "}
        </IconButton>
        <Box
          style={{
            fontSize: "14px",

            textTransform: "uppercase",
            width: "150px",
            textAlign: "center",
          }}
        >
          Eyes
        </Box>
        <IconButton
          onClick={() => {
            if (avatarStyleIndex.Eyes < AVATAR_STYLES.Eyes.length - 1) {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Eyes: avatarStyleIndex.Eyes + 1,
              });
            } else {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Eyes: 0,
              });
            }
          }}
        >
          <ArrowRightIcon fontSize="large" />{" "}
        </IconButton>
      </Stack>
      <Stack
        direction="row"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          mt: 0,
        }}
      >
        <IconButton
          onClick={() => {
            if (avatarStyleIndex.Bodies > 0) {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Bodies: avatarStyleIndex.Bodies - 1,
              });
            } else {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Bodies: AVATAR_STYLES.Bodies.length - 1,
              });
            }
          }}
        >
          <ArrowLeftIcon fontSize="large" />{" "}
        </IconButton>
        <Box
          style={{
            fontSize: "14px",

            textTransform: "uppercase",
            width: "150px",
            textAlign: "center",
          }}
        >
          Body
        </Box>
        <IconButton
          onClick={() => {
            if (avatarStyleIndex.Bodies < AVATAR_STYLES.Bodies.length - 1) {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Bodies: avatarStyleIndex.Bodies + 1,
              });
            } else {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Bodies: 0,
              });
            }
          }}
        >
          <ArrowRightIcon fontSize="large" />{" "}
        </IconButton>
      </Stack>
      <Stack
        direction="row"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          mt: 0,
          mb: 1,
        }}
      >
        <IconButton
          onClick={() => {
            if (avatarStyleIndex.Outfits > 0) {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Outfits: avatarStyleIndex.Outfits - 1,
              });
            } else {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Outfits: AVATAR_STYLES.Outfits.length - 1,
              });
            }
          }}
        >
          <ArrowLeftIcon fontSize="large" />{" "}
        </IconButton>
        <Box
          style={{
            fontSize: "14px",
            textTransform: "uppercase",
            width: "150px",
            textAlign: "center",
          }}
        >
          Outfit
        </Box>
        <IconButton
          onClick={() => {
            if (avatarStyleIndex.Outfits < AVATAR_STYLES.Outfits.length - 1) {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Outfits: avatarStyleIndex.Outfits + 1,
              });
            } else {
              setAvatarStyleIndex({
                ...avatarStyleIndex,
                Outfits: 0,
              });
            }
          }}
        >
          <ArrowRightIcon fontSize="large" />{" "}
        </IconButton>
      </Stack>
      <form onSubmit={handleSubmit}>
        {/*}
        <Button
          type="submit"
          variant="contained"
          color="success"
          fullWidth
          disabled={isDisabled}
          style={{ marginTop: "16px" }}
        >
          Update Avatar
        </Button>
        */}
        <canvas
          hidden={true}
          ref={canvasHiddenRef}
          height={CANVAS_HIDDEN.height}
          width={CANVAS_HIDDEN.width}
        />
      </form>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
