import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ListIcon from "@mui/icons-material/List";
import StyleIcon from "@mui/icons-material/Style";
import Copyright from "../Copyright/Copyright";
import Box from "@mui/material/Box";
import OpenAI from "openai";
import category_data from "./categories.json";
import UserCardSkeleton from "../Skeletons/UserCardSkeleton";
import {
  Autocomplete,
  Button,
  Chip,
  Container,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { postText } from "../../features/linguistics/postTextSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/useTypedSelector";
import { useNavigate } from "react-router-dom";
import { BodyOfText, BodyOfTextPaginated } from "../../types/linguisticsTypes";
import { listBodiesOfTextPaginated } from "../../features/linguistics/listBodiesOfTextPaginatedSlice";
import { getLanguagesAndCountries } from "../../features/languages/getLanguagesSlice";
import { TextCards } from "./TextCards/TextCards";
import CommunitySkeleton from "../Skeletons/CommunitySkeleton";
import { listVocabulary } from "../../features/linguistics/listVocabularySlice";
import { getUserProfile } from "../../features/users/getUserProfileSlice";
import AuthContext from "../../context/AuthContext";
import StoreIcon from "@mui/icons-material/Store";
const categoryAreas = category_data.categoryAreas;

function getTodaysDate(): string {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1 and pad start with '0' if needed
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

const Learning: FC = () => {
  /* 
  Load Dispatch
  */
  const dispatch = useAppDispatch();
  /* 
  GLOBAL STATE:
  */
  const { postTextPayload } = useAppSelector((state) => state.postText);
  const { getUserProfilePayload } = useAppSelector(
    (state) => state.getUserProfile
  );
  const { getLanguagesAndCountriesPayload } = useAppSelector(
    (state) => state.getLanguagesAndCountries
  );
  const {
    listBodiesOfTextPaginatedErrors,
    listBodiesOfTextPaginatedLoading,
    listBodiesOfTextPaginatedPayload,
  } = useAppSelector((state) => state.listBodiesOfTextPaginated);

  const { currentUser, isAuthenticated } = useContext(AuthContext);
  /*
  LOCAL STATE:
  */
  const [selectedTab, setSelectedTab] = useState(0);
  const [languagesLearnedLevel, setLanguagesLearnedLevel] = useState(["A1"]);
  const [languagesLearned, setLanguagesLearned] = useState(["en-US"]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<{
    selectedLanguage: string;
    selectedLanguageLevel: string;
    selectedCategories: string[];
  }>({
    selectedLanguage: "en-US",
    selectedLanguageLevel: "A1",
    selectedCategories: [],
  });
  const [currentLanguageIndex, setCurrentLanguageIndex] = useState(0);
  const [currentLanguageLevelIndex, setCurrentLanguageLevelIndex] = useState(0);
  /* 
  CONSTANTS:
  */
  const newTextTemplate: BodyOfText = {
    uuid: "",
    paragraphs: [
      {
        id: "",
        text: "",
        created_at: getTodaysDate(),
        by: "ai",
        language: languagesLearned[currentLanguageIndex],
        level: languagesLearnedLevel[currentLanguageLevelIndex],
        is_read: false,
        is_deleted: false,
        sentence_pair: null,
        sentences: [
          {
            id: "",
            words: [],
            text: "",
            by: "",
          },
        ],
        sentences_analyzed: [],
      },
    ],
    is_deleted: false,
    title: "",
    language: languagesLearned[currentLanguageIndex],
    level: languagesLearnedLevel[currentLanguageLevelIndex],
    created_at: getTodaysDate(),
  };

  const newTextTemplatePaginated: BodyOfTextPaginated = {
    count: 0,
    next: "",
    previous: "",
    results: [newTextTemplate],
  };

  /* 
  LOCAL STATE 2:
  */
  const [bodyOfText, setBodyOfText] = useState<BodyOfTextPaginated>(
    newTextTemplatePaginated
  );
  const [isMobile, setIsMobile] = useState(false);
  const [isInvalid, setIsInvalid] = useState(true);

  /* 
  CLICK HANDLERS:
  */
  const handleLanguageClick = () => {
    // Cycle to the next language or loop back to the first language
    setCurrentLanguageIndex((prevIndex) =>
      prevIndex === languagesLearned.length - 1 ? 0 : prevIndex + 1
    );
  };
  const handleLanguageLevelClick = () => {
    // Cycle to the next language or loop back to the first language
    setCurrentLanguageLevelIndex((prevIndex) =>
      prevIndex === languagesLearnedLevel.length - 1 ? 0 : prevIndex + 1
    );
  };
  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };
  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };
  // Function to handle changes in the dropdown
  const handleLanguageChange = (event: any) => {
    setFormData({ ...formData, selectedLanguage: event.target.value });
  };
  const handleLanguageLevelChange = (event: any) => {
    setFormData({ ...formData, selectedLanguageLevel: event.target.value });
  };
  // Function to check screen size and update state
  const checkScreenSize = () => {
    setIsMobile(window.innerWidth <= 768); // You can adjust the breakpoint as needed
  };
  // Function to handle form submission
  const handleSubmit = async () => {
    closeModal();

    // Update the texts state with the empty template at the beginning
    //setBodyOfText(newTextTemplatePaginated);
    setBodyOfText((prevBodyOfText) => ({
      ...prevBodyOfText,
      results: [newTextTemplate, ...prevBodyOfText.results],
    }));

    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      postText({
        access_token: access_token,
        text: "",
        categories: formData.selectedCategories,
        language: languagesLearned[currentLanguageIndex],
        level: languagesLearnedLevel[currentLanguageLevelIndex],
      })
    );
  };
  /* 
  DEBUG:
  */
  //console.log("postTextPayload", postTextPayload);
  /* 
  useEffect:
  */
  useEffect(() => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      listBodiesOfTextPaginated({
        access_token: access_token,
        language: languagesLearned[currentLanguageIndex],
        level: ["A1", "A2", "B1", "B2", "C1", "C2"],
        page: 1,
      })
    );

    dispatch(
      getUserProfile({
        access_token: access_token,
        subscriberProfileId: currentUser.profile_uuid,
      })
    );

    dispatch(getLanguagesAndCountries({ access_token: access_token }));

    if (listBodiesOfTextPaginatedPayload)
      setBodyOfText(listBodiesOfTextPaginatedPayload);

    checkScreenSize(); // Initial check
    window.addEventListener("resize", checkScreenSize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const { listVocabularyWordsPayload } = useAppSelector(
    (state) => state.listVocabulary
  );

  useEffect(() => {
    if (listBodiesOfTextPaginatedPayload)
      setBodyOfText(listBodiesOfTextPaginatedPayload);
  }, [listBodiesOfTextPaginatedPayload]);

  useEffect(() => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      listBodiesOfTextPaginated({
        access_token: access_token,
        language: languagesLearned[currentLanguageIndex],
        level: ["A1", "A2", "B1", "B2", "C1", "C2"],
        page: page,
      })
    );
  }, [currentLanguageIndex, currentLanguageLevelIndex]);

  useEffect(() => {
    if (getUserProfilePayload.learning_languages_names) {
      // Check if "en-US" is not in the list and add it if necessary
      if (
        getUserProfilePayload.learning_languages_names.length === 0 ||
        !getUserProfilePayload.learning_languages_names.includes("en-US")
      ) {
        setLanguagesLearned(["en-US"]);
        setLanguagesLearnedLevel(["A1"]);
      } else {
        setLanguagesLearned(getUserProfilePayload.learning_languages_names);
        setLanguagesLearnedLevel(
          getUserProfilePayload.learning_languages_levels
        );
      }
    }
  }, [getUserProfilePayload]);

  useEffect(() => {
    if (postTextPayload) {
      setBodyOfText(postTextPayload);
    }
  }, [postTextPayload]);

  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);

  const handlePaginationChange = (e: any, pageNumber: number) => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      listBodiesOfTextPaginated({
        access_token: access_token,
        page: pageNumber,
        language: languagesLearned[currentLanguageIndex],
        level: ["A1", "A2", "B1", "B2", "C1", "C2"],
      })
    );
    setPage(pageNumber);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      {/* Add another navbar inside grid */}
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        alignItems="center" // Center items vertically
      >
        <IconButton
          aria-label="Create Word Entry"
          color={"primary"}
          onClick={() => {
            setSelectedTab(0);
          }}
        >
          <ListIcon />
        </IconButton>
        <Button
          aria-label="List"
          color={"primary"}
          onClick={() => {
            //navigate("/groupchat/create");
            openModal();
          }}
        >
          {!isMobile ? (
            <>
              <ControlPointIcon
                sx={{
                  mr: 1,
                }}
              />{" "}
              Create Text
            </>
          ) : (
            <>
              <ControlPointIcon
                sx={{
                  mr: 0,
                }}
              />{" "}
            </>
          )}
        </Button>

        <Button
          aria-label="List"
          color={"primary"}
          onClick={() => {
            setSelectedTab(1);
          }}
        >
          {!isMobile ? (
            <>
              <StoreIcon
                sx={{
                  mr: 1,
                }}
              />{" "}
              Database
            </>
          ) : (
            <>
              <StoreIcon
                sx={{
                  mr: 0,
                }}
              />{" "}
            </>
          )}
        </Button>

        <Chip
          label={`${languagesLearned[currentLanguageIndex]}`}
          clickable
          color="primary"
          onClick={handleLanguageClick}
          size="small"
        />
      </Stack>
      {/* This is the textcards component */}
      {selectedTab === 0 && (
        <Box sx={{ mt: 2 }}>
          {listBodiesOfTextPaginatedLoading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <CommunitySkeleton />
              <CommunitySkeleton />
              <CommunitySkeleton />
              <CommunitySkeleton />
            </div>
          ) : bodyOfText.results.length > 0 ? (
            <TextCards
              bodyOfText={bodyOfText}
              language={languagesLearned[currentLanguageIndex]}
              level={languagesLearnedLevel[currentLanguageIndex]}
              languagesPayload={getLanguagesAndCountriesPayload}
            />
          ) : (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                marginTop: "20px",
                gap: "10px", // Adjust the gap as needed
              }}
            >
              No Texts found, create a text by clicking on "Create Text"
            </div>
          )}
        </Box>
      )}
      {selectedTab === 1 && (
        <Box sx={{ mt: 2 }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              marginTop: "20px",
              gap: "10px", // Adjust the gap as needed
            }}
          >
            No Texts found, create a text by clicking on "Create Text"
          </div>
        </Box>
      )}

      <Stack alignItems="center">
        <Pagination
          count={bodyOfText ? Math.ceil(bodyOfText.count / 5) : 1}
          siblingCount={5}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={(event, pageNumber) =>
            handlePaginationChange(event, pageNumber)
          }
        />
      </Stack>
      <Copyright sx={{ pt: 4 }} />
      <Dialog open={isModalOpen} onClose={closeModal} maxWidth="sm" fullWidth>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                <InputLabel htmlFor="language-input">Language</InputLabel>
                <Select
                  id="language-input"
                  label="Language"
                  variant="outlined"
                  value={languagesLearned[currentLanguageIndex]}
                  onChange={handleLanguageChange}
                  disabled
                  required
                >
                  {languagesLearned.map((language) => (
                    <MenuItem key={language} value={language}>
                      {language}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" style={{ marginLeft: "20px" }}>
                {" "}
                {/* Added margin for spacing */}
                <InputLabel htmlFor="level-input">Level</InputLabel>
                <Select
                  id="level-input"
                  label="Level"
                  variant="outlined"
                  value={languagesLearnedLevel[currentLanguageIndex]}
                  onChange={handleLanguageLevelChange}
                  required
                >
                  {["A1", "A2", "B1", "B2", "C1", "C2"].map((level) => (
                    <MenuItem key={level} value={level}>
                      {level}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Autocomplete
              multiple
              id="topic"
              options={categoryAreas}
              value={formData.selectedCategories}
              onChange={(_, newValue) => {
                setIsInvalid(newValue.length === 0);
                setFormData({ ...formData, selectedCategories: newValue });
              }}
              renderTags={(value, getTagProps) =>
                value.map((topic, index) => (
                  <Chip label={topic} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Topic"
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />

            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={isInvalid}
              sx={{ marginTop: 2 }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default Learning;
