import {
  Box,
  Avatar,
  CardHeader,
  Typography,
  CircularProgress,
  Card,
  CardActionArea,
  IconButton,
  Modal,
  CardContent,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ClearIcon from "@mui/icons-material/Clear"; // Reject icon
import { borderColor } from "../../../theme/colors";
import { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { IUserPending } from "../../../../types/userTypes";
import {
  initialUserPendingState,
  initialUserState,
} from "../../../../initalStates/initialUserState";

interface AppProps {
  users: any[];
  loading: boolean;
  title: string;
  onAccept?: (userId: string) => void;
  onReject?: (userId: string) => void; // Added the onReject prop
}
export default function PendingRequests({
  users,
  loading,
  title,
  onAccept,
  onReject,
}: AppProps) {
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<IUserPending>(
    initialUserPendingState
  );

  const handleOpen = (user: any) => {
    setCurrentUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentUser(initialUserPendingState);
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography fontSize="0.7rem" fontWeight="bold" component="div">
          {title.toUpperCase()}
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        {users.length > 0 &&
          users.map((user) => (
            <Card
              key={user.uuid}
              variant="outlined"
              style={{ marginBottom: "10px", borderColor: borderColor.pastel4 }}
            >
              <CardHeader
                avatar={
                  <Avatar src={user.avatarUrl} sx={{ width: 32, height: 32 }}>
                    {user.username.charAt(0)}
                  </Avatar>
                }
                title={user.username}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  paddingRight: 2,
                  marginBottom: 2,
                }}
              >
                <IconButton onClick={() => handleOpen(user)}>
                  <InfoIcon />
                </IconButton>
                {onReject && (
                  <IconButton
                    onClick={() => onReject(user.uuid)}
                    aria-label="reject"
                    style={{
                      color: "red",
                    }}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                )}
                {onAccept && (
                  <IconButton
                    onClick={() => onAccept(user.uuid)}
                    aria-label="accept"
                    style={{
                      color: "green",
                    }}
                  >
                    <CheckCircleOutlineIcon />
                  </IconButton>
                )}
              </Box>
            </Card>
          ))}

        {users.length === 0 && (
          <Typography fontSize={"small"}>- None -</Typography>
        )}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="user-info-title"
          aria-describedby="user-info-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="user-info-title" variant="h6">
              {currentUser?.username}
            </Typography>
            <Avatar
              src={currentUser?.profile_image}
              alt={currentUser?.username}
              sx={{ width: 60, height: 60, my: 2 }}
            />
            <Typography>
              <strong>Timezone:</strong> {currentUser?.timezone}
            </Typography>
            <Typography>
              <strong>Native Languages:</strong>
              {currentUser?.native_languages_names.length > 0
                ? currentUser.native_languages_names.join(", ")
                : " None"}
            </Typography>
            <Typography>
              <strong>Learning Languages:</strong>
              {currentUser?.learning_languages_names.length > 0
                ? currentUser.learning_languages_names.join(", ")
                : " None"}
            </Typography>
            <Typography>
              <strong>Learning Levels:</strong>
              {currentUser?.learning_languages_levels.length > 0
                ? currentUser.learning_languages_levels.join(", ")
                : " None"}
            </Typography>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
