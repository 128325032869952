export const teacherTypeStyle = (type: string) => {
  if (type === "nominated") {
    return "#3498db";
  } else if (type === "rejected") {
    return "#e74c3c";
  } else if (type === "accepted") {
    return "green";
  } else {
    return "red";
  }
};
