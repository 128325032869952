import {
  Box,
  Avatar,
  CardHeader,
  Typography,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import PlaceIcon from "@mui/icons-material/Place";
import Grid from "@mui/material/Grid";
import { CircleFlag } from "react-circle-flags";
import LanguageIcon from "@mui/icons-material/Language";
import TranslateIcon from "@mui/icons-material/Translate";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import { Fragment } from "react";
import LanguageLevel from "./LanguageLevel";
import { borderColor } from "../../../theme/colors";

interface AppProps {
  userProfile: any;
  loading: boolean;
  title: string;
}

export default function TeacherInfo({ userProfile, loading, title }: AppProps) {
  return loading ? (
    <CircularProgress />
  ) : (
    <Fragment key={userProfile.id}>
      <Box sx={{ mb: 2, mt: 2 }}>
        <Typography fontSize="0.7rem" fontWeight="bold" component="div">
          {title.toUpperCase()}
        </Typography>
      </Box>
      <Card
        sx={{
          borderColor: borderColor.logo,
          width: "100%",
        }}
        variant="outlined"
      >
        <CardHeader
          avatar={
            <Avatar
              alt={
                userProfile
                  ? userProfile.username.charAt(0).toUpperCase() +
                    userProfile.username.slice(1)
                  : ""
              }
              src={userProfile.profile_image}
              sx={{ width: 75, height: 75 }}
            />
          }
          titleTypographyProps={{ variant: "h6" }}
          title={
            userProfile
              ? userProfile.username.charAt(0).toUpperCase() +
                userProfile.username.slice(1)
              : ""
          }
        />

        <CardContent sx={{ ml: 2 }}>
          <Grid container direction="row" alignItems="center">
            <Grid item sx={{ mb: 4 }}>
              {userProfile.teacher_profile
                ? userProfile.teacher_profile.about
                : "NA"}
            </Grid>
          </Grid>

          <Grid container direction="row" alignItems="center">
            <Grid item>
              <PlaceIcon sx={{ mr: 2 }} />
            </Grid>
            <Grid item>
              {userProfile.timezone ? userProfile.timezone : "NA"}
            </Grid>
          </Grid>

          <Grid container direction="row" alignItems="center">
            <Grid item>
              <LanguageIcon sx={{ mr: 2 }} />
            </Grid>
            <Grid item>
              {userProfile.native_languages_names &&
              userProfile.native_languages_names.length > 0
                ? userProfile.native_languages_names.map(
                    (language: any, index: number) => (
                      <span key={index}>
                        <span> </span>
                        <CircleFlag
                          countryCode={language.split("-")[1].toLowerCase()}
                          style={{
                            width: "1em",
                            height: "1em",
                          }}
                        />
                      </span>
                    )
                  )
                : "NA"}
            </Grid>
          </Grid>

          <Grid container direction="row" alignItems="center">
            <Grid item>
              <HistoryEduIcon sx={{ mr: 2 }} />
            </Grid>
            <Grid item>
              {userProfile.teacher_profile &&
              userProfile.teacher_profile.teaching_languages.length > 0 ? (
                userProfile.teacher_profile.teaching_languages.map(
                  (language: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center", // Align items vertically
                        marginBottom: "8px", // Spacing between items
                      }}
                    >
                      <CircleFlag
                        countryCode={language.split("-")[1].toLowerCase()}
                        style={{
                          width: "1.2em", // Slightly larger for emphasis
                          height: "1.2em",
                          marginRight: "8px", // Spacing between flag and text
                        }}
                      />
                      <span style={{ fontSize: "0.9em" }}>
                        <strong>
                          {
                            userProfile.teacher_profile.years_of_experience[
                              index
                            ]
                          }
                        </strong>
                        &nbsp;Years of experience
                      </span>
                    </div>
                  )
                )
              ) : (
                <span style={{ fontSize: "0.9em", fontStyle: "italic" }}>
                  No experience provided
                </span>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  );
}
