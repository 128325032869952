import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IUserNew } from "../../types/userTypes";
import { IUserProfile } from "../../types/userProfileTypes";
import { IErrors } from "../../types/MessageTypes";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getUser = createAsyncThunk(
  "user/getUsers",
  async (data: { subscriberId: string; access_token: string }, thunkApi) => {
    try {
      const response = await axios.get<IUserNew[]>(
        `${url}/users/${data.subscriberId}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const getUserSlice = createSlice({
  name: "getUser",
  initialState: {
    getUserLoading: false,
    getUserError: {} as IErrors,
    getUserPayload: {
      email: "",
      role: "",
      all_image_urls: [""],
      images: [""],
      profile_details: {
        username: "",
        date_of_birth: "",
        about: "",
        native_languages: [],
        learning_languages: [],
        learning_languages_levels: [],
        timezone: "",
        countries_from: [],
        countries_living: [],
        images: [],
        age: "",
        profile_image: "",
        all_image_urls: [],
        is_online: false,
        is_teacher: false,
        is_bot: false,
        user_uuid: "",
        voted_by: "",
      } as IUserProfile,
      profile: {
        username: "",
        date_of_birth: "",
        about: "",
        native_languages: [],
        learning_languages: [],
        learning_languages_levels: [],
        timezone: "",
        countries_from: [],
        countries_living: [],
        images: [],
        age: "",
        profile_image: "",
        all_image_urls: [],
        is_online: false,
        is_teacher: false,
        is_bot: false,
        user_uuid: "",
        voted_by: "",
      } as IUserProfile,
      _id: "",
      id: 0,
      created_at: "",
      updated_at: "",
      __v: 0,
    } as IUserNew,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUser.pending, (state, action) => {
        state.getUserLoading = true;
      })
      .addCase(getUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.getUserLoading = false;
        state.getUserPayload = action.payload;
      })
      .addCase(getUser.rejected, (state, action: PayloadAction<any>) => {
        state.getUserLoading = false;
        state.getUserError = action.payload;
      });
  },
});

export default getUserSlice.reducer;
