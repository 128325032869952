import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const postTeacherNominationReject = createAsyncThunk(
  "userProfile/postTeacherNominationReject",
  async (data: { access_token: string; classroom_uuid: string }, thunkApi) => {
    try {
      const response = await fetch(`${url}/teachernominationreject/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        body: JSON.stringify({
          classroom_uuid: data.classroom_uuid,
        }),
      });
      if (response.status === 200) {
        return "Nomination successfully Rejected";
      } else return thunkApi.rejectWithValue("Something went wrong");
    } catch (error) {
      return thunkApi.rejectWithValue("Something went wrong");
    }
  }
);

const postTeacherNominationRejectSlice = createSlice({
  name: "postTeacherNominationReject",
  initialState: {
    postTeacherNominationRejectLoading: false,
    postTeacherNominationRejectErrors: null,
    postTeacherNominationRejectPayload: {
      Rejected: false,
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postTeacherNominationReject.pending, (state) => {
        state.postTeacherNominationRejectLoading = true;
      })
      .addCase(postTeacherNominationReject.fulfilled, (state, action: any) => {
        state.postTeacherNominationRejectLoading = false;
        state.postTeacherNominationRejectPayload = {
          Rejected: true,
        };
      })
      .addCase(postTeacherNominationReject.rejected, (state, action: any) => {
        state.postTeacherNominationRejectLoading = false;
        state.postTeacherNominationRejectErrors = action.payload;
      });
  },
});

export default postTeacherNominationRejectSlice.reducer;
