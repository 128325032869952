import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IErrors, IChannel } from "../../types/MessageTypes";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getChannel = createAsyncThunk(
  "user/getChannel",
  async (data: any, thunkApi) => {
    try {
      const response = await axios.get<IChannel[]>(`${url}/channel/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        //data: data.subscriberIds
      });
      console.log("response.data: ", response.data);
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const channelSlice = createSlice({
  name: "getChannel",
  initialState: {
    loading: false,
    errors: {} as IErrors,
    newMessages: [] as IChannel[],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getChannel.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getChannel.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.newMessages = action.payload;
      })
      .addCase(getChannel.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.errors = action.payload;
      });
  },
});

export default channelSlice.reducer;
