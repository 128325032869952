import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import Copyright from "../Copyright/Copyright";
import AuthContext from "../../context/AuthContext";
import { useGoogleOneTapLogin, GoogleLogin } from "@react-oauth/google";
import Loader from "../Loaders/Loader";
import Logo from "../Logo/LogoOnWhiteSmall";
import { Navigate } from "react-router-dom";
import theme from "../theme/theme";
import { access } from "fs";

const URL = process.env.REACT_APP_API_URL;

export default function SignIn() {
  let {
    isAuthenticated,
    signin,
    updateToken,
    isTokenExpired,
    signinGoogle,
    currentUser,
  } = useContext(AuthContext);
  const [hasRefreshToken, setHasRefreshToken] = useState(false);

  useEffect(() => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    if (access_token) {
      setHasRefreshToken(true);
      console.log(true);
    } else {
      console.log(false);
    }
  }, []);

  const [loading, setLoading] = useState(false); //useAppSelector((state: RootState) => state.getAuthenticatedUser.loading);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const email = event.currentTarget.email.value;
    const password = event.currentTarget.password.value;
    signin(email, password);
  };

  function handleGoogleLogin(credential: any) {
    setLoading(true);
    signinGoogle(credential);
  }

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      const credential = credentialResponse.credential;
      handleGoogleLogin(credential);
    },
    onError: () => {
      console.log("Login Failed");
    },
  });

  if (isAuthenticated) {
    return <Navigate to="/dashboard/" />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Logo />
          <Box>Sign in</Box>
          <Box sx={{ mt: 5 }}>
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                handleGoogleLogin(credentialResponse.credential);
              }}
              onError={() => {
                console.log("Login Failed");
              }}
            />
          </Box>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            {loading ? (
              <Loader />
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
            )}
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup/" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
