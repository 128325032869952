import React, { useState, useEffect } from "react";

interface CategoryMap {
  [key: string]: string[];
}

const WikipediaCategories: React.FC = () => {
  const [categories, setCategories] = useState<CategoryMap>({});

  const fetchCategories = async (
    category: string,
    depth: number = 1,
    maxDepth: number = 3
  ): Promise<CategoryMap> => {
    const endpoint = "https://en.wikipedia.org/w/api.php";
    const params = new URLSearchParams({
      action: "query",
      list: "categorymembers",
      cmtype: "subcat",
      cmtitle: `Category:${category}`,
      cmlimit: "max",
      format: "json",
      origin: "*",
    });

    const response = await fetch(`${endpoint}?${params}`);
    const data = await response.json();

    const subcategories = data.query.categorymembers.map((cat: any) =>
      cat.title.replace("Category:", "")
    );

    let results: CategoryMap = { [category]: subcategories };

    if (depth < maxDepth) {
      for (const subcat of subcategories) {
        const deeperCategories = await fetchCategories(
          subcat,
          depth + 1,
          maxDepth
        );
        results = { ...results, ...deeperCategories };
      }
    }

    return results;
  };

  useEffect(() => {
    fetchCategories("Main_topic_classifications").then((data) =>
      setCategories(data)
    );
  }, []);

  return (
    <div>
      <h1>Wikipedia Categories</h1>
      {Object.entries(categories).map(([category, subcategories]) => (
        <div key={category}>
          <h2>{category}</h2>
          <ul>
            {subcategories.map((subcat) => (
              <li key={subcat}>{subcat}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default WikipediaCategories;
