import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const listWords = createAsyncThunk(
  "linguistics/listWords",
  async (
    data: {
      access_token: string;
    },
    thunkApi
  ) => {
    try {
      const response = await fetch(`${url}/linguistics/words/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
      });
      // Check if the response is successful (status code 200)
      if (response.ok) {
        const jsonData = await response.json(); // Parse the response JSON
        return jsonData; // Return the fetched data
      } else {
        // If the response status is not OK, reject with an error message
        return thunkApi.rejectWithValue({ error: "Something went wrong" });
      }
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const listWordsSlice = createSlice({
  name: "linguistics/listWords",
  initialState: {
    listWordsLoading: false,
    listWordsError: null,
    listWordsPayload: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listWords.pending, (state) => {
        state.listWordsLoading = true;
      })
      .addCase(listWords.fulfilled, (state, action: any) => {
        state.listWordsLoading = false;
        state.listWordsPayload = action.payload;
      })
      .addCase(listWords.rejected, (state, action: any) => {
        state.listWordsLoading = false;
        state.listWordsError = action.payload;
      });
  },
});

export default listWordsSlice.reducer;
