import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const postAvatarChange = createAsyncThunk(
  "userProfile/postAvatarChange",
  async (data: { access_token: string; avatar_data: {} }, thunkApi) => {
    try {
      const response = await fetch(`${url}/avatarchange/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        body: JSON.stringify({
          avatar_data: data.avatar_data,
        }),
      });
      if (response.status === 200) {
        return "Nomination sent";
      } else return thunkApi.rejectWithValue("Something went wrong");
    } catch (error) {
      return thunkApi.rejectWithValue("Something went wrong");
    }
  }
);

const postAvatarChangeSlice = createSlice({
  name: "postAvatarChange",
  initialState: {
    postAvatarChangeLoading: false,
    postAvatarChangeErrors: null,
    postAvatarChangePayload: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postAvatarChange.pending, (state) => {
        state.postAvatarChangeLoading = true;
      })
      .addCase(postAvatarChange.fulfilled, (state, action: any) => {
        state.postAvatarChangeLoading = false;
        state.postAvatarChangePayload = action.payload;
      })
      .addCase(postAvatarChange.rejected, (state, action: any) => {
        state.postAvatarChangeLoading = false;
        state.postAvatarChangeErrors = action.payload;
      });
  },
});

export default postAvatarChangeSlice.reducer;
