import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const postTeacherUpvote = createAsyncThunk(
  "userProfile/postTeacherUpvote",
  async (
    data: {
      access_token: string;
      classroom_uuid: string;
      teacher_uuid: string;
    },
    thunkApi
  ) => {
    try {
      const response = await fetch(`${url}/teacherupvote/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        body: JSON.stringify({
          classroom_uuid: data.classroom_uuid,
          teacher_uuid: data.teacher_uuid,
        }),
      });
      if (response.status === 200) {
        return "Nomination sent";
      } else return thunkApi.rejectWithValue("Something went wrong");
    } catch (error) {
      return thunkApi.rejectWithValue("Something went wrong");
    }
  }
);

const postTeacherUpvoteSlice = createSlice({
  name: "postTeacherUpvote",
  initialState: {
    postTeacherUpvoteLoading: false,
    postTeacherUpvoteErrors: null,
    postTeacherUpvotePayload: {
      success: false,
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postTeacherUpvote.pending, (state) => {
        state.postTeacherUpvoteLoading = true;
      })
      .addCase(postTeacherUpvote.fulfilled, (state, action: any) => {
        state.postTeacherUpvoteLoading = false;
        state.postTeacherUpvotePayload = action.payload;
        state.postTeacherUpvotePayload = {
          success: true,
        };
      })
      .addCase(postTeacherUpvote.rejected, (state, action: any) => {
        state.postTeacherUpvoteLoading = false;
        state.postTeacherUpvoteErrors = action.payload;
      });
  },
});

export default postTeacherUpvoteSlice.reducer;
