import { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import CustomToolbar from "./CustomToolbar";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { useAppDispatch, useAppSelector } from "../../hooks/useTypedSelector";
import { getClassrooms } from "../../features/classrooms/getClassroomsSlice";
import { getUserProfile } from "../../features/users/getUserProfileSlice";
import { useNavigate } from "react-router-dom";

const localizer = momentLocalizer(moment);

interface IProps {}
interface IState {
  value: string;
}

const Scheduler = (props: IProps, state: IState) => {
  const { getClassroomsPayload } = useAppSelector(
    (state) => state.getClassrooms
  );
  const [events, setEvents] = useState<any>([
    {
      id: 0,
      start: moment().toDate(),
      end: moment().add(1, "days").toDate(),
      title: "Some title",
    },
    {
      id: 0,
      start: moment().add(1, "days").toDate(),
      end: moment().add(2, "days").toDate(),
      title: "Some asdasdasd",
    },
  ]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function onSelectEventHandler(slotInfo: any) {
    console.log(slotInfo);
    navigate("/groups/" + slotInfo.id);
  }

  useEffect(() => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(getClassrooms({ access_token: access_token }));
  }, []);

  useEffect(() => {
    const events_tmp: any = [];

    if (Array.isArray(getClassroomsPayload)) {
      getClassroomsPayload.map((classroom: any) => {
        classroom.calendar_events_details.map((event: any) => {
          events_tmp.push({
            id: classroom.uuid,
            title: classroom.title,
            start: new Date(event.start),
            end: new Date(event.end),
          });
        });
      });
    }

    setEvents(events_tmp);
  }, [getClassroomsPayload]);

  console.log("events", events);

  return (
    <div className="App">
      <Calendar
        onSelectEvent={(slotInfo) => onSelectEventHandler(slotInfo)}
        components={{ toolbar: CustomToolbar }}
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={events}
        style={{ height: "100vh" }}
      />
    </div>
  );
};

export default Scheduler;
