import { Fragment } from "react";
import { Box } from "@mui/material";
import ScheduleSelector from "react-schedule-selector";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

import { weekdayNumberToString } from "../convertTime";
import theme from "../../theme/theme";

interface AppProps {
  profile: any;
  errors: any;
  state: any;
  setState: any;
  setSaveButtonDisabled: any;
}

const TeacherAvailability = ({ state, setState }: AppProps) => {
  const handleChange = (newSchedule: any) => {
    // sets the state, careful: the state is not the user profile
    console.log(newSchedule);
    setState({
      ...state,
      teacher_profile: {
        ...state.teacher_profile,
        availability_template: newSchedule,
      },
    });
  };

  return (
    <Fragment>
      <Box sx={{ mb: 5 }}>Availability</Box>
      {/* Start Availability */}
      <Box style={{ maxHeight: 500, minHeight: 500, overflow: "auto" }}>
        <ScheduleSelector
          selection={state.teacher_profile.availability_template}
          numDays={7}
          minTime={0}
          maxTime={24}
          hourlyChunks={2}
          dateFormat={"ddd"}
          timeFormat={"hh:mm A"}
          onChange={handleChange}
          renderDateLabel={(date) => {
            return (
              <div
                style={{
                  backgroundColor: theme.palette.primary.light,
                  width: "100%",
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  color: "black",
                }}
              >
                {weekdayNumberToString(date.getDay())}
              </div>
            );
          }}
          renderDateCell={(date, selected, refSetter) => (
            <div
              style={{
                border: "1px solid #CDCDCD",
                backgroundColor: selected
                  ? theme.palette.primary.dark
                  : "white",
                width: "100%",
                height: "100%",
              }}
            />
          )}
        />
      </Box>
      {/* End Learning Languages */}
    </Fragment>
  );
};

export default TeacherAvailability;
