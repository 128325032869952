/* TODO:
  - Add date picker
  - Add password strength meter
  - Add password confirmation
  - Add username validation
  - Add email validation
  - Add password validation
  */

import React, { useState, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Copyright from "../Copyright/Copyright";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { Navigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useTypedSelector";
import { FormHelperText } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { registerUser } from "../../features/users/userRegisterSlice";
import Logo from "../Logo/LogoSmall";

const theme = createTheme();

export default function SignUp() {
  const { isRegistered, getUsersError, getUsersLoading } = useSelector(
    (state: RootState) => state.registerUser
  );

  console.log(getUsersError);

  const usernameHelper = useRef<HTMLDivElement>(null);
  const emailHelper = useRef<HTMLDivElement>(null);
  const passwordHelper = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState({
    email: "",
    username: "",
    date_of_birth: "1986-07-09",
    password: "",
    confirmPassword: "",
  });

  const [Formerror, setFormError] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    non_field_errors: [],
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    if (formData.get("password") !== formData.get("confirmPassword")) {
      setFormError({ ...Formerror, confirmPassword: "Passwords do not match" });
      return;
    }

    const arg = {
      email: formData.get("email") as string,
      username: formData.get("username") as string,
      password: formData.get("password") as string,
      date_of_birth: "1986-07-09",
      //date_of_birth: data.get('date_of_birth') as string,
    };
    console.log("arg: ", arg);
    dispatch(registerUser(arg));
  };

  if (isRegistered) {
    return <Navigate replace to="/signup/emailsent/" />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Logo />

          <Box>Sign up</Box>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={onChange}
                />
                <FormHelperText id="password-helper" ref={emailHelper}>
                  {getUsersError.email ? getUsersError.email : ""}
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  onChange={onChange}
                />
                <FormHelperText id="password-helper" ref={passwordHelper}>
                  {Formerror.confirmPassword
                    ? Formerror.confirmPassword + ". "
                    : " "}
                  {getUsersError.non_field_errors
                    ? getUsersError.non_field_errors
                    : " "}
                  {getUsersError.password ? getUsersError.password + ". " : " "}
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid>
            </Grid>
            {getUsersLoading ? (
              <CircularProgress />
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign Up
              </Button>
            )}
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/signin/" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
