import { backgroundColor } from "../theme/colors";

interface Event {
  eventType?: string;
  [key: string]: any; // for other properties of the event
}

export const eventStyleGetter = (
  event: Event,
  start: Date,
  end: Date,
  isSelected: boolean
): { style: React.CSSProperties } => {
  let style: React.CSSProperties = {
    backgroundColor: "#f00",
    color: "#fff",
    borderRadius: "5px",
    padding: "5px",
    fontSize: "small",
  };

  if (event.status === "active_future") {
    style.backgroundColor = backgroundColor.pastel_jeans;
  } else if (event.eventType === "inactive_future") {
    style.backgroundColor = backgroundColor.pastel_jeans;
  } else if (event.eventType === "past") {
    style.backgroundColor = backgroundColor.pastel_pink;
  } else {
    style.backgroundColor = backgroundColor.grey;
  }

  return {
    style: style,
  };
};
