import React, { useContext, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box"; // Import Box for centering
import theme from "../theme/theme";
import AuthContext from "../../context/AuthContext";
import Loader from "../Loaders/Loader";

export default function SignedOut() {
  const { signout, isLoading } = useContext(AuthContext);
  const [isSignedOut, setIsSignedOut] = useState(false);

  const handleLogout = async () => {
    try {
      // Call the logout function from your AuthContext
      await signout();
      setIsSignedOut(true);
    } catch (error) {
      // Handle any logout errors here
      console.error("Logout failed:", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          minHeight="100vh" // Ensure full viewport height
        >
          {isSignedOut ? (
            <Typography variant="h6">Successfully Signed Out</Typography>
          ) : (
            <div>
              <Typography variant="h6">
                Are you sure you want to sign out?
              </Typography>
              <Button
                variant="contained"
                color="primary" // Use the primary color from the theme
                onClick={handleLogout}
              >
                Sign out
              </Button>
            </div>
          )}
        </Box>
      )}
    </ThemeProvider>
  );
}
