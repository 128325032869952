import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const postTeacherDownvote = createAsyncThunk(
  "userProfile/postTeacherDownvote",
  async (
    data: {
      access_token: string;
      classroom_uuid: string;
      teacher_uuid: string;
    },
    thunkApi
  ) => {
    try {
      const response = await fetch(`${url}/classroompages/teacherdownvote/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        body: JSON.stringify({
          classroom_uuid: data.classroom_uuid,
          teacher_uuid: data.teacher_uuid,
        }),
      });
      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else return thunkApi.rejectWithValue("Something went wrong");
    } catch (error) {
      return thunkApi.rejectWithValue("Something went wrong");
    }
  }
);

const postTeacherDownvoteSlice = createSlice({
  name: "postTeacherDownvote",
  initialState: {
    postTeacherDownvoteLoading: false,
    postTeacherDownvoteErrors: null,
    postTeacherDownvotePayload: {
      success: false,
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postTeacherDownvote.pending, (state) => {
        state.postTeacherDownvoteLoading = true;
      })
      .addCase(postTeacherDownvote.fulfilled, (state, action: any) => {
        state.postTeacherDownvoteLoading = false;
        state.postTeacherDownvotePayload = action.payload;
        state.postTeacherDownvotePayload = {
          success: true,
        };
      })
      .addCase(postTeacherDownvote.rejected, (state, action: any) => {
        state.postTeacherDownvoteLoading = false;
        state.postTeacherDownvoteErrors = action.payload;
      });
  },
});

export default postTeacherDownvoteSlice.reducer;
