import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getBodyOfTextByUuid = createAsyncThunk(
  "linguistics/getBodyOfTextByUuid",
  async (
    data: {
      access_token: string;
      uuid: string;
    },
    thunkApi
  ) => {
    try {
      const response = await fetch(
        `${url}/linguistics/bodyoftext_uuid/${data.uuid}/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
        }
      );
      // Check if the response is successful (status code 200)
      if (response.ok) {
        const jsonData = await response.json(); // Parse the response JSON
        return jsonData; // Return the fetched data
      } else {
        // If the response status is not OK, reject with an error message
        return thunkApi.rejectWithValue({ error: "Something went wrong" });
      }
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const getBodyOfTextByUuidSlice = createSlice({
  name: "getBodyOfTextByUuidSlice",
  initialState: {
    getBodyOfTextByUuidLoading: false,
    getBodyOfTextByUuidError: null,
    getBodyOfTextByUuidPayload: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getBodyOfTextByUuid.pending, (state) => {
        state.getBodyOfTextByUuidLoading = true;
      })
      .addCase(getBodyOfTextByUuid.fulfilled, (state, action: any) => {
        state.getBodyOfTextByUuidLoading = false;
        state.getBodyOfTextByUuidPayload = action.payload;
      })
      .addCase(getBodyOfTextByUuid.rejected, (state, action: any) => {
        state.getBodyOfTextByUuidLoading = false;
        state.getBodyOfTextByUuidError = action.payload;
      });
  },
});

export default getBodyOfTextByUuidSlice.reducer;
