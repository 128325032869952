import Typography from "@mui/material/Typography";
import {
  Box,
  CardContent,
  CardActions,
  AvatarGroup,
  Avatar,
  IconButton,
  Alert,
  CardActionArea,
} from "@mui/material";
import Card from "@mui/material/Card";
import { useState } from "react";
import ShareIcon from "@mui/icons-material/Share";
import Snackbar from "@mui/material/Snackbar";
import { backgroundColor, borderColor } from "../../../theme/colors";
import { IClassroom } from "../../../../types/classroomTypes";

var url = process.env.REACT_APP_URL;

interface GroupListFilteredProps {
  classrooms: IClassroom[];
}

export const GroupListFiltered: React.FC<GroupListFilteredProps> = ({
  classrooms,
}) => {
  const [open, setOpen] = useState(false);
  const handleShare = (uuid: string) => {
    navigator.clipboard.writeText(url + "/groups/" + uuid);
    setOpen(true);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  console.log(classrooms);

  return (
    <div>
      {classrooms.length > 0 ? (
        classrooms.map((classroom, index) => (
          <Box sx={{ mb: 2 }} key={index}>
            <CardActionArea href={url + "/groups/" + classroom.uuid}>
              <Card
                sx={{
                  borderColor: borderColor.color4,
                  width: "100%",
                }}
                variant="outlined"
              >
                <CardContent>
                  <Box sx={{ m: 1 }}>
                    <Typography gutterBottom variant="h5" component="span">
                      {classroom.title}
                    </Typography>
                  </Box>
                  <Box sx={{ m: 1 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component="span"
                    >
                      {classroom.description}
                    </Typography>
                  </Box>
                  {/*<Box sx={{ mt: 2 }}>
             <Divider />
           </Box>*/}
                </CardContent>
                <CardActions>
                  <Box sx={{ ml: 1 }}>
                    <Snackbar
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="success"
                        sx={{ width: "100%" }}
                      >
                        Copied Link to Clipboard!
                      </Alert>
                    </Snackbar>
                  </Box>
                  <Box sx={{ flexGrow: 1, mr: 1 }}>
                    <AvatarGroup
                      max={4}
                      sx={{
                        "& .MuiAvatar-root": {
                          width: 24,
                          height: 24,
                          fontSize: "0.8rem",
                        },
                        "& .MuiAvatarGroup-avatar": {
                          width: 30,
                          height: 30,
                          fontSize: "0.8rem",
                          "&:last-child": {
                            fontSize: "0.5rem", // Adjusts the "+" size
                            border: "1px solid black", // Add a black circle around the last avatar
                          },
                        },
                      }}
                    >
                      {classroom.subscribers_details.map(
                        (subscriber: any, index) => (
                          <Avatar
                            key={index}
                            alt={subscriber.username}
                            src={subscriber.profile_image}
                            style={{
                              borderRadius: "50%",
                              border: "1px solid black",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "40px",
                              height: "40px",
                            }}
                          />
                        )
                      )}
                    </AvatarGroup>
                  </Box>
                </CardActions>
              </Card>
            </CardActionArea>
          </Box>
        ))
      ) : (
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" align="center">
            There are no groups with these filters applied
          </Typography>
        </Box>
      )}
    </div>
  );
};
