/*
https://stackoverflow.com/questions/71506466/websockets-react-django
*/

import {
  Divider,
  Avatar,
  Grid,
  TextField,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  Badge,
  styled,
  IconButton,
  Container,
  CssBaseline,
  ThemeProvider,
  CircularProgress,
  Box,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../hooks/useTypedSelector";
import { IChannel, IMessage, IMessageMinimal } from "../../types/MessageTypes";
import { getUserProfile } from "../../features/users/getUserProfileSlice";
import {
  useContext,
  useEffect,
  useState,
  useRef,
  startTransition,
} from "react";
import AuthContext from "../../context/AuthContext";
import SendIcon from "@mui/icons-material/Send";
import { postMessages } from "../../features/chat/postMessagesSlice";
import useMatchMedia from "../../hooks/matchMedia";
import uuid from "react-uuid";
import AppBar from "../Header/AppBar";
import { getChatPartners } from "../../features/chat/getChatPartnersSlice";
import { getChannelMessages } from "../../features/chat/getChannelMessagesSlice";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import theme from "../theme/theme";
import { useNavigate, useParams } from "react-router";
import ChannelsList from "./Components/ChannelsList";

var img_url = "";

if (process.env.STORAGE_TYPE === "S3") {
  img_url = `https://${process.env.AWS_STORAGE_BUCKET_NAME}.s3.amazonaws.com/`;
}

function formatDate(dateString: string) {
  let date = new Date(dateString);
  let dateNow = new Date();

  // if the date is of today
  if (date.toDateString() === dateNow.toDateString()) {
    return date.toLocaleTimeString();
  }

  // if the date is from some minutes ago
  let minutesDifference = (dateNow.getTime() - date.getTime()) / 1000 / 60;
  if (minutesDifference < 60) {
    return "Just Now";
  }

  // otherwise
  return date.toLocaleString();
}

const Chat = () => {
  const navigate = useNavigate();
  let { channelId } = useParams<{ channelId?: string }>();

  const { height, width } = useWindowDimensions();
  const [messages, setMessages] = useState<IMessageMinimal[]>([]);

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef<any>();
    useEffect(() => elementRef.current.scrollIntoView());
    return <List ref={elementRef} />;
  };

  const ref = useRef<any>(null);
  const [messagesCreate, setMessagesCreate] = useState<IMessageMinimal[]>([]);
  const [input, setInput] = useState("");
  const [channels, setChannels] = useState<IChannel[]>([]);
  const [selectedChannel, setSelectedChannel] = useState<any>({} as IChannel);
  const [selectedChannelMessages, setSelectedChannelMessages] = useState<
    IMessage[]
  >([]);

  const isDesktopResolution = useMatchMedia("(min-width: 600px)", true);

  const dispatch = useAppDispatch();
  const { currentUser, isAuthenticated } = useContext(AuthContext);
  const { getUserProfileLoading, getUserProfilePayload } = useAppSelector(
    (state) => state.getUserProfile
  );
  const { getChannelMessagesLoading, getChannelMessagesPayload } =
    useAppSelector((state) => state.getChannelMessages);
  const { getChatPartnersErrors, getChatPartnersPayload } = useAppSelector(
    (state) => state.getChatPartners
  );
  const { postMessageLoading, messagesReturned } = useAppSelector(
    (state) => state.postMessages
  );

  useEffect(() => {
    setMessagesCreate(messages);
    if (!isAuthenticated) return;

    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      getUserProfile({
        access_token: access_token,
        subscriberProfileId: currentUser.profile_uuid,
      })
    );
    dispatch(
      getChatPartners({ access_token: access_token, channelId: channelId })
    );
  }, []);

  useEffect(() => {
    if (getChatPartnersPayload) {
      if (getChatPartnersPayload[0]) {
        // set first channel as default
        setSelectedChannel(getChatPartnersPayload[0]);
        setChannels(getChatPartnersPayload);
      }
    }
  }, [getChatPartnersPayload]);

  useEffect(() => {
    if (getChannelMessagesPayload) {
      console.log("getChannelMessagesPayload", getChannelMessagesPayload);
      setSelectedChannelMessages(getChannelMessagesPayload);
    }
  }, [getChannelMessagesPayload]);

  useEffect(() => {
    if (selectedChannel.uuid) {
      let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
      let access_token = authTokens.access;
      dispatch(
        getChannelMessages({
          access_token: access_token,
          channelId: selectedChannel.uuid,
        })
      );
    }
  }, [selectedChannel, messagesReturned]);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [messagesCreate]);

  function updateLastMessage(messageText: string) {
    const newMessage = {
      id: 0,
      level: "",
      paragraph: "default_paragraph",
      uuid: uuid(), // generate a new UUID for the message
      text: messageText,
      sender: {
        // For the sake of simplicity, we'll just add the currentUser's details here
        // assuming currentUser has a similar structure to sender
        user_uuid: currentUser.uuid,
        profile_image:
          currentUser.profile_image ||
          "https://groupifier.s3.amazonaws.com/media/user_placeholder.jpg",
        all_image_urls: [
          currentUser.profile_image ||
            "https://groupifier.s3.amazonaws.com/media/user_placeholder.jpg",
        ],
      },
      created_at: new Date(),
      updated_at: new Date(),
      language: "en", // assuming English as a default language for the sake of this example
      is_read: false, // new message has not been read
    };

    // Update the state with the new message
    setSelectedChannelMessages((prevMessages) => [...prevMessages, newMessage]);
  }

  console.log(channels);

  function submitMessage(message: string) {
    message = message.trim();
    if (message.length > 0) {
      // send message to server
      let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
      let access_token = authTokens.access;
      dispatch(
        postMessages({
          access_token: access_token,
          message: message,
          channel_uuid: selectedChannel["uuid"],
        })
      );
    }
  }

  function onChangeHandler(event: any) {
    setInput(event.target.value);
  }

  function updateMessages() {
    setMessagesCreate([
      ...messagesCreate,
      {
        uuid: uuid(),
        sender: {
          uuid: getUserProfilePayload.uuid,
          username: getUserProfilePayload.username,
          profile_image: getUserProfilePayload.profile_image,
          native_languages_names: [],
          learning_languages_names: [],
          learning_languages_levels: [],
          timezone: getUserProfilePayload.timezone,
        },
        text: input,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      },
    ]);
  }

  function updateUserList() {}

  function onInputHandler(event: any) {
    if (event.key === "Enter") {
      if (postMessageLoading) return;
      updateLastMessage(input);
      updateMessages();
      updateUserList();
      submitMessage(input);
      setInput("");
    }
  }

  function onClickSendHandler() {
    if (input == "") return;
    updateLastMessage(input);
    updateMessages();
    updateUserList();
    submitMessage(input);
    setInput("");
  }

  function onClickChannelHandler(event: any) {
    setSelectedChannel({
      uuid: event.currentTarget.id,
      name: event.currentTarget.name,
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar currentUser={currentUser} userProfile={getUserProfilePayload} />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            {/* User Profile Section */}
            <List>
              <ListItem key={currentUser.id}>
                <ListItemIcon>
                  <Avatar
                    alt={getUserProfilePayload.username}
                    src={
                      getUserProfilePayload.profile_image
                        ? `${getUserProfilePayload.profile_image}`
                        : ""
                    }
                  />
                </ListItemIcon>
                {isDesktopResolution && (
                  <ListItemText
                    primary={getUserProfilePayload.username}
                    sx={{ userSelect: "none", pointerEvents: "none" }}
                  />
                )}
              </ListItem>
            </List>
            {/* Uncomment if you need the Search section later 
              <Divider />
              <Grid item xs={12} sx={{ padding: '10px' }}>
                <TextField id="outlined-basic-email" label="Search" variant="outlined" fullWidth />
              </Grid>
            */}
            {/*  <Divider />*/}

            <ChannelsList
              height={height}
              channels={channels}
              isDesktopResolution={isDesktopResolution}
              selectedChannel={selectedChannel}
              onClickChannelHandler={onClickChannelHandler}
            />
          </Grid>

          <Grid item xs={9}>
            {/* Message Section */}
            {getChannelMessagesPayload && (
              <List
                sx={{
                  maxHeight: height - 225,
                  minHeight: height - 225,
                  overflow: "auto",
                  border: "1px solid #e0e0e0", // This line adds a border around your list
                  borderRadius: "4px", // This line adds rounded corners to your border
                }}
                ref={ref}
              >
                {selectedChannelMessages &&
                  selectedChannelMessages.map((message: any, index: number) => (
                    <ListItem key={index}>
                      <Grid container>
                        <Grid
                          item
                          xs={1}
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          {message.sender.user_uuid !== currentUser.uuid && (
                            <Avatar
                              alt={message.sender.username}
                              src={
                                message.sender.profile_image
                                  ? `${message.sender.profile_image}`
                                  : ""
                              }
                              sx={{
                                height: isDesktopResolution ? "30px" : "15px",
                                width: isDesktopResolution ? "30px" : "15px",
                                mr: 1,
                              }}
                            />
                          )}
                        </Grid>

                        <Grid item xs={11}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign:
                                message.sender.user_uuid === currentUser.uuid
                                  ? "right"
                                  : "left",
                              alignItems:
                                message.sender.user_uuid === currentUser.uuid
                                  ? "flex-end"
                                  : "flex-start",
                            }}
                          >
                            <Box
                              sx={{
                                backgroundColor:
                                  message.sender.user_uuid === currentUser.uuid
                                    ? "primary.main"
                                    : "grey.300",
                                borderRadius: "5px",
                                px: 2,
                                py: 1,
                                color:
                                  message.sender.user_uuid === currentUser.uuid
                                    ? "white"
                                    : "black",
                              }}
                            >
                              {message.text}
                            </Box>
                            <Box
                              sx={{
                                mt: 1,
                                fontSize: "0.8em",
                                color: "grey.600",
                              }}
                            >
                              {formatDate(
                                new Date(message.created_at).toISOString()
                              )}
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                <AlwaysScrollToBottom />
              </List>
            )}

            {/* Message Input Section */}
            <Grid>
              {/*   <Divider />*/}
              <Grid container sx={{ padding: "20px" }}>
                <Grid
                  item
                  xs={11}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <TextField
                    id="outlined-basic-email"
                    label="Type a message"
                    variant="outlined"
                    fullWidth
                    value={input}
                    onKeyPress={onInputHandler}
                    onChange={onChangeHandler}
                    inputProps={{ autoComplete: "off" }}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  {postMessageLoading ? (
                    <CircularProgress size={24} /> // Size can be adjusted as per your design
                  ) : (
                    <IconButton
                      aria-label="send"
                      size="small"
                      onClick={onClickSendHandler}
                      sx={{ ml: 1 }}
                    >
                      <SendIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

export default Chat;
