import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import { postTeacherNominationAccept } from "../../../../features/classrooms/postTeacherNominationAcceptSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../hooks/useTypedSelector";
import TeacherInfo from "../../../Dashboard/TeacherInfo/TeacherInfo";
import ClassroomUsers from "../ClassroomUsers";
import { getUserProfile } from "../../../../features/users/getUserProfileSlice";
import AuthContext from "../../../../context/AuthContext";
import { getClassroom } from "../../../../features/classrooms/getClassroomSlice";
import { IUserProfile } from "../../../../types/userProfileTypes";
import { useParams } from "react-router";
import { borderColor } from "../../../theme/colors";

const SubdcriberInfo: React.FC = () => {
  // Params
  let { uuid } = useParams();

  const dispatch = useAppDispatch();

  const [selectedTab, setSelectedTab] = useState("users");

  // Selectors
  const { getClassroomLoading, getClassroomPayload } = useAppSelector(
    (state) => state.getClassroom
  );
  const { getUserProfileLoading, getUserProfilePayload } = useAppSelector(
    (state) => state.getUserProfile
  );
  const {
    postTeacherNominationAcceptLoading,
    postTeacherNominationAcceptPayload,
  } = useAppSelector((state) => state.postTeacherNominationAccept);

  // Context
  const { currentUser, isAuthenticated } = useContext(AuthContext);
  const [messages, setMessages] = useState<any>(null);
  const [nominatedTeachers, setNominatedTeachers] = useState<any>(null);
  const [nominatedTeachersUuids, setNominatedTeachersUuids] = useState<any>([]);

  // Effects
  useEffect(() => {
    if (!isAuthenticated) return;
    if (!getClassroomPayload.nominated_teachers_details) return;
    setMessages(getClassroomPayload.messages_details);
    setNominatedTeachers(getClassroomPayload.nominated_teachers_details);
    let teacher_uuids = [] as string[];
    getClassroomPayload.nominated_teachers_details.map(
      (teacher: IUserProfile) => {
        teacher_uuids.push(teacher.user_uuid);
      }
    );
    setNominatedTeachersUuids(teacher_uuids);
  }, [getClassroomPayload]);

  useEffect(() => {
    if (!isAuthenticated) return;
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      getUserProfile({
        access_token: access_token,
        subscriberProfileId: currentUser.profile_uuid,
      })
    );
    dispatch(
      getClassroom({
        access_token: access_token,
        classroomId: uuid ? uuid : "0",
      })
    );
  }, []);

  return (
    <>
      {getClassroomPayload.has_teacher &&
      getClassroomPayload.teacher_profile ? (
        <Card
          variant="outlined"
          sx={{ mb: 3, mt: 3, borderColor: borderColor.logo }}
        >
          <TeacherInfo
            userProfile={getClassroomPayload.teacher_profile}
            loading={getClassroomLoading}
            title={"Teacher"}
          />
        </Card>
      ) : nominatedTeachersUuids &&
        nominatedTeachersUuids.includes(getUserProfilePayload.user_uuid) &&
        !postTeacherNominationAcceptPayload.accepted ? (
        <>
          <Card
            variant="outlined"
            sx={{ mb: 3, mt: 6, borderColor: borderColor.logo }}
          >
            <CardContent>
              <Typography
                variant="body2"
                color="text.secondary"
                component="div"
                sx={{ m: 1 }}
              >
                You are nominated as a teacher
              </Typography>

              <Button
                sx={{ m: 1 }}
                onClick={() => {
                  // send to teachernominationaccept via axios
                  let authTokens = JSON.parse(
                    localStorage.getItem("authTokens") || "{}"
                  );
                  let access_token = authTokens.access;
                  dispatch(
                    postTeacherNominationAccept({
                      access_token: access_token,
                      classroom_uuid: getClassroomPayload.uuid,
                    })
                  );
                }}
              >
                Accept Invitation
              </Button>
            </CardContent>
          </Card>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 2,
              m: 0,
              height: 100,
              bgcolor: "background.paper",
            }}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              component="div"
              sx={{ m: 1 }}
            >
              No Teacher Assigned
            </Typography>
          </Box>
        </>
      )}
      <Box sx={{ mt: 4 }}>
        <ClassroomUsers
          id={getClassroomPayload.uuid || "0"}
          getClassroomPayload={getClassroomPayload}
          getClassroomLoading={getClassroomLoading}
        />
      </Box>
    </>
  );
};

export default SubdcriberInfo;
