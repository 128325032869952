import {
  INominationSimplified,
  IUserProfileNomination,
} from "../types/userProfileTypes";

export const initialNominationState: INominationSimplified = {
  classroom_uuid: "",
  classroom_title: "",
  classroom_language: "",
  classroom_level: "",
  status: "",
};
