// TODO:
// 1. Turn into accordion
// 2. Add a button to add a new classroom

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "../Header/AppBar";
import { useContext, useEffect } from "react";
import AuthContext from "../../context/AuthContext";
import { getUserProfile } from "../../features/users/getUserProfileSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/useTypedSelector";
import { getClassrooms } from "../../features/classrooms/getClassroomsSlice";
import AdjustTimeZone from "../AdjustTimeZone/AdjustTimeZone";
import theme from "../theme/theme";
import Community from "../Community/Community";
import Dictionary from "./Dictionary";
import { Backdrop, CircularProgress } from "@mui/material";
import FullScreenLoader from "../Backdrops/FullScreenLoader";

function Dashboard() {
  const dispatch = useAppDispatch();
  const { currentUser, isAuthenticated } = useContext(AuthContext);
  const { getUserProfileLoading, getUserProfilePayload } = useAppSelector(
    (state) => state.getUserProfile
  );

  useEffect(() => {
    if (!isAuthenticated) return;
    if (!currentUser.profile_uuid) return;
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    console.log("currentUser", currentUser);
    dispatch(
      getUserProfile({
        access_token: access_token,
        subscriberProfileId: currentUser.profile_uuid,
      })
    );
    dispatch(getClassrooms({ access_token: access_token }));
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar currentUser={currentUser} userProfile={getUserProfilePayload} />
      <Dictionary />
      {getUserProfilePayload.timezone ? (
        <AdjustTimeZone timezone={getUserProfilePayload.timezone} />
      ) : null}

      {/* Add this to show a backdrop while loading */}
      <Backdrop
        open={getUserProfileLoading}
        style={{ zIndex: theme.zIndex.drawer + 1, color: "#fff" }}
      >
        <FullScreenLoader />
      </Backdrop>
    </ThemeProvider>
  );
}

export default Dashboard;
