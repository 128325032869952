import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IClassroom } from "../../types/classroomTypes";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}
export const rejectJoinGroup = createAsyncThunk(
  "classroom/rejectJoinGroup",
  async (
    data: { access_token: string; groupId: string; userId: string },
    thunkApi
  ) => {
    try {
      // Use axios.post() to send a POST request
      const response = await axios.post<IClassroom>(
        `${url}/rejectjoingroup/`,
        { group_id: data.groupId, user_id: data.userId }, // Sending group_id in the request body
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`, // Set Authorization header
          },
        }
      );

      return response.data; // Return the response data
    } catch (error: any) {
      // If there's an error, return the error message
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

interface ClassroomsState {
  rejectJoinGroupLoading: boolean;
  rejectJoinGroupErrors: {};
  rejectJoinGroupPayload: IClassroom[];
}

const rejectJoinGroupSlice = createSlice({
  name: "rejectJoinGroup",
  initialState: {
    rejectJoinGroupLoading: false,
    rejectJoinGroupErrors: {},
    rejectJoinGroupPayload: [] as IClassroom[],
  } as ClassroomsState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(rejectJoinGroup.pending, (state, action) => {
        state.rejectJoinGroupLoading = true;
      })
      .addCase(
        rejectJoinGroup.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.rejectJoinGroupLoading = false;
          state.rejectJoinGroupPayload = action.payload;
        }
      )
      .addCase(
        rejectJoinGroup.rejected,
        (state, action: PayloadAction<any>) => {
          state.rejectJoinGroupErrors = action.payload;
        }
      );
  },
});

export default rejectJoinGroupSlice.reducer;
