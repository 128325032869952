import {
  createSlice,
  createAsyncThunk,
  SerializedError,
} from "@reduxjs/toolkit";
import axios from "axios";
import { IErrors, IChannel } from "../../types/MessageTypes";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getChatPartnersUser = createAsyncThunk<
  IChannel[],
  { access_token: string; userId: string | undefined },
  { rejectValue: SerializedError }
>("user/getChatPartnersUser", async (data, thunkApi) => {
  try {
    const response = await axios.get<IChannel[]>(`${url}/chatpartnersuser/`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${data.access_token}`,
      },
      params: {
        userId: data.userId,
      },
    });
    return response.data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.response?.data || error.message);
  }
});

const getChatPartnersSlice = createSlice({
  name: "getChatPartnersUser",
  initialState: {
    getChatPartnersLoading: false,
    getChatPartnersErrors: {} as IErrors,
    getChatPartnersPayload: [] as IChannel[],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getChatPartnersUser.pending, (state, action) => {
        state.getChatPartnersLoading = true;
      })
      .addCase(getChatPartnersUser.fulfilled, (state, action: any) => {
        state.getChatPartnersLoading = false;
        state.getChatPartnersPayload = action.payload;
      })
      .addCase(getChatPartnersUser.rejected, (state, action: any) => {
        state.getChatPartnersLoading = false;
        state.getChatPartnersErrors = action.payload;
      });
  },
});

export default getChatPartnersSlice.reducer;
