import {
  Box,
  CardContent,
  Pagination,
  Stack,
  IconButton,
  Typography,
  Chip,
  CardActionArea,
} from "@mui/material";
import Card from "@mui/material/Card";
import { forwardRef, useEffect, useState } from "react";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import ShareIcon from "@mui/icons-material/Share";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Skeleton } from "@mui/material";
import {
  BodyOfText,
  BodyOfTextPaginated,
  Paragraph,
} from "../../../types/linguisticsTypes";
import { useNavigate } from "react-router-dom";
import { listBodiesOfTextPaginated } from "../../../features/linguistics/listBodiesOfTextPaginatedSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../hooks/useTypedSelector";
import { listVocabulary } from "../../../features/linguistics/listVocabularySlice";

const url = process.env.REACT_APP_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

function formatDateToHumanReadable(dateString: string): string {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  };

  return date.toLocaleDateString("en-US", options);
}

// Define the Alert component
const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface AppProps {
  bodyOfText: BodyOfTextPaginated | null;
  language: string;
  level: string;
  languagesPayload: any;
}

export const TextCards = ({
  bodyOfText,
  language,
  level,
  languagesPayload,
}: AppProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const navigate = useNavigate();
  const handleShow = (uuid: string) => {
    navigate(`/text/${uuid}`);
  };

  const handleShare = (uuid: string) => {
    navigator.clipboard.writeText(`${url}/text/` + uuid);
    setOpen(true);
  };

  const handleDelete = (uuid: string) => {
    // Handle delete logic here
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    const authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    const access_token = authTokens.access;
    dispatch(
      listVocabulary({
        access_token: access_token,
      })
    );
  }, []);

  return (
    <Box>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
          gap: "2px", // Adjust the gap as needed
        }}
      >
        {bodyOfText &&
          bodyOfText.results &&
          bodyOfText.results.map((result, index) =>
            result.uuid !== "" ? (
              <div key={index}>
                {result.paragraphs.map((paragraph, paragraphIndex) => (
                  <Card
                    key={paragraphIndex}
                    style={{ marginBottom: "16px" }}
                    variant="outlined"
                  >
                    <CardContent>
                      <Typography variant="h6" sx={{ mb: 3 }}>
                        {result.title}
                      </Typography>
                      <Typography variant="body1">{result.language}</Typography>
                      <Typography variant="body2">
                        {formatDateToHumanReadable(result.created_at)}
                      </Typography>
                      <Chip
                        label={paragraph.by.toUpperCase()}
                        size="small"
                        sx={{
                          mt: 1,
                          mb: 2,
                          backgroundColor:
                            paragraph.by === "human" ? "#A8E6CF" : "#FFD3B6", // Pastel green and pastel red
                          color: "black",
                          fontSize: 12,
                        }}
                      />
                      <Chip
                        label={paragraph.level.toUpperCase()}
                        size="small"
                        sx={{
                          mt: 1,
                          mb: 2,
                          ml: 1,
                          backgroundColor: "#F2E0E7",
                          color: "black",
                          fontSize: 12,
                        }}
                      />
                      <Typography variant="body2" sx={{ mt: 3 }}>
                        {paragraph.text.length > 100
                          ? `${paragraph.text.slice(0, 100)}...`
                          : paragraph.text}
                      </Typography>
                    </CardContent>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "8px",
                      }}
                    >
                      <IconButton
                        aria-label="Show"
                        onClick={() => handleShow(result.uuid)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        aria-label="Share"
                        onClick={(event) => {
                          event.stopPropagation(); // This will stop the click event from reaching the CardActionArea
                          // Add any other code here you want to run when the Share button is clicked
                        }}
                      >
                        <ShareIcon />
                      </IconButton>
                      <IconButton
                        aria-label="Delete"
                        onClick={(event) => {
                          event.stopPropagation(); // This will stop the click event from reaching the CardActionArea
                          // Add any other code here you want to run when the Delete button is clicked
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </Card>
                ))}
              </div>
            ) : (
              <div key={index}>
                <Skeleton
                  variant="rectangular"
                  width="90%"
                  height="90%"
                  sx={{
                    mb: 2,
                    ml: 2,
                    mr: 2,
                    borderRadius: "5px",
                    minHeight: "100px",
                  }}
                />
              </div>
            )
          )}
      </div>
    </Box>
  );
};
