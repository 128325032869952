import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const postText = createAsyncThunk(
  "linguistics/postText",
  async (
    data: {
      access_token: string;
      text: string;
      categories: string[];
      language: string;
      level: string;
    },
    thunkApi
  ) => {
    try {
      const response = await fetch(`${url}/linguistics/analyzetext/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        body: JSON.stringify({
          text: data.text,
          categories: data.categories,
          language: data.language,
          level: data.level,
        }),
      });
      // await and if not ok return error
      const responseData = await response.json(); // Parse the response to JSON

      if (!response.ok) {
        return thunkApi.rejectWithValue({
          error: responseData.message || "Something went wrong",
        });
      }
      return responseData; // Return the parsed data instead of a custom object
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const postTextSlice = createSlice({
  name: "postTextSlice",
  initialState: {
    postTextLoading: false,
    postTextError: null,
    postTextPayload: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postText.pending, (state) => {
        state.postTextLoading = true;
      })
      .addCase(postText.fulfilled, (state, action: any) => {
        state.postTextLoading = false;
        state.postTextPayload = action.payload;
      })
      .addCase(postText.rejected, (state, action: any) => {
        state.postTextLoading = false;
        state.postTextError = action.payload;
      });
  },
});

export default postTextSlice.reducer;
