import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { BodyOfText, BodyOfTextPaginated } from "../../types/linguisticsTypes";
import axios from "axios";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

const arrayToQueryParam = (key: string, values: string[]): string => {
  return values.map((value) => `${key}=${value}`).join("&");
};

export const listBodiesOfTextPaginated = createAsyncThunk(
  "linguistics/listBodiesOfTextPaginatedSlice",
  async (
    data: {
      access_token: string;
      language: string;
      level: string[];
      page: number;
    },
    thunkApi
  ) => {
    try {
      const levelQuery = arrayToQueryParam("level", data.level);
      const response = await axios.get<BodyOfTextPaginated>(
        `${url}/linguistics/bodyoftextpages/?page=${data.page}&language=${data.language}&${levelQuery}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

interface listBodiesOfTextPaginatedState {
  listBodiesOfTextPaginatedLoading: boolean;
  listBodiesOfTextPaginatedErrors: {};
  listBodiesOfTextPaginatedPayload: BodyOfTextPaginated;
}

const listBodiesOfTextPaginatedSlice = createSlice({
  name: "linguistics/listBodiesOfTextPaginated",
  initialState: {
    listBodiesOfTextPaginatedLoading: false,
    listBodiesOfTextPaginatedErrors: {},
    listBodiesOfTextPaginatedPayload: {
      count: 1,
      next: null,
      previous: null,
      results: [],
    },
  } as listBodiesOfTextPaginatedState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listBodiesOfTextPaginated.pending, (state, action) => {
        state.listBodiesOfTextPaginatedLoading = true;
      })
      .addCase(
        listBodiesOfTextPaginated.fulfilled,
        (state, action: PayloadAction<BodyOfTextPaginated>) => {
          state.listBodiesOfTextPaginatedLoading = false;
          state.listBodiesOfTextPaginatedPayload = action.payload;
        }
      )
      .addCase(
        listBodiesOfTextPaginated.rejected,
        (state, action: PayloadAction<any>) => {
          state.listBodiesOfTextPaginatedErrors = action.payload;
        }
      );
  },
});

export default listBodiesOfTextPaginatedSlice.reducer;
