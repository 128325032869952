import React from "react";

const TermsOfService = () => {
  const serviceName = "Groupifier";
  const websiteName = "groupifier.com";
  const websiteURL = "https://groupifier.com";
  const companyName = "Groupifier, LLC";
  const companyNameShort = "Groupifier";
  const contactEmail = "admin@groupifier.com";

  return (
    <>
      <div>
        <h1>{serviceName.toUpperCase()} TERMS OF SERVICE AGREEMENT</h1>
        <p>Last Updated: 2022-10-06</p>
        <p>
          IMPORTANT: PLEASE REVIEW THE ARBITRATION AGREEMENT AND CLASS ACTION
          WAIVER SET FORTH IN SECTION 9 BELOW CAREFULLY, AS IT WILL REQUIRE YOU
          TO RESOLVE DISPUTES WITH {websiteName} ON AN INDIVIDUAL BASIS THROUGH
          FINAL AND BINDING ARBITRATION. BY ENTERING INTO THIS AGREEMENT, YOU
          EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL OF THE
          TERMS OF THIS AGREEMENT AND HAVE TAKEN TIME TO CONSIDER THE
          CONSEQUENCES OF THIS IMPORTANT DECISION.
        </p>
        <h2>1. APPLICABILITY AND ACCEPTANCE OF THESE TERMS</h2>
        <p>
          This Terms of Service Agreement (“Agreement” or “Terms”) is a legal
          agreement between you and {websiteName}, LLC (“{websiteName}”, “we”,
          or “us”). By accessing or using the {websiteName} website, currently
          located at {websiteName} (the “Site”), or any apps or other services
          offered or operated by {websiteName} (collectively, the “Services”),
          or by checking a box or clicking a button signifying your acceptance
          of these Terms, you acknowledge that you have read, understood and
          agree to be bound by these Terms. If you do not agree to these Terms,
          do not access or use any of the Services.
        </p>
        <p>
          When using the Services, you will be subject to {websiteName}'s
          Community Guidelines (“Community Guidelines”) found here, and any
          additional guidelines, policies or rules posted on the Services or
          otherwise made available or disclosed to you (collectively, the
          “Rules”). All such guidelines, policies and rules are incorporated
          into these Terms by this reference.
        </p>
        <h2>2. USE OF THE SERVICES BY MINORS AND BANNED PERSONS</h2>
        <p>
          The Services are not available to, and shall not be accessed or used
          by, persons under the age of 18. BY ACCESSING OR USING THE SERVICES,
          YOU REPRESENT AND WARRANT THAT YOU ARE AT LEAST 18 YEARS OF AGE.
        </p>
        <p>
          The Services are also not available to, and shall not be accessed or
          used by, any users previously blocked or otherwise banned from
          accessing or using the Services.
        </p>
        <h2>3. LIMITED LICENSE TO USE THE SERVICES</h2>
        <p>
          Subject to your compliance with these Terms and all other applicable
          Rules including but not limited to the Community Guidelines, you are
          granted a limited, non-exclusive, non-sublicensable, revocable,
          non-transferable license to access and use the Services solely for
          your personal and non-commercial use. No licenses or rights are
          granted to you by implication or otherwise under any intellectual
          property rights owned or controlled by {websiteName} or its licensors,
          except for licenses and rights expressly granted in these Terms.{" "}
          {websiteName} can terminate this license as provided in Section 10
          below.
        </p>
        <p>
          You are solely responsible for compliance with any and all laws,
          rules, and regulations that may apply to your access to or use of the
          Services. You may not access or use the Services in any manner that
          could interfere with, disrupt, negatively affect or inhibit other
          users from fully enjoying the Services or that could damage, disable,
          overburden or impair the functioning of the Services in any manner.
        </p>
        <p>
          You may not access or use the Services for any illegal or unauthorized
          purpose. You agree not to use the Services to:
        </p>
        <ul>
          <li>
            upload, post, email, transmit or otherwise make available any
            Content that is unlawful, harmful, threatening, abusive, harassing,
            tortious, defamatory, vulgar, obscene, libelous, invasive of
            another's privacy, hateful, or racially, ethnically or otherwise
            objectionable;
          </li>
          <li>harm minors in any way;</li>
          <li>
            impersonate any person or entity, including any of {websiteName}'s
            employees or representatives;
          </li>
          <li>
            forge headers or otherwise manipulate identifiers in order to
            disguise the origin of any Content transmitted through the Services;
          </li>
          <li>
            upload, post, email, transmit or otherwise make available any
            Content that you do not have a right to make available under any law
            or under contractual or fiduciary relationships (such as inside
            information, proprietary and confidential information learned or
            disclosed as part of employment relationships or under nondisclosure
            agreements);
          </li>
          <li>
            upload, post, email, transmit or otherwise make available any
            Content that infringes any patent, trademark, trade secret,
            copyright or other proprietary rights of any party;
          </li>
          <li>
            upload, post, email, transmit or otherwise make available any
            unsolicited or unauthorized advertising, promotional materials,
            "junk mail," "spam," "chain letters," "pyramid schemes," or any
            other form of solicitation, except in those areas that may be
            designated for such purpose;
          </li>
          <li>
            upload, post, email, transmit or otherwise make available any
            material that contains software viruses or any other computer code,
            files or programs designed to interrupt, destroy or limit the
            functionality of any computer software or hardware or
            telecommunications equipment;
          </li>
          <li>
            interfere with or disrupt the Services or servers or networks
            connected to the Services, or disobey any requirements, procedures,
            policies or regulations of networks connected to the Services;
          </li>
          <li>
            intentionally or unintentionally violate any applicable local,
            state, national or international law, including, without limitation,
            any regulations having the force of law;
          </li>
          <li>"stalk" or otherwise harass another;</li>
          <li>
            collect or store personal data about other users in connection with
            the prohibited conduct and activities set forth in this Section 3;
          </li>
          <li>
            use the Services to advertise or offer to sell or buy any goods or
            services for any commercial purpose, unless such Communication
            Facility specifically allows such messages;
          </li>
          <li>
            use the Services to send automated queries to any website or to send
            any unsolicited commercial email;
          </li>
          <li>
            use the Services in a manner that could disable, overburden, damage,
            or impair the Services or interfere with any other party's use and
            enjoyment of the Services;
          </li>
          <li>
            use any robot, spider, scraper or other automated means to access
            the Services for any purpose without our express written permission;
          </li>
          <li>
            deep-link to any portion of the Services (including, without
            limitation, the full URL path) for any purpose without our express
            written permission;
          </li>
          <li>
            frame or mirror any part of the Services without our prior written
            authorization;
          </li>
          <li>
            attempt to bypass any measures of the Services designed to prevent
            or restrict access to the Services, or any portion of the Services;
          </li>
          <li>copy, modify, or distribute any part of the Services;</li>
          <li>
            decipher, decompile, disassemble or reverse engineer any of the
            software comprising or in any way making up a part of the Services;
          </li>
          <li>
            access the Services in order to build a similar or competitive
            website, product or service;
          </li>
          <li>
            access the Services in order to build a product using similar ideas,
            features, functions or graphics of the Services;
          </li>
          <li>
            access the Services in order to copy any ideas, features, functions
            or graphics of the Services;
          </li>
          <li>
            access the Services in order to write a review of the Services;
          </li>
        </ul>
        <p>
          {websiteName} reserves the right to terminate your access to the
          Services, without notice, for any reason whatsoever.
        </p>
        <h2>4. USER ACCOUNTS AND REGISTRATION</h2>
        <p>
          To access certain features or areas of the Services, you may be
          required to create a user account and provide certain registration
          details or other information. You agree that all information you
          provide to {websiteName} in connection with your registration and use
          of the Services will be true, accurate, current, and complete, and you
          will promptly update such information as necessary to ensure that it
          remains true, accurate, current, and complete. You are responsible for
          maintaining the confidentiality of your account credentials, including
          your username and password, and for all activities that occur under
          your account. You agree to notify {websiteName} immediately of any
          unauthorized access to or use of your account or any other breach of
          security. {websiteName} reserves the right to disable any user
          account, at any time and for any reason, including if {websiteName}{" "}
          believes that you have violated these Terms.
        </p>
        <h2>5. CONTENT AND INTELLECTUAL PROPERTY</h2>
        <p>
          The Services may allow you and other users to submit, post, transmit,
          or otherwise make available certain content, including but not limited
          to text, images, videos, audio, or other materials (collectively,
          "User Content"). You retain ownership of any intellectual property
          rights that you hold in your User Content. By submitting, posting,
          transmitting, or otherwise making available User Content on or through
          the Services, you grant {websiteName} a non-exclusive, royalty-free,
          worldwide, perpetual, irrevocable, sublicensable, and transferable
          license to use, reproduce, distribute, prepare derivative works of,
          display, and perform your User Content in connection with the
          operation of the Services and {websiteName}'s (and its successors' and
          affiliates') business, including without limitation for promoting and
          redistributing part or all of the Services (and derivative works
          thereof) in any media formats and through any media channels.
        </p>
        <p>
          You represent and warrant that you have all the rights necessary to
          grant the licenses and rights set forth in this Section 5, and that
          your User Content does not violate the intellectual property rights or
          other rights of any third party, and complies with all applicable laws
          and regulations. {websiteName} reserves the right, but not the
          obligation, to monitor, edit, remove, or disable access to any User
          Content in its sole discretion, including without limitation User
          Content that violates these Terms or the Community Guidelines, or is
          otherwise objectionable or inappropriate.
        </p>
        <h2>6. PROHIBITED CONDUCT</h2>
        <p>
          You agree not to engage in any of the following prohibited activities
          in connection with your use of the Services:
        </p>
        <ul>
          <li>
            Use the Services for any unlawful, fraudulent, or malicious purpose,
            or in any manner that could interfere with, disrupt, negatively
            affect, or inhibit other users from fully enjoying the Services, or
            that could damage, disable, overburden, or impair the functioning of
            the Services or any {websiteName} server, or network connected to
            any {websiteName} server.
          </li>
          <li>
            Use the Services to transmit, distribute, post, or submit any
            information concerning any other person or entity, including without
            limitation, photographs, personal contact information, or financial
            information of others without proper authorization.
          </li>
          <li>
            Impersonate any person or entity, or falsely state or otherwise
            misrepresent your affiliation with any person or entity.
          </li>
          <li>
            Use the Services to engage in any harassing, intimidating,
            threatening, predatory, or stalking conduct.
          </li>
          <li>
            Use the Services in any manner that violates any applicable laws,
            regulations, or
            <a href="https://www.ftc.gov/tips-advice/business-center/guidance/childrens-online-privacy-protection-rule-faqs">
              {" "}
              Children's Online Privacy Protection Act
            </a>{" "}
            requirements.
          </li>
        </ul>
        <h2>7. INDEMNIFICATION</h2>
        <p>
          You agree to indemnify and hold {websiteName} and its subsidiaries,
          affiliates, officers, agents, and other partners and employees,
          harmless from any loss, liability, claim, or demand, including
          reasonable attorneys' fees, made by any third party due to or arising
          out of your use of the Services in violation of these Terms and/or
          arising from a breach of these Terms and/or any breach of your
          representations and warranties set forth above.
        </p>
        <h2>8. DISCLAIMER OF WARRANTIES</h2>

        <p>YOU EXPRESSLY UNDERSTAND AND AGREE THAT:</p>
        <ul>
          <li>
            YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE SERVICES ARE
            PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. {websiteName}{" "}
            EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
            IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT.
          </li>
          <li>
            {websiteName} MAKES NO WARRANTY THAT (I) THE SERVICES WILL MEET YOUR
            REQUIREMENTS, (II) THE SERVICES WILL BE UNINTERRUPTED, TIMELY,
            SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM
            THE USE OF THE SERVICES WILL BE ACCURATE OR RELIABLE, OR (IV) THE
            QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL
            PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICES WILL MEET YOUR
            EXPECTATIONS.
          </li>
          <li>
            ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
            SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE
            SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF
            DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
          </li>
          <li>
            NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
            FROM {websiteName} OR THROUGH OR FROM THE SERVICES SHALL CREATE ANY
            WARRANTY NOT EXPRESSLY STATED IN THESE TERMS.
          </li>
        </ul>
        <h2>9. LIMITATION OF LIABILITY</h2>
        <p>
          YOU EXPRESSLY UNDERSTAND AND AGREE THAT {websiteName} SHALL NOT BE
          LIABLE TO YOU FOR:
        </p>
        <ul>
          <li>
            ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
            EXEMPLARY DAMAGES; THIS INCLUDES, BUT IS NOT LIMITED TO, DAMAGES FOR
            LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES
            (EVEN IF {websiteName} HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES).
          </li>
          <li>THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES.</li>
          <li>
            UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA.
          </li>
          <li>STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICES.</li>
        </ul>
        <p>
          THESE LIMITATIONS SHALL APPLY REGARDLESS OF WHETHER THE DAMAGES ARISE
          OUT OF BREACH OF CONTRACT, TORT, OR ANY OTHER LEGAL THEORY OR FORM OF
          ACTION.
        </p>
        <h2>10. RELEASE</h2>
        <p>
          If you have a dispute with one or more users, you release{" "}
          {websiteName} (and our officers, directors, agents, subsidiaries,
          joint ventures, and employees) from claims, demands, and damages
          (actual and consequential) of every kind and nature, known and
          unknown, arising out of or in any way connected with such disputes.
        </p>
        <h2>11. GENERAL</h2>
        <p>
          These Terms constitute the entire agreement between you and{" "}
          {websiteName} and govern your use of the Services, superseding any
          prior agreements between you and {websiteName} with respect to the
          Services. You also may be subject to additional terms and conditions
          that may apply when you use or purchase certain other {websiteName}{" "}
          services, affiliate services, third-party content or third-party
          software.
        </p>
        <p>
          The failure of {websiteName} to exercise or enforce any right or
          provision of these Terms shall not constitute a waiver of such right
          or provision. If any provision of these Terms is found by a court of
          competent jurisdiction to be invalid, the parties nevertheless agree
          that the court should endeavor to give effect to the parties'
          intentions as reflected in the provision, and the other provisions of
          these Terms remain in full force and effect.
        </p>
        <p>
          These Terms operate to the fullest extent permissible by law. We may
          assign any or all of our rights and obligations to others at any time.
          We shall not be responsible or liable for any loss, damage, delay, or
          failure to act caused by any cause beyond our reasonable control. If
          any part of these Terms is determined to be invalid or unenforceable
          pursuant to applicable law including, but not limited to, the warranty
          disclaimers and liability limitations set forth above, then the
          invalid or unenforceable provision will be deemed superseded by a
          valid, enforceable provision that most closely matches the intent of
          the original provision and the remainder of the Terms shall continue
          in effect.
        </p>
        <p>
          Unless otherwise specified herein, these Terms constitute the entire
          agreement between you and {websiteName} with respect to the Services
          and it supersedes all prior or contemporaneous communications and
          proposals, whether electronic, oral, or written, between you and{" "}
          {websiteName} with respect to the Services. A printed version of these
          Terms and of any notice given in electronic form shall be admissible
          in judicial or administrative proceedings based upon or relating to
          these Terms to the same extent and subject to the same conditions as
          other business documents and records originally generated and
          maintained in printed form.
        </p>
        <p>
          You may be subject to additional terms and conditions that may apply
          when you use or purchase certain other {websiteName} services,
          affiliate services, third-party content or third-party software.
        </p>
        <h2>12. CONTACT US</h2>
        <p>
          In order to resolve a complaint regarding the Services or to receive
          further information regarding use of the Services, please contact us
          at:
        </p>
        <p>{contactEmail}</p>
      </div>
    </>
  );
};

export default TermsOfService;
