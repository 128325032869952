import { createContext, useEffect, useState } from "react";
import { IAuthContext, Ijwt } from "../types/authContextTypes";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { IUserNew } from "../types/userTypes";
import { initialAuthState } from "../initalStates/initialAuthState";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

// Adjusting to match the token expiration from Django settings (5 minutes)
const fiveMinutesInMilliseconds = 5 * 60 * 1000;

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const AuthContext = createContext<IAuthContext>(initialAuthState);

export default AuthContext;

export const AuthProvider = ({ children }: any) => {
  const navigate = useNavigate();
  // Manage isAuthenticated with useState
  const [isAuth, setIsAuth] = useState<boolean>(
    !!localStorage.getItem("authTokens")
  );
  let [loading, setLoading] = useState(true);
  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens") || "")
      : null
  );

  useEffect(() => {
    // Update isAuthenticated state when authTokens change
    setIsAuthenticated(!!authTokens);
  }, [authTokens]);

  let [user, setUser] = useState<Ijwt | null>(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens") || "")
      : null
  );

  let signinUser = async (email: string, password: string) => {
    setIsLoading(true);
    let response = await fetch(`${url}/token/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });
    let result = await response.json();
    if (response.status === 200 || response.status === 201) {
      console.log("SUCCESS");
      setAuthTokens(result);
      setUser(jwt_decode(result.access));
      localStorage.setItem("authTokens", JSON.stringify(result));

      // Store the tokenExpirationTime in localStorage
      localStorage.setItem(
        "tokenExpirationTime",
        (Date.now() + fiveMinutesInMilliseconds).toString()
      );

      navigate("/dashboard/");
      await sleep(300); // otherwise i have to click login twice
      setIsLoading(false);
    } else {
      navigate("/signup/");

      setIsLoading(false);
    }
  };

  let signinGoogle = async (credential: any) => {
    setIsLoading(true);
    let response = await fetch(`${url}/google/token/verify/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ credential }),
    });

    let result = await response.json();

    if (response.status === 200 || response.status === 201) {
      setAuthTokens(result.tokens);
      //console.log("access", jwt_decode(result.tokens.access));
      setUser(jwt_decode(result.tokens.access));
      localStorage.setItem("authTokens", JSON.stringify(result.tokens));
      navigate("/dashboard/");
      setIsLoading(false);
    } else {
      console.log("FAILED: ", result);
      navigate("/signup/");
      setIsLoading(false);
    }
  };

  let isTokenExpired = () => {
    const expirationTime = localStorage.getItem("tokenExpirationTime");
    //console.log("expirationTime", expirationTime);
    if (expirationTime) {
      return Date.now() > parseInt(expirationTime);
    }
    return true; // default to expired if we can't find the expiration time
  };

  let updateToken = async () => {
    setIsLoading(true);
    if (isTokenExpired()) {
      console.log("Token expired");
      signoutUser();
      setIsLoading(false);
      return;
    }
    //console.log("Token not expired");
    let response = await fetch(`${url}/token/refresh/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh: authTokens?.refresh,
      }),
    });
    let result = await response.json();
    if (response.status === 200) {
      //console.log("updateToken: ", result);
      setAuthTokens(result);
      setUser(jwt_decode(result.access));
      localStorage.setItem("authTokens", JSON.stringify(result));
      // Store the tokenExpirationTime in localStorage
      localStorage.setItem(
        "tokenExpirationTime",
        (Date.now() + fiveMinutesInMilliseconds).toString()
      );
      console.log("expiration time updated");

      setIsLoading(false);
    } else {
      console.log(result);
      signoutUser();
      setIsLoading(false);
    }
  };

  let signoutUser = () => {
    setIsAuthenticated(false);
    setIsAuth(false); //which one
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    navigate("/signin/");
  };

  // let isAuthenticated = () => {
  //   if (authTokens && !isTokenExpired()) {
  //     return true;
  //   }
  //   return false;
  // };

  let setIsAuthenticated = (authBool: boolean) => {
    setIsAuth(authBool);
  };
  let setIsLoading = (loadingBool: boolean) => {
    setLoading(loadingBool);
  };
  let contextData = {
    currentUser: {
      email: user ? user.email : "",
      images: user ? user.images : [""],
      profile_image: user ? user.profile_image : "",
      _id: "",
      id: user ? user.user_id : 0,
      uuid: user ? user.uuid : "",
      profile_uuid: user ? user.profile_uuid : "",
      created_at: "",
      updated_at: "",
      __v: 1,
    } as IUserNew,
    isLoading: loading,
    setIsLoading: setLoading,
    isAuthenticated: isAuth,
    setIsAuthenticated: setIsAuth,
    access_token: authTokens ? authTokens.access : "",
    updateToken: updateToken,
    isTokenExpired: isTokenExpired,
    signin: signinUser,
    signinGoogle: signinGoogle,
    signout: signoutUser,
    recoverPassword: (e: any) => {},
    update: (e: any) => {},
  };

  useEffect(() => {
    if (!authTokens) {
      return; // No tokens, don't set up interval
    }

    let fourMinutes = 240000; // five minutes, one minute buffer
    let threeMinutes = 180000; // three minutes in milliseconds
    let interval = setInterval(() => {
      updateToken();
      console.log("Token updated");
    }, threeMinutes);

    return () => {
      clearInterval(interval); // Cleanup on unmount or if authTokens changes
    };
  }, [authTokens]);

  useEffect(() => {
    if (authTokens) {
      updateToken();
    } else setLoading(false); // Ensure loading state is updated if there are no tokens
  }, []);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
