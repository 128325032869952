export const BASE_URL = "https://groupifier.com";

export const COUNTRIES = [
  { name: "Afghanistan", code: "AF", lat: 34.5553, lon: 69.2075 },
  { name: "Åland Islands", code: "AX", lat: 60.1165, lon: 19.7095 },
  { name: "Albania", code: "AL", lat: 41.3275, lon: 19.8187 },
  { name: "Algeria", code: "DZ", lat: 36.7372, lon: 3.0865 },
  { name: "American Samoa", code: "AS", lat: -14.27, lon: -170.1322 },
  { name: "AndorrA", code: "AD", lat: 42.5063, lon: 1.5218 },
  { name: "Angola", code: "AO", lat: -8.8393, lon: 13.2894 },
  { name: "Anguilla", code: "AI", lat: 18.2205, lon: -63.0686 },
  { name: "Antarctica", code: "AQ", lat: -82.8628, lon: 135.0 },
  { name: "Antigua and Barbuda", code: "AG", lat: 17.0747, lon: -61.8175 },
  { name: "Argentina", code: "AR", lat: -34.6092, lon: -58.4371 },
  { name: "Armenia", code: "AM", lat: 40.1776, lon: 44.5126 },
  { name: "Aruba", code: "AW", lat: 12.5211, lon: -69.9683 },
  { name: "Australia", code: "AU", lat: -33.8688, lon: 151.2093 },
  { name: "Austria", code: "AT", lat: 48.2082, lon: 16.3738 },
  { name: "Azerbaijan", code: "AZ", lat: 40.4093, lon: 49.8671 },
  { name: "Bahamas", code: "BS", lat: 25.0343, lon: -77.3963 },
  { name: "Bahrain", code: "BH", lat: 26.2235, lon: 50.585 },
  { name: "Bangladesh", code: "BD", lat: 23.8103, lon: 90.4125 },
  { name: "Barbados", code: "BB", lat: 13.1939, lon: -59.5432 },
  { name: "Belarus", code: "BY", lat: 53.9006, lon: 27.559 },
  { name: "Belgium", code: "BE", lat: 50.8503, lon: 4.3517 },
  { name: "Belize", code: "BZ", lat: 17.1899, lon: -88.4976 },
  { name: "Benin", code: "BJ", lat: 6.5244, lon: 2.2238 },
  { name: "Bermuda", code: "BM", lat: 32.2949, lon: -64.7839 },
  { name: "Bhutan", code: "BT", lat: 27.473, lon: 89.6393 },
  { name: "Bolivia", code: "BO", lat: -16.4897, lon: -68.1193 },
  { name: "Bosnia and Herzegovina", code: "BA", lat: 43.8563, lon: 18.4131 },
  { name: "Botswana", code: "BW", lat: -24.6282, lon: 25.9231 },
  { name: "Bouvet Island", code: "BV", lat: -54.4208, lon: 3.3464 }, // These coordinates are very general as it's a remote island
  { name: "Brazil", code: "BR", lat: -22.9068, lon: -43.1729 },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    lat: -6.3432,
    lon: 71.8765,
  },
  { name: "Brunei Darussalam", code: "BN", lat: 4.9031, lon: 114.9398 },
  { name: "Bulgaria", code: "BG", lat: 42.6977, lon: 23.3219 },
  { name: "Burkina Faso", code: "BF", lat: 12.3714, lon: -1.5197 },
  { name: "Burundi", code: "BI", lat: -3.3822, lon: 29.3644 },
  { name: "Cambodia", code: "KH", lat: 11.5564, lon: 104.9282 },
  { name: "Cameroon", code: "CM", lat: 3.848, lon: 11.5021 },
  { name: "Canada", code: "CA", lat: 45.4215, lon: -75.707 },
  { name: "Cape Verde", code: "CV", lat: 14.9335, lon: -23.5133 },
  { name: "Cayman Islands", code: "KY", lat: 19.3133, lon: -81.2546 },
  { name: "Central African Republic", code: "CF", lat: 4.3947, lon: 18.5582 },
  { name: "Chad", code: "TD", lat: 12.1348, lon: 15.0557 },
  { name: "Chile", code: "CL", lat: -33.4489, lon: -70.6693 },
  { name: "China", code: "CN", lat: 39.9042, lon: 116.4074 },
  { name: "Christmas Island", code: "CX", lat: -10.4503, lon: 105.6904 },
  { name: "Cocos (Keeling) Islands", code: "CC", lat: -12.1642, lon: 96.841 },
  { name: "Colombia", code: "CO", lat: 4.71, lon: -74.0721 },
  { name: "Comoros", code: "KM", lat: -11.6946, lon: 43.3779 },
  { name: "Congo", code: "CG", latitude: -0.228021, longitude: 15.827659 },
  {
    name: "Congo, The Democratic Republic of the",
    code: "CD",
    latitude: -4.038333,
    longitude: 21.758664,
  },
  {
    name: "Cook Islands",
    code: "CK",
    latitude: -21.236736,
    longitude: -159.777671,
  },
  { name: "Costa Rica", code: "CR", latitude: 9.748917, longitude: -83.753428 },
  {
    name: "Cote D'Ivoire",
    code: "CI",
    latitude: 7.539989,
    longitude: -5.54708,
  },
  { name: "Croatia", code: "HR", latitude: 45.1, longitude: 15.2 },
  { name: "Cuba", code: "CU", latitude: 21.521757, longitude: -77.781167 },
  { name: "Cyprus", code: "CY", latitude: 35.126413, longitude: 33.429859 },
  {
    name: "Czech Republic",
    code: "CZ",
    latitude: 49.817492,
    longitude: 15.472962,
  },
  { name: "Denmark", code: "DK", latitude: 56.26392, longitude: 9.501785 },
  { name: "Djibouti", code: "DJ", latitude: 11.825138, longitude: 42.590275 },
  { name: "Dominica", code: "DM", latitude: 15.414999, longitude: -61.370976 },
  {
    name: "Dominican Republic",
    code: "DO",
    latitude: 18.735693,
    longitude: -70.162651,
  },
  { name: "Ecuador", code: "EC", latitude: -1.831239, longitude: -78.183406 },
  { name: "Egypt", code: "EG", latitude: 26.820553, longitude: 30.802498 },
  {
    name: "El Salvador",
    code: "SV",
    latitude: 13.794185,
    longitude: -88.89653,
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    latitude: 1.650801,
    longitude: 10.267895,
  },
  { name: "Eritrea", code: "ER", latitude: 15.179384, longitude: 39.782334 },
  { name: "Estonia", code: "EE", latitude: 58.595272, longitude: 25.013607 },
  { name: "Ethiopia", code: "ET", latitude: 9.145, longitude: 40.489673 },
  {
    name: "Falkland Islands (Malvinas)",
    code: "FK",
    latitude: -51.796253,
    longitude: -59.523613,
  },
  {
    name: "Faroe Islands",
    code: "FO",
    latitude: 61.892635,
    longitude: -6.911806,
  },
  { name: "Fiji", code: "FJ", latitude: -16.578193, longitude: 179.414413 },
  { name: "Finland", code: "FI", latitude: 61.92411, longitude: 25.748151 },
  { name: "France", code: "FR", latitude: 46.227638, longitude: 2.213749 },
  {
    name: "French Guiana",
    code: "GF",
    latitude: 3.933889,
    longitude: -53.125782,
  },
  {
    name: "French Polynesia",
    code: "PF",
    latitude: -17.679742,
    longitude: -149.406843,
  },
  {
    name: "French Southern Territories",
    code: "TF",
    latitude: -49.280366,
    longitude: 69.348557,
  },
  { name: "Gabon", code: "GA", latitude: -0.803689, longitude: 11.609444 },
  { name: "Gambia", code: "GM", latitude: 13.443182, longitude: -15.310139 },
  { name: "Georgia", code: "GE", latitude: 42.315407, longitude: 43.356892 },
  { name: "Germany", code: "DE", latitude: 51.165691, longitude: 10.451526 },
  { name: "Ghana", code: "GH", latitude: 7.946527, longitude: -1.023194 },
  { name: "Gibraltar", code: "GI", latitude: 36.137741, longitude: -5.345374 },
  { name: "Greece", code: "GR", latitude: 39.074208, longitude: 21.824312 },
  { name: "Greenland", code: "GL", latitude: 71.706936, longitude: -42.604303 },
  { name: "Grenada", code: "GD", latitude: 12.1165, longitude: -61.679 },
  { name: "Guadeloupe", code: "GP", latitude: 16.265, longitude: -61.551 },
  { name: "Guam", code: "GU", latitude: 13.4443, longitude: 144.7937 },
  { name: "Guatemala", code: "GT", latitude: 15.7835, longitude: -90.2308 },
  { name: "Guernsey", code: "GG", latitude: 49.4657, longitude: -2.5853 },
  { name: "Guinea", code: "GN", latitude: 9.9456, longitude: -9.6966 },
  { name: "Guinea-Bissau", code: "GW", latitude: 11.8037, longitude: -15.1804 },
  { name: "Guyana", code: "GY", latitude: 4.8604, longitude: -58.9302 },
  { name: "Haiti", code: "HT", latitude: 18.9712, longitude: -72.2852 },
  {
    name: "Heard Island and Mcdonald Islands",
    code: "HM",
    latitude: -53.0818,
    longitude: 73.5042,
  },
  {
    name: "Holy See (Vatican City State)",
    code: "VA",
    latitude: 41.9029,
    longitude: 12.4534,
  },
  { name: "Honduras", code: "HN", latitude: 15.2, longitude: -86.2419 },
  { name: "Hong Kong", code: "HK", latitude: 22.3193, longitude: 114.1694 },
  { name: "Hungary", code: "HU", latitude: 47.1625, longitude: 19.5033 },
  { name: "Iceland", code: "IS", latitude: 64.9631, longitude: -19.0208 },
  { name: "India", code: "IN", latitude: 20.5937, longitude: 78.9629 },
  { name: "Indonesia", code: "ID", latitude: -0.7893, longitude: 113.9213 },
  {
    name: "Iran, Islamic Republic Of",
    code: "IR",
    latitude: 32.4279,
    longitude: 53.688,
  },
  { name: "Iraq", code: "IQ", latitude: 33.2232, longitude: 43.6793 },
  { name: "Ireland", code: "IE", latitude: 53.1424, longitude: -7.6921 },
  { name: "Isle of Man", code: "IM", latitude: 54.2361, longitude: -4.5481 },
  { name: "Israel", code: "IL", latitude: 31.0461, longitude: 34.8516 },
  { name: "Italy", code: "IT", latitude: 41.8719, longitude: 12.5674 },
  { name: "Jamaica", code: "JM", latitude: 18.1096, longitude: -77.2975 },
  { name: "Japan", code: "JP", latitude: 36.2048, longitude: 138.2529 },
  { name: "Jersey", code: "JE", latitude: 49.2144, longitude: -2.1313 },
  { name: "Jordan", code: "JO", latitude: 30.5852, longitude: 36.2384 },
  { name: "Kazakhstan", code: "KZ", latitude: 48.0196, longitude: 66.9237 },
  { name: "Kenya", code: "KE", latitude: -1.2921, longitude: 36.8219 },
  { name: "Kiribati", code: "KI", latitude: -3.3704, longitude: -168.734 },
  {
    name: "Korea, Democratic People'S Republic of",
    code: "KP",
    latitude: 40.3399,
    longitude: 127.5101,
  },
  {
    name: "Korea, Republic of",
    code: "KR",
    latitude: 35.9078,
    longitude: 127.7669,
  },
  { name: "Kuwait", code: "KW", latitude: 29.3759, longitude: 47.9774 },
  { name: "Kyrgyzstan", code: "KG", latitude: 41.2044, longitude: 74.7661 },
  {
    name: "Lao People'S Democratic Republic",
    code: "LA",
    latitude: 19.8563,
    longitude: 102.4955,
  },
  { name: "Latvia", code: "LV", latitude: 56.8796, longitude: 24.6032 },
  { name: "Lebanon", code: "LB", latitude: 33.8547, longitude: 35.8623 },
  { name: "Lesotho", code: "LS", latitude: -29.609988, longitude: 28.233608 },
  { name: "Liberia", code: "LR", latitude: 6.428055, longitude: -9.429499 },
  {
    name: "Libyan Arab Jamahiriya",
    code: "LY",
    latitude: 26.3351,
    longitude: 17.2283,
  },
  { name: "Liechtenstein", code: "LI", latitude: 47.166, longitude: 9.5554 },
  { name: "Lithuania", code: "LT", latitude: 55.1694, longitude: 23.8813 },
  { name: "Luxembourg", code: "LU", latitude: 49.8153, longitude: 6.1296 },
  { name: "Macao", code: "MO", latitude: 22.1987, longitude: 113.5439 },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    code: "MK",
    latitude: 41.6086,
    longitude: 21.7453,
  },
  { name: "Madagascar", code: "MG", latitude: -18.7669, longitude: 46.8691 },
  { name: "Malawi", code: "MW", latitude: -13.2543, longitude: 34.3015 },
  { name: "Malaysia", code: "MY", latitude: 4.2105, longitude: 101.9758 },
  { name: "Maldives", code: "MV", latitude: 3.2028, longitude: 73.2207 },
  { name: "Mali", code: "ML", latitude: 17.5707, longitude: -3.9962 },
  { name: "Malta", code: "MT", latitude: 35.9375, longitude: 14.3754 },
  {
    name: "Marshall Islands",
    code: "MH",
    latitude: 7.1315,
    longitude: 171.1845,
  },
  { name: "Martinique", code: "MQ", latitude: 14.6415, longitude: -61.0242 },
  { name: "Mauritania", code: "MR", latitude: 21.0079, longitude: -10.9408 },
  { name: "Mauritius", code: "MU", latitude: -20.3485, longitude: 57.5522 },
  { name: "Mayotte", code: "YT", latitude: -12.8275, longitude: 45.1662 },
  { name: "Mexico", code: "MX", latitude: 23.6345, longitude: -102.5528 },
  {
    name: "Micronesia, Federated States of",
    code: "FM",
    latitude: 7.4256,
    longitude: 150.5508,
  },
  {
    name: "Moldova, Republic of",
    code: "MD",
    latitude: 47.4116,
    longitude: 28.3699,
  },
  { name: "Monaco", code: "MC", latitude: 43.7503, longitude: 7.4128 },
  { name: "Mongolia", code: "MN", latitude: 46.8625, longitude: 103.8467 },
  { name: "Montserrat", code: "MS", latitude: 16.7425, longitude: -62.1874 },
  { name: "Morocco", code: "MA", latitude: 31.7917, longitude: -7.0926 },
  { name: "Mozambique", code: "MZ", latitude: -18.6657, longitude: 35.5296 },
  { name: "Myanmar", code: "MM", latitude: 21.9162, longitude: 95.956 },
  { name: "Namibia", code: "NA", latitude: -22.9576, longitude: 18.4904 },
  { name: "Nauru", code: "NR", latitude: -0.5228, longitude: 166.9315 },
  { name: "Nepal", code: "NP", latitude: 28.3949, longitude: 84.124 },
  { name: "Netherlands", code: "NL", latitude: 52.1326, longitude: 5.2913 },
  {
    name: "Netherlands Antilles",
    code: "AN",
    latitude: 12.2261,
    longitude: -69.0601,
  }, // Note: Netherlands Antilles has been dissolved.
  { name: "New Caledonia", code: "NC", latitude: -20.9043, longitude: 165.618 },
  { name: "New Zealand", code: "NZ", latitude: -40.9006, longitude: 174.886 },
  { name: "Nicaragua", code: "NI", latitude: 12.8654, longitude: -85.2072 },
  { name: "Niger", code: "NE", latitude: 17.6078, longitude: 8.0817 },
  { name: "Nigeria", code: "NG", latitude: 9.082, longitude: 8.6753 },
  { name: "Niue", code: "NU", latitude: -19.0544, longitude: -169.8672 },
  {
    name: "Norfolk Island",
    code: "NF",
    latitude: -29.0408,
    longitude: 167.9547,
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
    latitude: 17.3308,
    longitude: 145.3847,
  },
  { name: "Norway", code: "NO", latitude: 60.472, longitude: 8.4689 },
  { name: "Oman", code: "OM", latitude: 21.5126, longitude: 55.9233 },
  { name: "Pakistan", code: "PK", latitude: 30.3753, longitude: 69.3451 },
  { name: "Palau", code: "PW", latitude: 7.5149, longitude: 134.5825 },
  {
    name: "Palestinian Territory, Occupied",
    code: "PS",
    latitude: 31.9522,
    longitude: 35.2332,
  },
  { name: "Panama", code: "PA", latitude: 8.538, longitude: -80.7821 },
  {
    name: "Papua New Guinea",
    code: "PG",
    latitude: -6.3149,
    longitude: 143.9555,
  },
  { name: "Paraguay", code: "PY", latitude: -23.4425, longitude: -58.4438 },
  { name: "Peru", code: "PE", latitude: -9.19, longitude: -75.0152 },
  { name: "Philippines", code: "PH", latitude: 12.8797, longitude: 121.774 },
  { name: "Pitcairn", code: "PN", latitude: -24.3768, longitude: -128.3242 },
  { name: "Poland", code: "PL", latitude: 51.9194, longitude: 19.1451 },
  { name: "Portugal", code: "PT", latitude: 39.3999, longitude: -8.2245 },
  { name: "Puerto Rico", code: "PR", latitude: 18.2208, longitude: -66.5901 },
  { name: "Qatar", code: "QA", latitude: 25.3548, longitude: 51.1839 },
  { name: "Reunion", code: "RE", latitude: -21.1151, longitude: 55.5364 },
  { name: "Romania", code: "RO", latitude: 45.9432, longitude: 24.9668 },
  {
    name: "Russian Federation",
    code: "RU",
    latitude: 61.524,
    longitude: 105.3188,
  },
  { name: "RWANDA", code: "RW", latitude: -1.9403, longitude: 29.8739 },
  { name: "Saint Helena", code: "SH", latitude: -15.965, longitude: -5.7089 },
  {
    name: "Saint Kitts and Nevis",
    code: "KN",
    latitude: 17.3578,
    longitude: -62.7832,
  },
  { name: "Saint Lucia", code: "LC", latitude: 13.9094, longitude: -60.9789 },
  {
    name: "Saint Pierre and Miquelon",
    code: "PM",
    latitude: 46.9419,
    longitude: -56.2711,
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    latitude: 12.9843,
    longitude: -61.2872,
  },
  { name: "Samoa", code: "WS", latitude: -13.759, longitude: -172.1046 },
  { name: "San Marino", code: "SM", latitude: 43.9424, longitude: 12.4578 },
  {
    name: "Sao Tome and Principe",
    code: "ST",
    latitude: 0.1864,
    longitude: 6.6131,
  },
  { name: "Saudi Arabia", code: "SA", latitude: 23.8859, longitude: 45.0792 },
  { name: "Senegal", code: "SN", latitude: 14.4974, longitude: -14.4524 },
  {
    name: "Serbia and Montenegro",
    code: "CS",
    latitude: 44.0165,
    longitude: 21.0059,
  }, // Note: Now separate countries as Serbia and Montenegro.
  { name: "Seychelles", code: "SC", latitude: -4.6796, longitude: 55.492 },
  { name: "Sierra Leone", code: "SL", latitude: 8.4606, longitude: -11.7799 },
  { name: "Singapore", code: "SG", latitude: 1.3521, longitude: 103.8198 },
  { name: "Slovakia", code: "SK", latitude: 48.669, longitude: 19.699 },
  { name: "Slovenia", code: "SI", latitude: 46.1512, longitude: 14.9955 },
  {
    name: "Solomon Islands",
    code: "SB",
    latitude: -9.7443,
    longitude: 160.0758,
  },
  { name: "Somalia", code: "SO", latitude: 5.1521, longitude: 46.1996 },
  { name: "South Africa", code: "ZA", latitude: -30.5595, longitude: 22.9375 },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    latitude: -54.4296,
    longitude: -36.5879,
  },
  { name: "Spain", code: "ES", latitude: 40.4637, longitude: -3.7492 },
  { name: "Sri Lanka", code: "LK", latitude: 7.8731, longitude: 80.7718 },
  { name: "Sudan", code: "SD", latitude: 12.8628, longitude: 30.2176 },
  { name: "Suriname", code: "SR", latitude: 3.9193, longitude: -56.0278 },
  {
    name: "Svalbard and Jan Mayen",
    code: "SJ",
    latitude: 77.5536,
    longitude: 23.6703,
  },
  { name: "Swaziland", code: "SZ", latitude: -26.5225, longitude: 31.4659 }, // Note: Now known as Eswatini.
  { name: "Sweden", code: "SE", latitude: 60.1282, longitude: 18.6435 },
  { name: "Switzerland", code: "CH", latitude: 46.8182, longitude: 8.2275 },
  {
    name: "Syrian Arab Republic",
    code: "SY",
    latitude: 34.8021,
    longitude: 38.9968,
  },
  {
    name: "Taiwan, Province of China",
    code: "TW",
    latitude: 23.6978,
    longitude: 120.9605,
  },
  { name: "Tajikistan", code: "TJ", latitude: 38.861, longitude: 71.2761 },
  {
    name: "Tanzania, United Republic of",
    code: "TZ",
    latitude: -6.369,
    longitude: 34.8888,
  },
  { name: "Thailand", code: "TH", latitude: 15.87, longitude: 100.9925 },
  { name: "Timor-Leste", code: "TL", latitude: -8.8742, longitude: 125.7275 },
  { name: "Togo", code: "TG", latitude: 8.6195, longitude: 0.8248 },
  { name: "Tokelau", code: "TK", latitude: -9.2002, longitude: -171.8484 },
  { name: "Tonga", code: "TO", latitude: -21.1789, longitude: -175.1982 },
  {
    name: "Trinidad and Tobago",
    code: "TT",
    latitude: 10.6918,
    longitude: -61.2225,
  },
  { name: "Tunisia", code: "TN", latitude: 33.8869, longitude: 9.5375 },
  { name: "Turkey", code: "TR", latitude: 38.9637, longitude: 35.2433 },
  { name: "Turkmenistan", code: "TM", latitude: 38.9697, longitude: 59.5563 },
  {
    name: "Turks and Caicos Islands",
    code: "TC",
    latitude: 21.694,
    longitude: -71.7979,
  },
  { name: "Tuvalu", code: "TV", latitude: -7.1095, longitude: 177.6493 },
  { name: "Uganda", code: "UG", latitude: 1.3733, longitude: 32.2903 },
  { name: "Ukraine", code: "UA", latitude: 48.3794, longitude: 31.1656 },
  {
    name: "United Arab Emirates",
    code: "AE",
    latitude: 23.4241,
    longitude: 53.8478,
  },
  { name: "United Kingdom", code: "GB", latitude: 55.3781, longitude: -3.436 },
  { name: "United States", code: "US", latitude: 37.0902, longitude: -95.7129 },
  {
    name: "United States Minor Outlying Islands",
    code: "UM",
    latitude: 19.2823,
    longitude: 166.647,
  },
  { name: "Uruguay", code: "UY", latitude: -32.5228, longitude: -55.7658 },
  { name: "Uzbekistan", code: "UZ", latitude: 41.3775, longitude: 64.5853 },
  { name: "Vanuatu", code: "VU", latitude: -15.3767, longitude: 166.9592 },
  { name: "Venezuela", code: "VE", latitude: 6.4238, longitude: -66.5897 },
  { name: "Viet Nam", code: "VN", latitude: 14.0583, longitude: 108.2772 },
  {
    name: "Virgin Islands, British",
    code: "VG",
    latitude: 18.4207,
    longitude: -64.64,
  },
  {
    name: "Virgin Islands, U.S.",
    code: "VI",
    latitude: 18.3358,
    longitude: -64.8963,
  },
  {
    name: "Wallis and Futuna",
    code: "WF",
    latitude: -13.7688,
    longitude: -177.1561,
  },
  {
    name: "Western Sahara",
    code: "EH",
    latitude: 24.2155,
    longitude: -12.8858,
  },
  { name: "Yemen", code: "YE", latitude: 15.5527, longitude: 48.5164 },
  { name: "Zambia", code: "ZM", latitude: -13.1339, longitude: 27.8493 },
  { name: "Zimbabwe", code: "ZW", latitude: -19.0154, longitude: 29.1549 },
];

export const LANGUAGES = [
  { code: "ab", name: "Abkhaz", nativeName: "аҧсуа" },
  { code: "aa", name: "Afar", nativeName: "Afaraf" },
  { code: "af", name: "Afrikaans", nativeName: "Afrikaans" },
  { code: "ak", name: "Akan", nativeName: "Akan" },
  { code: "sq", name: "Albanian", nativeName: "Shqip" },
  { code: "am", name: "Amharic", nativeName: "አማርኛ" },
  { code: "ar", name: "Arabic", nativeName: "العربية" },
  { code: "an", name: "Aragonese", nativeName: "Aragonés" },
  { code: "hy", name: "Armenian", nativeName: "Հայերեն" },
  { code: "as", name: "Assamese", nativeName: "অসমীয়া" },
  { code: "av", name: "Avaric", nativeName: "авар мацӀ, магӀарул мацӀ" },
  { code: "ae", name: "Avestan", nativeName: "avesta" },
  { code: "ay", name: "Aymara", nativeName: "aymar aru" },
  { code: "az", name: "Azerbaijani", nativeName: "azərbaycan dili" },
  { code: "bm", name: "Bambara", nativeName: "bamanankan" },
  { code: "ba", name: "Bashkir", nativeName: "башҡорт теле" },
  { code: "eu", name: "Basque", nativeName: "euskara, euskera" },
  { code: "be", name: "Belarusian", nativeName: "Беларуская" },
  { code: "bn", name: "Bengali", nativeName: "বাংলা" },
  { code: "bh", name: "Bihari", nativeName: "भोजपुरी" },
  { code: "bi", name: "Bislama", nativeName: "Bislama" },
  { code: "bs", name: "Bosnian", nativeName: "bosanski jezik" },
  { code: "br", name: "Breton", nativeName: "brezhoneg" },
  { code: "bg", name: "Bulgarian", nativeName: "български език" },
  { code: "my", name: "Burmese", nativeName: "ဗမာစာ" },
  { code: "ca", name: "Catalan; Valencian", nativeName: "Català" },
  { code: "ch", name: "Chamorro", nativeName: "Chamoru" },
  { code: "ce", name: "Chechen", nativeName: "нохчийн мотт" },
  {
    code: "ny",
    name: "Chichewa; Chewa; Nyanja",
    nativeName: "chiCheŵa, chinyanja",
  },
  { code: "zh", name: "Chinese", nativeName: "中文 (Zhōngwén), 汉语, 漢語" },
  { code: "cv", name: "Chuvash", nativeName: "чӑваш чӗлхи" },
  { code: "kw", name: "Cornish", nativeName: "Kernewek" },
  { code: "co", name: "Corsican", nativeName: "corsu, lingua corsa" },
  { code: "cr", name: "Cree", nativeName: "ᓀᐦᐃᔭᐍᐏᐣ" },
  { code: "hr", name: "Croatian", nativeName: "hrvatski" },
  { code: "cs", name: "Czech", nativeName: "česky, čeština" },
  { code: "da", name: "Danish", nativeName: "dansk" },
  { code: "dv", name: "Divehi; Dhivehi; Maldivian;", nativeName: "ދިވެހި" },
  { code: "nl", name: "Dutch", nativeName: "Nederlands, Vlaams" },
  { code: "en", name: "English", nativeName: "English" },
  { code: "eo", name: "Esperanto", nativeName: "Esperanto" },
  { code: "et", name: "Estonian", nativeName: "eesti, eesti keel" },
  { code: "ee", name: "Ewe", nativeName: "Eʋegbe" },
  { code: "fo", name: "Faroese", nativeName: "føroyskt" },
  { code: "fj", name: "Fijian", nativeName: "vosa Vakaviti" },
  { code: "fi", name: "Finnish", nativeName: "suomi, suomen kieli" },
  { code: "fr", name: "French", nativeName: "français, langue française" },
  {
    code: "ff",
    name: "Fula; Fulah; Pulaar; Pular",
    nativeName: "Fulfulde, Pulaar, Pular",
  },
  { code: "gl", name: "Galician", nativeName: "Galego" },
  { code: "ka", name: "Georgian", nativeName: "ქართული" },
  { code: "de", name: "German", nativeName: "Deutsch" },
  { code: "el", name: "Greek, Modern", nativeName: "Ελληνικά" },
  { code: "gn", name: "Guaraní", nativeName: "Avañeẽ" },
  { code: "gu", name: "Gujarati", nativeName: "ગુજરાતી" },
  { code: "ht", name: "Haitian; Haitian Creole", nativeName: "Kreyòl ayisyen" },
  { code: "ha", name: "Hausa", nativeName: "Hausa, هَوُسَ" },
  { code: "he", name: "Hebrew (modern)", nativeName: "עברית" },
  { code: "hz", name: "Herero", nativeName: "Otjiherero" },
  { code: "hi", name: "Hindi", nativeName: "हिन्दी, हिंदी" },
  { code: "ho", name: "Hiri Motu", nativeName: "Hiri Motu" },
  { code: "hu", name: "Hungarian", nativeName: "Magyar" },
  { code: "ia", name: "Interlingua", nativeName: "Interlingua" },
  { code: "id", name: "Indonesian", nativeName: "Bahasa Indonesia" },
  {
    code: "ie",
    name: "Interlingue",
    nativeName: "Originally called Occidental; then Interlingue after WWII",
  },
  { code: "ga", name: "Irish", nativeName: "Gaeilge" },
  { code: "ig", name: "Igbo", nativeName: "Asụsụ Igbo" },
  { code: "ik", name: "Inupiaq", nativeName: "Iñupiaq, Iñupiatun" },
  { code: "io", name: "Ido", nativeName: "Ido" },
  { code: "is", name: "Icelandic", nativeName: "Íslenska" },
  { code: "it", name: "Italian", nativeName: "Italiano" },
  { code: "iu", name: "Inuktitut", nativeName: "ᐃᓄᒃᑎᑐᑦ" },
  { code: "ja", name: "Japanese", nativeName: "日本語 (にほんご／にっぽんご)" },
  { code: "jv", name: "Javanese", nativeName: "basa Jawa" },
  {
    code: "kl",
    name: "Kalaallisut, Greenlandic",
    nativeName: "kalaallisut, kalaallit oqaasii",
  },
  { code: "kn", name: "Kannada", nativeName: "ಕನ್ನಡ" },
  { code: "kr", name: "Kanuri", nativeName: "Kanuri" },
  { code: "ks", name: "Kashmiri", nativeName: "कश्मीरी, كشميري‎" },
  { code: "kk", name: "Kazakh", nativeName: "Қазақ тілі" },
  { code: "km", name: "Khmer", nativeName: "ភាសាខ្មែរ" },
  { code: "ki", name: "Kikuyu, Gikuyu", nativeName: "Gĩkũyũ" },
  { code: "rw", name: "Kinyarwanda", nativeName: "Ikinyarwanda" },
  { code: "ky", name: "Kirghiz, Kyrgyz", nativeName: "кыргыз тили" },
  { code: "kv", name: "Komi", nativeName: "коми кыв" },
  { code: "kg", name: "Kongo", nativeName: "KiKongo" },
  {
    code: "ko",
    name: "Korean",
    nativeName: "한국어 (韓國語), 조선말 (朝鮮語)",
  },
  { code: "ku", name: "Kurdish", nativeName: "Kurdî, كوردی‎" },
  { code: "kj", name: "Kwanyama, Kuanyama", nativeName: "Kuanyama" },
  { code: "la", name: "Latin", nativeName: "latine, lingua latina" },
  {
    code: "lb",
    name: "Luxembourgish, Letzeburgesch",
    nativeName: "Lëtzebuergesch",
  },
  { code: "lg", name: "Luganda", nativeName: "Luganda" },
  {
    code: "li",
    name: "Limburgish, Limburgan, Limburger",
    nativeName: "Limburgs",
  },
  { code: "ln", name: "Lingala", nativeName: "Lingála" },
  { code: "lo", name: "Lao", nativeName: "ພາສາລາວ" },
  { code: "lt", name: "Lithuanian", nativeName: "lietuvių kalba" },
  { code: "lu", name: "Luba-Katanga", nativeName: "" },
  { code: "lv", name: "Latvian", nativeName: "latviešu valoda" },
  { code: "gv", name: "Manx", nativeName: "Gaelg, Gailck" },
  { code: "mk", name: "Macedonian", nativeName: "македонски јазик" },
  { code: "mg", name: "Malagasy", nativeName: "Malagasy fiteny" },
  { code: "ms", name: "Malay", nativeName: "bahasa Melayu, بهاس ملايو‎" },
  { code: "ml", name: "Malayalam", nativeName: "മലയാളം" },
  { code: "mt", name: "Maltese", nativeName: "Malti" },
  { code: "mi", name: "Māori", nativeName: "te reo Māori" },
  { code: "mr", name: "Marathi (Marāṭhī)", nativeName: "मराठी" },
  { code: "mh", name: "Marshallese", nativeName: "Kajin M̧ajeļ" },
  { code: "mn", name: "Mongolian", nativeName: "монгол" },
  { code: "na", name: "Nauru", nativeName: "Ekakairũ Naoero" },
  { code: "nv", name: "Navajo, Navaho", nativeName: "Diné bizaad, Dinékʼehǰí" },
  { code: "nb", name: "Norwegian Bokmål", nativeName: "Norsk bokmål" },
  { code: "nd", name: "North Ndebele", nativeName: "isiNdebele" },
  { code: "ne", name: "Nepali", nativeName: "नेपाली" },
  { code: "ng", name: "Ndonga", nativeName: "Owambo" },
  { code: "nn", name: "Norwegian Nynorsk", nativeName: "Norsk nynorsk" },
  { code: "no", name: "Norwegian", nativeName: "Norsk" },
  { code: "ii", name: "Nuosu", nativeName: "ꆈꌠ꒿ Nuosuhxop" },
  { code: "nr", name: "South Ndebele", nativeName: "isiNdebele" },
  { code: "oc", name: "Occitan", nativeName: "Occitan" },
  { code: "oj", name: "Ojibwe, Ojibwa", nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ" },
  {
    code: "cu",
    name: "Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic",
    nativeName: "ѩзыкъ словѣньскъ",
  },
  { code: "om", name: "Oromo", nativeName: "Afaan Oromoo" },
  { code: "or", name: "Oriya", nativeName: "ଓଡ଼ିଆ" },
  { code: "os", name: "Ossetian, Ossetic", nativeName: "ирон æвзаг" },
  { code: "pa", name: "Panjabi, Punjabi", nativeName: "ਪੰਜਾਬੀ, پنجابی‎" },
  { code: "pi", name: "Pāli", nativeName: "पाऴि" },
  { code: "fa", name: "Persian", nativeName: "فارسی" },
  { code: "pl", name: "Polish", nativeName: "polski" },
  { code: "ps", name: "Pashto, Pushto", nativeName: "پښتو" },
  { code: "pt", name: "Portuguese", nativeName: "Português" },
  { code: "qu", name: "Quechua", nativeName: "Runa Simi, Kichwa" },
  { code: "rm", name: "Romansh", nativeName: "rumantsch grischun" },
  { code: "rn", name: "Kirundi", nativeName: "kiRundi" },
  { code: "ro", name: "Romanian, Moldavian, Moldovan", nativeName: "română" },
  { code: "ru", name: "Russian", nativeName: "русский язык" },
  { code: "sa", name: "Sanskrit (Saṁskṛta)", nativeName: "संस्कृतम्" },
  { code: "sc", name: "Sardinian", nativeName: "sardu" },
  { code: "sd", name: "Sindhi", nativeName: "सिन्धी, سنڌي، سندھی‎" },
  { code: "se", name: "Northern Sami", nativeName: "Davvisámegiella" },
  { code: "sm", name: "Samoan", nativeName: "gagana faa Samoa" },
  { code: "sg", name: "Sango", nativeName: "yângâ tî sängö" },
  { code: "sr", name: "Serbian", nativeName: "српски језик" },
  { code: "gd", name: "Scottish Gaelic; Gaelic", nativeName: "Gàidhlig" },
  { code: "sn", name: "Shona", nativeName: "chiShona" },
  { code: "si", name: "Sinhala, Sinhalese", nativeName: "සිංහල" },
  { code: "sk", name: "Slovak", nativeName: "slovenčina" },
  { code: "sl", name: "Slovene", nativeName: "slovenščina" },
  { code: "so", name: "Somali", nativeName: "Soomaaliga, af Soomaali" },
  { code: "st", name: "Southern Sotho", nativeName: "Sesotho" },
  { code: "es", name: "Spanish; Castilian", nativeName: "español, castellano" },
  { code: "su", name: "Sundanese", nativeName: "Basa Sunda" },
  { code: "sw", name: "Swahili", nativeName: "Kiswahili" },
  { code: "ss", name: "Swati", nativeName: "SiSwati" },
  { code: "sv", name: "Swedish", nativeName: "svenska" },
  { code: "ta", name: "Tamil", nativeName: "தமிழ்" },
  { code: "te", name: "Telugu", nativeName: "తెలుగు" },
  { code: "tg", name: "Tajik", nativeName: "тоҷикӣ, toğikī, تاجیکی‎" },
  { code: "th", name: "Thai", nativeName: "ไทย" },
  { code: "ti", name: "Tigrinya", nativeName: "ትግርኛ" },
  {
    code: "bo",
    name: "Tibetan Standard, Tibetan, Central",
    nativeName: "བོད་ཡིག",
  },
  { code: "tk", name: "Turkmen", nativeName: "Türkmen, Түркмен" },
  { code: "tl", name: "Tagalog", nativeName: "Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔" },
  { code: "tn", name: "Tswana", nativeName: "Setswana" },
  { code: "to", name: "Tonga (Tonga Islands)", nativeName: "faka Tonga" },
  { code: "tr", name: "Turkish", nativeName: "Türkçe" },
  { code: "ts", name: "Tsonga", nativeName: "Xitsonga" },
  { code: "tt", name: "Tatar", nativeName: "татарча, tatarça, تاتارچا‎" },
  { code: "tw", name: "Twi", nativeName: "Twi" },
  { code: "ty", name: "Tahitian", nativeName: "Reo Tahiti" },
  { code: "ug", name: "Uighur, Uyghur", nativeName: "Uyƣurqə, ئۇيغۇرچە‎" },
  { code: "uk", name: "Ukrainian", nativeName: "українська" },
  { code: "ur", name: "Urdu", nativeName: "اردو" },
  { code: "uz", name: "Uzbek", nativeName: "zbek, Ўзбек, أۇزبېك‎" },
  { code: "ve", name: "Venda", nativeName: "Tshivenḓa" },
  { code: "vi", name: "Vietnamese", nativeName: "Tiếng Việt" },
  { code: "vo", name: "Volapük", nativeName: "Volapük" },
  { code: "wa", name: "Walloon", nativeName: "Walon" },
  { code: "cy", name: "Welsh", nativeName: "Cymraeg" },
  { code: "wo", name: "Wolof", nativeName: "Wollof" },
  { code: "fy", name: "Western Frisian", nativeName: "Frysk" },
  { code: "xh", name: "Xhosa", nativeName: "isiXhosa" },
  { code: "yi", name: "Yiddish", nativeName: "ייִדיש" },
  { code: "yo", name: "Yoruba", nativeName: "Yorùbá" },
  { code: "za", name: "Zhuang, Chuang", nativeName: "Saɯ cueŋƅ, Saw cuengh" },
];

export const EXPERIENCE = [
  {
    value: 0,
    label: "No experience",
  },
  {
    value: 1,
    label: "More than 1 year",
  },
  {
    value: 2,
    label: "More than 2 years",
  },
  {
    value: 3,
    label: "More than 3 years",
  },
  {
    value: 4,
    label: "More than 4 years",
  },
  {
    value: 5,
    label: "More than 5 years",
  },
  {
    value: 10,
    label: "More than 10 years",
  },
  {
    value: 15,
    label: "More than 15 years",
  },
];

export const LEVELS = [
  {
    value: "A1",
    label: "Beginner",
  },
  {
    value: "A2",
    label: "Elementary",
  },
  {
    value: "B1",
    label: "Pre-Intermediate",
  },
  {
    value: "B2",
    label: "Intermediate",
  },
  {
    value: "C1",
    label: "Upper-Intermediate",
  },
  {
    value: "C2",
    label: "Advanced",
  },
];

export const FREQUENCIES = [
  {
    short: "d",
    long: "Daily",
  },
  {
    short: "w",
    long: "Weekly",
  },
  {
    short: "m",
    long: "Monthly",
  },
  {
    short: "y",
    long: "Yearly",
  },
];
