import {
  Box,
  CardContent,
  IconButton,
  Typography,
  Chip,
  Snackbar,
} from "@mui/material";
import Card from "@mui/material/Card";
import { forwardRef, useEffect, useState } from "react";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import DeleteIcon from "@mui/icons-material/Delete";
import { Skeleton } from "@mui/material";
import {
  Vocabulary,
  VocabularyPaginated,
} from "../../../types/linguisticsTypes";
import { useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../hooks/useTypedSelector";
import { deleteVocabulary } from "../../../features/linguistics/deleteVocabularySlice";
import { borderColor } from "../../theme/colors";

const url = process.env.REACT_APP_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

function getTodaysDate(): string {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1 and pad start with '0' if needed
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function formatDateToHumanReadable(dateString: string): string {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short",
  };

  return date.toLocaleDateString("en-US", options);
}

// Define the Alert component
const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface AppProps {
  VocabularyPaginated: VocabularyPaginated | null;
  language: string;
  languagesPayload: any;
}

export const WordCards = ({
  VocabularyPaginated,
  language,
  languagesPayload,
}: AppProps) => {
  const [vocabularyList, setVocabularyList] = useState<Vocabulary[]>([
    {
      uuid: "",
      word: {
        uuid: "",
        text: "",
        language: "",
        is_deleted: false,
        parts_of_speech: [],
        lexemes: [],
        user_interactions: 0,
        translations: [],
      },
      created_at: "",
    },
  ]);

  const [open, setOpen] = useState<boolean>(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const navigate = useNavigate();
  const handleShow = (uuid: string) => {
    navigate(`/word/${uuid}`);
  };

  const handleShare = (uuid: string) => {
    navigator.clipboard.writeText(`${url}/word/` + uuid);
    setOpen(true);
  };

  useEffect(() => {
    if (VocabularyPaginated && VocabularyPaginated.results) {
      setVocabularyList(VocabularyPaginated.results);
    }
  }, [VocabularyPaginated]);

  const handleDelete = (uuid: string) => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      deleteVocabulary({
        access_token: access_token,
        vocabulary_uuid: uuid,
      })
    );

    setVocabularyList((prevList) => {
      return prevList.filter((vocab) => {
        //console.log("Current vocab's UUID:", vocab.uuid); // This will log the uuid of each vocab as it's checked
        //console.log("uuid to delete: ", uuid);
        return vocab.uuid !== uuid;
      });
    });
  };
  const dispatch = useAppDispatch();
  /* 
  GLOBAL STATE:
  */
  const { deleteVocabularyPayload, deleteVocabularyLoading } = useAppSelector(
    (state) => state.deleteVocabulary
  );

  console.log("vocabularyList", vocabularyList);

  return (
    <Box>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
          gap: "2px", // Adjust the gap as needed
        }}
      >
        {vocabularyList &&
          vocabularyList.map((result: Vocabulary, index: number) =>
            result.uuid !== "" ? (
              <Card
                key={index}
                style={{ marginBottom: "16px" }}
                variant="outlined"
              >
                <CardContent>
                  <Chip
                    label={
                      result.word &&
                      result.word.text &&
                      result.word.text.length > 100
                        ? `${result.word.text.slice(0, 100)}...`
                        : result.word.text
                    }
                    size="small"
                    sx={{
                      mt: 1,
                      mb: 2,
                      backgroundColor:
                        "human" === "human" ? borderColor.pastel5 : "#FFD3B6", // Pastel green and pastel red
                      color: "black",
                      fontSize: 12,
                    }}
                  />
                  <Typography variant="body1">
                    {result.word.language}
                  </Typography>
                  <Typography variant="body2">
                    {formatDateToHumanReadable(result.created_at)}
                  </Typography>

                  <Typography variant="body2" sx={{ mt: 3 }}>
                    {result.word.parts_of_speech.map(
                      (pos: string, posIndex: number) => (
                        <span key={posIndex}>{pos} </span>
                      )
                    )}
                  </Typography>
                </CardContent>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "8px",
                  }}
                >
                  <IconButton
                    aria-label="Delete"
                    onClick={() => handleDelete(result.uuid)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Card>
            ) : (
              <div key={index}>
                <Skeleton
                  variant="rectangular"
                  width="90%"
                  height="90%"
                  sx={{
                    mb: 2,
                    ml: 2,
                    mr: 2,
                    borderRadius: "5px",
                    minHeight: "100px",
                  }}
                />
              </div>
            )
          )}
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Copied Link to Clipboard!
        </Alert>
      </Snackbar>
    </Box>
  );
};
