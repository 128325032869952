import {
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Stack,
} from "@mui/material";
import { FC, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../hooks/useTypedSelector";
import Copyright from "../../Copyright/Copyright";
import Box from "@mui/material/Box";
import AuthContext from "../../../context/AuthContext";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListIcon from "@mui/icons-material/List";
import InfoIcon from "@mui/icons-material/Info";
import MessageIcon from "@mui/icons-material/Message";
import useMatchMedia from "../../../hooks/matchMedia";
import UserInfoSimple from "./TeacherNominations/UserInfo/UserInfoSimple";
import { getClassroom } from "../../../features/classrooms/getClassroomSlice";
import ClassroomInfo from "./ClassroomInfo/ClassroomInfo";
import { IEvent } from "../../Dashboard/types";
import ChatNoChannels from "../../Chat/ChatNoChannels";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SubscriberInfo from "./SubscriberInfo/SubscriberInfo";
import { backgroundColor, borderColor } from "../../theme/colors";
import SchedulerOne from "./Scheduler/SchedulerOne";
import ShowTeacherInvitations from "./TeacherNominations/TeacherNominations";
import HailIcon from "@mui/icons-material/Hail";
import TeacherInvite from "./NominateTeacher/NominateTeacher";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import { joinGroup } from "../../../features/classrooms/joinGroupSlice";
import { leaveGroup } from "../../../features/classrooms/leaveGroupSlice";
import PendingRequests from "./PendingRequests/PendingRequests";
import { acceptJoinGroup } from "../../../features/classrooms/acceptJoinGroupSlice";
import UserSkeleton from "../../Skeletons/UserSkeleton";
import GroupInfoSkeleton from "../../Skeletons/GroupInfoSkeleton";

var img_url = "";

if (process.env.STORAGE_TYPE === "S3") {
  img_url = `https://${process.env.AWS_STORAGE_BUCKET_NAME}.s3.amazonaws.com/`;
}

const DashboardOne: FC = () => {
  let { uuid } = useParams();

  const isDesktopResolution = useMatchMedia("(min-width:992px)", true);

  const dispatch = useAppDispatch();

  // times
  const [adjustedTimes, setAdjustedTimes] = useState<IEvent[] | null>(null);

  const { currentUser, isAuthenticated } = useContext(AuthContext);
  const { getUserProfileLoading, getUserProfilePayload } = useAppSelector(
    (state) => state.getUserProfile
  );
  const { getClassroomLoading, getClassroomPayload } = useAppSelector(
    (state) => state.getClassroom
  );
  const [selectedTab, setSelectedTab] = useState(0);

  function handleAcceptUser(user_uuid: string) {
    if (!isAuthenticated) return;
    if (!currentUser.profile_uuid) return;

    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;

    dispatch(
      acceptJoinGroup({
        access_token: access_token,
        groupId: getClassroomPayload.uuid,
        userId: user_uuid,
      })
    ).then((result) => {
      // check if the dispatch was fulfilled
      if (result.meta.requestStatus === "fulfilled") {
        dispatch(
          getClassroom({
            access_token: access_token,
            classroomId: getClassroomPayload.uuid || "",
          })
        );
      }
    });
  }

  // is author or subscriber
  const isSubscriber =
    getClassroomPayload &&
    getClassroomPayload.subscribers_details &&
    getClassroomPayload.subscribers_details.some(
      (subscriber) => subscriber.uuid === currentUser.uuid
    );
  const isPendingSubscriber =
    getClassroomPayload &&
    getClassroomPayload.subscribers_pending_details &&
    getClassroomPayload.subscribers_pending_details.some(
      (subscriber) => subscriber.uuid === currentUser.uuid
    );
  const isAuthor =
    getClassroomPayload.author_details &&
    getClassroomPayload.author_details.uuid &&
    getClassroomPayload.author_details.uuid === currentUser.uuid;

  const isPaid = getClassroomPayload && getClassroomPayload.paid;

  function handleRejectUser(user_uuid: string) {
    console.log("rejecting");
  }

  function handleJoinGroup() {
    if (!isAuthenticated) return;
    if (!currentUser.profile_uuid) return;

    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;

    dispatch(
      joinGroup({
        access_token: access_token,
        groupId: getClassroomPayload.uuid,
      })
    ).then((result) => {
      // check if the dispatch was fulfilled
      if (result.meta.requestStatus === "fulfilled") {
        dispatch(
          getClassroom({
            access_token: access_token,
            classroomId: getClassroomPayload.uuid || "",
          })
        );
      }
    });
  }

  // debug

  console.log("getClassroomPayload", getClassroomPayload);

  function handleLeaveGroup() {
    if (!isAuthenticated) return;
    if (!currentUser.profile_uuid) return;

    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;

    dispatch(
      leaveGroup({
        access_token: access_token,
        groupId: getClassroomPayload.uuid,
      })
    ).then((result) => {
      // check if the dispatch was fulfilled
      if (result.meta.requestStatus === "fulfilled") {
        dispatch(
          getClassroom({
            access_token: access_token,
            classroomId: getClassroomPayload.uuid || "",
          })
        );
      }
    });
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {/* Left Row */}
        {isDesktopResolution ? (
          <Grid item xs={12} md={4} lg={4}>
            {/* Classroom Info */}
            <Box sx={{ mb: 3 }}>
              {getClassroomPayload &&
              getClassroomPayload.calendar_events_details &&
              getClassroomPayload.calendar_events_details.length > 0 &&
              getClassroomPayload.calendar_events_details[0].start &&
              getUserProfilePayload ? (
                <ClassroomInfo
                  classroom={getClassroomPayload}
                  loading={getClassroomLoading}
                  userProfile={getUserProfilePayload}
                  adjustedTimes={adjustedTimes}
                  title={"Group Info"}
                />
              ) : (
                <GroupInfoSkeleton />
              )}
            </Box>
            {/* Join Group Button */}
            {/* If Current User is the owner */}
            {getClassroomPayload && isAuthor ? (
              <PendingRequests
                users={getClassroomPayload.subscribers_pending_details}
                loading={getClassroomLoading}
                title="Pending Join Requests"
                onAccept={(userId) => handleAcceptUser(userId)}
                onReject={(userId) => handleRejectUser(userId)}
              />
            ) : (
              // Other conditions for Join, Leave, and Pending buttons
              <>
                {getClassroomPayload.subscribers_details &&
                isPendingSubscriber ? (
                  <Button
                    variant="outlined"
                    fullWidth
                    style={{
                      marginBottom: 30,
                      backgroundColor: backgroundColor.pastel5,
                    }}
                    disabled
                  >
                    Pending
                  </Button>
                ) : getClassroomPayload.subscribers_details && isSubscriber ? (
                  <Button
                    variant="outlined"
                    fullWidth
                    style={{
                      marginBottom: 30,
                      backgroundColor: backgroundColor.pastel3,
                    }}
                    onClick={handleLeaveGroup}
                  >
                    Leave Group
                  </Button>
                ) : (
                  getClassroomPayload.subscribers_details && (
                    <Button
                      variant="outlined"
                      fullWidth
                      style={{
                        marginBottom: 30,
                        backgroundColor: backgroundColor.pastel4,
                      }}
                      onClick={handleJoinGroup}
                    >
                      Join Group
                    </Button>
                  )
                )}
              </>
            )}

            {/* Created By */}
            <Box sx={{ mb: 3 }}>
              {getUserProfilePayload &&
                getClassroomPayload.author_details &&
                getUserProfilePayload.username && (
                  <UserInfoSimple
                    authorProfile={getClassroomPayload.author_details}
                    loading={getUserProfileLoading}
                    title={"Author"}
                  />
                )}
            </Box>
          </Grid>
        ) : null}

        {/* End Left Row */}
        {/* Right Row */}
        <Grid item xs={12} md={8} lg={8}>
          {/* Add another navbar inside grid */}
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
          >
            {!isDesktopResolution ? (
              <>
                <IconButton
                  aria-label="List"
                  color={"primary"}
                  onClick={() => {
                    setSelectedTab(10);
                  }}
                >
                  <InfoIcon />
                </IconButton>
                <IconButton
                  aria-label="Info"
                  color={"primary"}
                  onClick={() => {
                    setSelectedTab(0);
                  }}
                >
                  <ListIcon />
                </IconButton>
              </>
            ) : null}
            <IconButton
              aria-label="Calendar"
              color={"primary"}
              onClick={() => {
                setSelectedTab(3);
              }}
            >
              <CalendarMonthIcon />
            </IconButton>

            {isAuthor || isSubscriber ? (
              <>
                <IconButton
                  aria-label="New Messages"
                  color={"primary"}
                  onClick={() => {
                    setSelectedTab(2);
                  }}
                >
                  <MessageIcon />
                </IconButton>

                {isPaid ? (
                  <>
                    <IconButton
                      aria-label="Nominations"
                      color={"primary"}
                      onClick={() => {
                        setSelectedTab(4);
                      }}
                    >
                      <HailIcon />
                    </IconButton>
                    <IconButton
                      aria-label="Status"
                      color={"primary"}
                      onClick={() => {
                        setSelectedTab(5);
                      }}
                    >
                      <MilitaryTechIcon />
                    </IconButton>
                  </>
                ) : (
                  <></>
                )}

                {isDesktopResolution ? (
                  <Button disabled>
                    Enter Virtual Classroom <PlayArrowIcon />
                  </Button>
                ) : null}
              </>
            ) : (
              <></>
            )}
          </Stack>

          {/* This is the users component */}
          {selectedTab === 0 && (
            <Box sx={{ mt: 2 }}>
              <SubscriberInfo />
            </Box>
          )}

          {selectedTab === 2 && (
            <Box sx={{ mt: 2 }}>
              <ChatNoChannels />
            </Box>
          )}
          {/* This is the scheduler component */}
          {selectedTab === 3 && (
            <Box sx={{ mt: 2 }}>
              <SchedulerOne />
            </Box>
          )}
          {/* This is the invite teacher component */}
          {selectedTab === 4 && (
            <Box sx={{ mt: 4 }}>
              <TeacherInvite />
            </Box>
          )}
          {selectedTab === 5 && (
            <Box sx={{ mt: 4 }}>
              <ShowTeacherInvitations />
            </Box>
          )}
          {selectedTab === 10 && (
            <Box sx={{ mt: 2 }}>
              {/* Classroom Info */}
              <Box sx={{ mb: 3 }}>
                {getClassroomPayload &&
                getClassroomPayload.calendar_events_details &&
                getClassroomPayload.calendar_events_details.length > 0 &&
                getClassroomPayload.calendar_events_details[0].start &&
                getUserProfilePayload ? (
                  <ClassroomInfo
                    classroom={getClassroomPayload}
                    loading={getClassroomLoading}
                    userProfile={getUserProfilePayload}
                    adjustedTimes={adjustedTimes}
                    title={"Group Info"}
                  />
                ) : (
                  <GroupInfoSkeleton />
                )}
              </Box>
              {/* Join Group Button */}
              {/* If Current User is the owner */}
              {getClassroomPayload && isAuthor ? (
                <PendingRequests
                  users={getClassroomPayload.subscribers_pending_details}
                  loading={getClassroomLoading}
                  title="Pending Join Requests"
                  onAccept={(userId) => handleAcceptUser(userId)}
                  onReject={(userId) => handleRejectUser(userId)}
                />
              ) : (
                // Other conditions for Join, Leave, and Pending buttons
                <>
                  {getClassroomPayload.subscribers_details &&
                  isPendingSubscriber ? (
                    <Button
                      variant="outlined"
                      fullWidth
                      style={{
                        marginBottom: 30,
                        backgroundColor: backgroundColor.pastel5,
                      }}
                      disabled
                    >
                      Pending
                    </Button>
                  ) : getClassroomPayload.subscribers_details &&
                    isSubscriber ? (
                    <Button
                      variant="outlined"
                      fullWidth
                      style={{
                        marginBottom: 30,
                        backgroundColor: backgroundColor.pastel3,
                      }}
                      onClick={handleLeaveGroup}
                    >
                      Leave Group
                    </Button>
                  ) : (
                    getClassroomPayload.subscribers_details && (
                      <Button
                        variant="outlined"
                        fullWidth
                        style={{
                          marginBottom: 30,
                          backgroundColor: backgroundColor.pastel4,
                        }}
                        onClick={handleJoinGroup}
                      >
                        Join Group
                      </Button>
                    )
                  )}
                </>
              )}

              {/* Created By */}
              <Box sx={{ mb: 3 }}>
                {getUserProfilePayload &&
                  getClassroomPayload.author_details &&
                  getUserProfilePayload.username && (
                    <UserInfoSimple
                      authorProfile={getClassroomPayload.author_details}
                      loading={getUserProfileLoading}
                      title={"Author"}
                    />
                  )}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
};

export default DashboardOne;
