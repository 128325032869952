import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";

export default function NoPage() {
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid item xs>
            <h1>404 not found</h1>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
