import React from "react";

const Privacy = () => {
  const serviceName = "Groupifier";
  const websiteName = "groupifier.com";
  const websiteURL = "https://groupifier.com";
  const companyName = "Groupifier, LLC";
  const companyNameShort = "Groupifier";
  const contactEmail = "admin@groupifier.com";

  return (
    <>
      <div>
        <h1>Privacy Policy</h1>
        <p>Last Updated: 2022-10-06</p>
        <p>
          {websiteName} ("we", "us", or "our") is committed to protecting your
          privacy. This Privacy Policy ("Policy") describes how we collect, use,
          and disclose information when you access or use the {websiteName}{" "}
          website located at {websiteURL} (the "Site"), or any apps or other
          services offered or operated by {websiteName} (collectively, the
          "Services").
        </p>
        <h2>1. INFORMATION WE COLLECT</h2>
        <h3>1.1 Personal Information</h3>
        <p>
          We may collect personal information from you, such as your name, email
          address, and other contact information when you voluntarily provide it
          to us through the Services, such as when you create an account,
          contact us, or participate in surveys or promotions.
        </p>
        <h3>1.2 Usage Information</h3>
        <p>
          We may automatically collect certain information about how you access
          and use the Services, including your IP address, browser type,
          operating system, device information, and usage patterns.
        </p>
        <h3>1.3 Cookies and Tracking Technologies</h3>
        <p>
          We may use cookies, web beacons, and other tracking technologies to
          collect information about your use of the Services and to improve your
          experience. You can disable cookies through your browser settings, but
          please note that disabling cookies may affect the functionality of the
          Services.
        </p>
        <h2>2. HOW WE USE YOUR INFORMATION</h2>
        <h3>2.1 Provide and Improve the Services</h3>
        <p>
          We may use the information we collect to provide and improve the
          Services, including to customize content, features, and
          advertisements, and to communicate with you about updates, promotions,
          and other relevant information.
        </p>
        <h3>2.2 Analytics and Research</h3>
        <p>
          We may use aggregated or de-identified information for analytics,
          research, and reporting purposes, to analyze usage trends, understand
          user preferences, and improve the Services.
        </p>
        <h3>2.3 Legal Requirements</h3>
        <p>
          We may use your information to comply with applicable laws,
          regulations, and legal processes, and to protect the rights, property,
          or safety of {websiteName}, its users, and others.
        </p>
        <h2>3. HOW WE SHARE YOUR INFORMATION</h2>
        <h3>3.1 Service Providers</h3>
        <p>
          We may share your information with third-party service providers who
          assist us in providing the Services, such as hosting, data analytics,
          customer support, and marketing services. These service providers are
          bound by confidentiality obligations and are prohibited from using
          your information for any other purpose.
        </p>
        <h3>3.2 Business Transfers</h3>
        <p>
          We may share your information in connection with a merger,
          acquisition, consolidation, or sale of all or a portion of our assets,
          as well as in the event of bankruptcy or other corporate
          reorganization.
        </p>
        <h3>3.3 Legal Requirements</h3>
        <p>
          We may disclose your information to comply with applicable laws,
          regulations, legal processes, or government requests, and to protect
          the rights, property, or safety of {websiteName}, its users, and
          others.
        </p>
        <h3>3.4 Aggregated or De-identified Information</h3>
        <p>
          We may share aggregated or de-identified information, which cannot be
          used to identify you personally, with third parties for various
          purposes, including analytics, research, and marketing.
        </p>
        <h3>3.5 With Your Consent</h3>
        <p>
          We may share your information with third parties for other purposes
          with your consent, which you may provide through the Services or
          through other means.
        </p>
        <h2>4. YOUR CHOICES</h2>
        <h3>4.1 Account Information</h3>
        <p>
          You may review, update, or delete your account information by logging
          into your account settings or contacting us. Please note that deleting
          your account may result in the loss of access to certain features and
          services.
        </p>
        <h3>4.2 Communications</h3>
        <p>
          You may opt out of receiving promotional communications from us by
          following the instructions in those communications or by contacting
          us. Please note that even if you opt out of receiving promotional
          communications, we may still send you non-promotional messages related
          to your use of the Services, such as administrative or transactional
          messages.
        </p>
        <h3>4.3 Cookies</h3>
        <p>
          You can disable cookies through your browser settings or other
          settings in your device, but please note that disabling cookies may
          affect the functionality of the Services.
        </p>
        <h2>5. SECURITY</h2>
        <p>
          We take reasonable measures to protect the information we collect from
          loss, theft, misuse, and unauthorized access, disclosure, alteration,
          and destruction. However, no security measures are perfect and we
          cannot guarantee the security of your information.
        </p>
        <h2>6. LINKS TO THIRD-PARTY SITES</h2>
        <p>
          The Services may contain links to third-party websites or resources
          that are not operated or controlled by {websiteName}. This Privacy
          Policy does not apply to such third-party sites, and we are not
          responsible for the privacy practices of such sites. We encourage you
          to review the privacy policies of those third-party sites to
          understand their practices.
        </p>
        <h2>7. CHILDREN'S PRIVACY</h2>
        <p>
          The Services are not intended for children under the age of 13. We do
          not knowingly collect personal information from children under the age
          of 13. If we learn that we have collected personal information from a
          child under the age of 13, we will promptly delete such information.
          If you are a parent or guardian and believe that your child has
          provided us with personal information, please contact us.
        </p>
        <h2>8. CHANGES TO THIS POLICY</h2>
        <p>
          We may update this Privacy Policy from time to time by posting the
          updated version on the Site or through the Services. The updated
          version will be effective as of the date of posting. We encourage you
          to review this Privacy Policy periodically for any changes.
        </p>
        <h2>9. CONTACT US</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at [contact email or address].
        </p>
      </div>
    </>
  );
};

export default Privacy;
