import { grammar_colors } from "./grammar";

const Legend: React.FC = () => {
  return (
    <div style={{ marginTop: "20px" }}>
      <strong>Legend:</strong>
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {Object.entries(grammar_colors).map(([key, color]) => (
          <li
            key={key}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                backgroundColor: color,
                marginRight: "10px",
                border: "1px solid #000",
              }}
            ></span>
            {key}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Legend;
