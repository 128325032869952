import { FC } from "react";
import logo from "../../images/LogoOnWhite.png";

const LogoSmall: FC = (props) => {
  return (
    <div className="logo">
      <img src={logo} width="75em" alt="logo" />
    </div>
  );
};

export default LogoSmall;
