import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IUserNew, IUserNewPaginated } from "../../types/userTypes";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getUsersPaginated = createAsyncThunk(
  "users/getUsers",
  async (data: { access_token: string; page: number }, thunkApi) => {
    try {
      const response = await axios.get<IUserNewPaginated>(
        `${url}/userspages/?page=${data.page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

interface ClassroomState {
  getUsersPaginatedLoading: boolean;
  getUsersPaginatedErrors: {};
  getUsersPaginatedPayload: IUserNewPaginated;
}

const getUsersPaginatedSlice = createSlice({
  name: "classrooms",
  initialState: {
    getUsersPaginatedLoading: false,
    getUsersPaginatedErrors: {},
    getUsersPaginatedPayload: {
      count: 0,
      next: null,
      previous: null,
      results: [] as IUserNew[],
    },
  } as ClassroomState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUsersPaginated.pending, (state, action) => {
        state.getUsersPaginatedLoading = true;
      })
      .addCase(
        getUsersPaginated.fulfilled,
        (state, action: PayloadAction<IUserNewPaginated>) => {
          state.getUsersPaginatedLoading = false;
          state.getUsersPaginatedPayload = action.payload;
        }
      )
      .addCase(
        getUsersPaginated.rejected,
        (state, action: PayloadAction<any>) => {
          state.getUsersPaginatedErrors = action.payload;
        }
      );
  },
});

export default getUsersPaginatedSlice.reducer;
