import {
  Box,
  CardHeader,
  Button,
  IconButton,
  Typography,
  Divider,
  Stack,
  Card,
  Chip,
  CardContent,
  CardActions,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import { CircleFlag } from "react-circle-flags";
import LanguageIcon from "@mui/icons-material/LanguageOutlined";
import {
  forwardRef,
  Fragment,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { IClassroom } from "../../../../types/classroomTypes";
import ShareIcon from "@mui/icons-material/Share";
import TuneIcon from "@mui/icons-material/Tune";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../hooks/useTypedSelector";
import { postStartPauseCycle } from "../../../../features/classrooms/postStartPauseCycleSlice";
import AuthContext from "../../../../context/AuthContext";
import { IEvent } from "../../../Dashboard/types";
import { borderColor } from "../../../theme/colors";
import { getStatusColor } from "../helperfunctions";
import { backgroundColor } from "../../../theme/colors";
import { Type } from "react-toastify/dist/utils";
import { statusChipColorSwitch } from "./statusChipColorSwitch";
import { typeChipColorSwitch } from "./typeChipColorSwitch";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface AppProps {
  classroom: IClassroom;
  loading: boolean;
  title: string;
  userProfile: any;
  adjustedTimes: IEvent[] | null;
}

type ErrorType = { statusText: string } | null;

export default function UserInfo({
  classroom,
  loading,
  title,
  userProfile,
  adjustedTimes,
}: AppProps) {
  const [classroomStatus, setClassroomStatus] = useState(
    classroom.status ? classroom.status : "inactive"
  );
  const [SnackbarOpen, setSnackbarOpen] = useState(false);
  const [ErrorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

  const handleSnackbarClose = (
    event: React.SyntheticEvent<any, Event> | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleErrorSnackbarClose = (
    event: React.SyntheticEvent<any, Event> | Event,
    reason: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorSnackbarOpen(false);
  };

  const handleShare = () => {
    navigator.clipboard.writeText(window.location.href);
    setSnackbarOpen(true);
  };

  const startPauseCycleHandler = () => {
    if (!isAuthenticated) return;
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      postStartPauseCycle({
        access_token: access_token,
        classroom_uuid: classroom.uuid,
      })
    );
  };

  const dispatch = useAppDispatch();
  const {
    postStartPauseCycleLoading,
    postStartPauseCyclePayload,
    postStartPauseCycleErrors,
  } = useAppSelector((state) => state.postStartPauseCycle);

  // Context
  const { currentUser, isAuthenticated } = useContext(AuthContext);

  const isCurrentUserOwner = currentUser.uuid === classroom.author_details.uuid;

  //console.log("userProfile", userProfile.timezone)

  // debug
  //console.log("classroom", classroom);

  useEffect(() => {
    if (postStartPauseCyclePayload.success === true) {
      if (postStartPauseCyclePayload.status === "active") {
        setClassroomStatus("active");
      } else if (postStartPauseCyclePayload.status === "inactive") {
        setClassroomStatus("inactive");
      } else if (postStartPauseCyclePayload.status === "inactive") {
        setClassroomStatus("inactive");
      }
    }
  }, [postStartPauseCyclePayload]);

  useEffect(() => {
    if (postStartPauseCycleErrors) {
      setErrorSnackbarOpen(true);
    }
  }, [postStartPauseCycleErrors]);

  return (
    <Fragment>
      <Box sx={{ mb: 2 }}>
        <Typography fontSize="0.7rem" fontWeight="bold" component="div">
          {title.toUpperCase()}
        </Typography>
      </Box>
      <Card
        sx={{
          borderColor: borderColor.pastel3,
          width: "100%",
        }}
        variant="outlined"
      >
        <CardHeader
          titleTypographyProps={{ variant: "h6" }}
          title={
            classroom
              ? classroom.title.charAt(0).toUpperCase() +
                classroom.title.slice(1)
              : ""
          }
        />
        <CardContent>
          <Box sx={{ ml: 2 }}>
            <Typography>{classroom ? classroom.description : ""}</Typography>
          </Box>
          <Box sx={{ m: 2 }}>
            <Grid container direction="row" alignItems="center">
              {adjustedTimes &&
                adjustedTimes.map((time, index) => (
                  <Fragment key={index}>
                    <Grid item>
                      <AccessTimeIcon sx={{ mr: 2 }} />
                    </Grid>
                    <Grid item>
                      {adjustedTimes ? (
                        <Typography>
                          {time.start
                            ? time.start.toLocaleString("en-US", {
                                weekday: "short",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })
                            : ""}{" "}
                          -{" "}
                          {time.end
                            ? time.end.toLocaleString("en-US", {
                                weekday: "short",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              })
                            : ""}
                        </Typography>
                      ) : null}
                    </Grid>
                  </Fragment>
                ))}
            </Grid>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <LanguageIcon sx={{ mr: 2 }} />
              </Grid>
              <Grid item>
                <CircleFlag
                  countryCode={
                    classroom.language_string
                      ? classroom.language_string.split("-")[1].toLowerCase()
                      : ""
                  }
                  style={{ width: "20px", height: "20px" }}
                ></CircleFlag>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                sx={{ mt: 2 }}
              >
                <Grid item>
                  <Chip
                    label={typeChipColorSwitch(classroom.paid).toUpperCase()}
                    variant="outlined"
                    sx={{
                      mr: 1,
                      backgroundColor: backgroundColor.logo,
                      fontSize: 10,
                    }}
                  />
                </Grid>
                <Grid item>
                  <Chip
                    label={classroom.status.toUpperCase()}
                    variant="outlined"
                    sx={{
                      mr: 1,
                      backgroundColor: statusChipColorSwitch(classroom.status),
                      fontSize: 10,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </CardContent>

        <CardActions>
          <Stack direction="row" spacing={2} sx={{ m: 2 }}>
            <IconButton onClick={handleShare}>
              <ShareIcon sx={{ fontSize: "1rem" }} />
            </IconButton>
            {isAuthenticated && isCurrentUserOwner ? (
              <IconButton
                href={"/groups/update/" + classroom.uuid}
                size="small"
                disabled
              >
                <TuneIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            ) : null}
          </Stack>
          <Snackbar
            open={SnackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert severity="success" sx={{ width: "100%" }}>
              Copied Link to Clipboard!
            </Alert>
          </Snackbar>
        </CardActions>
      </Card>
      {isAuthenticated && isCurrentUserOwner && (
        <>
          <Box sx={{ m: 2 }}>
            <Button
              onClick={
                classroomStatus === "nomination"
                  ? undefined
                  : startPauseCycleHandler
              }
              disabled={classroomStatus === "nomination"}
              size="small"
              variant="outlined"
              sx={{
                mr: 1,
                backgroundColor:
                  classroomStatus === "active"
                    ? "#4caf50"
                    : classroomStatus === "nomination"
                    ? backgroundColor.pastel2 // Assuming you want a different color for nominated status.
                    : "#ff9800",
                color: "black",
                ":hover": {
                  bgcolor:
                    classroomStatus === "active"
                      ? "#4caf50"
                      : classroomStatus === "nomination"
                      ? "gray" // To match the nominated color.
                      : "#ff9800",
                  color: "black",
                },
                width: "100%",
                height: "60px",
              }}
            >
              {classroomStatus.toUpperCase()}{" "}
            </Button>
            <Box>
              <Typography>
                {/* Any text or value to display here? */}
              </Typography>
            </Box>
            <Snackbar
              open={ErrorSnackbarOpen}
              autoHideDuration={6000}
              onClose={handleErrorSnackbarClose}
            >
              <Alert severity="error" sx={{ width: "100%" }}>
                {ErrorSnackbarOpen && postStartPauseCycleErrors}
              </Alert>
            </Snackbar>
          </Box>
        </>
      )}
    </Fragment>
  );
}
