import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { IErrors, IMessage } from "../../types/MessageTypes";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const postMessages = createAsyncThunk(
  "messages/postMessages",
  async (
    data: { access_token: string; message: string; channel_uuid: string },
    thunkApi
  ) => {
    try {
      const response = await fetch(`${url}/messages/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        body: JSON.stringify({
          text: data.message,
          channel_uuid: data.channel_uuid,
        }),
      });
      console.log(response);
      const json = await response.json();
      return json;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const messagesSlice = createSlice({
  name: "postMessages",
  initialState: {
    postMessageLoading: false,
    errors: {} as IErrors,
    messagesReturned: [] as IMessage[],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postMessages.pending, (state, action) => {
        state.postMessageLoading = true;
      })
      .addCase(postMessages.fulfilled, (state, action: PayloadAction<any>) => {
        state.postMessageLoading = false;
        state.messagesReturned = action.payload;
      })
      .addCase(postMessages.rejected, (state, action: PayloadAction<any>) => {
        state.postMessageLoading = false;
        state.errors = action.payload;
      });
  },
});

export default messagesSlice.reducer;
