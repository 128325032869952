import * as React from "react";
import { useContext, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ThemeProvider } from "@mui/material/styles";
import Copyright from "../Copyright/Copyright";
import theme from "../theme/theme";
import Logo from "../Logo/LogoOnWhiteSmall";
import { GoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import AuthContext from "../../context/AuthContext";
import preview from "../../images/preview.gif";
import { Typography } from "@mui/material";

export default function SignInSide() {
  let {
    signin,
    updateToken,
    signinGoogle,
    currentUser,
    isLoading,
    setIsLoading,
  } = useContext(AuthContext);

  const [formEnabled, setFormEnabled] = useState(true); // State variable to control form enabling

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    event.preventDefault();
    const email = event.currentTarget.email.value;
    const password = event.currentTarget.password.value;
    signin(email, password);
  };

  function handleGoogleLogin(credential: any) {
    signinGoogle(credential);
  }

  useGoogleOneTapLogin({
    onSuccess: (response) => {
      const credential = response.credential;
      handleGoogleLogin(credential);
    },
    onError: () => {
      console.log("Login Failed");
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
          }}
        >
          <img
            src={preview}
            alt="Preview GIF"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Logo />
            <Box>Sign in</Box>
            <Box sx={{ mt: 5 }}>
              <GoogleLogin
                onSuccess={(response) => {
                  handleGoogleLogin(response.credential);
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </Box>

            {/* Conditionally render the form based on formEnabled state */}
            {formEnabled ? (
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="#" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            ) : (
              // Display a "Coming Soon" message when the form is disabled
              <Box>
                <Typography variant="h6">Coming Soon...</Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
