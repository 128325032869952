import {
  Box,
  Avatar,
  CardHeader,
  Typography,
  CircularProgress,
  Card,
} from "@mui/material";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import PlaceIcon from "@mui/icons-material/Place";
import Grid from "@mui/material/Grid";
import { CircleFlag } from "react-circle-flags";
import LanguageLevel from "./LanguageLevel";
import TranslateIcon from "@mui/icons-material/Translate";
import { Fragment } from "react";
interface AppProps {
  userProfile: any;
  loading: boolean;
  title: string;
}

export default function TeacherInfo({ userProfile, loading, title }: AppProps) {
  return loading ? (
    <CircularProgress />
  ) : (
    <Fragment key={userProfile.id}>
      <Box sx={{ mb: 2 }}>
        <Typography fontSize="0.7rem" fontWeight="bold" component="div">
          {title.toUpperCase()}
        </Typography>
      </Box>
      <Card sx={{ mb: 2 }}>
        <CardHeader
          avatar={
            <Avatar
              alt={
                userProfile
                  ? userProfile.username.charAt(0).toUpperCase() +
                    userProfile.username.slice(1)
                  : ""
              }
              src={"/media/images/upload/" + userProfile.profile_image}
              sx={{ width: 75, height: 75 }}
            />
          }
          titleTypographyProps={{ variant: "h6" }}
          title={
            userProfile
              ? userProfile.username.charAt(0).toUpperCase() +
                userProfile.username.slice(1)
              : ""
          }
        />

        <Box sx={{ ml: 2 }}>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <PlaceIcon sx={{ mr: 2 }} />
            </Grid>
            <Grid item>
              {userProfile.timezone ? userProfile.timezone : "NA"}
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <TranslateIcon sx={{ mr: 2 }} />
            </Grid>
            <Grid item>
              {userProfile.native_languages_names &&
              userProfile.native_languages_names.length > 0
                ? userProfile.native_languages_names.map(
                    (language: any, index: number) => (
                      <span key={index}>
                        <span> </span>
                        <CircleFlag
                          countryCode={language.split("-")[1].toLowerCase()}
                          style={{
                            width: "1em",
                            height: "1em",
                          }}
                        />
                      </span>
                    )
                  )
                : "NA"}
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <LocalLibraryIcon sx={{ mr: 2 }} />
            </Grid>
            <Grid item>
              {userProfile.learning_languages_names &&
              userProfile.learning_languages_names.length > 0
                ? userProfile.learning_languages_names.map(
                    (language: any, index: number) => (
                      <Fragment key={index}>
                        <span>
                          <span> </span>
                          <CircleFlag
                            countryCode={language.split("-")[1].toLowerCase()}
                            style={{
                              width: "1em",
                              height: "1em",
                            }}
                          />
                        </span>
                        {userProfile.learning_languages_levels &&
                        userProfile.learning_languages_levels.length > 0 ? (
                          <LanguageLevel
                            level={userProfile.learning_languages_levels[index]}
                          />
                        ) : (
                          <span
                            style={{
                              color: "#DCDCDC",
                              width: "1em",
                              height: "1em",
                            }}
                          >
                            NA
                          </span>
                        )}
                      </Fragment>
                    )
                  )
                : "NA"}
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Fragment>
  );
}
