/* TODO:
- Add logout functionality
  - Add a search bar
  - implement notification icon
  - Add a mail icon
  */

import { Badge, Link } from "@mui/material";
import { useState } from "react";
import { IUserNew } from "../../types/userTypes";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import LogoOnBlueSmall from "../Logo/LogoOnBlueSmall";
import theme from "../theme/theme";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MailIcon from "@mui/icons-material/Mail";
import LogoOnWhiteSmall from "../Logo/LogoOnWhiteSmall";
import React from "react";
import TokenExpirationCheck from "./TokenExpirationCheck";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import SchoolIcon from "@mui/icons-material/School";
import PeopleIcon from "@mui/icons-material/People";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import PersonIcon from "@mui/icons-material/Person";
import ChatBubble from "@mui/icons-material/ChatBubble";
import ForumIcon from "@mui/icons-material/Forum";
import ArticleIcon from "@mui/icons-material/Article";
import StyleIcon from "@mui/icons-material/Style";

import { borderColor } from "../theme/colors";
const url = process.env.REACT_APP_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

var img_url = "";

if (process.env.STORAGE_TYPE === "S3") {
  img_url = `https://${process.env.AWS_STORAGE_BUCKET_NAME}.s3.amazonaws.com/`;
}

const pages = [
  {
    name: "Community",
    link: "/community/",
    icon: (
      <Box
        sx={{
          borderRadius: "50%",
          overflow: "hidden",
          border: "2px solid white",
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 1,
        }}
      >
        <ForumIcon sx={{ width: "15px", height: "15px" }} />
      </Box>
    ),
  },
  {
    name: "Groups",
    link: "/groups/",
    icon: (
      <Box
        sx={{
          borderRadius: "50%",
          overflow: "hidden",
          border: "2px solid white",
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Diversity3Icon sx={{ width: "15px", height: "15px" }} />
      </Box>
    ),
  },
  {
    name: "Texts",
    link: "/texts/",
    icon: (
      <Box
        sx={{
          borderRadius: "50%",
          overflow: "hidden",
          border: "2px solid white",
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 1,
        }}
      >
        <ArticleIcon sx={{ width: "15px", height: "15px" }} />
      </Box>
    ),
  },
  {
    name: "Dictionary",
    link: "/dictionary/",
    icon: (
      <Box
        sx={{
          borderRadius: "50%",
          overflow: "hidden",
          border: "2px solid white",
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 1,
        }}
      >
        <StyleIcon sx={{ width: "15px", height: "15px" }} />
      </Box>
    ),
  },
];

const settingsLoggedIn = [
  { name: "Profile", link: "/profile/update/" },
  { name: "Signout", link: "/signout/" },
];
const settingsLoggedOut = ["Signin", "Register"];

interface AppProps {
  currentUser: IUserNew;
  userProfile: any;
}

export default function ResponsiveAppBar({
  currentUser,
  userProfile,
}: AppProps) {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (event: any) => {
    setAnchorElNav(null);
    navigate(event.currentTarget.id.toLowerCase());
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
    // logout();
  };

  return (
    userProfile.username && (
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/dashboard/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              {theme.palette.mode === "dark" ? (
                <LogoOnWhiteSmall />
              ) : (
                <LogoOnBlueSmall />
              )}
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page, index) => (
                  <MenuItem
                    key={index}
                    onClick={handleCloseNavMenu}
                    id={page.link}
                  >
                    <Typography textAlign="center">{page.name}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              component="a"
              href="/dashboard/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontWeight: 700,
                letterSpacing: ".3rem",
                textDecoration: "none",
              }}
            >
              {theme.palette.mode === "dark" ? (
                <LogoOnWhiteSmall />
              ) : (
                <LogoOnBlueSmall />
              )}
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "center",
              }}
            >
              {pages.map((page, index) => (
                <Link
                  key={index}
                  href={page.link.toLowerCase()}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    key={index}
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      color: window.location.pathname.includes(
                        page.link.toLowerCase()
                      )
                        ? borderColor.logo // Color when active
                        : theme.palette.primary.light, // Color when not active
                      display: "block",
                    }}
                  >
                    {page.icon} {page.name}
                  </Button>
                </Link>
              ))}
            </Box>

            <Box
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            ></Box>
            <Box sx={{ display: { xs: "none", md: "flex" }, mr: 4 }}>
              {/*
              <IconButton>
                <Badge
                  badgeContent={userProfile.notifications_count}
                  color="error"
                >
                  <NotificationsIcon
                    style={{
                      color:
                        theme.palette.mode === "dark"
                          ? theme.palette.primary.dark
                          : theme.palette.primary.light,
                    }}
                  />
                </Badge>
              </IconButton>
                  */}
              <IconButton href="/chat/">
                <Badge
                  badgeContent={userProfile.unread_messages_count}
                  color="error"
                >
                  <MailIcon
                    style={{
                      color:
                        theme.palette.mode === "dark"
                          ? theme.palette.primary.dark
                          : theme.palette.primary.light,
                    }}
                  />
                </Badge>
              </IconButton>
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt={userProfile.username}
                    src={userProfile.profile_image}
                    sx={{ width: 30, height: 30 }} // adjust these values as needed
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {currentUser
                  ? settingsLoggedIn.map((setting, index) => (
                      <Link
                        key={index}
                        href={setting.link.toLowerCase()}
                        style={{ textDecoration: "none" }}
                      >
                        <MenuItem onClick={handleCloseUserMenu}>
                          <Typography textAlign="center" color={"primary"}>
                            {setting.name}
                          </Typography>
                        </MenuItem>
                      </Link>
                    ))
                  : settingsLoggedOut.map((setting) => (
                      <MenuItem key={setting}>
                        <Link href={setting}>setting</Link>
                      </MenuItem>
                    ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>

        {/*  <TokenExpirationCheck /> */}
      </AppBar>
    )
  );
}
