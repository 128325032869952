import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import General from "./Sections/CreateClassroomGeneral";
import Schedule from "./Sections/CreateClassroomSchedule";
import Review from "./Sections/CreateClassroomReview";
import { useState, useEffect, useContext } from "react";
import {
  IClassroom,
  ICreateClassroom,
  PaymentTypeEnum,
} from "../../../types/classroomTypes";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../hooks/useTypedSelector";
import { getUserProfile } from "../../../features/users/getUserProfileSlice";
import { getLanguagesAndCountries } from "../../../features/languages/getLanguagesSlice";
import AuthContext from "../../../context/AuthContext";
import { CircularProgress } from "@mui/material";
import {
  DayHours,
  EventActions,
  ProcessedEvent,
} from "@aldabil/react-scheduler/types";

import { postClassroom } from "../../../features/classrooms/postClassroomSlice";
import { getClassrooms } from "../../../features/classrooms/getClassroomsSlice";
import { Navigate } from "react-router-dom";

var navigate_to = process.env.NAVIGATE_TO || "/groups/";

const steps = ["General Information", "Schedule", "Review"];

interface Ierrors {
  language: string;
  title: string;
  description: string;
  software: string;
  tags: string;
  paid: string;
  price: string;
  currency: string;
  payment_type: string;
  start_date: string;
  end_date: string;
  start_time: string;
  end_time: string;
}

export default function CasualGroup() {
  const dispatch = useAppDispatch();
  const { currentUser } = useContext(AuthContext);

  const [earliestHour, setEarliestHour] = useState<DayHours>(8);
  const [latestHour, setLatestHour] = useState<DayHours>(20);
  const [activeStep, setActiveStep] = useState(0);

  const [processedEvents, setProcessedEvents] = useState<ProcessedEvent[]>([]);
  const [processedEventToAdd, setProcessedEventToAdd] =
    useState<ProcessedEvent>({
      event_id: 1,
      title: "New Event",
      start: new Date(new Date(new Date().setHours(14)).setMinutes(0)),
      end: new Date(new Date(new Date().setHours(16)).setMinutes(0)),
      frequency: "w",
      disabled: false,
      color: "#900000",
      admin_id: [1, 2, 3, 4],
      editable: true,
      deletable: true,
    });

  const { getClassroomsLoading, getClassroomsErrors, getClassroomsPayload } =
    useAppSelector((state) => state.getClassrooms);
  const { getUserProfileLoading, getUserProfilePayload } = useAppSelector(
    (state) => state.getUserProfile
  );
  const { postClassroomLoading, postClassroomPayload } = useAppSelector(
    (state) => state.postClassroom
  );

  const [classroom, setClassroom] = useState<ICreateClassroom>({
    uuid: "",
    language: "en-US",
    title: "",
    level: "A1",
    description: "",
    software: "jitsi",
    n: [4],
    paid: false,
    prices: [0],
    tags: ["Languge learning"],
    paymentType: PaymentTypeEnum.PERSTUDENT,
    category: "",
    students_invited: [],
    frequency: "w",
    calendar_events: [
      {
        event_id: 1,
        title: "",
        start: new Date(new Date(new Date()).setMinutes(0)),
        end: new Date(new Date(new Date()).setHours(new Date().getHours() + 1)),
        disabled: false,
        color: "#900000",
        editable: true,
        deletable: true,
      },
    ],
    time_zone: "",
    time_zone_offset: 0,
  });

  const { getLanguagesAndCountriesPayload, getLanguagesAndCountriesLoading } =
    useAppSelector((state) => state.getLanguagesAndCountries);

  useEffect(() => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      getUserProfile({
        access_token: access_token,
        subscriberProfileId: currentUser.profile_uuid,
      })
    );
    dispatch(getLanguagesAndCountries({ access_token: access_token }));
    dispatch(getClassrooms({ access_token: access_token }));
  }, []);

  const updateCalendarEvents = () => {
    if (Array.isArray(getClassroomsPayload)) {
      let events: ProcessedEvent[] = [];
      getClassroomsPayload.forEach((classroom: IClassroom) => {
        events.push({
          event_id: classroom.calendar_events_details[0]
            ? classroom.calendar_events_details[0].uuid
            : "",
          title: classroom.title,
          start: classroom.calendar_events_details[0]
            ? new Date(classroom.calendar_events_details[0].start)
            : new Date(),
          end: classroom.calendar_events_details[0]
            ? new Date(classroom.calendar_events_details[0].end)
            : new Date(),
          disabled: false,
          color: "#900000",
          admin_id: [1, 2, 3, 4],
          editable: true,
          deletable: false,
        });
      });
      // add processedEventToAdd to events
      events.push(processedEventToAdd);
      setProcessedEvents(events);
    }
  };

  useEffect(() => {
    updateCalendarEvents();
    // update timezone
    if (getUserProfilePayload) {
      setClassroom({ ...classroom, time_zone: getUserProfilePayload.timezone });
    }
  }, [getClassroomsPayload, processedEventToAdd]);

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <General
            classroom={classroom}
            setClassroom={setClassroom}
            getLanguagesAndCountriesLoading={getLanguagesAndCountriesLoading}
            getLanguagesAndCountriesPayload={getLanguagesAndCountriesPayload}
          />
        );
      case 1:
        return (
          <Schedule
            classroom={classroom}
            setClassroom={setClassroom}
            getUserClassroomsLoading={getClassroomsLoading}
            getUserClassroomsErrors={getClassroomsErrors}
            getUserClassroomsPayload={getClassroomsPayload}
            processedEvents={processedEvents}
            setProcessedEvents={setProcessedEvents}
            earliestHour={earliestHour}
            setEarliestHour={setEarliestHour}
            latestHour={latestHour}
            setLatestHour={setLatestHour}
            processedEventToAdd={processedEventToAdd}
            setProcessedEventToAdd={setProcessedEventToAdd}
            getUserProfilePayload={getUserProfilePayload}
          />
        );
      case 2:
        return (
          <Review
            classroom={classroom}
            setClassroom={setClassroom}
            processedEventToAdd={processedEventToAdd}
            setProcessedEventToAdd={setProcessedEventToAdd}
          />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  const handleNext = () => {
    if (activeStep === 0) {
      if (classroom.title.length < 3) {
        return;
      }
      if (classroom.description.length < 3) {
        return;
      }
    }
    if (activeStep === 1) {
      if (classroom.calendar_events.length < 1) {
        return;
      }
    }
    if (activeStep === 2) {
      if (classroom.prices.length < 1) {
        return;
      }
    }
    setActiveStep(activeStep + 1);
    if (activeStep === 2) {
      let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
      let access_token = authTokens.access;
      // log times to console

      dispatch(
        postClassroom({
          access_token: access_token,
          classroom: classroom,
        })
      );
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const fetchRemote = async (): Promise<ProcessedEvent[]> => {
    return processedEvents;
  };

  const handleEventConfirm = async (
    event: ProcessedEvent,
    action: EventActions
  ): Promise<ProcessedEvent> => {
    console.log("handleConfirm =", action, event.title);
    if (action === "edit") {
      console.log(event);
      /** PUT event to remote DB */
    } else if (action === "create") {
      /**POST event to remote DB */
      setProcessedEventToAdd(event);
    } else if (action === "delete") {
      /**DELETE event from remote DB */
    }
    /**
     * Make sure to return 4 mandatory fields:
     * event_id: string|number
     * title: string
     * start: Date|string
     * end: Date|string
     * ....extra other fields depend on your custom fields/editor properties
     */
    return {
      ...event,
      event_id: 0,
    };
  };

  const handleDelete = async (deletedId: string): Promise<string> => {
    // Simulate http request: return the deleted id
    return new Promise((res, rej) => {
      setTimeout(() => {
        res(deletedId);
      }, 3000);
    });
  };

  return (
    <>
      <Typography component="h1" variant="h4" align="center">
        Create Casual Group
      </Typography>
      <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
        {steps.map((label, i) => (
          <Step key={i}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        postClassroomLoading ? (
          <>
            <CircularProgress />
          </>
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography variant="h5" gutterBottom component="div">
                Classroom created successfully.
              </Typography>
              <Navigate to={navigate_to} />
            </Box>
          </>
        )
      ) : (
        <>
          {getStepContent(activeStep)}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {activeStep !== 0 && (
              <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                Back
              </Button>
            )}
            <Button
              variant="contained"
              onClick={handleNext}
              sx={{ mt: 3, ml: 1 }}
            >
              {activeStep === steps.length - 1 ? "Create" : "Next"}
            </Button>
          </Box>
        </>
      )}
    </>
  );
}
