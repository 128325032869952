import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { ILanguage } from "../../types/languageTypes";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getLanguages = createAsyncThunk(
  "languages/getLanguages",
  async (data: { access_token: string }, thunkApi) => {
    try {
      const response = await axios.get<ILanguage[]>(`${url}/languages/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        //data: data.subscriberIds
      });
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const getLanguagesSlice = createSlice({
  name: "languages/getLanguages",
  initialState: {
    getLanguagesLoading: false,
    getLanguagesError: null,
    getLanguagesPayload: [
      {
        BCP_47: "en",
        display_name: "English",
        country_list: ["US"],
        country_display_names_list: ["United States"],
      },
    ] as ILanguage[],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getLanguages.pending, (state) => {
        state.getLanguagesLoading = true;
      })
      .addCase(
        getLanguages.fulfilled,
        (state, action: PayloadAction<ILanguage[]>) => {
          state.getLanguagesLoading = false;
          state.getLanguagesPayload = action.payload;
        }
      )
      .addCase(getLanguages.rejected, (state, action: any) => {
        state.getLanguagesLoading = false;
        state.getLanguagesError = action.payload;
      });
  },
});

export default getLanguagesSlice.reducer;
