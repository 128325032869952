import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "../Header/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import General from "./CreateTeacherProfileGeneral";
import Availability from "./CreateTeacherProfileAvailability";
import Review from "./CreateTeacherProfileReview";
import Copyright from "../Copyright/Copyright";
import { useState, useEffect, useContext } from "react";
import { ITeacherApplication } from "../../types/classroomTypes";
import { useAppDispatch, useAppSelector } from "../../hooks/useTypedSelector";
import { getUserProfile } from "../../features/users/getUserProfileSlice";
import { getLanguagesAndCountries } from "../../features/languages/getLanguagesSlice";
import AuthContext from "../../context/AuthContext";
import { CircularProgress } from "@mui/material";
import { getClassrooms } from "../../features/classrooms/getClassroomsSlice";
import { Navigate } from "react-router-dom";
import { postTeacherProfile } from "../../features/users/postTeacherProfileSlice";
import theme from "../theme/theme";

const steps = ["General Information", "Availability", "Review"];

interface Ierrors {
  teaching_languages: string;
  title: string;
  introduction: string;
  tags: string;
  price: string;
  currency: string;
  payment_type: string;
  availability: string;
}

export default function TeacherApplication() {
  const dispatch = useAppDispatch();
  const { currentUser } = useContext(AuthContext);
  const [activeStep, setActiveStep] = useState(0);

  const [application, setApplication] = useState<ITeacherApplication>({
    uuid: "",
    first_name: "",
    last_name: "",
    introduction: "",
    teaching_languages: [],
    tags: ["English"],
    availability_template: [],
  });

  const { getLanguagesAndCountriesLoading, getLanguagesAndCountriesPayload } =
    useAppSelector((state) => state.getLanguagesAndCountries);
  const { getUserProfileLoading, getUserProfilePayload } = useAppSelector(
    (state) => state.getUserProfile
  );
  const { postClassroomLoading, postClassroomPayload } = useAppSelector(
    (state) => state.postClassroom
  );

  useEffect(() => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      getUserProfile({
        access_token: access_token,
        subscriberProfileId: currentUser.profile_uuid,
      })
    );
    dispatch(getLanguagesAndCountries({ access_token: access_token }));
    dispatch(getClassrooms({ access_token: access_token }));
  }, []);

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <General
            application={application}
            setApplication={setApplication}
            languageList={getLanguagesAndCountriesPayload}
          />
        );
      case 1:
        return (
          <Availability
            application={application}
            setApplication={setApplication}
          />
        );
      case 2:
        return (
          <Review application={application} setApplication={setApplication} />
        );
      default:
        throw new Error("Unknown step");
    }
  }

  const handleNext = () => {
    if (activeStep === 0) {
      if (application.first_name.length < 1) {
        return;
      }
      if (application.last_name.length < 1) {
        return;
      }
      if (application.introduction.length < 3) {
        return;
      }
      if (application.teaching_languages.length < 1) {
        return;
      }
    }
    setActiveStep(activeStep + 1);
    if (activeStep === 2) {
      let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
      let access_token = authTokens.access;
      dispatch(
        postTeacherProfile({
          access_token: access_token,
          application: application,
        })
      );
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar currentUser={currentUser} userProfile={getUserProfilePayload} />

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Typography component="h1" variant="h4" align="center">
              Create a Teacher Profile
            </Typography>

            {getUserProfilePayload.is_teacher ? (
              <>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  sx={{ m: 5 }}
                >
                  You already have a teacher profile. Click here to edit:
                </Typography>
                <Link href={`/profile/update/`}>
                  <Button variant="contained" sx={{ ml: 5, mb: 5 }}>
                    Edit Profile
                  </Button>
                </Link>
              </>
            ) : (
              <>
                <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                  {steps.map((label, i) => (
                    <Step key={i}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {activeStep === steps.length ? (
                  postClassroomLoading ? (
                    <>
                      <CircularProgress />
                    </>
                  ) : (
                    <>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Typography variant="h5" gutterBottom component="div">
                          Teacher profile successfully created!
                        </Typography>
                        <Navigate to="/classrooms/" />
                      </Box>
                    </>
                  )
                ) : (
                  <>
                    {getStepContent(activeStep)}
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      {activeStep !== 0 && (
                        <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                          Back
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 3, ml: 1 }}
                      >
                        {activeStep === steps.length - 1 ? "Create" : "Next"}
                      </Button>
                    </Box>
                  </>
                )}
              </>
            )}
          </Paper>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </ThemeProvider>
  );
}
