import Typography from "@mui/material/Typography";
import {
  Box,
  CardContent,
  Divider,
  CardActions,
  AvatarGroup,
  Avatar,
  Pagination,
  Stack,
  IconButton,
  CardActionArea,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardSkeleton from "../../../Skeletons/CardSkeleton";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../hooks/useTypedSelector";
import { getClassroomsPaginatedUser } from "../../../../features/classrooms/getClassroomsPaginatedUserSlice";
import { forwardRef, useEffect, useState } from "react";
import ShareIcon from "@mui/icons-material/Share";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { borderColor } from "../../../theme/colors";

var img_url = "";
var url = process.env.REACT_APP_URL;

if (process.env.STORAGE_TYPE === "S3") {
  img_url = `https://${process.env.AWS_STORAGE_BUCKET_NAME}.s3.amazonaws.com/`;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Classrooms() {
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleShare = (uuid: string) => {
    navigator.clipboard.writeText(url + "/groups/" + uuid);
    setOpen(true);
  };

  const handlePaginationChange = (e: any, pageNumber: number) => {
    console.log("pageNumber: ", pageNumber);
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      getClassroomsPaginatedUser({
        access_token: access_token,
        page: pageNumber,
      })
    );
    setPage(pageNumber);
  };

  const dispatch = useAppDispatch();
  const {
    getClassroomsPaginatedUserPayload,
    getClassroomsPaginatedUserLoading,
    getClassroomsPaginatedUserErrors,
  } = useAppSelector((state) => state.getClassroomsPaginatedUser);

  console.log(
    "getClassroomsPaginatedUserPayload",
    getClassroomsPaginatedUserPayload
  );

  useEffect(() => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      getClassroomsPaginatedUser({ access_token: access_token, page: page })
    );
    //dispatch(getUsers({ access_token: access_token, subscriberIds: userIds }))
  }, []);

  return (
    <Box>
      {getClassroomsPaginatedUserLoading ? (
        <>
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </>
      ) : (
        getClassroomsPaginatedUserPayload.results &&
        getClassroomsPaginatedUserPayload.results.map((classroom, index) => (
          <Box sx={{ mb: 2 }} key={index}>
            {" "}
            <CardActionArea href={url + "/groups/" + classroom.uuid}>
              <Card
                sx={{
                  width: "100%",
                  borderColor: borderColor.pastel_jeans,
                  padding: 1.5,
                }}
                variant="outlined"
              >
                <CardContent>
                  <Box sx={{ m: 1 }}>
                    <Typography gutterBottom variant="h5" component="span">
                      {classroom.title}
                    </Typography>
                  </Box>
                  <Box sx={{ m: 1 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component="span"
                    >
                      {classroom.description}
                    </Typography>
                  </Box>
                  {/*<Box sx={{ mt: 2 }}>
                  <Divider />
                </Box>*/}
                </CardContent>
                <CardActions>
                  <Box sx={{ ml: 1 }}>
                    <Snackbar
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert
                        onClose={handleClose}
                        severity="success"
                        sx={{ width: "100%" }}
                      >
                        Copied Link to Clipboard!
                      </Alert>
                    </Snackbar>
                  </Box>
                  <Box sx={{ flexGrow: 1, mr: 1 }}>
                    <AvatarGroup
                      max={4}
                      sx={{
                        "& .MuiAvatar-root": {
                          width: 24,
                          height: 24,
                          fontSize: "0.8rem",
                        },
                        "& .MuiAvatarGroup-avatar": {
                          width: 30,
                          height: 30,
                          fontSize: "0.8rem",
                          "&:last-child": {
                            fontSize: "0.7rem", // Adjusts the "+" size
                          },
                        },
                      }}
                    >
                      {classroom.subscribers_details.map(
                        (subscriber: any, index) => (
                          <Avatar
                            key={index}
                            alt={subscriber.username}
                            src={img_url + subscriber.profile_image}
                          />
                        )
                      )}
                    </AvatarGroup>
                  </Box>
                </CardActions>
              </Card>
            </CardActionArea>
          </Box>
        ))
      )}
      <Stack alignItems="center">
        <Pagination
          count={
            getClassroomsPaginatedUserPayload
              ? Math.round(getClassroomsPaginatedUserPayload.count / 5)
              : 0
          }
          siblingCount={5}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={(event, pageNumber) =>
            handlePaginationChange(event, pageNumber)
          }
        />
      </Stack>
    </Box>
  );
}
