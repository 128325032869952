import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchEvents, pastEvents, upcomingEvents } from "./actions";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  Button,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import useMatchMedia from "../../hooks/matchMedia";
import { ICalendar, IState } from "../../types/calendarTypes";

const navigation = {
  PREVIOUS: "PREV",
  NEXT: "NEXT",
  TODAY: "TODAY",
  DATE: "DATE",
};

const views = {
  MONTH: "month",
  WEEK: "week",
  DAY: "day",
  AGENDA: "agenda",
};

const CustomToolbar = (props: ICalendar, state: IState) => {
  const isDesktopResolution = useMatchMedia("(min-width: 600px)", true);

  function onClickAllEvents() {
    fetchEvents();
  }

  function onClickPastEvents() {
    pastEvents();
  }

  function onClickUpcomingEvents() {
    upcomingEvents();
  }

  const handleDayChange = (event: any, mconte: any) => {
    mconte(event.target.value);
  };

  const navigate = (action: any) => {
    props.onNavigate(action);
  };
  const view = (view: any) => {
    props.onView(view);
    console.log("view", view);
  };

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <IconButton
          onClick={navigate.bind(null, navigation.PREVIOUS)}
          style={{ height: "40px" }}
        >
          <ArrowLeftIcon />
        </IconButton>
        <IconButton
          onClick={navigate.bind(null, navigation.NEXT)}
          style={{ height: "40px" }}
        >
          <ArrowRightIcon />
        </IconButton>
      </span>
      <span className="rbc-toolbar-label">{props.label}</span>
      {isDesktopResolution ? (
        <span className="rbc-btn-group">
          <Button
            className="btn btn-month"
            onClick={view.bind(null, views.MONTH)}
          >
            Month
          </Button>
          <Button
            className="btn btn-week"
            onClick={view.bind(null, views.WEEK)}
          >
            Week
          </Button>
          {/* <Button className="btn btn-day" onClick={view.bind(null, views.DAY)}>Day</Button> */}
          <Button
            className="btn btn-agenda"
            onClick={view.bind(null, views.AGENDA)}
          >
            Agenda
          </Button>
        </span>
      ) : (
        <div className="rbc-btn-group">
          <Select
            className="form-control"
            onChange={(e) =>
              handleDayChange(e, view.bind(null, e.target.value))
            }
            defaultValue={"week"}
            style={{ height: "30px" }}
          >
            {/*
                            <MenuItem value={"day"}>
                                <ListItemText primary="Day" />
                            </MenuItem>
                            */}
            <MenuItem value={"week"}>
              <ListItemText primary="Week" />
            </MenuItem>
            <MenuItem value={"month"}>
              <ListItemText primary="Month" />
            </MenuItem>
            <MenuItem value={"agenda"}>
              <ListItemText primary="Agenda" />
            </MenuItem>
          </Select>
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    events: state.events,
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      fetchEvents,
      pastEvents,
      upcomingEvents,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomToolbar);
