export var borderColor = {
  color1: "#bd",
  color2: "#5CB5DB",
  color3: "#F2E0E7",
  color4: "#A8E0D5",
  logo: "#2eb8df",
  grey: "grey",
  black: "black",
  pastel1: "#FFEDBB", // Blond
  pastel2: "#FFFAFD", // Snow
  pastel3: "#F2E0E7", // Piggy Pink
  pastel4: "#A8E0D5", // Crystal
  pastel5: "#5CB5DB", // Blue Jeans
  pastel_blond: "#FFEDBB", // Blond
  pastel_snow: "#FFFAFD", // Snow
  pastel_pink: "#F2E0E7", // Piggy Pink
  pastel_crystal: "#A8E0D5", // Crystal
  pastel_jeans: "#5CB5DB", // Blue Jeans
  main: "#2E3B55",
  white: "#FFFFFF",
};

export var backgroundColor = {
  color1: "#bd",
  color2: "#5CB5DB",
  color3: "#F2E0E7",
  color4: "#A8E0D5",
  logo: "#2eb8df",
  grey: "grey",
  black: "black",
  pastel1: "#FFEDBB", // Blond
  pastel2: "#FFFAFD", // Snow
  pastel3: "#F2E0E7", // Piggy Pink
  pastel4: "#A8E0D5", // Crystal
  pastel5: "#5CB5DB", // Blue Jeans
  pastel_blond: "#FFEDBB", // Blond
  pastel_snow: "#FFFAFD", // Snow
  pastel_pink: "#F2E0E7", // Piggy Pink
  pastel_crystal: "#A8E0D5", // Crystal
  pastel_jeans: "#5CB5DB", // Blue Jeans
  main: "#2E3B55",
  white: "#FFFFFF",
};
