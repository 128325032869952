import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IClassroom } from "../../types/classroomTypes";
import { ILngLat, ITag } from "../../types/languageTypes";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}
export const getClassroomsFiltered = createAsyncThunk(
  "classrooms/getClassroomsFiltered",
  async (
    data: {
      access_token: string;
      tags: ITag[];
      ne: ILngLat;
      sw: ILngLat;
      type: string;
    },
    thunkApi
  ) => {
    try {
      // Construct body from data
      const body = {
        tags: data.tags.map((tag) => tag.name),
        ne: `${data.ne.lat},${data.ne.lng}`,
        sw: `${data.sw.lat},${data.sw.lng}`,
        type: data.type,
      };

      const response = await axios.post<IClassroom[]>(
        `${url}/classroomsfiltered/`,
        body, // sending body in POST request
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

interface ClassroomState {
  getClassroomsFilteredLoading: boolean;
  getClassroomsFilteredErrors: {};
  getClassroomsFilteredPayload: IClassroom[];
}

const getClassroomsFilteredSlice = createSlice({
  name: "classrooms/getClassroomsFiltered",
  initialState: {
    getClassroomsFilteredLoading: false,
    getClassroomsFilteredErrors: {},
    getClassroomsFilteredPayload: [],
  } as ClassroomState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getClassroomsFiltered.pending, (state, action) => {
        state.getClassroomsFilteredLoading = true;
      })
      .addCase(
        getClassroomsFiltered.fulfilled,
        (state, action: PayloadAction<IClassroom[]>) => {
          state.getClassroomsFilteredLoading = false;
          state.getClassroomsFilteredPayload = action.payload;
        }
      )
      .addCase(
        getClassroomsFiltered.rejected,
        (state, action: PayloadAction<any>) => {
          state.getClassroomsFilteredErrors = action.payload;
        }
      );
  },
});

export default getClassroomsFilteredSlice.reducer;
