import {
  Box,
  Avatar,
  CardHeader,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import PlaceIcon from "@mui/icons-material/Place";
import Grid from "@mui/material/Grid";
import { CircleFlag } from "react-circle-flags";
import LanguageLevel from "./LanguageLevel";
import LanguageIcon from "@mui/icons-material/Language";
import { Fragment } from "react";
import { borderColor } from "../../../../theme/colors";

interface AppProps {
  subscriber: any;
  loading: boolean;
}

export default function UserInfo({ subscriber, loading }: AppProps) {
  return loading ? (
    <CircularProgress />
  ) : (
    <>
      <Box sx={{ mb: 2 }} key={subscriber.id}>
        <Card
          sx={{
            borderColor: borderColor.logo,
            width: "100%",
          }}
          variant="outlined"
        >
          <CardHeader
            avatar={
              <Avatar
                alt={
                  subscriber
                    ? subscriber.username.charAt(0).toUpperCase() +
                      subscriber.username.slice(1)
                    : ""
                }
                src={subscriber.profile_image}
                sx={{ width: 75, height: 75 }}
              />
            }
            titleTypographyProps={{ variant: "h6" }}
            title={
              subscriber
                ? subscriber.username.charAt(0).toUpperCase() +
                  subscriber.username.slice(1)
                : ""
            }
          />
          <CardContent>
            <Box sx={{ ml: 2 }}>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <PlaceIcon sx={{ mr: 1 }} />
                </Grid>
                <Grid item>
                  {subscriber.timezone ? subscriber.timezone : "NA"}
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <LanguageIcon sx={{ mr: 1 }} />
                </Grid>
                <Grid item>
                  {subscriber.native_languages_names &&
                  subscriber.native_languages_names.length > 0
                    ? subscriber.native_languages_names.map(
                        (language: any, index: number) => (
                          <span key={index}>
                            <span> </span>
                            <CircleFlag
                              countryCode={language.split("-")[1].toLowerCase()}
                              style={{
                                width: "1em",
                                height: "1em",
                              }}
                            />
                          </span>
                        )
                      )
                    : "NA"}
                </Grid>
              </Grid>
              <Grid container direction="row" alignItems="center">
                <Grid item>
                  <LocalLibraryIcon sx={{ mr: 1 }} />
                </Grid>
                <Grid item>
                  {subscriber.learning_languages_names &&
                  subscriber.learning_languages_names.length > 0
                    ? subscriber.learning_languages_names.map(
                        (language: any, index: number) => (
                          <Fragment key={index}>
                            <span>
                              <span> </span>
                              <CircleFlag
                                countryCode={language
                                  .split("-")[1]
                                  .toLowerCase()}
                                style={{
                                  width: "1em",
                                  height: "1em",
                                }}
                              />
                            </span>
                            {subscriber.learning_languages_levels &&
                            subscriber.learning_languages_levels.length > 0 ? (
                              <LanguageLevel
                                level={
                                  subscriber.learning_languages_levels[index]
                                }
                              />
                            ) : (
                              <span
                                style={{
                                  color: "#DCDCDC",
                                  width: "1em",
                                  height: "1em",
                                }}
                              >
                                NA
                              </span>
                            )}
                          </Fragment>
                        )
                      )
                    : "NA"}
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
