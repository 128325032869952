import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IErrors, IMessage, IChannel } from "../../types/MessageTypes";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getChannelsMessages = createAsyncThunk(
  "user/getUsers",
  async (data: { access_token: string }, thunkApi) => {
    try {
      const response = await axios.get<IChannel[]>(`${url}/channels/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
      });
      //console.log("response.data: ", response.data)
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const ChannelsMessagesSlice = createSlice({
  name: "getMessages",
  initialState: {
    getChannelsMessagesLoading: false,
    getChannelsMessagesErrors: {} as IErrors,
    getChannelsMessagesPayload: [] as IChannel[],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getChannelsMessages.pending, (state, action) => {
        state.getChannelsMessagesLoading = true;
      })
      .addCase(getChannelsMessages.fulfilled, (state, action: any) => {
        state.getChannelsMessagesLoading = false;
        state.getChannelsMessagesPayload = action.payload;
      })
      .addCase(getChannelsMessages.rejected, (state, action: any) => {
        state.getChannelsMessagesLoading = false;
        state.getChannelsMessagesErrors = action.payload;
      });
  },
});

export default ChannelsMessagesSlice.reducer;
