// ChannelsList.tsx or ChannelsList.jsx

import React from "react";

import {
  List,
  ListItem,
  ListItemIcon,
  Avatar,
  ListItemText,
  Typography,
  styled,
  Badge,
} from "@mui/material";
import { IChannel } from "../../../types/MessageTypes";

export const StyledBadgeOnline = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export const StyledBadgeOffline = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#c90223",
    color: "#c90223",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));

interface Channel {
  uuid: string;
  name?: string;
  profile_image?: string;
  last_message?: string;
  any_subscriber_online_except?: boolean;
  is_only_other_user_bot?: boolean;
}

interface ChannelsListProps {
  height: number;
  channels: IChannel[];
  isDesktopResolution: boolean;
  selectedChannel: Channel;
  onClickChannelHandler: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
}

const ChannelsList: React.FC<ChannelsListProps> = ({
  height,
  channels,
  isDesktopResolution,
  selectedChannel,
  onClickChannelHandler,
}) => (
  <List
    style={{
      maxHeight: height - 300,
      minHeight: height - 300,
      overflow: "auto",
      border: "1px solid #e0e0e0", // This line adds a border around your list
      borderRadius: "4px", // This line adds rounded corners to your border
    }}
  >
    {channels.map((channel, index) => (
      <ListItem
        button
        key={index}
        id={channel.uuid}
        onClick={onClickChannelHandler}
        selected={selectedChannel.uuid === channel.uuid}
      >
        {channel.any_subscriber_online_except ||
        channel.is_only_other_user_bot ? (
          <ListItemIcon>
            <StyledBadgeOnline
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar
                alt={channel.name || ""}
                src={channel.channel_image ? `${channel.channel_image}` : ""}
              />
            </StyledBadgeOnline>
          </ListItemIcon>
        ) : (
          <ListItemIcon>
            <StyledBadgeOffline
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              <Avatar
                alt={channel.name || ""}
                src={channel.channel_image ? `${channel.channel_image}` : ""}
              />
            </StyledBadgeOffline>
          </ListItemIcon>
        )}
        {isDesktopResolution && (
          <ListItemText
            primary={
              <Typography sx={{ fontSize: "0.875rem" }}>
                {channel.name && channel.name.length > 20
                  ? channel.name.substring(0, 20) + "..."
                  : channel.name || ""}
              </Typography>
            }
            secondary={
              <Typography sx={{ fontSize: "0.875rem" }}>
                {channel.last_message && channel.last_message.length > 20
                  ? channel.last_message.substring(0, 20) + "..."
                  : channel.last_message || ""}
              </Typography>
            }
          />
        )}
      </ListItem>
    ))}
  </List>
);

export default ChannelsList;
