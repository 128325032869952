import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

export default function SkeletonColor() {
  return (
    <>
      <Skeleton
        variant="rectangular"
        width="100%"
        height={400}
        sx={{ mb: 2, borderRadius: "5px" }}
      />
    </>
  );
}
