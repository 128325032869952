import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const postWord = createAsyncThunk(
  "linguistics/postWord",
  async (
    data: {
      access_token: string;
      word: string;
      context: string;
      language: string;
      level: string;
    },
    thunkApi
  ) => {
    try {
      const response = await fetch(`${url}/linguistics/analyzeword/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        body: JSON.stringify({
          word: data.word,
          context: data.context,
          language: data.language,
          level: data.level,
        }),
      });
      // await and if not ok return error
      const responseData = await response.json(); // Parse the response to JSON

      if (!response.ok) {
        return thunkApi.rejectWithValue({
          error: responseData.message || "Something went wrong",
        });
      }
      return responseData; // Return the parsed data instead of a custom object
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const postWordSlice = createSlice({
  name: "postWordSlice",
  initialState: {
    postWordLoading: false,
    postWordError: null,
    postWordPayload: {
      analysis: {
        description: "",
        word: "",
        context: "",
        language: "",
        parts_of_speech: "",
        translations: "",
      },
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postWord.pending, (state) => {
        state.postWordLoading = true;
      })
      .addCase(postWord.fulfilled, (state, action: any) => {
        state.postWordLoading = false;
        state.postWordPayload = action.payload;
      })
      .addCase(postWord.rejected, (state, action: any) => {
        state.postWordLoading = false;
        state.postWordError = action.payload;
      });
  },
});

export default postWordSlice.reducer;
