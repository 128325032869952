export const getTeacherTypeLabel = (type: string): string => {
  switch (type) {
    case "nominated":
      return "Nominated";
    case "rejected":
      return "Rejected";
    case "accepted":
      return "Accepted";
    default:
      return "Unknown";
  }
};
