import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { FormHelperText, MenuItem, Select } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IClassroom, ICreateClassroom } from "../../../../types/classroomTypes";
import { FREQUENCIES } from "../../../../constants/misc";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DayHours, ProcessedEvent } from "@aldabil/react-scheduler/types";
import timezones from "timezones-list";
import { IUserProfilePlusEmailPlusTeacher } from "../../../../types/userProfileTypes";

const durations = [
  ["30 minutes", 30],
  ["45 minutes", 45],
  ["1 hour", 60],
  ["1 hour 15 minutes", 75],
  ["1 hour 30 minutes", 90],
  ["1 hour 45 minutes", 105],
  ["2 hours", 120],
  ["2 hours 15 minutes", 135],
  ["2 hours 30 minutes", 150],
  ["2 hours 45 minutes", 165],
  ["3 hours", 180],
  ["3 hours 15 minutes", 195],
  ["3 hours 30 minutes", 210],
  ["3 hours 45 minutes", 225],
  ["4 hours", 240],
  ["4 hours 15 minutes", 255],
  ["4 hours 30 minutes", 270],
  ["4 hours 45 minutes", 285],
  ["5 hours", 300],
];

interface AppProps {
  classroom: ICreateClassroom;
  setClassroom: Dispatch<SetStateAction<ICreateClassroom>>;
  getUserClassroomsLoading: boolean;
  getUserClassroomsErrors: {};
  getUserClassroomsPayload: IClassroom[];
  processedEvents: ProcessedEvent[];
  setProcessedEvents: Dispatch<SetStateAction<ProcessedEvent[]>>;
  earliestHour: DayHours;
  setEarliestHour: Dispatch<SetStateAction<DayHours>>;
  latestHour: DayHours;
  setLatestHour: Dispatch<SetStateAction<DayHours>>;
  processedEventToAdd: ProcessedEvent;
  setProcessedEventToAdd: Dispatch<SetStateAction<ProcessedEvent>>;
  //handleConfirm: (event: ProcessedEvent, action: EventActions) => void;
  getUserProfilePayload: IUserProfilePlusEmailPlusTeacher;
}

const Schedule = ({
  classroom,
  setClassroom,
  getUserClassroomsLoading,
  getUserClassroomsErrors,
  getUserClassroomsPayload,
  processedEvents,
  setProcessedEvents,
  earliestHour,
  setEarliestHour,
  latestHour,
  setLatestHour,
  processedEventToAdd,
  setProcessedEventToAdd,
  getUserProfilePayload,
}: AppProps) => {
  const [classroomDuration, setClassroomDuration] = useState(60);
  const [timeZone, setTimeZone] = useState(getUserProfilePayload.timezone);
  const [timeZoneOffset, setTimeZoneOffset] = useState(0);
  const [today, setToday] = useState(new Date());

  useEffect(() => {
    const today = new Date();
    // round to next full 15 minutes, plus 3 hours from now
    today.setHours(today.getHours() + 3);
    today.setMinutes(Math.ceil(today.getMinutes() / 15) * 15);
    today.setSeconds(0);
    today.setMilliseconds(0);
    const oneDayLater = new Date(today);
    oneDayLater.setDate(oneDayLater.getDate() + 1);
    const oneHourLater = new Date(today);
    oneHourLater.setHours(today.getHours() + 1);
    setToday(today);
    setClassroom({
      ...classroom,
      calendar_events: [
        {
          ...classroom.calendar_events[0],
          start: today,
          end: oneHourLater,
        },
      ],
    });
  }, []);

  console.log("timezone", timeZone);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4} md={4} sx={{ alignItems: "top" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
              label="Start date and time"
              inputFormat="DD/MM/YYYY HH:mm"
              value={classroom.calendar_events[0].start}
              minDate={today}
              minutesStep={15}
              onChange={(event) => {
                const start = new Date(event ? event : today);
                console.log("start", start);
                // ends one hour later
                const end = new Date(start);
                end.setHours(end.getHours() + classroomDuration / 60);
                end.setMinutes(end.getMinutes() + (classroomDuration % 60));
                setClassroom({
                  ...classroom,
                  calendar_events: [
                    {
                      ...classroom.calendar_events[0],
                      start: start,
                      end: end,
                    },
                  ],
                });
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
          <FormHelperText>
            Choose the start date and time of the class
          </FormHelperText>
        </Grid>
        <Grid item xs={12} sm={4} md={4} sx={{ alignItems: "top" }}>
          <Select
            labelId="duration"
            id="duration"
            name="duration"
            fullWidth
            defaultValue={60}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            onChange={(event) => {
              //const start = new Date(+event.target.value);
              const start = new Date(classroom.calendar_events[0].start);
              // and add the length
              const end = new Date(start);
              const dur = +event.target.value;
              setClassroomDuration(dur);
              end.setHours(end.getHours() + dur / 60);
              end.setMinutes(end.getMinutes() + (dur % 60));
              setClassroom({
                ...classroom,
                calendar_events: [
                  {
                    ...classroom.calendar_events[0],
                    end: end,
                  },
                ],
              });
            }}
          >
            {durations.map((duration, k) => (
              <MenuItem key={"duration-" + k} value={duration[1]}>
                {duration[0]}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Choose the duration of the class</FormHelperText>
        </Grid>
        <Grid item xs={12} sm={4} md={4} sx={{ alignItems: "top" }}>
          <Select
            labelId="frequency"
            id="frequency"
            name="frequency"
            fullWidth
            defaultValue={"w"}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
            }}
            onChange={(event) => {
              setClassroom({
                ...classroom,
                frequency: event.target.value,
              });
            }}
          >
            {FREQUENCIES.map((frequency, k) => (
              <MenuItem key={"frequency-" + k} value={frequency.short}>
                {frequency.long}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Choose the frequency of the class</FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <Select
            native
            fullWidth
            label="Timezone"
            value={classroom.time_zone}
            onChange={(e) => {
              setClassroom({ ...classroom, time_zone: e.target.value });
            }}
            inputProps={{
              name: "timezone",
              id: "timezone",
            }}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
              height: 55,
            }}
          >
            <option value="">Select timezone</option>
            {timezones.map((timezone: any, index: number) => (
              <option value={timezone.value} key={index}>
                {timezone.tzCode}
              </option>
            ))}
          </Select>
          <FormHelperText>Choose the timezone for the class</FormHelperText>
        </Grid>
      </Grid>
    </>
  );
};

export default Schedule;
