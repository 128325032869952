export function typeChipColorSwitch(paid: boolean): string {
  switch (paid) {
    case true:
      return "Paid";
    case false:
      return "Casual";
    default:
      return "Casual";
  }
}
