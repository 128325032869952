import * as React from "react";
import { Dayjs } from "dayjs";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useEffect } from "react";
import { Box, Select, SelectChangeEvent } from "@mui/material";
import timezones from "timezones-list";

interface AppProps {
  profile: any;
  errors: any;
  state: any;
  setState: any;
  setSaveButtonDisabled: any;
}

const PersonalInformation = ({
  profile,
  errors,
  state,
  setState,
  setSaveButtonDisabled,
}: AppProps) => {
  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, username: e.target.value });
    setSaveButtonDisabled(false);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, email: e.target.value });
    setSaveButtonDisabled(false);
  };
  const handleDateChange = (newValue: Dayjs | null) => {
    setState({
      ...state,
      year: newValue && +newValue.year(),
      month: newValue && +newValue.month(),
      day: newValue && +newValue.date(),
    });
    setSaveButtonDisabled(false);
  };
  const handleTimeZoneChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    setState({ ...state, timezone: event.target.value });
    setSaveButtonDisabled(false);
  };
  const handleAboutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, about: e.target.value });
    setSaveButtonDisabled(false);
  };
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
    });
  });

  return (
    <React.Fragment>
      <Box sx={{ mb: 5 }}>Personal Information</Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            error={errors.username ? true : false}
            id="username"
            placeholder="Enter user name"
            label="User name"
            variant="outlined"
            helperText={errors ? errors.username : ""}
            fullWidth
            required
            defaultValue={profile ? profile.username : ""}
            onChange={handleUsernameChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="email"
            type="email"
            placeholder="Enter email"
            label="Email"
            variant="outlined"
            fullWidth
            required
            defaultValue={profile ? profile.email : ""}
            disabled={true}
            onChange={handleEmailChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              label="Date of birth"
              inputFormat="MM/DD/YYYY"
              value={
                state.year && state.month && state.day
                  ? new Date(state.year, state.month, state.day)
                  : null
              }
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <Select
            native
            fullWidth
            label="Timezone"
            defaultValue={
              state.timezone
                ? state.timezone
                : Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            onChange={handleTimeZoneChange}
            inputProps={{
              name: "timezone",
              id: "timezone",
            }}
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
              height: 55,
            }}
          >
            <option value="">Select timezone</option>
            {timezones.map((timezone: any, index: number) => (
              <option value={timezone.value} key={index}>
                {timezone.tzCode}
              </option>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            error={errors.about ? true : false}
            id="about"
            label="About"
            multiline
            rows={4}
            placeholder="Write something about you"
            variant="outlined"
            helperText={errors ? errors.about : ""}
            fullWidth
            defaultValue={profile ? profile.about : ""}
            onChange={handleAboutChange}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PersonalInformation;
