import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { Fragment, useContext, useEffect, useState } from "react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { CircleFlag } from "react-circle-flags";
import LanguageLevel from "./LanguageLevel";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import LanguageIcon from "@mui/icons-material/Language";
import PlaceIcon from "@mui/icons-material/Place";
import SellIcon from "@mui/icons-material/Sell";
import InfoIcon from "@mui/icons-material/Info";
import AuthContext from "../../../../context/AuthContext";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../hooks/useTypedSelector";
import { ITeacher, IUpDownVotes } from "../../../../types/classroomTypes";
import { postTeacherUpDownvote } from "../../../../features/classrooms/postTeacherUpDownvoteSlice";
import { backgroundColor, borderColor } from "../../../theme/colors";
import { teacherTypeStyle } from "./teacherTypeStyleGetter";
import { getTeacherTypeLabel } from "./teacherTypeLabelGetter";

interface AppProps {}

function ShowTeacherInvitations({}: AppProps) {
  const { getClassroomLoading, getClassroomPayload } = useAppSelector(
    (state) => state.getClassroom
  );

  function handleUpDownVote(event: any) {
    if (getClassroomPayload.uuid && event.currentTarget.id) {
      let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
      let access_token = authTokens.access;
      dispatch(
        postTeacherUpDownvote({
          access_token: access_token,
          classroom_uuid: getClassroomPayload.uuid,
          teacher_uuid: event.currentTarget.id,
        })
      );
      // if upvoted, dispatch downvote
      if (upvoted === event.currentTarget.id) {
        setUpvoted("");
        // delete current user from upDownVotes
        let upDownVotesCopy = { ...upDownVotes };
        upDownVotesCopy[event.currentTarget.id] = upDownVotesCopy[
          event.currentTarget.id
        ].filter((user_uuid: string) => user_uuid !== currentUser.uuid);
        setUpDownVotes(upDownVotesCopy);
        return;
      } else {
        setUpvoted(event.currentTarget.id);
        // add current user to upDownVotes
        let upDownVotesCopy = { ...upDownVotes };
        if (upDownVotesCopy[event.currentTarget.id]) {
          upDownVotesCopy[event.currentTarget.id].push(currentUser.uuid);
        } else {
          upDownVotesCopy[event.currentTarget.id] = [currentUser.uuid];
        }
        // remove current user from all other members of upDownVotes
        Object.keys(upDownVotesCopy).map((teacher_uuid: string) => {
          if (teacher_uuid !== event.currentTarget.id) {
            upDownVotesCopy[teacher_uuid] = upDownVotesCopy[
              teacher_uuid
            ].filter((user_uuid: string) => user_uuid !== currentUser.uuid);
          }
        });
        setUpDownVotes(upDownVotesCopy);
      }
    }
  }

  const [upDownVotes, setUpDownVotes] = useState<IUpDownVotes>({
    "0": [],
  });
  const [upvoted, setUpvoted] = useState("");
  const {
    postTeacherUpvotePayload,
    postTeacherUpvoteLoading,
    postTeacherUpvoteErrors,
  } = useAppSelector((state) => state.postTeacherUpvote);

  // Dispatch
  const dispatch = useAppDispatch();

  // Context
  const { currentUser, isAuthenticated } = useContext(AuthContext);

  const isCurrentUserOwner =
    currentUser.uuid === getClassroomPayload.author_details.uuid;

  // debug

  useEffect(() => {
    if (!isAuthenticated) return;
    let udv = {} as IUpDownVotes;
    getClassroomPayload.nominated_teachers_details.map(
      (teacher: any, index: number) => {
        // create empty array for each teacher
        udv[teacher.user_uuid] = [];
        // add user uuid to array
        teacher.voted_by.map((uuid: string) => {
          udv[teacher.user_uuid].push(uuid);
        });
      }
    );
    setUpDownVotes(udv);
    // find user uuid in voted_by array and set upvoted
    getClassroomPayload.nominated_teachers_details.map(
      (teacher: any, index: number) => {
        if (teacher.voted_by.includes(currentUser.uuid)) {
          setUpvoted(teacher.user_uuid);
        }
      }
    );
  }, [getClassroomPayload]);

  return (
    <Fragment>
      {getClassroomPayload.nominated_teachers_details.length > 0 ? (
        getClassroomPayload.nominated_teachers_details.map(
          (teacher: any, index: number) => {
            return (
              <Fragment key={index}>
                <Card
                  sx={{
                    borderColor: borderColor.pastel3,
                    width: "100%",
                    mt: 2,
                  }}
                  variant="outlined"
                >
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ p: 2 }}
                    >
                      <Grid item>
                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <Avatar
                              alt={
                                teacher.username.charAt(0).toUpperCase() +
                                teacher.username.slice(1)
                              }
                              src={teacher.profile_image}
                              sx={{ width: 35, height: 35 }}
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              fontSize="0.7rem"
                              fontWeight="bold"
                              component="div"
                              sx={{ ml: 2 }}
                            >
                              {teacher.username.charAt(0).toUpperCase() +
                                teacher.username.slice(1)}
                            </Typography>
                          </Grid>
                          <Grid item>
                            {/* Teacher type */}
                            <Chip label={"Tutor"} sx={{ ml: 2 }} />
                            {/* End Teacher type */}

                            {/* Teacher status */}
                            <Chip
                              label={getTeacherTypeLabel(teacher.status)}
                              sx={{
                                ml: 1,
                                backgroundColor: teacherTypeStyle(
                                  teacher.status
                                ),
                                color: "#fff",
                              }}
                            />
                            <Tooltip
                              title="You have to wait until the teacher accepts the invitation"
                              placement="right"
                            >
                              <InfoIcon
                                style={{
                                  height: "0.5em",
                                  width: "0.5em",
                                }}
                                sx={{ ml: 0.5 }}
                              />
                            </Tooltip>
                            {/* End Teacher status */}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <IconButton
                              sx={{
                                mr: 1,
                                fontSize: "0.5em",
                              }}
                              id={teacher.user_uuid}
                              onClick={handleUpDownVote}
                            >
                              <ThumbUpIcon
                                color={
                                  upvoted == teacher.user_uuid
                                    ? "success"
                                    : "primary"
                                }
                              />
                            </IconButton>
                          </Grid>
                          <Grid item>
                            {upDownVotes[teacher.user_uuid]
                              ? upDownVotes[teacher.user_uuid].length
                              : 0}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <PlaceIcon sx={{ mr: 2, ml: 2 }} />
                      </Grid>
                      <Grid item>
                        {teacher.timezone ? teacher.timezone : "NA"}
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <LanguageIcon sx={{ mr: 2, ml: 2 }} />
                      </Grid>
                      <Grid item>
                        {teacher.native_languages_names &&
                        teacher.native_languages_names.length > 0
                          ? teacher.native_languages_names.map(
                              (language: any, index: number) => (
                                <span key={index}>
                                  <span> </span>
                                  <CircleFlag
                                    countryCode={language
                                      .split("-")[1]
                                      .toLowerCase()}
                                    style={{
                                      width: "1em",
                                      height: "1em",
                                    }}
                                  />
                                </span>
                              )
                            )
                          : "NA"}
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <LocalLibraryIcon
                          sx={{ mr: 2, ml: 2, fontSize: "1rem" }}
                        />
                      </Grid>
                      <Grid item>
                        {teacher.learning_languages_names &&
                        teacher.learning_languages_names.length > 0
                          ? teacher.learning_languages_names.map(
                              (language: any, index: number) => (
                                <Fragment key={index}>
                                  <span>
                                    <CircleFlag
                                      countryCode={language
                                        .split("-")[1]
                                        .toLowerCase()}
                                      style={{
                                        width: "1em",
                                        height: "1em",
                                        fontSize: "small",
                                      }}
                                    />
                                  </span>
                                  {teacher.learning_languages_levels &&
                                  teacher.learning_languages_levels.length >
                                    0 ? (
                                    <LanguageLevel
                                      level={
                                        teacher.learning_languages_levels[index]
                                      }
                                    />
                                  ) : (
                                    <span
                                      style={{
                                        color: "#DCDCDC",
                                        width: "1em",
                                        height: "1em",
                                      }}
                                    >
                                      NA
                                    </span>
                                  )}
                                </Fragment>
                              )
                            )
                          : "NA"}
                      </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <SellIcon sx={{ mr: 2, ml: 2, mt: 2 }} />
                      </Grid>
                      <Grid item sx={{ mt: 2 }}>
                        {/* Price */}
                        {"100USD"}
                        {/* End Price */}

                        {/* Payment type */}
                        <Chip
                          label={"per person"}
                          style={{
                            backgroundColor: "lightblue",
                            height: "1.2em",
                          }}
                          sx={{ ml: 0.5 }}
                        />
                        <Tooltip
                          title="Per class means that the price is divided by the number of people in the classroom; the more people the cheaper the class. Per person means that each person will have to pay this amount of money."
                          placement="right"
                        >
                          <InfoIcon
                            style={{
                              height: "0.5em",
                              width: "0.5em",
                            }}
                            sx={{ ml: 0.5 }}
                          />
                        </Tooltip>
                        {/* End Payment type */}
                      </Grid>
                    </Grid>
                    <Grid item>
                      {isCurrentUserOwner && (
                        <Box sx={{ p: 1 }}>
                          {teacher.has_accepted ? (
                            <Button
                              variant="contained"
                              sx={{
                                ml: 1,
                                mt: 3,
                                mb: 3,
                                bgcolor: backgroundColor.pastel5,
                              }}
                            >
                              Pick this teacher
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Box>
                      )}
                    </Grid>
                  </CardContent>
                  <CardActions></CardActions>
                </Card>
              </Fragment>
            );
          }
        )
      ) : (
        <Fragment>
          <Card
            sx={{
              borderColor: borderColor.pastel3,
              width: "100%",
            }}
            variant="outlined"
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ p: 2 }}
            >
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <Grid item>
                    <Avatar
                      alt={"No teacher"}
                      src={"no_teacher.png"}
                      sx={{ width: 35, height: 35 }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      fontSize="0.7rem"
                      fontWeight="bold"
                      component="div"
                      sx={{ ml: 2 }}
                    >
                      {"No teacher"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Fragment>
      )}
    </Fragment>
  );
}
export default ShowTeacherInvitations;
