import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IClassroom } from "../../types/classroomTypes";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getClassroom = createAsyncThunk(
  "classroom/getClassroom",
  async (data: { access_token: string; classroomId: string }, thunkApi) => {
    try {
      const response = await axios.get<IClassroom>(
        `${url}/classrooms/${data.classroomId}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

interface ClassroomState {
  getClassroomLoading: boolean;
  getClassroomErrors: {};
  getClassroomPayload: IClassroom;
}

const getClassroomSlice = createSlice({
  name: "getClassroom",
  initialState: {
    getClassroomLoading: false,
    getClassroomErrors: {},
    getClassroomPayload: {} as IClassroom,
  } as ClassroomState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getClassroom.pending, (state, action) => {
        state.getClassroomLoading = true;
      })
      .addCase(getClassroom.fulfilled, (state, action: PayloadAction<any>) => {
        state.getClassroomLoading = false;
        state.getClassroomPayload = action.payload;
      })
      .addCase(getClassroom.rejected, (state, action: PayloadAction<any>) => {
        state.getClassroomErrors = action.payload;
      });
  },
});

export default getClassroomSlice.reducer;
