import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
  IconButton,
  SelectChangeEvent,
  Stack,
  styled,
  Switch,
  Tooltip,
} from "@mui/material";
import { Fragment, SetStateAction, useEffect, useState } from "react";
import {
  ICreateClassroom,
  PaymentTypeEnum,
} from "../../../../types/classroomTypes";
import InfoIcon from "@mui/icons-material/Info";
import PerStudent from "../../PerStudent";
import PerClass from "../../PerClass";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(180,90,180,1)",
    boxSizing: "border-box",
  },
}));

interface AppProps {
  classroom: ICreateClassroom;
  setClassrooms: React.Dispatch<SetStateAction<ICreateClassroom>>;
}

const Payment = ({ classroom, setClassrooms }: AppProps) => {
  const [prices, setPrices] = useState(classroom.prices);
  const [numbers, setNumbers] = useState(classroom.n);

  useEffect(() => {
    setClassrooms({ ...classroom, prices: prices, n: numbers });
  }, [prices, numbers]);

  const handleChangeNumbers = (event: SelectChangeEvent) => {
    // get event target id
    const name = event.target.name;
    // get event target value
    const value = event.target.value;
    console.log(name);
    console.log(value);
    // get index of language
    const index = parseInt(name.split("-")[1]);
    // get new language list
    const newNumbers = [...classroom.n];
    // set new language
    newNumbers[index] = +value;
    // set new language list
    setPrices(newNumbers);
  };

  const handleChangePrice = (event: SelectChangeEvent) => {
    // get event target id
    const name = event.target.name;
    // get event target value
    const value = event.target.value;
    console.log(name);
    console.log(value);
    // get index of level
    const index = parseInt(name.split("-")[1]);
    // get new level list
    const newPrices = [...classroom.prices];
    // set new level
    newPrices[index] = +value;
    // set new level list
    setPrices(newPrices);
  };

  const handleChangePaymentType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setClassrooms({
      ...classroom,
      paymentType: checked
        ? PaymentTypeEnum.PERSTUDENT
        : PaymentTypeEnum.PERCLASS,
    });
  };

  const addPriceAndNumber = () => {
    // add new language
    setPrices([...classroom.prices, 0]);
    setNumbers([...classroom.n, 1]);
  };

  const removePriceAndNumber = (index: number) => {
    // dont do anything if only one language is left
    if (classroom.n.length === 1) return;
    // get new language list
    const newPrices = [...classroom.prices];
    const newNumbers = [...classroom.n];
    // remove language
    newPrices.splice(index, 1);
    newNumbers.splice(index, 1);
    setPrices(newPrices);
    setNumbers(newNumbers);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
            <Typography>Per class</Typography>
            <AntSwitch
              defaultChecked
              inputProps={{ "aria-label": "ant design" }}
              onChange={handleChangePaymentType}
            />
            <Typography>Per student</Typography>
            <Tooltip title="There are two payment options for students: (1) Per class: The teacher's salary is fixed per hour, with the cost per student decreasing as more join. For example, if a class is set at 100 USD and two students join, each student pays 50 USD (100/2=50). (2) Per student: Each student pays a fixed amount, and the teacher's salary increases as more students join.">
              <IconButton>
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>
        {classroom.paymentType === PaymentTypeEnum.PERCLASS && (
          <PerClass classrooms={classroom} setClassrooms={setClassrooms} />
        )}
        {classroom.paymentType === PaymentTypeEnum.PERSTUDENT && (
          <PerStudent classrooms={classroom} setClassrooms={setClassrooms} />
        )}
      </Grid>
    </>
  );
};

export default Payment;
