import { ThemeProvider } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../hooks/useTypedSelector";
import { useContext, useEffect, useState } from "react";
import { getUserProfile } from "../../features/users/getUserProfileSlice";
import { patchUserProfile } from "../../features/users/patchUserProfileSlice";
import { getLanguagesAndCountries } from "../../features/languages/getLanguagesSlice";
import { Divider, Grid, Stack } from "@mui/material";
import { setNextDate, dateToDayTime } from "./convertTime";
import { ToastContainer, toast } from "react-toastify";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Copyright from "../Copyright/Copyright";
import AppBar from "../Header/AppBar";
import AuthContext from "../../context/AuthContext";
import Loader from "../Loaders/Loader";
import "react-toastify/dist/ReactToastify.css";

import theme from "../theme/theme";
import CharacterCreation from "./Forms/Character/CharacterCreation";
import { postAvatarChange } from "../../features/users/postAvatarChangeSlice";
import Language from "./Forms/Language";
import Images from "./Forms/Images";
import PersonalInformation from "./Forms/PersonalInformation";
import Teacher from "./Forms/Teacher";
import TeacherDocuments from "./Forms/TeacherDocuments";
import TeacherAvailability from "./Forms/TeacherAvailability";

export default function ProfileUpdate() {
  const dispatch = useAppDispatch();
  const { currentUser } = useContext(AuthContext);

  const [activeWindow, setActiveWindow] = useState("personalInformation");

  const [errors, setErrors] = useState<any>({
    username: "",
    email: "",
    about: "",
    dateOfBirth: "",
    countriesLiving: "",
    countriesFrom: "",
    nativeLanguages: "",
    learningLanguages: "",
    learningLevels: "",
    profileImages: "",
    teaching_languages: "",
    teaching_languages_experience: "",
    first_name: "",
    last_name: "",
    availability: "",
    availability_template: "",
    avatar: "",
  });

  const [state, setState] = useState<any>({
    username: "",
    email: "",
    about: "",
    date_of_birth: {
      year: 2023,
      month: 1,
      day: 1,
    },
    countries_living: [],
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    countries_from: [],
    native_languages: ["en-US"],
    learning_languages: ["en-US"],
    learning_levels: ["A1"],
    profile_images: {
      profile_image: "",
      images: [],
    },
    teacher_profile: {
      teaching_languages: ["en-US"],
      teaching_languages_experience: ["0"],
      first_name: "",
      last_name: "",
      about: [],
      availability_template: [],
      availability: [],
    },
    avatar_data: {},
  });

  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);
  const { getUserProfileLoading, getUserProfilePayload } = useAppSelector(
    (state) => state.getUserProfile
  );
  const { patchUserProfileError } = useAppSelector(
    (state) => state.patchUserProfile
  );
  const { getLanguagesAndCountriesPayload } = useAppSelector(
    (state) => state.getLanguagesAndCountries
  );

  useEffect(() => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      getUserProfile({
        access_token: access_token,
        subscriberProfileId: currentUser.profile_uuid,
      })
    );
    dispatch(getLanguagesAndCountries({ access_token: access_token }));
  }, []);

  useEffect(() => {
    if (getUserProfilePayload) {
      setState({
        ...state,
        username: getUserProfilePayload.username,
        email: getUserProfilePayload.email,
        about: getUserProfilePayload.about,
        date_of_birth: {
          year: +getUserProfilePayload.date_of_birth.split("-")[0],
          month: +getUserProfilePayload.date_of_birth.split("-")[1],
          day: +getUserProfilePayload.date_of_birth.split("-")[2],
        },
        countries_living: getUserProfilePayload.countries_living,
        timezone: getUserProfilePayload.timezone,
        countries_from: getUserProfilePayload.countries_from,
        native_languages: getUserProfilePayload.native_languages_names,
        learning_languages: getUserProfilePayload.learning_languages_names,
        learning_levels: getUserProfilePayload.learning_languages_levels,
        profile_images: {
          profile_image: getUserProfilePayload.profile_image,
          images: getUserProfilePayload.images,
        },
        teacher_profile: {
          teaching_languages:
            getUserProfilePayload.teacher_profile.teaching_languages,
          teaching_languages_experience:
            getUserProfilePayload.teacher_profile.years_of_experience,
          about: getUserProfilePayload.teacher_profile.about,
          first_name: getUserProfilePayload.teacher_profile.first_name,
          last_name: getUserProfilePayload.teacher_profile.last_name,
          //availability: convertTimeToDate30Min(getUserProfilePayload.teacher_profile.availability),
          availability_template: setNextDate(
            getUserProfilePayload.teacher_profile.availability_template
          ),
          documents: getUserProfilePayload.teacher_profile.documents,
        },
        avatar_data: getUserProfilePayload.avatar_data,
      });
    }
  }, [getUserProfilePayload]);

  useEffect(() => {
    if (patchUserProfileError) {
      setErrors({
        username: patchUserProfileError["username"]
          ? patchUserProfileError["username"]
          : "",
        email: patchUserProfileError["email"]
          ? patchUserProfileError["email"]
          : "",
        about: patchUserProfileError["about"]
          ? patchUserProfileError["about"]
          : "",
        avatar_data: patchUserProfileError["avatar_data"]
          ? patchUserProfileError["avatar_data"]
          : "",
        date_of_birth: patchUserProfileError["date_of_birth"]
          ? patchUserProfileError["date_of_birth"]
          : "",
        timezone: patchUserProfileError["timezone"]
          ? patchUserProfileError["timezone"]
          : "",
        countries_living: patchUserProfileError["countries_living"]
          ? patchUserProfileError["countries_living"]
          : "",
        countries_from: patchUserProfileError["countries_from"]
          ? patchUserProfileError["countries_from"]
          : "",
        native_languages: patchUserProfileError["native_languages"]
          ? patchUserProfileError["native_languages"]
          : "",
        learning_languages: patchUserProfileError["learning_languages"]
          ? patchUserProfileError["learning_languages"]
          : "",
        learning_levels: patchUserProfileError["learning_languages_levels"]
          ? patchUserProfileError["learning_languages_levels"]
          : "",
        profile_images: patchUserProfileError["profile_image"]
          ? patchUserProfileError["profile_image"]
          : "",
        teaching_languages: patchUserProfileError["teaching_languages"]
          ? patchUserProfileError["teaching_languages"]
          : "",
        teaching_languages_experience: patchUserProfileError[
          "teaching_languages_experience"
        ]
          ? patchUserProfileError["teaching_languages_experience"]
          : "",
        availability: patchUserProfileError["availability"]
          ? patchUserProfileError["availability"]
          : "",
        availability_template: patchUserProfileError["availability_template"]
          ? patchUserProfileError["availability_template"]
          : "",
        first_name: patchUserProfileError["first_name"]
          ? patchUserProfileError["first_name"]
          : "",
        last_name: patchUserProfileError["last_name"]
          ? patchUserProfileError["last_name"]
          : "",
        documents: patchUserProfileError["documents"]
          ? patchUserProfileError["documents"]
          : "",
      });
    }
  }, [patchUserProfileError]);

  useEffect(() => {
    setSaveButtonDisabled(true);
  }, []);

  const handleSaveClick = () => {
    console.log(state);
    save();
  };

  // Function to check if there are any errors
  const hasErrors = (errors: any) => {
    for (const key in errors) {
      if (typeof errors[key] === "string" && errors[key] !== "") {
        return errors[key]; // Return true if any error is found
      }
    }
    return false; // Return false if no errors are found
  };

  const save = () => {
    let data = {
      username: state.username,
      email: state.email,
      avatar_data: state.avatar_data,
      about: state.about,
      date_of_birth: `${state.date_of_birth.year}-${state.date_of_birth.month}-${state.date_of_birth.day}`,
      countries_living: state.countries_living,
      countries_from: state.countries_from,
      timezone: state.timezone,
      native_languages: state.native_languages,
      learning_languages: state.learning_languages,
      learning_languages_levels: state.learning_levels,
      profileImages: state.profile_images,
      is_online: true,
      teacher_profile: {
        teaching_languages: state.teacher_profile.teaching_languages,
        years_of_experience:
          state.teacher_profile.teaching_languages_experience,
        about: state.teacher_profile.about,
        first_name: state.teacher_profile.first_name,
        last_name: state.teacher_profile.last_name,
        //availability: convertDateToTime30Min(state.teacher_profile.availability),
        availability_template: dateToDayTime(
          state.teacher_profile.availability_template
        ),
        documents: state.teacher_profile.documents,
      },
    };
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      patchUserProfile({
        access_token: access_token,
        data: data,
        subscriberId: getUserProfilePayload.uuid,
      })
    );
    dispatch(
      postAvatarChange({
        access_token: access_token,
        avatar_data: state.avatar_data,
      })
    );

    const error = hasErrors(errors);
    // If there are errors, show a notification
    if (hasErrors(errors)) {
      toast.error(`Error: ${error}. Please fix the errors in the form.`, {
        position: "top-left",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    setSaveButtonDisabled(true);
  };

  return getUserProfileLoading ? (
    <div>
      <Loader />
    </div>
  ) : (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar currentUser={currentUser} userProfile={getUserProfilePayload} />

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Paper
                variant="outlined"
                sx={{ mt: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
              >
                {/* Create a tablike alternative with buttons instead of tabs */}
                <Stack direction="column" spacing={2} sx={{ mb: 2 }}>
                  <Button
                    variant="contained"
                    color={
                      activeWindow == "personalInformation" ? "primary" : "info"
                    }
                    onClick={() => setActiveWindow("personalInformation")}
                  >
                    Personal Info
                  </Button>
                  <Button
                    variant="contained"
                    color={
                      activeWindow == "languageInformation" ? "primary" : "info"
                    }
                    onClick={() => setActiveWindow("languageInformation")}
                  >
                    Language Info
                  </Button>
                  <Button
                    variant="contained"
                    color={activeWindow == "images" ? "primary" : "info"}
                    onClick={() => setActiveWindow("images")}
                  >
                    Image
                  </Button>
                  <Button
                    variant="contained"
                    color={
                      activeWindow == "characterpicker" ? "primary" : "info"
                    }
                    onClick={() => setActiveWindow("characterpicker")}
                    sx={{ m: 1 }}
                  >
                    Character
                  </Button>
                  {getUserProfilePayload &&
                    getUserProfilePayload.is_teacher && (
                      <>
                        <Divider />
                        <Button
                          variant="contained"
                          color={
                            activeWindow == "teacherProfile"
                              ? "primary"
                              : "info"
                          }
                          onClick={() => setActiveWindow("teacherProfile")}
                        >
                          Teacher Profile
                        </Button>
                        <Button
                          variant="contained"
                          color={
                            activeWindow == "teacheravailability"
                              ? "primary"
                              : "info"
                          }
                          onClick={() => setActiveWindow("teacheravailability")}
                          sx={{ m: 1 }}
                        >
                          Teacher Availability
                        </Button>
                        {/*
                      <Button variant="contained" onClick={() => setActiveWindow("teacherdocuments")} sx={{ m: 1 }}>
                        Teacher Documents
                      </Button>
                      */}
                      </>
                    )}
                  <Button
                    variant="contained"
                    color={"success"}
                    onClick={handleSaveClick}
                    disabled={saveButtonDisabled}
                    sx={{ mr: 1, mb: 1 }}
                  >
                    Save
                  </Button>
                </Stack>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={9}>
              <Paper
                variant="outlined"
                sx={{
                  my: { xs: 3, md: 6 },
                  p: { xs: 2, md: 3 },
                  minHeight: "70vh",
                }}
              >
                {
                  {
                    personalInformation: (
                      <PersonalInformation
                        profile={getUserProfilePayload}
                        errors={errors}
                        state={state}
                        setState={setState}
                        setSaveButtonDisabled={setSaveButtonDisabled}
                      />
                    ),
                    languageInformation: (
                      <Language
                        profile={getUserProfilePayload}
                        errors={errors}
                        languageList={getLanguagesAndCountriesPayload}
                        state={state}
                        setState={setState}
                        setSaveButtonDisabled={setSaveButtonDisabled}
                      />
                    ),
                    images: (
                      <Images
                        profile={getUserProfilePayload}
                        errors={errors}
                        state={state}
                        setState={setState}
                      />
                    ),
                    teacherProfile: (
                      <Teacher
                        profile={getUserProfilePayload}
                        errors={errors}
                        languageList={getLanguagesAndCountriesPayload}
                        state={state}
                        setState={setState}
                        setSaveButtonDisabled={setSaveButtonDisabled}
                      />
                    ),
                    teacheravailability: (
                      <TeacherAvailability
                        profile={getUserProfilePayload}
                        errors={errors}
                        state={state}
                        setState={setState}
                        setSaveButtonDisabled={setSaveButtonDisabled}
                      />
                    ),
                    teacherdocuments: (
                      <TeacherDocuments
                        profile={getUserProfilePayload}
                        errors={errors}
                        state={state}
                        setState={setState}
                        setSaveButtonDisabled={setSaveButtonDisabled}
                      />
                    ),
                    characterpicker: (
                      <CharacterCreation
                        profile={getUserProfilePayload}
                        errors={errors}
                        state={state}
                        setState={setState}
                        setSaveButtonDisabled={setSaveButtonDisabled}
                      />
                    ),
                  }[activeWindow]
                }
                {/* Toast container for notifications */}
                <ToastContainer />
              </Paper>
            </Grid>
          </Grid>
          <Copyright />
        </Container>
      </Box>
    </ThemeProvider>
  );
}
