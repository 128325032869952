import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IClassroom } from "../../types/classroomTypes";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}
export const leaveGroup = createAsyncThunk(
  "classroom/leaveGroup",
  async (data: { access_token: string; groupId: string }, thunkApi) => {
    try {
      // Use axios.post() to send a POST request
      const response = await axios.post<IClassroom>(
        `${url}/leavegroup/`,
        { group_id: data.groupId }, // Sending group_id in the request body
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`, // Set Authorization header
          },
        }
      );

      return response.data; // Return the response data
    } catch (error: any) {
      // If there's an error, return the error message
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

interface ClassroomsState {
  leaveGroupLoading: boolean;
  leaveGroupErrors: {};
  leaveGroupPayload: IClassroom[];
}

const leaveGroupSlice = createSlice({
  name: "leaveGroup",
  initialState: {
    leaveGroupLoading: false,
    leaveGroupErrors: {},
    leaveGroupPayload: [] as IClassroom[],
  } as ClassroomsState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(leaveGroup.pending, (state, action) => {
        state.leaveGroupLoading = true;
      })
      .addCase(leaveGroup.fulfilled, (state, action: PayloadAction<any>) => {
        state.leaveGroupLoading = false;
        state.leaveGroupPayload = action.payload;
      })
      .addCase(leaveGroup.rejected, (state, action: PayloadAction<any>) => {
        state.leaveGroupErrors = action.payload;
      });
  },
});

export default leaveGroupSlice.reducer;
