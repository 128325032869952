import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  BodyOfText,
  BodyOfTextPaginated,
  VocabularyPaginated,
} from "../../types/linguisticsTypes";
import axios from "axios";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const listVocabularyPaginated = createAsyncThunk(
  "linguistics/listVocabularyPaginated",
  async (
    data: {
      access_token: string;
      page: number;
      language?: string; // added optional language filter
      level?: string[]; // added optional level filter
    },
    thunkApi
  ) => {
    try {
      const levelQuery = data.level ? `&level=${data.level.join(",")}` : ""; // Convert level array to comma-separated string
      const response = await axios.get<VocabularyPaginated>(
        `${url}/linguistics/vocabularywordspages/?page=${data.page}` +
          (data.language ? `&language=${data.language}` : "") +
          levelQuery, // Use the converted level string
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

interface listVocabularyPaginatedState {
  listVocabularyPaginatedLoading: boolean;
  listVocabularyPaginatedErrors: {};
  listVocabularyPaginatedPayload: VocabularyPaginated;
}

const listVocabularyPaginatedSlice = createSlice({
  name: "linguistics/listVocabularyPaginated",
  initialState: {
    listVocabularyPaginatedLoading: false,
    listVocabularyPaginatedErrors: {},
    listVocabularyPaginatedPayload: {
      count: 1,
      next: null,
      previous: null,
      results: [],
    },
  } as listVocabularyPaginatedState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listVocabularyPaginated.pending, (state, action) => {
        state.listVocabularyPaginatedLoading = true;
      })
      .addCase(
        listVocabularyPaginated.fulfilled,
        (state, action: PayloadAction<VocabularyPaginated>) => {
          state.listVocabularyPaginatedLoading = false;
          state.listVocabularyPaginatedPayload = action.payload;
        }
      )
      .addCase(
        listVocabularyPaginated.rejected,
        (state, action: PayloadAction<any>) => {
          state.listVocabularyPaginatedErrors = action.payload;
        }
      );
  },
});

export default listVocabularyPaginatedSlice.reducer;
