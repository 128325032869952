/*
TODO: profile_details_for_teacher experience has more than one, filter based on the language that is searched for
*/

import {
  Box,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Button,
  IconButton,
  Typography,
  CircularProgress,
  TextField,
  Select,
  ListItemIcon,
  Checkbox,
  Paper,
  Modal,
  Chip,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  forwardRef,
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { IClassroom } from "../../../../types/classroomTypes";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { postTeacherNomination } from "../../../../features/classrooms/postTeacherNominationSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../hooks/useTypedSelector";
import AuthContext from "../../../../context/AuthContext";
import { getLanguagesAndCountries } from "../../../../features/languages/getLanguagesSlice";
import { getTeachers } from "../../../../features/users/getTeachersSlice";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import TeacherInfo from "./TeacherInfo";
import SimpleBackdrop from "../../../Backdrops/SimpleBackdrop";
import useMatchMedia from "../../../../hooks/matchMedia";
import { borderColor } from "../../../theme/colors";
import { useParams } from "react-router";
import { getTeacherTypeLabel } from "./teacherTypeLabelGetter";
import { teacherTypeStyle } from "./teacherTypeStyleGetter";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const yearsOfExperience = [
  {
    value: "0",
    label: "any",
  },
  {
    value: "3",
    label: "3 years +",
  },
  {
    value: "5",
    label: "5 years +",
  },
  {
    value: "10",
    label: "10 years +",
  },
];

const preFilterTeachers = (
  getTeachersPayload: any,
  currentUser: any,
  nominatedTeachers: any
) => {
  // filter teachers locally, excluding all people who are subscribers of the classroom
  let filteredTeachers = getTeachersPayload;
  // remove current user
  filteredTeachers = filteredTeachers.filter(
    (teacher: any) => teacher.profile_details.user_uuid !== currentUser.uuid
  );
  // remove teachers who are already nominated
  // removed this becuase already nominated teachers should be shown in the list with a chip saying "invited"
  // filteredTeachers = filteredTeachers.filter((teacher: any) => !nominatedTeachers.includes(teacher.uuid));
  // This commented section is left out as per your comments:

  // remove bots
  filteredTeachers = filteredTeachers.filter(
    (teacher: any) => teacher.is_bot === false
  );
  return filteredTeachers;
};

export default function TeacherInvite() {
  const [snackbarErrorOpen, setSnackbarErrorOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const [inviteButtonHidden, setInviteButtonHidden] = useState(true);

  const handleSnackbarErrorClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setModalOpen(false);
    setSnackbarErrorOpen(false);
    setInviteButtonHidden(false);
  };

  const inviteButtonRef = useRef<HTMLButtonElement>(null);

  const dispatch = useAppDispatch();
  const isDesktopResolution = useMatchMedia("(min-width: 600px)", true);

  // states
  const { getLanguagesAndCountriesPayload, getLanguagesAndCountriesLoading } =
    useAppSelector((state) => state.getLanguagesAndCountries);
  const { getTeachersPayload, getTeachersLoading } = useAppSelector(
    (state) => state.getTeachers
  );
  const {
    postTeacherNominationPayload,
    postTeacherNominationLoading,
    postTeacherNominationErrors,
  } = useAppSelector((state) => state.postTeacherNomination);
  // context
  const { currentUser, isAuthenticated } = useContext(AuthContext);
  // local states
  const [teachers, setTeachers] = useState<any>([]);
  const [filteredTeachers, setFilteredTeachers] = useState<any>([]);
  const [nominatedTeachers, setNominatedTeachers] = useState<any>([]);
  const [selectedTeacher, setSelectedTeacher] = useState<any>(null);

  const [countries, setCountries] = useState<string[][]>([]);
  const [languages, setLanguages] = useState<string[][]>([]);
  const [searchCriteria, setSearchCriteria] = useState({
    username: "",
    country: "",
    years_experience: 0,
    country_living: "",
    country_from: "",
    is_available: true,
    only_language: true,
  });

  const { getClassroomLoading, getClassroomPayload } = useAppSelector(
    (state) => state.getClassroom
  );

  // Debug
  //console.log("nominatedTeachers", nominatedTeachers);
  //console.log("searchCriteria: ", searchCriteria);
  //console.log("teachers: ", teachers)
  //console.log("getTeachersPayload", getTeachersPayload);

  useEffect(() => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(getLanguagesAndCountries({ access_token: access_token }));
    if (getClassroomPayload.language_string)
      dispatch(
        getTeachers({
          access_token: access_token,
          language: getClassroomPayload.language_string,
          experience: searchCriteria.years_experience,
        })
      );
  }, []);

  useEffect(() => {
    const uniqueCountries: { [key: string]: string } = {};

    // Extract unique countries
    getLanguagesAndCountriesPayload.forEach((item) => {
      const code = item[0].split("-")[1];
      const match = item[1].match(/\((.*?)\)/);
      if (match && match[1] && !uniqueCountries[code]) {
        uniqueCountries[code] = match[1];
      }
    });

    // Convert the uniqueCountries object to the desired format
    const formattedCountries = Object.entries(uniqueCountries);
    //console.log("formattedCountries", formattedCountries);
    setCountries(formattedCountries);

    const uniqueLanguages: { [key: string]: string } = {};

    // Extract unique languages
    getLanguagesAndCountriesPayload.forEach((item) => {
      const code = item[0].split("-")[0];
      const languageName = item[1].split(" ")[0]; // Assuming the format is always 'Language (Country)'
      if (!uniqueLanguages[code]) {
        uniqueLanguages[code] = languageName;
      }
    });

    // Convert the uniqueLanguages object to the desired format
    const formattedLanguages = Object.entries(uniqueLanguages);

    setLanguages(formattedLanguages);
  }, [getLanguagesAndCountriesPayload]);

  interface INominatedTeacherMinimal {
    uuid: string;
    status: string;
  }

  useEffect(() => {
    let nominated_teachers: INominatedTeacherMinimal[] = [];
    if (getClassroomPayload) {
      getClassroomPayload.nominated_teachers_details.forEach((teacher: any) => {
        nominated_teachers.push({
          uuid: teacher.user_uuid,
          status: teacher.status,
        });
      });
      setNominatedTeachers(nominated_teachers);
    }
  }, [getClassroomPayload]);

  //debug
  //console.log("countries", countries);
  console.log("nominated teachers", nominatedTeachers);

  useEffect(() => {
    setTeachers(
      preFilterTeachers(getTeachersPayload, currentUser, nominatedTeachers)
    );
    setFilteredTeachers(
      preFilterTeachers(getTeachersPayload, currentUser, nominatedTeachers)
    );
  }, [getTeachersPayload]);

  useEffect(() => {
    // filter teachers by search criteria
    if (getTeachersPayload) {
      let filteredTeachers = preFilterTeachers(
        getTeachersPayload,
        currentUser,
        nominatedTeachers
      );
      //if (searchCriteria.is_available) {
      //    filteredTeachers = filteredTeachers.filter((teacher: any) => teacher.profile_details.is_available === searchCriteria.is_available);
      //}
      // remove current user
      if (searchCriteria.username) {
        filteredTeachers = filteredTeachers.filter((teacher: any) =>
          teacher.profile_details.username
            .toLowerCase()
            .includes(searchCriteria.username.toLowerCase())
        );
      }
      if (searchCriteria.years_experience !== 0) {
        filteredTeachers = filteredTeachers.filter(
          (teacher: any) =>
            teacher.profile_details.years_experience >=
              searchCriteria.years_experience &&
            teacher.profile_details.teaching_languages_simplified ===
              getClassroomPayload.language_string
        );
      }
      if (searchCriteria.country_living) {
        filteredTeachers = filteredTeachers.filter((teacher: any) =>
          teacher.profile_details.countries_living.includes(
            searchCriteria.country_living
          )
        );
      }
      if (searchCriteria.country_from) {
        filteredTeachers = filteredTeachers.filter((teacher: any) =>
          teacher.profile_details.countries_from.includes(
            searchCriteria.country_from
          )
        );
      }
      setFilteredTeachers(filteredTeachers);
    }
  }, [searchCriteria]);

  useEffect(() => {
    if (postTeacherNominationErrors) {
      setSnackbarErrorOpen(true);
    }
  }, [postTeacherNominationErrors]);

  const handleSubmit = () => {
    if (!isAuthenticated) return;
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    // dispatch nomination to server
    setInviteButtonHidden(true);
    setModalOpen(false);
    if (selectedTeacher.uuid && getClassroomPayload.uuid)
      dispatch(
        postTeacherNomination({
          access_token: access_token,
          classroom_uuid: getClassroomPayload.uuid,
          user_uuid: selectedTeacher.uuid,
        })
      );
    // append to nominated teachers
    setNominatedTeachers([
      ...nominatedTeachers,
      { uuid: selectedTeacher.uuid, status: "nominated" },
    ]);
  };

  const onClickTeacherHandler = (event: any) => {
    // get the teacher with matching uuid
    if (teachers) {
      const teacher = teachers.find(
        (teacher: any) => teacher.uuid === event.currentTarget.id
      );
      setSelectedTeacher(teacher);
      setModalOpen(true);
    }
  };

  return (
    <Fragment>
      <Box sx={{ mb: 3, mt: 3 }}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "auto",
            borderColor: borderColor.logo,
            width: "100%",
          }}
          variant="outlined"
        >
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  Only{" "}
                  {getClassroomPayload.language_string_long.replace(
                    / \(.*\)/,
                    ""
                  )}
                  <Checkbox
                    checked={searchCriteria.only_language}
                    onChange={(e) =>
                      setSearchCriteria({
                        ...searchCriteria,
                        only_language: e.target.checked,
                      })
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  Only available teachers
                  <Checkbox
                    checked={searchCriteria.is_available}
                    onChange={(e) =>
                      setSearchCriteria({
                        ...searchCriteria,
                        is_available: e.target.checked,
                      })
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="username"
                    placeholder="Username"
                    label="Filter by username"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    onChange={(e) =>
                      setSearchCriteria({
                        ...searchCriteria,
                        username: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Select
                    native
                    fullWidth
                    label="country_from"
                    defaultValue={countries[0] ? countries[0][1] : ""}
                    onChange={(e) =>
                      setSearchCriteria({
                        ...searchCriteria,
                        country_from: e.target.value,
                      })
                    }
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      height: 55,
                    }}
                  >
                    <option value="">Filter by country of origin</option>
                    {countries.map((country: string[], index: number) => (
                      <option value={country[0]} key={index}>
                        {country[1]}
                      </option>
                    ))}
                  </Select>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Select
                    native
                    fullWidth
                    label="country_living"
                    defaultValue={
                      getLanguagesAndCountriesPayload[0]
                        ? getLanguagesAndCountriesPayload[0][1]
                        : ""
                    }
                    onChange={(e) =>
                      setSearchCriteria({
                        ...searchCriteria,
                        country_living: e.target.value,
                      })
                    }
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      height: 55,
                    }}
                  >
                    <option value="">Filter by country of residence</option>
                    {countries.map((country: string[], index: number) => (
                      <option value={country[0]} key={index}>
                        {country[1]}
                      </option>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Select
                    native
                    fullWidth
                    label="years_experience"
                    defaultValue={yearsOfExperience[0]}
                    onChange={(e) =>
                      setSearchCriteria({
                        ...searchCriteria,
                        years_experience: +e.target.value,
                      })
                    }
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      height: 55,
                    }}
                  >
                    <option value="">Filter by years of experience</option>
                    {yearsOfExperience.map((year: any, index: number) => (
                      <option value={year.value} key={index}>
                        {year.label}
                      </option>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <List
                style={{
                  overflow: "auto",
                  minHeight: "500px",
                }}
                sx={{ border: 0 }}
              >
                {!filteredTeachers.length && (
                  <ListItem>
                    <ListItemText
                      disableTypography
                      primary={
                        <Typography variant="body1" style={{ color: "black" }}>
                          No teachers found
                        </Typography>
                      }
                    />
                  </ListItem>
                )}

                {filteredTeachers.map((teacher: any, index: number) => {
                  const isTeacherNominated = nominatedTeachers.some(
                    (nominatedTeacher: INominatedTeacherMinimal) =>
                      nominatedTeacher.uuid ===
                      teacher.profile_details.user_uuid
                  );

                  // Find the teacher in the nominatedTeachers list
                  const nominatedTeacher = nominatedTeachers.find(
                    (nominatedTeacher: INominatedTeacherMinimal) =>
                      nominatedTeacher.uuid ===
                      teacher.profile_details.user_uuid
                  );

                  // If found, extract their status
                  const teacherStatus = nominatedTeacher
                    ? nominatedTeacher.status
                    : undefined;

                  return (
                    <ListItem
                      button
                      divider
                      disabled={isTeacherNominated}
                      key={index}
                      id={teacher.uuid}
                      onClick={onClickTeacherHandler}
                      selected={
                        selectedTeacher && teacher
                          ? selectedTeacher.uuid === teacher.uuid
                          : false
                      }
                    >
                      <ListItemIcon>
                        <Avatar
                          alt={teacher.profile_details.username}
                          src={
                            teacher.profile_details.profile_image
                              ? teacher.profile_details.profile_image
                              : ""
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={
                          <Typography
                            variant="body1"
                            style={{ color: "black" }}
                          >
                            {teacher.profile_details.username}
                          </Typography>
                        }
                        secondary={
                          isTeacherNominated ? (
                            <Chip
                              label={getTeacherTypeLabel(teacherStatus)}
                              style={{
                                backgroundColor:
                                  teacherTypeStyle(teacherStatus),
                              }}
                            />
                          ) : (
                            <></>
                          )
                        }
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
          <Modal
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <>
              <Box sx={style}>
                <IconButton
                  sx={{ position: "fixed", top: 0, right: 0, zIndex: 2000 }}
                  onClick={handleModalClose}
                >
                  <CloseIcon />
                </IconButton>
                {!selectedTeacher ? (
                  <CircularProgress />
                ) : (
                  <>
                    <TeacherInfo
                      userProfile={selectedTeacher.profile_details}
                      loading={getTeachersLoading}
                      title="Teacher Info"
                    />
                    <br />
                    <Button
                      fullWidth
                      ref={inviteButtonRef}
                      variant="contained"
                      onClick={handleSubmit}
                      hidden={inviteButtonHidden}
                    >
                      Nominate
                    </Button>
                  </>
                )}
              </Box>
            </>
          </Modal>
          {postTeacherNominationLoading ? <SimpleBackdrop /> : null}
        </Paper>
      </Box>
      <Snackbar
        open={snackbarErrorOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarErrorClose}
      >
        <Alert
          onClose={handleSnackbarErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error inviting teacher!
        </Alert>
      </Snackbar>
    </Fragment>
  );
}
