import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { LANGUAGES, LEVELS } from "../../../../constants/misc";
import {
  Autocomplete,
  Box,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ICreateClassroom } from "../../../../types/classroomTypes";
import { MuiChipsInput } from "mui-chips-input";
import { useState, useEffect, useContext } from "react";

const software = [
  ["jitsi", "Jitsi"],
  ["meet", "Google Meet"],
  ["zoom", "Zoom"],
  ["teams", "Microsoft Teams"],
  ["skype", "Skype"],
  ["inperson", "In Person"],
  ["other", "Other"],
];

interface AppProps {
  classroom: ICreateClassroom;
  setClassroom: React.Dispatch<React.SetStateAction<ICreateClassroom>>;
  getLanguagesAndCountriesLoading: boolean;
  getLanguagesAndCountriesPayload: string[][];
}

const General = ({
  classroom,
  setClassroom,
  getLanguagesAndCountriesLoading,
  getLanguagesAndCountriesPayload,
}: AppProps) => {
  const helperTexts = {
    title: "Choose a catchy title for your group.",
    description: "Write a short description of your group.",
    tags: "Add tags to help people find your group.",
    software: "Choose the software you want to use for your group.",
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            error={classroom.title.length < 3 || classroom.title.length > 50}
            required
            id="title"
            name="title"
            label="Title"
            fullWidth
            autoComplete="title"
            variant="outlined"
            helperText={helperTexts.title}
            value={classroom.title}
            onChange={(e) =>
              setClassroom({
                ...classroom,
                title: e.target.value,
                calendar_events: [
                  {
                    ...classroom.calendar_events[0],
                    title: e.target.value,
                  },
                ],
              })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={
              classroom.description.length < 3 ||
              classroom.description.length > 500
            }
            required
            id="description"
            name="description"
            label="Description"
            fullWidth
            autoComplete="description"
            variant="outlined"
            helperText={
              classroom.description.length < 3 ||
              classroom.description.length > 500
                ? helperTexts.description
                : `${classroom.description.length}/500`
            }
            multiline
            rows={4}
            value={classroom.description}
            onChange={(e) =>
              setClassroom({ ...classroom, description: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Select
            labelId={"software"}
            id={"software"}
            label="Software"
            value={classroom.software}
            onChange={(e) =>
              setClassroom({ ...classroom, software: e.target.value })
            }
            fullWidth
            sx={{
              "& legend": { display: "none" },
              "& fieldset": { top: 0 },
              height: 55,
            }}
            name={"software"}
          >
            {software.map((l, i) => {
              return (
                <MenuItem key={i} value={l[0]}>
                  <ListItemText primary={l[1]} />
                </MenuItem>
              );
            })}
          </Select>
          <FormHelperText>
            <FormHelperText>{helperTexts.software}</FormHelperText>
          </FormHelperText>
        </Grid>
        <Grid item xs={12} sm={12}>
          <MuiChipsInput
            value={classroom.tags}
            onChange={(e) => setClassroom({ ...classroom, tags: e })}
            label="Tags"
            variant="outlined"
            helperText={helperTexts.tags}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default General;
