import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Fragment } from "react";
import {
  Box,
  Button,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { EXPERIENCE } from "../../../constants/misc";

interface AppProps {
  profile: any;
  errors: any;
  languageList: any;
  state: any;
  setState: any;
  setSaveButtonDisabled: any;
}

const Teacher = ({
  profile,
  errors,
  languageList,
  state,
  setState,
  setSaveButtonDisabled,
}: AppProps) => {
  const handleChangeTeachingLanguage = (event: SelectChangeEvent) => {
    // get event target id
    const name = event.target.name;
    // get event target value
    const value = event.target.value;
    // get index of experience
    const index = parseInt(name.split("-")[1]);
    // get new language list
    const new_teaching_languages = [
      ...state.teacher_profile.teaching_languages,
    ];
    // set new language
    new_teaching_languages[index] = value;
    // set new language list
    setState({
      ...state,
      teacher_profile: {
        ...state.teacher_profile,
        teaching_languages: new_teaching_languages,
      },
    });
    setSaveButtonDisabled(false);
  };

  const handleChangeTeachingExperience = (event: SelectChangeEvent) => {
    // get event target id
    const name = event.target.name;
    // get event target value
    const value = event.target.value;
    // get index of language
    const index = parseInt(name.split("-")[1]);
    // get new language list
    const new_teaching_languages_experience = [
      ...state.teacher_profile.teaching_languages_experience,
    ];
    // set new language
    new_teaching_languages_experience[index] = value;
    // set new language list
    setState({
      ...state,
      teacher_profile: {
        ...state.teacher_profile,
        teaching_languages_experience: new_teaching_languages_experience,
      },
    });
    setSaveButtonDisabled(false);
  };

  const addTeachingLanguage = () => {
    // add new language
    const new_teaching_languages = [
      ...state.teacher_profile.teaching_languages,
    ];
    const new_teaching_languages_experience = [
      ...state.teacher_profile.teaching_languages_experience,
    ];
    new_teaching_languages.push(languageList[0][0]); // en?
    new_teaching_languages_experience.push(EXPERIENCE[0].value); // A1
    setState({
      ...state,
      teacher_profile: {
        ...state.teacher_profile,
        teaching_languages: new_teaching_languages,
        teaching_languages_experience: new_teaching_languages_experience,
      },
    });
    setSaveButtonDisabled(false);
  };

  const removeTeachingLanguage = (index: number) => {
    // dont do anything if only one language is left
    if (state.teacher_profile.teaching_languages.length === 1) return;
    const new_teaching_languages = [
      ...state.teacher_profile.teaching_languages,
    ];
    const new_teaching_languages_experience = [
      ...state.teacher_profile.teaching_languages_experience,
    ];
    new_teaching_languages.splice(index, 1);
    new_teaching_languages_experience.splice(index, 1);
    setState({
      ...state,
      teacher_profile: {
        ...state.teacher_profile,
        teaching_languages: new_teaching_languages,
        teaching_languages_experience: new_teaching_languages_experience,
      },
    });
    setSaveButtonDisabled(false);
  };

  return (
    <Fragment>
      <Box sx={{ mb: 5 }}>Personal Information</Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            error={errors.first_name ? true : false}
            id="first_name"
            placeholder="Enter first name"
            label="First name"
            variant="outlined"
            helperText={
              errors.teacher_profile.first_name
                ? errors.teacher_profile.first_name
                : ""
            }
            fullWidth
            required
            defaultValue={profile ? profile.teacher_profile.first_name : ""}
            onChange={(e) =>
              setState({
                ...state,
                teacher_profile: {
                  ...state.teacher_profile,
                  first_name: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="last_name"
            type="last_name"
            placeholder="Enter Last name"
            label="Last name"
            variant="outlined"
            fullWidth
            required
            defaultValue={
              profile.teacher_profile.last_name
                ? profile.teacher_profile.last_name
                : ""
            }
            onChange={(e) =>
              setState({
                ...state,
                teacher_profile: {
                  ...state.teacher_profile,
                  last_name: e.target.value,
                },
              })
            }
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            error={errors.about ? true : false}
            id="about"
            label="About"
            multiline
            rows={4}
            placeholder="Write something about you as a teacher"
            variant="outlined"
            helperText={
              errors.teacher_profile.about ? errors.teacher_profile.about : ""
            }
            fullWidth
            defaultValue={
              profile.teacher_profile.about ? profile.teacher_profile.about : ""
            }
            onChange={(e) =>
              setState({
                ...state,
                teacher_profile: {
                  ...state.teacher_profile,
                  about: e.target.value,
                },
              })
            }
          />
        </Grid>
        {/* Start Teaching Languages */}
        <Grid item xs={12}>
          <Box sx={{ mb: 0 }}>The language(s) you are teaching</Box>
        </Grid>
        {state.teacher_profile.teaching_languages.map(
          (language: string, k: number) => {
            return (
              <Fragment key={k}>
                <Grid item xs={6}>
                  <Select
                    labelId={"language-teaching-" + k.toString()}
                    id={"language-teaching-" + k.toString()}
                    label="Language"
                    value={language}
                    onChange={handleChangeTeachingLanguage}
                    fullWidth
                    name={"language-" + k.toString()}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      height: 55,
                    }}
                  >
                    {languageList.map((l: string, i: number) => {
                      if (
                        !state.teacher_profile.teaching_languages.includes(
                          l[0]
                        ) ||
                        l[0] === language
                      )
                        return (
                          <MenuItem key={i} value={l[0]}>
                            <ListItemText primary={l[1]} />
                          </MenuItem>
                        );
                    })}
                  </Select>
                </Grid>
                <Grid item xs={4}>
                  <Select
                    labelId={"language-teaching-experience-" + k.toString()}
                    id={"level-teaching-experience-" + k.toString()}
                    label="Level"
                    value={
                      state.teacher_profile.teaching_languages_experience[k]
                    }
                    onChange={handleChangeTeachingExperience}
                    fullWidth
                    name={"level-" + k.toString()}
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                      height: 55,
                    }}
                  >
                    {/*
                  Here the language levels are entered
                  */}
                    {EXPERIENCE.map((experience, j) => (
                      <MenuItem key={experience.value} value={experience.value}>
                        <ListItemText primary={experience.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={1}>
                  <Button
                    id={"language-button-" + k.toString()}
                    sx={{ height: 55 }}
                    color="warning"
                    variant="outlined"
                    component="label"
                    fullWidth
                    onClick={() => removeTeachingLanguage(k)}
                  >
                    -
                  </Button>
                </Grid>
              </Fragment>
            );
          }
        )}
        <Grid item xs={12}>
          <Button
            variant="outlined"
            component="label"
            fullWidth
            onClick={() => addTeachingLanguage()}
          >
            +
          </Button>
        </Grid>
        {/* End Learning Languages */}
      </Grid>
    </Fragment>
  );
};

export default Teacher;
