import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
} from "@mui/material";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch, useAppSelector } from "../../hooks/useTypedSelector";
import { putAdjustTimeZone } from "../../features/users/putTimeZoneSlice";

interface props {
  timezone: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function TeacherNomination({ timezone }: props) {
  const [open, setOpen] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);

  const dispatch = useAppDispatch();

  const {
    putAdjustTimeZoneError,
    putAdjustTimeZoneLoading,
    putAdjustTimeZonePayload,
  } = useAppSelector((state) => state.putAdjustTimeZone);

  const adjustTimeZone = () => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      putAdjustTimeZone({
        access_token: access_token,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    );
  };

  useEffect(() => {
    if (timezone !== Intl.DateTimeFormat().resolvedOptions().timeZone) {
      setModalOpen(true);
    }
  }, []);

  useEffect(() => {
    if (putAdjustTimeZonePayload) {
      setButtonDisabled(true);
    }
  }, [putAdjustTimeZonePayload]);

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Box sx={style}>
          <IconButton
            sx={{ position: "fixed", top: 0, right: 0, zIndex: 2000 }}
            onClick={handleModalClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Timezone
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            The timezone set in your profile is different from your current
            timezone ({Intl.DateTimeFormat().resolvedOptions().timeZone}). Do
            you want to change it?
          </Typography>
          {putAdjustTimeZoneLoading ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              sx={{ mt: 2 }}
              onClick={() => {
                adjustTimeZone();
              }}
              disabled={buttonDisabled}
            >
              Yes, adjust my timezone
            </Button>
          )}
          {putAdjustTimeZoneError && (
            <Typography sx={{ mt: 2 }} color="error">
              {putAdjustTimeZoneError}
            </Typography>
          )}
          {putAdjustTimeZonePayload && (
            <Typography sx={{ mt: 2 }} color="green">
              Timezone adjusted successfully
            </Typography>
          )}
        </Box>
      </>
    </Modal>
  );
}
