import {
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
} from "@mui/material";
import { FC } from "react";
import { CircleFlag } from "react-circle-flags";
import { borderColor } from "../../theme/colors";

import { Link as RouterLink } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MessageIcon from "@mui/icons-material/Message";
import BlockIcon from "@mui/icons-material/Block";

interface UserProfileInfoProps {
  userPayload: any; // Ideally, replace any with a proper type definition.
}

const UserProfileInfo: FC<UserProfileInfoProps> = ({ userPayload }) => {
  return (
    <Card variant="outlined" sx={{ borderColor: borderColor.logo }}>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {userPayload.name}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography color="disabled" sx={{ mb: 1 }}>
              <b>LEARNING</b>
            </Typography>
            {userPayload.profile_details.learning_languages.map(
              (language: any, index: number) => (
                <Box
                  key={"learning-" + index}
                  sx={{ display: "flex", alignItems: "center", mb: 1 }}
                >
                  <CircleFlag
                    countryCode={language.split("-")[1].toLowerCase()}
                    style={{
                      width: "1.5em",
                      height: "1.5em",
                      marginRight: "0.5em",
                    }}
                  />
                  <Typography variant="subtitle2">{language}</Typography>
                </Box>
              )
            )}
          </Grid>

          <Grid item xs={6}>
            <Typography color="disabled" sx={{ mb: 1 }}>
              <b>SPEAKING</b>
            </Typography>
            {userPayload.profile_details.native_languages.map(
              (language: any, index: number) => (
                <Box
                  key={"native-" + index}
                  sx={{ display: "flex", alignItems: "center", mb: 1 }}
                >
                  <CircleFlag
                    countryCode={language.split("-")[1].toLowerCase()}
                    style={{
                      width: "1.5em",
                      height: "1.5em",
                      marginRight: "0.5em",
                    }}
                  />
                  <Typography variant="subtitle2">{language}</Typography>
                </Box>
              )
            )}
          </Grid>
        </Grid>

        <Box mt={2}>
          <Typography variant="subtitle1" gutterBottom>
            <b>ABOUT ME</b>
          </Typography>
          <Typography variant="subtitle2">
            {userPayload.profile_details.about}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="start" sx={{ mt: 4 }}>
          <IconButton
            component={RouterLink}
            to={`/user/chat/${userPayload.uuid}`}
            size="small"
          >
            <div
              style={{
                borderRadius: "50%",
                border: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "40px",
                height: "40px",
              }}
            >
              <MessageIcon style={{ fontSize: "15px" }} />
            </div>
          </IconButton>
          <IconButton href={"/user/" + userPayload.uuid}>
            <div
              style={{
                borderRadius: "50%",
                border: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "40px",
                height: "40px",
              }}
            >
              <VisibilityIcon style={{ fontSize: "15px" }} />
            </div>
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default UserProfileInfo;
