import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CssBaseline, Container, Paper } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from "../../hooks/useTypedSelector";
import { getUserProfile } from "../../features/users/getUserProfileSlice";
import { getLanguagesAndCountries } from "../../features/languages/getLanguagesSlice";
import AuthContext from "../../context/AuthContext";
import theme from "../theme/theme";
import AppBar from "../Header/AppBar";
import CasualGroup from "./CasualGroup/CasualGroup";
import PaidGroup from "./PaidGroup/PaidGroup";
import Copyright from "../Copyright/Copyright";

export default function GroupCreate() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { getUserProfileLoading, getUserProfilePayload } = useAppSelector(
    (state) => state.getUserProfile
  );
  const dispatch = useAppDispatch();
  const { currentUser } = React.useContext(AuthContext);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar currentUser={currentUser} userProfile={getUserProfilePayload} />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="Type of Group"
              >
                <Tab label="Create Casual Group" />
                <Tab label="Create Paid Group" />
              </Tabs>
              <Box mt={3}>{value === 0 ? <CasualGroup /> : <PaidGroup />}</Box>
            </Paper>
          </Container>
        </Box>
      </ThemeProvider>
      <Copyright sx={{ pt: 4 }} />
    </>
  );
}
