
import { useContext, useState } from 'react';
import Avatar from '@mui/material/Avatar';

import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from '../Copyright/Copyright';
import { Link } from 'react-router-dom';

const theme = createTheme();

export default function SignIn() {

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Email Sent to your email address
                    </Typography>
                    <Typography component="h1" variant="h5">
                        Please check your email and click on the link to authenticate your account
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                        <Typography component="h1" variant="h5">
                            <Link to="/signin/">
                                {"Sign in now"}
                            </Link>
                        </Typography>
                    </Box>
                    <Copyright sx={{ mt: 8, mb: 4 }} />
                </Box>
            </Container>
        </ThemeProvider>
    );
}