import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IUserProfilePlusEmailPlusTeacher } from "../../types/userProfileTypes";
import { initialUserProfileState } from "../../initalStates/initialUserState";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getUserProfile = createAsyncThunk(
  "userProfile/getUserProfile",
  async (
    data: { access_token: string; subscriberProfileId: string },
    thunkApi
  ) => {
    try {
      const response = await axios.get<IUserProfilePlusEmailPlusTeacher>(
        `${url}/profile/${data.subscriberProfileId}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const getUserProfileSlice = createSlice({
  name: "getUserProfile",
  initialState: {
    getUserProfileLoading: false,
    getUserProfileError: null,
    getUserProfilePayload:
      initialUserProfileState as IUserProfilePlusEmailPlusTeacher,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUserProfile.pending, (state) => {
        state.getUserProfileLoading = true;
      })
      .addCase(
        getUserProfile.fulfilled,
        (state, action: PayloadAction<IUserProfilePlusEmailPlusTeacher>) => {
          state.getUserProfileLoading = false;
          state.getUserProfilePayload = action.payload;
        }
      )
      .addCase(getUserProfile.rejected, (state, action: any) => {
        state.getUserProfileLoading = false;
        state.getUserProfileError = action.payload;
      });
  },
});

export default getUserProfileSlice.reducer;
