import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  Switch,
  Tooltip,
} from "@mui/material";
import { Fragment, SetStateAction, useEffect, useState } from "react";
import { ICreateClassroom, PaymentTypeEnum } from "../../types/classroomTypes";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";

const numberChoices = [
  ["1 time", 1],
  ["2 times", 2],
  ["3 times", 3],
  ["4 times", 4],
  ["5 times", 5],
  ["6 times", 6],
  ["7 times", 7],
  ["8 times", 8],
  ["9 times", 9],
  ["10 times", 10],
];

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(180,90,180,1)",
    boxSizing: "border-box",
  },
}));

interface AppProps {
  classrooms: ICreateClassroom;
  setClassrooms: React.Dispatch<SetStateAction<ICreateClassroom>>;
}

const PerStudent = ({ classrooms, setClassrooms }: AppProps) => {
  const [prices, setPrices] = useState(classrooms.prices);
  const [numbers, setNumbers] = useState(classrooms.n);

  useEffect(() => {
    setClassrooms({ ...classrooms, prices: prices, n: numbers });
  }, [prices, numbers]);

  const handleChangeNumbers = (event: SelectChangeEvent) => {
    // get event target id
    const name = event.target.name;
    // get event target value
    const value = event.target.value;
    console.log(name);
    console.log(value);
    // get index of language
    const index = parseInt(name.split("-")[1]);
    // get new language list
    const newNumbers = [...classrooms.n];
    // set new language
    newNumbers[index] = +value;
    // set new language list
    setPrices(newNumbers);
  };

  const handleChangePrice = (event: SelectChangeEvent) => {
    // get event target id
    const name = event.target.name;
    // get event target value
    const value = event.target.value;
    console.log(name);
    console.log(value);
    // get index of level
    const index = parseInt(name.split("-")[1]);
    // get new level list
    const newPrices = [...classrooms.prices];
    // set new level
    newPrices[index] = +value;
    // set new level list
    setPrices(newPrices);
  };

  const handleChangePaymentType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setClassrooms({
      ...classrooms,
      paymentType: checked
        ? PaymentTypeEnum.PERSTUDENT
        : PaymentTypeEnum.PERCLASS,
    });
  };

  const addPriceAndNumber = () => {
    // add new language
    setPrices([...classrooms.prices, 0]);
    setNumbers([...classrooms.n, 1]);
  };

  const removePriceAndNumber = (index: number) => {
    // dont do anything if only one language is left
    if (classrooms.n.length === 1) return;
    // get new language list
    const newPrices = [...classrooms.prices];
    const newNumbers = [...classrooms.n];
    // remove language
    newPrices.splice(index, 1);
    newNumbers.splice(index, 1);
    setPrices(newPrices);
    setNumbers(newNumbers);
  };

  return (
    <>
      {classrooms.n.map((number, k) => {
        return (
          <Fragment key={k}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={3}>
                <Select
                  labelId={"number-" + k.toString()}
                  id={"number-" + k.toString()}
                  label="Number"
                  value={number.toString()}
                  onChange={handleChangeNumbers}
                  fullWidth
                  name={"number-" + k.toString()}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    height: 55,
                  }}
                >
                  {numberChoices.map((n, i) => {
                    if (!classrooms.n.includes(+n[1]) || n[1] === number)
                      return (
                        <MenuItem key={i} value={n[1]}>
                          <ListItemText primary={n[0]} />
                        </MenuItem>
                      );
                  })}
                </Select>
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  height: 55,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <small>each time:</small>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id={"price-" + k.toString()}
                  label="Price (USD)"
                  type="number"
                  sx={{ height: 55 }}
                  defaultValue={prices[k]}
                  name={"price-" + k.toString()}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  height: 55,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <small>per student</small>
              </Grid>
              <Grid item xs={1}>
                <Tooltip title="Delete">
                  <IconButton onClick={() => removePriceAndNumber(k)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Fragment>
        );
      })}

      <Grid item xs={12}>
        <Button
          variant="outlined"
          component="label"
          fullWidth
          onClick={() => addPriceAndNumber()}
        >
          +
        </Button>
      </Grid>
    </>
  );
};

export default PerStudent;
