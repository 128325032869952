import { createTheme } from "@mui/material";

// Define custom colors
const customColors = {
  link: "#ffffff",
  icon: "#ffffff",
};

let theme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#F4F4F4", //"#eceaff",
      paper: "#ffffff",
    },
    primary: {
      main: "#2E3B55",
      light: "#ffffff",
    },
    secondary: {
      main: "#edf2ff",
      light: "#edf2ff",
    },
    ...customColors,
  },
  typography: {
    fontFamily: '"Lato", sans-serif', // Update with your desired font family
  },
});

export default theme;
