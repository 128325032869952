import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../types/userTypes";
import { IUserProfile } from "../../types/userProfileTypes";
import { IErrors, IRegister } from "../../types/MessageTypes";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const registerUser = createAsyncThunk(
  "user/registerUser",
  async (arg: IRegister, thunkApi) => {
    const body = JSON.stringify(arg);
    try {
      const res = await fetch(`${url}/user/register/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body,
      });
      const data = await res.json();
      if (res.status === 201) {
        return data;
      } else {
        return thunkApi.rejectWithValue(data);
      }
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const registerUserSlice = createSlice({
  name: "registerUser",
  initialState: {
    getUsersLoading: false,
    isRegistered: false,
    getUsersError: {} as IErrors,
    getUsersPayload: [
      {
        username: "",
        email: "",
        role: "",
        profile: {
          username: "",
          date_of_birth: "",
          about: "",
          native_languages: [],
          learning_languages: [],
          learning_languages_levels: [],
          countries_from: [],
          countries_living: [],
          timezone: "",
          images: [],
          is_online: false,
          age: "",
          profile_image: "",
          all_image_urls: [],
          is_teacher: false,
          is_bot: false,
          user_uuid: "",
          voted_by: "",
        } as IUserProfile,
        _id: "",
        id: 0,
        created_at: "",
        updated_at: "",
        __v: 0,
      } as IUser,
    ],
  },
  reducers: {
    resetRegisteredUser(state) {
      state.isRegistered = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(registerUser.pending, (state, action) => {
        state.getUsersLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.getUsersPayload = action.payload;
        state.getUsersLoading = false;
        state.isRegistered = true;
      })
      .addCase(registerUser.rejected, (state, action: PayloadAction<any>) => {
        state.getUsersLoading = false;
        state.getUsersError = action.payload;
      });
  },
});

export default registerUserSlice.reducer;
