import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Fragment } from "react";
import { LEVELS } from "../../../constants/misc";

interface AppProps {
  profile: any;
  errors: [];
  languageList: string[][];
  state: any;
  setState: any;
  setSaveButtonDisabled: any;
}

const ProfileUpdateFormLanguage = ({
  languageList,
  state,
  setState,
  errors,
  setSaveButtonDisabled,
}: AppProps) => {
  const handleChangeLearningLanguage = (event: SelectChangeEvent) => {
    const name = event.target.name;
    const value = event.target.value;
    const index = parseInt(name.split("-")[1]);
    const new_learning_languages = [...state.learning_languages];
    new_learning_languages[index] = value;
    setState({
      ...state,
      learning_languages: new_learning_languages,
    });
    setSaveButtonDisabled(false);
  };

  const handleChangeLearningLevel = (event: SelectChangeEvent) => {
    const name = event.target.name;
    const value = event.target.value;
    const index = parseInt(name.split("-")[1]);
    const new_learning_levels = [...state.learning_levels];
    new_learning_levels[index] = value;
    setState({
      ...state,
      learning_levels: new_learning_levels,
    });
    setSaveButtonDisabled(false);
  };

  const addLearningLanguage = () => {
    // add new language
    const new_earning_languages = [...state.learning_languages];
    const new_learning_levels = [...state.learning_levels];
    new_earning_languages.push(languageList[0][0]); // en?
    new_learning_levels.push(LEVELS[0].value); // A1
    setState({
      ...state,
      learning_languages: new_earning_languages,
      learning_levels: new_learning_levels,
    });
    setSaveButtonDisabled(false);
  };

  const removeLearningLanguage = (index: number) => {
    // dont do anything if only one language is left
    if (state.learning_languages.length === 1) return;
    const new_learning_languages = [...state.learning_languages];
    const new_learning_levels = [...state.learning_levels];
    new_learning_languages.splice(index, 1);
    new_learning_levels.splice(index, 1);
    setState({
      ...state,
      learning_languages: new_learning_languages,
      learning_levels: new_learning_levels,
    });
    setSaveButtonDisabled(false);
  };

  const handleLanguageChange = () => {
    setSaveButtonDisabled(false);
  };

  return (
    <>
      <Box sx={{ mb: 3 }}>Native language(s)</Box>
      <Grid container spacing={3}>
        {/* Start Native Languages */}
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="native"
            onChange={(event, newValue) => {
              setState({
                ...state,
                native_languages: newValue.map((l) => l[0]),
              });
              handleLanguageChange();
            }}
            options={languageList}
            getOptionLabel={(option) => (option ? option[1] : "")} // 0: code, 1: display_name
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={option ? option[0] : ""}
                  {...getTagProps({ index })}
                />
              ))
            }
            fullWidth
            // take the values from nativeLanguages and find display name in languageList
            value={state.native_languages.map((l: any) =>
              languageList.find((ll) => ll[0] === l)
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Native language(s)"
                placeholder="Choose your native language(s)"
              />
            )}
          />
          <Box sx={{ mb: 3 }} />
        </Grid>
        {/* End Native Languages */}
        {/* Start Learning Languages */}
        <Grid item xs={12}>
          <Box sx={{ mb: 0 }}>The language(s) you are learning</Box>
        </Grid>
        {state.learning_languages.map((language: string, k: number) => {
          return (
            <Fragment key={k}>
              <Grid item xs={5}>
                <Select
                  labelId={"language-learning-" + k.toString()}
                  id={"language-learning-" + k.toString()}
                  label="Language"
                  value={language}
                  onChange={handleChangeLearningLanguage}
                  fullWidth
                  name={"language-" + k.toString()}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    height: 50, // Reduced height
                    fontSize: "0.3rem", // Smaller font for mobile screens
                  }}
                >
                  {languageList.map((l, i) => {
                    if (
                      !state.learning_languages.includes(l[0]) ||
                      l[0] === language
                    )
                      return (
                        <MenuItem key={l[0]} value={l[0]}>
                          <ListItemText primary={l[1]} />
                        </MenuItem>
                      );
                  })}
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Select
                  labelId={"language-learning-level-" + k.toString()}
                  id={"level-learning-level-" + k.toString()}
                  label="Level"
                  value={state.learning_levels[k]}
                  onChange={handleChangeLearningLevel}
                  fullWidth
                  name={"level-" + k.toString()}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                    height: 50, // Reduced height
                    fontSize: "0.3rem", // Smaller font for mobile screens
                  }}
                >
                  {/*
                  Here the language levels are entered
                  */}
                  {LEVELS.map((level, j) => (
                    <MenuItem key={level.value} value={level.value}>
                      <ListItemText primary={level.label} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={1}>
                <Button
                  id={"language-button-" + k.toString()}
                  sx={{ height: 50, width: "100%" }} // Adjusted size
                  color="warning"
                  variant="outlined"
                  component="label"
                  fullWidth
                  onClick={() => removeLearningLanguage(k)}
                >
                  -
                </Button>
              </Grid>
            </Fragment>
          );
        })}
        <Grid item xs={12}>
          <Button
            variant="outlined"
            component="label"
            fullWidth
            onClick={() => addLearningLanguage()}
          >
            +
          </Button>
        </Grid>
        {/* End Learning Languages */}
      </Grid>
    </>
  );
};

export default ProfileUpdateFormLanguage;
