import {
  Box,
  Avatar,
  CardHeader,
  Typography,
  CircularProgress,
  Card,
  CardActionArea,
  IconButton,
  Modal,
  Chip,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ClearIcon from "@mui/icons-material/Clear"; // Reject icon
import { borderColor } from "../../../theme/colors";
import { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IUserPending } from "../../../../types/userTypes";
import { initialNominationState } from "../../../../initalStates/initialNominationState";
import { INominationSimplified } from "../../../../types/userProfileTypes";
import { Tooltip } from "@mui/material";
function getStatusColors(status: string) {
  switch (status) {
    case "nominated":
      return borderColor.pastel_blond;
    case "accepted":
      return borderColor.pastel_crystal;
    case "rejected":
      return borderColor.pastel_pink;
    default:
      return borderColor.logo;
  }
}

function getStatusDescription(status: string) {
  switch (status) {
    case "nominated":
      return "You have been nominated to teach this class.";
    case "accepted":
      return "You have accepted the nomination to teach this class. Now it's up to the students to pick a suitable teacher. You will be informed as soon as a decision is made.";
    case "rejected":
      return "You have rejected the nomination to teach this class.";
    default:
      return "";
  }
}

interface AppProps {
  nominations: INominationSimplified[];
  loading: boolean;
  title: string;
  onAccept?: (userId: string) => void;
  onReject?: (userId: string) => void; // Added the onReject prop
}
export default function PendingRequests({
  nominations,
  loading,
  title,
  onAccept,
  onReject,
}: AppProps) {
  const [open, setOpen] = useState(false);
  const [currentNomination, setCurrentNomination] =
    useState<INominationSimplified>(initialNominationState);

  const handleOpen = (user: any) => {
    setCurrentNomination(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentNomination(initialNominationState);
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography fontSize="0.7rem" fontWeight="bold" component="div">
          INVITATION TO TEACH
        </Typography>
        <Typography fontSize="0.9rem" component="div">
          You've been invited to be a teacher for the following classrooms.
          Check out the details and decide if you'd like to accept or decline
          the invitation.
        </Typography>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography fontSize="0.7rem" fontWeight="bold" component="div">
          {title.toUpperCase()}
        </Typography>
      </Box>
      <Box sx={{ mb: 2 }}>
        {nominations.map((nomination: any, index: number) => (
          <Card
            key={index}
            variant="outlined"
            style={{
              marginBottom: "10px",
              borderColor: getStatusColors(nomination.status),
            }}
          >
            <Typography fontSize="0.9rem" component="div" sx={{ padding: 2 }}>
              {nomination.classroom_title}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingRight: 2,
                marginBottom: 2,
              }}
            >
              <IconButton onClick={() => handleOpen(nomination)}>
                <InfoIcon />
              </IconButton>

              {nomination.status === "nominated" ? (
                <>
                  {onReject && (
                    <IconButton
                      onClick={() => onReject(nomination.classroom_uuid)}
                      aria-label="reject"
                      style={{ color: "red" }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  )}
                  {onAccept && (
                    <IconButton
                      onClick={() => onAccept(nomination.classroom_uuid)}
                      aria-label="accept"
                      style={{ color: "green" }}
                    >
                      <CheckCircleOutlineIcon />
                    </IconButton>
                  )}
                </>
              ) : (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                >
                  <Chip
                    label={nomination.status}
                    style={{
                      backgroundColor: getStatusColors(nomination.status),
                      color: "black",
                    }}
                  />
                  <Tooltip
                    title={getStatusDescription(nomination.status)}
                    arrow
                  >
                    <InfoOutlinedIcon
                      fontSize="inherit"
                      style={{ transform: "scale(0.8)" }}
                    />
                  </Tooltip>
                </Typography>
              )}
            </Box>
          </Card>
        ))}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="nomination-info-title"
          aria-describedby="nomination-info-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="nomination-info-title" variant="h6">
              {currentNomination.classroom_title}
            </Typography>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
