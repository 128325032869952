import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IProfileImages } from "../../types/userProfileImageTypes";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const putUserProfileImageList = createAsyncThunk(
  "userProfile/putUserProfileImageList",
  async (data: { access_token: string; profile_images: any }, thunkApi) => {
    try {
      const response = await fetch(`${url}/userProfileImageList/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        body: JSON.stringify(data.profile_images),
      });
      // await and if not ok return error
      const json = await response.json();
      if (!response.ok) {
        return thunkApi.rejectWithValue(json);
      }
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const putUserProfileImageListSlice = createSlice({
  name: "putUserProfileImageList",
  initialState: {
    putUserProfileImageListLoading: false,
    putUserProfileImageListError: null,
    putUserProfileImageListPayload: {
      images: [],
      profile_image: "",
    } as IProfileImages,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(putUserProfileImageList.pending, (state) => {
        state.putUserProfileImageListLoading = true;
      })
      .addCase(putUserProfileImageList.fulfilled, (state, action: any) => {
        state.putUserProfileImageListLoading = false;
        state.putUserProfileImageListPayload = action.payload;
      })
      .addCase(putUserProfileImageList.rejected, (state, action: any) => {
        state.putUserProfileImageListLoading = false;
        state.putUserProfileImageListError = action.payload;
      });
  },
});

export default putUserProfileImageListSlice.reducer;
