import { IAuthContext } from "../types/authContextTypes";
import { IUserNew } from "../types/userTypes";
import { initialUserNewState } from "./initialUserState";

export const initialAuthState = {
  currentUser: initialUserNewState as IUserNew,
  isLoading: true,
  setIsLoading: (isLoading: boolean) => {},
  isAuthenticated: false,
  setIsAuthenticated: (isAuthenticated: boolean) => {},
  updateToken: () => {},
  isTokenExpired: () => {},
  access_token: "",
  signin: (email: string, password: string) => {},
  signinGoogle: (credential: any) => {},
  signup: (e: any) => {},
  signout: () => {},
  recoverPassword: (e: any) => {},
  update: (e: any) => {},
} as IAuthContext;
