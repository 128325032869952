import {
  Box,
  Avatar,
  CardHeader,
  Typography,
  CircularProgress,
  Card,
  CardActionArea,
} from "@mui/material";
import { borderColor } from "../../../../theme/colors";

interface AppProps {
  authorProfile: any;
  loading: boolean;
  title: string;
}

export default function UserInfo({ authorProfile, loading, title }: AppProps) {
  return loading && !authorProfile ? (
    <CircularProgress />
  ) : (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Typography fontSize="0.7rem" fontWeight="bold" component="div">
          {title.toUpperCase()}
        </Typography>
      </Box>

      <CardActionArea href={"/user/" + authorProfile.uuid}>
        <Card
          sx={{
            borderColor: borderColor.pastel3,
            marginBottom: "10px", // This ensures consistency in the space between cards
            width: "100%",
          }}
          variant="outlined"
        >
          <CardHeader
            avatar={
              <Avatar
                alt={
                  authorProfile.username
                    ? authorProfile.username.charAt(0).toUpperCase() +
                      authorProfile.username.slice(1)
                    : ""
                }
                src={
                  authorProfile.profile_image
                    ? authorProfile.profile_image
                    : "https://groupifier.s3.ap-southeast-2.amazonaws.com/media/user_placeholder.jpg"
                }
                sx={{ width: 32, height: 32 }} // This matches the size in PendingRequests
              />
            }
            title={
              authorProfile.username
                ? authorProfile.username.charAt(0).toUpperCase() +
                  authorProfile.username.slice(1)
                : ""
            }
          />
        </Card>
      </CardActionArea>
    </Box>
  );
}
