import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import {
  BodyOfText,
  BodyOfTextPaginated,
  Vocabulary,
  VocabularyPaginated,
} from "../../types/linguisticsTypes";
import axios from "axios";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const listVocabulary = createAsyncThunk(
  "linguistics/listVocabulary",
  async (data: { access_token: string }, thunkApi) => {
    try {
      const response = await axios.get<Vocabulary[]>(
        `${url}/linguistics/vocabularyword/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

interface listVocabularyWordsState {
  listVocabularyWordsLoading: boolean;
  listVocabularyWordsErrors: {};
  listVocabularyWordsPayload: Vocabulary[];
}

const listVocabularySlice = createSlice({
  name: "linguistics/listVocabularyWords",
  initialState: {
    listVocabularyWordsLoading: false,
    listVocabularyWordsErrors: {},
    listVocabularyWordsPayload: [],
  } as listVocabularyWordsState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listVocabulary.pending, (state, action) => {
        state.listVocabularyWordsLoading = true;
      })
      .addCase(
        listVocabulary.fulfilled,
        (state, action: PayloadAction<Vocabulary[]>) => {
          state.listVocabularyWordsLoading = false;
          state.listVocabularyWordsPayload = action.payload;
        }
      )
      .addCase(listVocabulary.rejected, (state, action: PayloadAction<any>) => {
        state.listVocabularyWordsErrors = action.payload;
      });
  },
});

export default listVocabularySlice.reducer;
