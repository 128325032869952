import { backgroundColor } from "../../../theme/colors";

export function statusChipColorSwitch(status: string): string {
  switch (status) {
    case "active":
      return backgroundColor.pastel_crystal;
    case "nomination":
      return backgroundColor.pastel_blond;
    case "inactive":
      return backgroundColor.pastel_pink;
    default:
      return backgroundColor.grey;
  }
}
