import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IClassroom } from "../../types/classroomTypes";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getClassrooms = createAsyncThunk(
  "classroom/getClassrooms",
  async (data: { access_token: string }, thunkApi) => {
    try {
      const response = await axios.get<IClassroom>(`${url}/classrooms/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

interface ClassroomsState {
  getClassroomsLoading: boolean;
  getClassroomsErrors: {};
  getClassroomsPayload: IClassroom[];
}

const getClassroomsSlice = createSlice({
  name: "getClassrooms",
  initialState: {
    getClassroomsLoading: false,
    getClassroomsErrors: {},
    getClassroomsPayload: [] as IClassroom[],
  } as ClassroomsState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getClassrooms.pending, (state, action) => {
        state.getClassroomsLoading = true;
      })
      .addCase(getClassrooms.fulfilled, (state, action: PayloadAction<any>) => {
        state.getClassroomsLoading = false;
        state.getClassroomsPayload = action.payload;
      })
      .addCase(getClassrooms.rejected, (state, action: PayloadAction<any>) => {
        state.getClassroomsErrors = action.payload;
      });
  },
});

export default getClassroomsSlice.reducer;
