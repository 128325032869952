import { FC } from "react";
import logo from "../../images/LogoOnWhiteSmall4.png";

const Logo: FC = (props) => {
  return (
    <div className="logo">
      <img src={logo} width="150em" alt="logo" />
    </div>
  );
};

export default Logo;
