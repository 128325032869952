import Box from "@mui/material/Box";
import {
  Container,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import Copyright from "../Copyright/Copyright";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import InfoIcon from "@mui/icons-material/Info";
import MessageIcon from "@mui/icons-material/Message";
import BlockIcon from "@mui/icons-material/Block";
import { useAppDispatch, useAppSelector } from "../../hooks/useTypedSelector";
import { Link, useNavigate, useParams } from "react-router-dom";
import UserCardSkeleton from "../Skeletons/UserCardSkeleton";
import UserProfileInfo from "./UserProfileComponents/UserProfileInfo";
import UserProfileImages from "./UserProfileComponents/UserProfileImages";
import { getUser } from "../../features/users/getUserSlice";

const UserProfile: FC = () => {
  let { id } = useParams();

  const dispatch = useAppDispatch();
  const { getUserLoading, getUserPayload } = useAppSelector(
    (state) => state.getUser
  );

  const [selectedTab, setSelectedTab] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      getUser({
        access_token: access_token,
        subscriberId: id || "",
      })
    );
  }, []);
  console.log(getUserPayload);

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      {/* Add another navbar inside grid */}
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        alignItems="center" // Center items vertically
      >
        <IconButton
          aria-label="See Info"
          color={"primary"}
          onClick={() => {
            setSelectedTab(0);
          }}
        >
          <InfoIcon />
        </IconButton>
        <IconButton
          aria-label="See Images"
          color={"primary"}
          onClick={() => {
            setSelectedTab(1);
          }}
        >
          <PermMediaIcon />
        </IconButton>
        <IconButton
          aria-label="Block"
          color={"primary"}
          onClick={() => {
            setSelectedTab(2);
          }}
        >
          <BlockIcon />
        </IconButton>
      </Stack>
      {/* This is the classroom component */}
      <Box>
        {getUserLoading ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              marginTop: "20px",
              gap: "16px", // Adjust the gap as needed
            }}
          >
            <UserCardSkeleton />
          </div>
        ) : (
          <div>
            {/* This is the user info component */}
            {selectedTab === 0 && (
              <Box sx={{ mt: 2 }}>
                <UserProfileInfo userPayload={getUserPayload} />
              </Box>
            )}
            {/* This is the images component */}
            {selectedTab === 1 && (
              <Box sx={{ mt: 2 }}>
                {" "}
                <UserProfileImages userPayload={getUserPayload} />
              </Box>
            )}
            {selectedTab === 2 && (
              <Box sx={{ mt: 2 }}>
                <Typography>This functionality is not available yet</Typography>
              </Box>
            )}
          </div>
        )}
      </Box>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
};

export default UserProfile;
