import { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import CustomToolbar from "../../../Scheduler/CustomToolbar";

import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../hooks/useTypedSelector";
import { getClassrooms } from "../../../../features/classrooms/getClassroomsSlice";
import { IClassroom } from "../../../../types/classroomTypes";
import { IEvent } from "../../../Dashboard/types";
import { getEvents } from "../../../../features/classrooms/eventsSlice";
import { useNavigate, useParams } from "react-router-dom";
import { getClassroom } from "../../../../features/classrooms/getClassroomSlice";
import { eventStyleGetter } from "../../../Scheduler/eventStyleGetter";
import CustomToolbarSchedulerOne from "./CustomToolbarSchedulerOne";

const localizer = momentLocalizer(moment);

function calculateTimeDifference(tzString: string) {
  // convert the timezone string to a date object
  let tzDate = new Date().toLocaleString("en-US", { timeZone: tzString });
  // convert the date object to the number of milliseconds since the epoch
  let tzMillis = Date.parse(tzDate);

  // create another date object for the zulu timezone
  let zDate = new Date().toLocaleString("en-US", { timeZone: "Etc/UTC" });
  // convert the date object to the number of milliseconds since the epoch
  let zMillis = Date.parse(zDate);

  // calculate the difference in milliseconds
  let diff = tzMillis - zMillis;
  // convert the difference in milliseconds to the difference in hours
  let diffHours = diff / (1000 * 60 * 60);

  return diffHours;
}

const SchedulerOne = () => {
  const events = useAppSelector((state) => state.events.events);
  const [localizedEvents, setLocalizedEvents] = useState<any>([]);
  // times
  const [adjustedTimes, setAdjustedTimes] = useState<IEvent[] | null>(null);
  const { getClassroomLoading, getClassroomPayload } = useAppSelector(
    (state) => state.getClassroom
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // debug
  //console.log("adjustedTimes ", adjustedTimes)
  console.log("events", events);

  function onSelectEventHandler(slotInfo: any) {
    console.log("slotInfo", slotInfo);
    navigate(`/groups/${slotInfo.id}`);
  }

  function onNavigate(date: any, view: any, action: any) {
    console.log("date", date);
    console.log("view", view);
    console.log("action", action);
    if (!date) return;
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    // from first day of month to last day of month
    if (view === "month") {
      dispatch(
        getEvents({
          access_token: access_token,
          from: moment(date).startOf("month").format("YYYY-MM-DD"),
          to: moment(date).endOf("month").format("YYYY-MM-DD"),
          classroom: getClassroomPayload.uuid,
        })
      );
    } else if (view === "week") {
      dispatch(
        getEvents({
          access_token: access_token,
          from: moment(date).startOf("week").format("YYYY-MM-DD"),
          to: moment(date).endOf("week").format("YYYY-MM-DD"),
          classroom: getClassroomPayload.uuid,
        })
      );
    } else if (view === "day") {
      dispatch(
        getEvents({
          access_token: access_token,
          from: moment(date).format("YYYY-MM-DD"),
          to: moment(date).format("YYYY-MM-DD"),
          classroom: getClassroomPayload.uuid,
        })
      );
    }
  }

  const { getUserProfileLoading, getUserProfilePayload } = useAppSelector(
    (state) => state.getUserProfile
  );

  useEffect(() => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(getClassrooms({ access_token: access_token }));
    // from first day of month to last day of month of current date
    dispatch(
      getEvents({
        access_token: access_token,
        from: moment().startOf("month").format("YYYY-MM-DD"),
        to: moment().endOf("month").format("YYYY-MM-DD"),
        classroom: getClassroomPayload.uuid,
      })
    );
  }, []);

  /*
    useEffect(() => {
        if (events.length > 0 && getUserProfilePayload.timezone) {
            let hoursToBeAdded = calculateTimeDifference(getUserProfilePayload.timezone);
            events.map((event: any) => {
                // if classroom is not current classroom, return
                if (event.classroom_uuid === getClassroomPayload.uuid) return
                let startTime = new Date(event.start);
                let endTime = new Date(event.end);
                startTime.setHours(startTime.getHours() + hoursToBeAdded);
                endTime.setHours(endTime.getHours() + hoursToBeAdded);
                setAdjustedTimes([{
                    id: event.uuid,
                    title: getClassroomPayload.title,
                    start: startTime,
                    end: endTime,
                }])
            })  
        }
    }, [events]);
    */

  useEffect(() => {
    if (events.length > 0 && getUserProfilePayload.timezone) {
      let eventsToShow = [] as IEvent[];
      console.log(events);
      events.map((event: any) => {
        // if classroom is not current classroom, return
        //console.log("event.classroom_uuid ", event.classroom_uuid_str)
        if (event.classroom_uuid_str !== getClassroomPayload.uuid) return;
        let startTime = new Date(event.start);
        let endTime = new Date(event.end);
        eventsToShow.push({
          id: event.classroom_uuid_str,
          title: getClassroomPayload.title,
          start: startTime,
          end: endTime,
        });
      });
      setAdjustedTimes(eventsToShow);
    }
  }, [events]);

  return (
    <div className="App">
      <Calendar
        onSelectEvent={(slotInfo) => onSelectEventHandler(slotInfo)}
        onNavigate={onNavigate}
        components={{ toolbar: CustomToolbarSchedulerOne }}
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={adjustedTimes ? adjustedTimes : []}
        style={{ height: "100vh" }}
        eventPropGetter={eventStyleGetter}
      />
    </div>
  );
};

export default SchedulerOne;
