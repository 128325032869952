import React from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  CardContent,
  Avatar,
  IconButton,
  CardActions,
} from "@mui/material";
import Card from "@mui/material/Card";
import MessageIcon from "@mui/icons-material/Message";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link as RouterLink } from "react-router-dom";
import { CircleFlag } from "react-circle-flags";
import { borderColor } from "../../theme/colors";

var img_url = "";
const flags_to_show = 5;
const about_letters_to_show = 100;

if (process.env.STORAGE_TYPE === "S3") {
  img_url = `https://${process.env.AWS_STORAGE_BUCKET_NAME}.s3.amazonaws.com/`;
}

// Styles section
const styles = {
  card: {
    mb: 0,
    p: 2,
    borderColor: borderColor.logo,
  },
  usernameText: {
    ml: 2,
    fontSize: 16,
  },
  aboutText: {
    mt: 2,
    mb: 2,
  },
  flagStyle: {
    marginRight: "8px",
    width: "1em",
    height: "1em",
  },
  flagMoreStyle: {
    // separate style for the "..." Typography
    marginLeft: "8px",
    width: "1em",
    height: "1em",
  },
  finalFlagStyle: {
    width: "1em",
    height: "1em",
  },
};

type User = {
  uuid: string;
  profile_details: {
    username: string;
    about: string;
    native_languages: string[];
  };
  all_image_urls: string[];
};

interface UserCardProps {
  user: User;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function UserCard({ user }: UserCardProps) {
  const [open, setOpen] = React.useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  // Content section
  const userAvatar = (
    <div
      style={{
        borderRadius: "50%",
        overflow: "hidden",
        border: "2px solid black",
        width: "60px",
        height: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Avatar
        src={user.all_image_urls[0]}
        alt={user.profile_details.username}
        style={{ width: "50px", height: "50px" }}
      />
    </div>
  );

  const nativeLanguages = (
    <>
      {user.profile_details.native_languages &&
        user.profile_details.native_languages
          .slice(0, flags_to_show)
          .map((language: any, index: number) => (
            <CircleFlag
              key={index}
              countryCode={language.split("-")[1].toLowerCase()}
              style={
                index !== user.profile_details.native_languages.length - 1
                  ? styles.flagStyle
                  : styles.finalFlagStyle
              }
            />
          ))}

      {user.profile_details.native_languages &&
        user.profile_details.native_languages.length > flags_to_show && (
          <Typography
            variant="body1"
            sx={styles.flagMoreStyle}
            color="text.secondary"
          >
            ...
          </Typography>
        )}
    </>
  );

  const userActions = (
    <Box display="flex" justifyContent="start">
      <IconButton
        component={RouterLink}
        to={`/user/chat/${user.uuid}`}
        size="small"
      >
        <div
          style={{
            borderRadius: "50%",
            border: "1px solid black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "40px",
            height: "40px",
          }}
        >
          <MessageIcon style={{ fontSize: "15px" }} />
        </div>
      </IconButton>
      <IconButton href={"/user/" + user.uuid}>
        <div
          style={{
            borderRadius: "50%",
            border: "1px solid black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "40px",
            height: "40px",
          }}
        >
          <VisibilityIcon style={{ fontSize: "15px" }} />
        </div>
      </IconButton>
    </Box>
  );

  return (
    <>
      <Card sx={styles.card} variant="outlined">
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Box display="flex" alignItems="center">
              {userAvatar}
              <Typography sx={styles.usernameText} color="text.secondary">
                {user.profile_details.username &&
                user.profile_details.username.length > 10
                  ? `${user.profile_details.username.slice(0, 10)}...`
                  : user.profile_details.username}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
              {nativeLanguages}
            </Box>
            <Typography sx={styles.aboutText} color="text.secondary">
              {user.profile_details.about &&
              user.profile_details.about.length > about_letters_to_show
                ? `${user.profile_details.about.slice(
                    0,
                    about_letters_to_show
                  )}...`
                : user.profile_details.about}
            </Typography>
          </Box>
        </CardContent>

        <CardActions>{userActions}</CardActions>
      </Card>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Copied Link to Clipboard!
        </Alert>
      </Snackbar>
    </>
  );
}
