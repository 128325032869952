import {
  Avatar,
  CardHeader,
  IconButton,
  CircularProgress,
  Chip,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import PlaceIcon from "@mui/icons-material/Place";
import Grid from "@mui/material/Grid";
import TuneIcon from "@mui/icons-material/Tune";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { CircleFlag } from "react-circle-flags";
import LanguageLevel from "./LanguageLevel";
import { Fragment } from "react";
import { borderColor } from "../../../theme/colors";

function formatTimezone(timezone: string): string {
  const parts = timezone.split("/");
  return parts[parts.length - 1].replace("_", " ");
}

interface AppProps {
  userProfile: any;
  loading: boolean;
  title: string;
  img_url: string;
}

export default function UserInfo({ userProfile, loading, img_url }: AppProps) {
  return loading ? (
    <CircularProgress />
  ) : (
    <>
      <Card
        sx={{
          mb: 3,
          width: "100%",
          borderColor: borderColor.color3,
          padding: 2,
        }}
        variant="outlined"
      >
        <CardHeader
          avatar={
            <Avatar
              alt={
                userProfile
                  ? userProfile.username.charAt(0).toUpperCase() +
                    userProfile.username.slice(1)
                  : ""
              }
              src={userProfile.profile_image}
              sx={{ width: 75, height: 75 }}
            />
          }
          titleTypographyProps={{ variant: "h6" }}
          title={
            userProfile
              ? userProfile.username.charAt(0).toUpperCase() +
                userProfile.username.slice(1)
              : ""
          }
        />
        <CardContent>
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <PlaceIcon sx={{ mr: 2 }} style={{ verticalAlign: "middle" }} />
            </Grid>
            <Grid item>
              <span style={{ fontSize: "0.8em" }}>
                {userProfile.timezone ? (
                  formatTimezone(userProfile.timezone)
                ) : (
                  <HelpOutlineIcon sx={{ fontSize: "small" }} />
                )}
              </span>
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <LanguageIcon
                sx={{ mr: 2 }}
                style={{ verticalAlign: "middle" }}
              />
            </Grid>
            <Grid item>
              {userProfile.native_languages_names &&
              userProfile.native_languages_names.length > 0 ? (
                userProfile.native_languages_names.map(
                  (language: any, index: number) => (
                    <span key={index} style={{ marginRight: "8px" }}>
                      <CircleFlag
                        countryCode={language.split("-")[1].toLowerCase()}
                        style={{
                          width: "0.8em",
                          height: "0.8em",
                        }}
                      />
                    </span>
                  )
                )
              ) : (
                <span style={{ fontSize: "0.8em", fontWeight: "bold" }}>
                  <HelpOutlineIcon sx={{ fontSize: "small" }} />
                </span>
              )}
            </Grid>
          </Grid>
          <Grid container direction="row" alignItems="center" spacing={2}>
            <Grid item>
              <LocalLibraryIcon
                sx={{ mr: 2 }}
                style={{ verticalAlign: "middle" }}
              />
            </Grid>
            <Grid item>
              {userProfile.learning_languages_names &&
              userProfile.learning_languages_names.length > 0 ? (
                userProfile.learning_languages_names.map(
                  (language: any, index: number) => (
                    <Fragment key={index}>
                      <span>
                        <CircleFlag
                          countryCode={language.split("-")[1].toLowerCase()}
                          style={{
                            width: "0.8em",
                            height: "0.8em",
                          }}
                        />
                      </span>
                      {userProfile.learning_languages_levels &&
                      userProfile.learning_languages_levels.length > 0 ? (
                        <LanguageLevel
                          level={userProfile.learning_languages_levels[index]}
                        />
                      ) : (
                        <span style={{ fontSize: "0.8em", fontWeight: "bold" }}>
                          <HelpOutlineIcon sx={{ fontSize: "small" }} />
                        </span>
                      )}
                    </Fragment>
                  )
                )
              ) : (
                <span style={{ fontSize: "0.8em", fontWeight: "bold" }}>
                  <HelpOutlineIcon sx={{ fontSize: "small" }} />
                </span>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ mt: 2 }}
          >
            {userProfile.is_admin && (
              <Grid item>
                <Chip
                  label="Admin"
                  color="error"
                  variant="outlined"
                  sx={{ mr: 1 }}
                />
              </Grid>
            )}
            {userProfile.is_teacher && (
              <Grid item>
                <Chip
                  label="Teacher"
                  color="primary"
                  variant="outlined"
                  sx={{ mr: 0 }}
                />
              </Grid>
            )}
            <Grid item>
              <Chip
                label="Student"
                color="success"
                variant="outlined"
                sx={{ mr: 1 }} // Adjust margins as needed
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <IconButton href="/profile/update/" size="small">
            <TuneIcon sx={{ fontSize: "1rem" }} />
          </IconButton>
        </CardActions>
      </Card>
    </>
  );
}
