import { IUser, IUserNew, IUserPending } from "../types/userTypes";
import {
  IUserProfile,
  IUserProfilePlusEmailPlusTeacher,
} from "../types/userProfileTypes";

export const initialUserState = {
  username: "",
  email: "",
  role: "",
  images: [""],
  profile: {
    is_invited: false,
    has_accepted: false,
    voted_by: [],
    username: "",
    date_of_birth: "",
    about: "",
    native_languages: [],
    learning_languages: [],
    learning_languages_levels: [],
    native_languages_names: [],
    learning_languages_names: [],
    timezone: "",
    countries_from: [],
    countries_living: [],
    images: [""],
    age: "",
    profile_image: "",
    all_image_urls: [""],
    is_online: false,
    is_teacher: false,
    is_bot: false,
    user_uuid: "",
  } as IUserProfile,
  _id: "",
  id: 0,
  created_at: "",
  updated_at: "",
  __v: 0,
} as IUser;

export const initialUserPendingState: IUserPending = {
  uuid: "",
  username: "",
  profile_image:
    "https://groupifier.s3.amazonaws.com/media/user_placeholder.jpg", // Default to placeholder image
  native_languages_names: [],
  learning_languages_names: [],
  learning_languages_levels: [],
  timezone: "",
};

export const initialUserProfileState: IUserProfilePlusEmailPlusTeacher = {
  user_uuid: "",
  uuid: "",
  username: "",
  email: "",
  date_of_birth: "",
  about: "",
  native_languages_names: [],
  learning_languages_names: [],
  learning_languages_levels: [],
  countries_from: [],
  countries_living: [],
  timezone: "",
  images: [],
  is_online: false,
  age: "",
  profile_image: "",
  all_image_urls: [],
  is_teacher: false,
  has_nomination: false,
  invites: [],
  teacher_profile: {
    teacher_profile_uuid: "",
    teacher_type: "",
    status: "",
    first_name: "",
    last_name: "",
    about: "",
    availability: [],
    availability_template: [],
    documents: [],
    teaching_languages: [],
    years_of_experience: [],
    nomination_details: [],
  },
  avatar_data: {},
};

export const initialUserNewState: IUserNew = {
  email: "",
  role: "",
  all_image_urls: [],
  profile: {
    username: "",
    date_of_birth: "",
    about: "",
    native_languages: [],
    learning_languages: [],
    learning_languages_levels: [],
    timezone: "",
    countries_from: [],
    countries_living: [],
    images: [],
    is_online: false,
    age: "",
    profile_image: "",
    all_image_urls: [],
    is_teacher: false,
    is_bot: false,
    user_uuid: "",
    voted_by: "",
  } as IUserProfile,
  profile_details: {
    username: "",
    date_of_birth: "",
    about: "",
    native_languages: [],
    learning_languages: [],
    learning_languages_levels: [],
    timezone: "",
    countries_from: [],
    countries_living: [],
    images: [],
    is_online: false,
    age: "",
    profile_image: "",
    all_image_urls: [],
    is_teacher: false,
    is_bot: false,
    user_uuid: "",
    voted_by: "",
  } as IUserProfile,
  images: [""],
  profile_image: "",
  _id: "",
  id: 0,
  uuid: "",
  profile_uuid: "",
  created_at: "",
  updated_at: "",
  __v: 1,
  is_teacher: false,
  is_bot: false,
};
