import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

export default function CommunitySkeletons() {
  const skeletonCount = 20; // Number of skeletons

  return (
    <>
      {Array.from({ length: skeletonCount }, (_, index) => (
        <Skeleton
          key={index}
          variant="rectangular"
          width={240}
          height={300}
          sx={{ m: 1, borderRadius: "5px" }}
        />
      ))}
    </>
  );
}
