export enum ROLE {
    ADMIN = 'admin',
    TEACHER = 'teacher',
    STUDENT = 'student',
  }

 export interface ILanguage {
    language: string;
    level: string;
}

export interface ILanguageAndLevel {
  language: string;
  level: string;
}


export interface ILanguageExtended {
  language: string;
  level: string;
  nativeName: string;
}


export interface ILanguageListItem {
  code:string;
  name: string; nativeName: string
}

export enum FrequencyEnum {
  d = 'Daily',
  w = 'Weekly',
  m = 'monthly',
  tpw = 'Twice per week',
}

export interface IEvent {
  id: number;
  title: string;
  start: Date;
  end: Date;
}