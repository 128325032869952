import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { IErrors, IChannel } from "../../types/MessageTypes";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getChatPartners = createAsyncThunk(
  "user/getChatPartners",
  async (
    data: { access_token: string; channelId: string | undefined },
    thunkApi
  ) => {
    try {
      const response = await axios.get<IChannel[]>(`${url}/chatpartners/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        params: {
          channelId: data.channelId,
        },
      });
      //console.log("response.data: ", response.data)
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const getChatPartnersSlice = createSlice({
  name: "getChatPartners",
  initialState: {
    getChatPartnersLoading: false,
    getChatPartnersErrors: {} as IErrors,
    getChatPartnersPayload: [] as IChannel[],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getChatPartners.pending, (state, action) => {
        state.getChatPartnersLoading = true;
      })
      .addCase(getChatPartners.fulfilled, (state, action: any) => {
        state.getChatPartnersLoading = false;
        state.getChatPartnersPayload = action.payload;
      })
      .addCase(getChatPartners.rejected, (state, action: any) => {
        state.getChatPartnersLoading = false;
        state.getChatPartnersErrors = action.payload;
      });
  },
});

export default getChatPartnersSlice.reducer;
