import { IMessage } from "./MessageTypes";
import { IUser, IUserNew } from "./userTypes";
import { IUserProfile, IUserProfileNomination } from "./userProfileTypes";
import { FrequencyEnum } from "../components/Dashboard/types";
import { initialClassroomState } from "../initalStates/initialClassroomState";

export interface IClassroom {
  id: string;
  uuid: string;
  author: number;
  author_details: IUser;
  language_string: string;
  language_string_long: string;
  title: string;
  short_title: string;
  description: string;
  software: string;
  price: string;
  paid: boolean;
  payment: string;
  status: string;
  longitude: number;
  latitude: number;
  color: string;
  tags: string[];
  frequency: string;
  category: string;
  created_at: string;
  updated_at: string;
  subscribers: IUserNew[];
  subscribers_pending: IUserNew[];
  subscribers_details: IUserNew[];
  subscribers_pending_details: IUserNew[];
  calendar_events: ICalendarEvent[];
  calendar_events_details: {
    uuid: any;
    start: string;
    end: string;
    frequency: FrequencyEnum;
    duration: number;
  }[];
  channel_uuid: string;
  is_owner: boolean;
  messages_details: IMessage[];
  has_teacher: boolean;
  teacher_profile: IUserProfileNomination;
  nominated_teachers_details: IUserProfileNomination[];
}

export interface IClassroomPaginated {
  count: number;
  next: string | null;
  previous: string | null;
  results: IClassroom[];
}

export enum PaymentTypeEnum {
  "PERSTUDENT" = "perstudent",
  "PERCLASS" = "perclass",
}

export enum EveryEnum {
  "DAY" = "d",
  "WEEK" = "w",
  "FORTNIGHT" = "f",
  "MONTH" = "m",
}

export interface ITeacherApplication {
  uuid: string;
  first_name: string;
  last_name: string;
  introduction: string;
  teaching_languages: string[];
  tags: string[];
  availability_template: Date[];
}

export interface ICreateClassroom {
  uuid: string;
  language: string;
  level: string;
  title: string;
  description: string;
  software: string;
  paymentType: PaymentTypeEnum;
  n: number[];
  paid: boolean;
  prices: number[];
  tags: string[];
  category: string;
  students_invited: string[];
  frequency: string;
  calendar_events: ICalendarEvent[];
  time_zone: string;
  time_zone_offset: number;
}

export interface ICalendarEvent {
  event_id: number | string;
  title: string;
  start: Date;
  end: Date;
  disabled?: boolean;
  color?: string;
  editable?: boolean;
  deletable?: boolean;
  draggable?: boolean;
  allDay?: boolean;
}

export interface ITeacher {
  user_uuid: string;
  username: string;
  profile_image: string;
  native_languages_names: string[];
  learning_languages_names: string[];
  learning_languages_levels: string[];
  timezone: string;
  upvotes: number;
  voted_by: string[];
  is_invited: boolean;
  has_accepted: boolean;
  status: string;
}

export interface IUpDownVotes {
  [key: string]: string[];
}
