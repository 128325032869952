// UserProfileSection.tsx or UserProfileSection.jsx

import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  Avatar,
  ListItemText,
} from "@mui/material";

interface UserProfileSectionProps {
  currentUser: { id: string | number };
  isDesktopResolution: boolean;
  getUserProfilePayload: {
    username: string;
    profile_image?: string;
  };
}

const UserProfileSection: React.FC<UserProfileSectionProps> = ({
  currentUser,
  isDesktopResolution,
  getUserProfilePayload,
}) => (
  <List>
    <ListItem key={currentUser.id}>
      <ListItemIcon>
        <Avatar
          alt={getUserProfilePayload.username}
          src={
            getUserProfilePayload.profile_image
              ? `${getUserProfilePayload.profile_image}`
              : ""
          }
        />
      </ListItemIcon>
      {isDesktopResolution && (
        <ListItemText
          primary={getUserProfilePayload.username}
          sx={{ userSelect: "none", pointerEvents: "none" }}
        />
      )}
    </ListItem>
  </List>
);

export default UserProfileSection;
