import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
  Chip,
  SelectChangeEvent,
} from "@mui/material";
import CardSkeleton from "../../Skeletons/CardSkeleton";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../hooks/useTypedSelector";
import { getClassroomsPaginated } from "../../../features/classrooms/getClassroomsPaginatedSlice";
import { forwardRef, useEffect, useState } from "react";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { getCountries } from "../../../features/languages/getCountriesSlice";
import { ICountry, ITag } from "../../../types/languageTypes";
import { listTags } from "../../../features/classrooms/listTagsSlice";
import { getClassroomsFiltered } from "../../../features/classrooms/getClassroomsFilteredSlice";
import GoogleMapReact from "google-map-react";
import { IDataType } from "../../../types/languageTypes";
import Marker from "./Marker";
import { GroupListFiltered } from "./GroupLists/GroupListFiltered";
import { GroupListUnfiltered } from "./GroupLists/GroupListUnfiltered";

const defaultProps = {
  center: {
    lat: 10.99835602,
    lng: 77.01502627,
  },
  zoom: 11,
};

// New York
const defaultNE = {
  lat: 40.882214,
  lng: -73.907,
};
const defaultSW = {
  lat: 40.680396,
  lng: -74.047285,
};

var img_url = "";
var url = process.env.REACT_APP_URL;

if (process.env.STORAGE_TYPE === "S3") {
  img_url = `https://${process.env.AWS_STORAGE_BUCKET_NAME}.s3.amazonaws.com/`;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function GroupList() {
  const [open, setOpen] = useState(false);
  const [tagList, setTagList] = useState<ITag[]>([
    {
      name: "English",
      color: "",
    },
  ]);
  const [filterTag, setFilterTag] = useState<ITag[]>([]);
  const [filter, setFilter] = useState<string | null>(null);
  const [filterCountry, setFilterCountry] = useState<string | null>(null);
  const [countries, setCountries] = useState<ICountry[]>([
    {
      ISO: "US",
      display_name: "United States",
      latitude: 10.99835602,
      longitude: 77.01502627,
    },
  ]);
  const [filterCity, setFilterCity] = useState<string | null>(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 59.955413,
    lng: 30.337844,
  });

  const [bounds, setBounds] = useState<{
    sw: { lat: number; lng: number };
    ne: { lat: number; lng: number };
  } | null>(null);

  const handleMapChange = (data: MapChangeEventData) => {
    console.log(data.bounds);
    setBounds(data.bounds);
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      getClassroomsFiltered({
        access_token: access_token,
        tags: filterTag,
        ne: data.bounds?.ne ?? defaultNE,
        sw: data.bounds?.sw ?? defaultSW,
        type: "",
      })
    );
  };

  const [data, setData] = useState<IDataType[]>([]);

  const handleCountryChange = (countryISO: string) => {
    const selectedCountry = countries.find(
      (country) => country.ISO === countryISO
    );
    if (selectedCountry) {
      setMapCenter({
        lat: selectedCountry.latitude,
        lng: selectedCountry.longitude,
      });
    }
  };

  type MapChangeEventData = {
    center: { lat: number; lng: number };
    zoom: number;
    bounds: {
      sw: { lat: number; lng: number };
      ne: { lat: number; lng: number };
    };
  };

  const onCountryChange = (e: SelectChangeEvent<string>) => {
    const selectedCountry = e.target.value;
    setFilterCountry(selectedCountry);
    handleCountryChange(selectedCountry);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleShare = (uuid: string) => {
    navigator.clipboard.writeText(url + "/groups/" + uuid);
    setOpen(true);
  };

  const dispatch = useAppDispatch();
  const {
    getClassroomsPaginatedPayload,
    getClassroomsPaginatedLoading,
    getClassroomsPaginatedErrors,
  } = useAppSelector((state) => state.getClassroomsPaginated);
  const { listTagsPayload } = useAppSelector((state) => state.listTags);

  const { getCountriesPayload, getCountriesLoading } = useAppSelector(
    (state) => state.getCountries
  );
  const { getClassroomsFilteredPayload } = useAppSelector(
    (state) => state.getClassroomsFiltered
  );

  useEffect(() => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(getClassroomsPaginated({ access_token: access_token, page: 1 }));
    dispatch(getCountries({ access_token: access_token }));
    dispatch(listTags({ access_token: access_token }));
  }, []);

  useEffect(() => {
    setCountries(getCountriesPayload);
  }, [getCountriesPayload]);

  useEffect(() => {
    setTagList(listTagsPayload);
  }, [listTagsPayload]);

  useEffect(() => {
    if (getClassroomsFilteredPayload) {
      const transformedDataArray = getClassroomsFilteredPayload.map((item) => ({
        uuid: item.uuid,
        latitude: item.latitude,
        longitude: item.longitude,
        name: item.language_string,
      }));

      setData(transformedDataArray);
    }
  }, [getClassroomsFilteredPayload]);

  console.log("data", data);

  useEffect(() => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      getClassroomsFiltered({
        access_token: access_token,
        tags: filterTag,
        ne: bounds?.ne ?? defaultNE,
        sw: bounds?.sw ?? defaultSW,
        type: "",
      })
    );
  }, [filterTag]);

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  console.log("getClassroomsFilteredPayload", getClassroomsFilteredPayload);
  console.log("filterCountry", filterCountry);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          {/* Right Row: Filters and Inputs */}
          <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
            <InputLabel>Filter By</InputLabel>
            <Select
              label="Filter By"
              value={filter ? filter : ""}
              onChange={(e) => setFilter(e.target.value as string)}
            >
              <MenuItem value={"online"}>Online</MenuItem>
              <MenuItem value={"inperson"}>In Person</MenuItem>
            </Select>
          </FormControl>
          {filter === "inperson" && (
            <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
              <InputLabel id="country-label">Country</InputLabel>
              <Select
                labelId="country-label"
                label="Country"
                value={filterCountry ? filterCountry : ""}
                onChange={onCountryChange}
              >
                {getCountriesLoading ? (
                  <MenuItem value="" disabled>
                    Loading...
                  </MenuItem>
                ) : (
                  getCountriesPayload.map((country) => (
                    <MenuItem key={country.ISO} value={country.ISO}>
                      {country.display_name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          )}
          {filter === "inperson" && filterCountry && (
            <div style={{ height: "300px", width: "100%", marginBottom: 10 }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAPS_API || "",
                }}
                center={mapCenter}
                defaultZoom={defaultProps.zoom - 5}
                onChange={handleMapChange}
              >
                {data.map((item: IDataType, index: number) => (
                  <Marker
                    key={index}
                    lat={item.latitude}
                    lng={item.longitude}
                    text={item.name}
                  />
                ))}
              </GoogleMapReact>
            </div>
          )}
          <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
            <Autocomplete
              multiple
              id="topics-autocomplete"
              onChange={(event, newValues: ITag[]) => {
                setFilterTag(newValues);
              }}
              options={tagList}
              getOptionLabel={(option: ITag) =>
                capitalizeFirstLetter(option.name)
              }
              renderTags={(tagValue: ITag[], getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={capitalizeFirstLetter(option.name)}
                    {...getTagProps({ index })}
                  />
                ))
              }
              fullWidth
              value={filterTag}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Topics"
                  placeholder="Choose a topic"
                />
              )}
            />
          </FormControl>
          {/* Add more inputs or dropdowns as needed */}
        </Grid>
        <Grid item xs={12} md={7}>
          {/* Left Row: Existing Content */}
          {getClassroomsPaginatedLoading ? (
            <>
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </>
          ) : filterCountry || filterCity ? (
            <GroupListFiltered classrooms={getClassroomsFilteredPayload} />
          ) : (
            <GroupListUnfiltered
              classroomsPaginated={getClassroomsPaginatedPayload}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
