import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { IClassroomPaginated, IClassroom } from "../../types/classroomTypes";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getClassroomsPaginated = createAsyncThunk(
  "classroom/getClassrooms",
  async (data: { access_token: string; page: number }, thunkApi) => {
    try {
      const response = await axios.get<IClassroomPaginated>(
        `${url}/classroompages/?page=${data.page}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

interface ClassroomState {
  getClassroomsPaginatedLoading: boolean;
  getClassroomsPaginatedErrors: {};
  getClassroomsPaginatedPayload: IClassroomPaginated;
}

const getClassroomsPaginatedSlice = createSlice({
  name: "classrooms/getClassrooms",
  initialState: {
    getClassroomsPaginatedLoading: false,
    getClassroomsPaginatedErrors: {},
    getClassroomsPaginatedPayload: {
      count: 0,
      next: null,
      previous: null,
      results: [] as IClassroom[],
    },
  } as ClassroomState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getClassroomsPaginated.pending, (state, action) => {
        state.getClassroomsPaginatedLoading = true;
      })
      .addCase(
        getClassroomsPaginated.fulfilled,
        (state, action: PayloadAction<IClassroomPaginated>) => {
          state.getClassroomsPaginatedLoading = false;
          state.getClassroomsPaginatedPayload = action.payload;
        }
      )
      .addCase(
        getClassroomsPaginated.rejected,
        (state, action: PayloadAction<any>) => {
          state.getClassroomsPaginatedErrors = action.payload;
        }
      );
  },
});

export default getClassroomsPaginatedSlice.reducer;
