import * as React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { ICreateClassroom } from "../../../../types/classroomTypes";
import { FREQUENCIES } from "../../../../constants/misc";
import { FrequencyEnum } from "../../../Dashboard/types";

const products = [
  {
    name: "Product 1",
    desc: "A nice thing",
    price: "$9.99",
  },
  {
    name: "Product 2",
    desc: "Another thing",
    price: "$3.45",
  },
  {
    name: "Product 3",
    desc: "Something else",
    price: "$6.51",
  },
  {
    name: "Product 4",
    desc: "Best thing of all",
    price: "$14.11",
  },
  { name: "Shipping", desc: "", price: "Free" },
];
const addresses = ["1 MUI Drive", "Reactville", "Anytown", "99999", "USA"];
const payments = [
  { name: "Card type", detail: "Visa" },
  { name: "Card holder", detail: "Mr John Smith" },
  { name: "Card number", detail: "xxxx-xxxx-xxxx-1234" },
  { name: "Expiry date", detail: "04/2024" },
];

interface AppProps {
  classroom: ICreateClassroom;
  setClassroom: React.Dispatch<React.SetStateAction<ICreateClassroom>>;
  processedEventToAdd: any;
  setProcessedEventToAdd: React.Dispatch<React.SetStateAction<any>>;
}

const Review = ({
  classroom,
  setClassroom,
  processedEventToAdd,
  setProcessedEventToAdd,
}: AppProps) => {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Classroom summary
      </Typography>
      <List disablePadding>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary={"Title"} secondary={classroom.title} />
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText
            primary={"Date, Time and Frequency"}
            secondary={
              processedEventToAdd.start.toDateString() +
              "-" +
              processedEventToAdd.end.toDateString()
            }
          />
          <Typography variant="body2">
            Frequency:{" "}
            {FREQUENCIES.find((f) => f.short == classroom.frequency)?.long}
          </Typography>
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText
            primary={"Description"}
            secondary={classroom.description}
          />
        </ListItem>
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText
            primary={"Payment"}
            secondary={
              classroom.paymentType == "perstudent"
                ? "Per Student"
                : "Per Class"
            }
          />
          <Typography variant="body2">
            {classroom.paymentType == "perstudent"
              ? `Each student pays ${classroom.prices} per class`
              : `All students pay together ${classroom.prices} per class`}
          </Typography>
        </ListItem>
      </List>
    </React.Fragment>
  );
};

export default Review;
