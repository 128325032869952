import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const deleteVocabulary = createAsyncThunk(
  "linguistics/deleteVocabulary",
  async (data: { access_token: string; vocabulary_uuid: string }, thunkApi) => {
    try {
      const response = await fetch(
        `${url}/linguistics/vocabularyword/${data.vocabulary_uuid}/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.access_token}`,
          },
        }
      );
      if (response.status === 204) {
        return { success: true };
      } else return thunkApi.rejectWithValue(response.statusText);
    } catch (error) {
      return thunkApi.rejectWithValue("Something went wrong");
    }
  }
);

const deleteVocabularySlice = createSlice({
  name: "linguistics/deleteVocabulary",
  initialState: {
    deleteVocabularyLoading: false,
    deleteVocabularyErrors: null,
    deleteVocabularyPayload: {
      success: false,
      status: "",
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(deleteVocabulary.pending, (state) => {
        state.deleteVocabularyLoading = true;
      })
      .addCase(deleteVocabulary.fulfilled, (state, action: any) => {
        state.deleteVocabularyLoading = false;
        state.deleteVocabularyErrors = action.payload;
        state.deleteVocabularyPayload = {
          success: true,
          status: action.payload.classroom_status,
        };
      })
      .addCase(deleteVocabulary.rejected, (state, action: any) => {
        state.deleteVocabularyLoading = false;
        state.deleteVocabularyErrors = action.payload;
      });
  },
});

export default deleteVocabularySlice.reducer;
