import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

export default function SkeletonColor() {
  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Skeleton
        variant="rectangular"
        width="90%"
        height={240}
        sx={{ mb: 2, ml: 2, borderRadius: "5px" }}
      />
    </Box>
  );
}
