import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { ITeacherApplication } from "../../types/classroomTypes";
import ScheduleSelector from "react-schedule-selector";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  arrayOfDatesToRanges,
  weekdayNumberToString,
} from "../UserProfileUpdate/convertTime";
import theme from "../theme/theme";

function combineDates(dates: Date[]): Date[][] {
  // a function in typescript that takes an array of dates Date[] in increments of 15 minutes
  // and turns it into an array of from to date Date[][].
  // combine data that is consecutive into one from_date to to_date combination.
  let datePairs: Date[][] = [];
  let fromDate = dates[0];
  let toDate = dates[0];

  for (let i = 1; i < dates.length; i++) {
    let currentDate = dates[i];
    let timeDifference = currentDate.getTime() - toDate.getTime();
    if (timeDifference === 900000) {
      toDate = currentDate;
    } else {
      datePairs.push([fromDate, toDate]);
      fromDate = currentDate;
      toDate = currentDate;
    }
  }
  datePairs.push([fromDate, toDate]);
  return datePairs;
}

interface AppProps {
  application: ITeacherApplication;
  setApplication: Dispatch<SetStateAction<ITeacherApplication>>;
}

const Availability = ({ application, setApplication }: AppProps) => {
  const { height, width } = useWindowDimensions();
  const handleChange = (newSchedule: any) => {
    setState({ schedule: newSchedule });
    setApplication({ ...application, availability_template: newSchedule });
  };
  const [state, setState] = useState({
    schedule: [],
  });

  console.log(
    "application.schedule",
    arrayOfDatesToRanges(application.availability_template)
  );

  return (
    <>
      <Grid container spacing={3}></Grid>
      <Grid container spacing={3} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={12} md={12}>
          <Box
            style={{
              maxHeight: height - 500,
              minHeight: height - 500,
              overflow: "auto",
            }}
          >
            <ScheduleSelector
              selection={application.availability_template}
              numDays={7}
              minTime={0}
              maxTime={24}
              hourlyChunks={2}
              dateFormat={"ddd"}
              timeFormat={"hh:mm A"}
              onChange={handleChange}
              renderDateLabel={(date) => {
                return (
                  <div
                    style={{
                      backgroundColor: theme.palette.primary.light,
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      color: "black",
                    }}
                  >
                    {weekdayNumberToString(date.getDay())}
                  </div>
                );
              }}
              renderDateCell={(date, selected, refSetter) => (
                <div
                  style={{
                    border: "1px solid #CDCDCD",
                    backgroundColor: selected
                      ? theme.palette.primary.dark
                      : "white",
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Availability;
