import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "@reduxjs/toolkit";
import dictionarySlice from "./dictionary/dictionarySlice";
import postTeacherUpvoteSlice from "./classrooms/postTeacherUpvoteSlice";
import postTeacherDownvoteSlice from "./classrooms/postTeacherDownvoteSlice";
import getClassroomsPaginatedSlice from "./classrooms/getClassroomsPaginatedSlice";
import getUserProfileSlice from "./users/getUserProfileSlice";
import patchUserProfileSlice from "./users/patchUserProfileSlice";
import registerUserSlice from "./users/userRegisterSlice";
import getUsersSlice from "./users/getUsersSlice";
import getUserSlice from "./users/getUserSlice";
import putUserProfileImageListSlice from "./users/putUserProfileImageList";
import getMessagesSlice from "./chat/getMessagesSlice";
import getChannelMessagesSlice from "./chat/getChannelMessagesSlice";
import getChannelsMessagesSlice from "./chat/getChannelsMessagesSlice";
import postMessagesSlice from "./chat/postMessagesSlice";
import postTeacherNominationSlice from "./classrooms/postTeacherNominationSlice";
import postTeacherProfileSlice from "./users/postTeacherProfileSlice";
import channelSlice from "./chat/channelSlice";
import getLanguagesSlice from "./languages/getLanguagesDetailedSlice";
import getLanguagesAndCountriesSlice from "./languages/getLanguagesSlice";
import postClassroomSlice from "./classrooms/postClassroomSlice";
import getChatPartnersSlice from "./chat/getChatPartnersSlice";
import getChatPartnersUserSlice from "./chat/getChatPartnersUserSlice";
import getTeacherProfileSlice from "./users/getTeacherProfileSlice";
import getTeachersSlice from "./users/getTeachersSlice";
import postTeacherUpDownvoteSlice from "./classrooms/postTeacherUpDownvoteSlice";
import postStartPauseCycleSlice from "./classrooms/postStartPauseCycleSlice";
import postTeacherNominationAcceptSlice from "./classrooms/postTeacherNominationAcceptSlice";
import putAdjustTimeZoneSlice from "./users/putTimeZoneSlice";
import eventsSlice from "./classrooms/eventsSlice";
import postAvatarChangeSlice from "./users/postAvatarChangeSlice";
import postTextSlice from "./linguistics/postTextSlice";
import listParagraphSlice from "./linguistics/listParagraphSlice";
import listWordsSlice from "./linguistics/listWordsSlice";
import listBodiesOfTextSlice from "./linguistics/listBodiesOfTextSlice";
import listBodiesOfTextPaginatedSlice from "./linguistics/listBodiesOfTextPaginatedSlice";
import getBodyOfTextByUuidSlice from "./linguistics/getBodyOfTextByUuidSlice";
import getUsersPaginatedSlice from "./users/getUsersPaginatedSlice";
import getClassroomsSlice from "./classrooms/getClassroomsSlice";
import getClassroomSlice from "./classrooms/getClassroomSlice";
import getClassroomsPaginatedUserSlice from "./classrooms/getClassroomsPaginatedUserSlice";
import postWordSlice from "./linguistics/postWordSlice";
import getCountriesSlice from "./languages/getCountriesSlice";
import listTagsSlice from "./classrooms/listTagsSlice";
import getClassroomsFilteredSlice from "./classrooms/getClassroomsFilteredSlice";
import listVocabularyPaginatedSlice from "./linguistics/listVocabularyPaginatedSlice";
import listVocabularySlice from "./linguistics/listVocabularySlice";
import deleteVocabularySlice from "./linguistics/deleteVocabularySlice";
import postVocabularySlice from "./linguistics/postVocabularySlice";
import joinGroupSlice from "./classrooms/joinGroupSlice";
import leaveGroupSlice from "./classrooms/leaveGroupSlice";
import acceptTeacherNominationSlice from "./classrooms/acceptJoinGroupSlice";
import acceptJoinGroupSlice from "./classrooms/acceptJoinGroupSlice";
import rejectJoinGroupSlice from "./classrooms/rejectJoinGroupSlice";

const rootReducer = combineReducers({
  // users
  getUsersPaginated: getUsersPaginatedSlice,
  getUsers: getUsersSlice,
  getUser: getUserSlice,
  registerUser: registerUserSlice,
  getTeachers: getTeachersSlice,
  // user profile
  getUserProfile: getUserProfileSlice,
  getTeacherProfile: getTeacherProfileSlice,
  patchUserProfile: patchUserProfileSlice,
  putUserProfileImageList: putUserProfileImageListSlice,
  putAdjustTimeZone: putAdjustTimeZoneSlice,
  postAvatarChange: postAvatarChangeSlice,
  // teacher profile
  postTeacherProfile: postTeacherProfileSlice,
  // chat
  channel: channelSlice,
  getMessages: getMessagesSlice,
  getChatPartners: getChatPartnersSlice,
  getChatPartnersUser: getChatPartnersUserSlice,
  getChannelMessages: getChannelMessagesSlice,
  getChannelsMessages: getChannelsMessagesSlice,
  postMessages: postMessagesSlice,
  // classrooms
  getClassroomsPaginated: getClassroomsPaginatedSlice,
  getClassroomsFiltered: getClassroomsFilteredSlice,
  getClassroomsPaginatedUser: getClassroomsPaginatedUserSlice,
  getClassrooms: getClassroomsSlice,
  getClassroom: getClassroomSlice,
  postClassroom: postClassroomSlice,
  postTeacherNomination: postTeacherNominationSlice,
  postTeacherUpvote: postTeacherUpvoteSlice,
  postTeacherDownvote: postTeacherDownvoteSlice,
  postTeacherUpDownvote: postTeacherUpDownvoteSlice,
  postStartPauseCycle: postStartPauseCycleSlice,
  postTeacherNominationAccept: postTeacherNominationAcceptSlice,
  joinGroup: joinGroupSlice,
  leaveGroup: leaveGroupSlice,
  acceptJoinGroup: acceptJoinGroupSlice,
  rejectJoinGroup: rejectJoinGroupSlice,
  listTags: listTagsSlice,
  // events
  events: eventsSlice,
  // dictionary
  dictionary: dictionarySlice,
  // linguistics
  postText: postTextSlice,
  postWord: postWordSlice,
  listParagraphs: listParagraphSlice,
  listWordsSlice: listWordsSlice,
  listVocabularyPaginated: listVocabularyPaginatedSlice,
  listVocabulary: listVocabularySlice,
  deleteVocabulary: deleteVocabularySlice,
  listBodiesOfText: listBodiesOfTextSlice,
  listBodiesOfTextPaginated: listBodiesOfTextPaginatedSlice,
  getBodyOfTextByUuid: getBodyOfTextByUuidSlice,
  postVocabulary: postVocabularySlice,
  // languages
  getLanguages: getLanguagesSlice,
  getLanguagesAndCountries: getLanguagesAndCountriesSlice,
  getCountries: getCountriesSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend
      // correctly typed middlewares can just be used as-is
      (),
  // prepend and concat calls can be chained
});

export type AppDispatch = typeof store.dispatch;

export default store;
