import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Autocomplete, Box, Chip } from "@mui/material";
import { ITeacherApplication } from "../../types/classroomTypes";
import { MuiChipsInput } from "mui-chips-input";
import { Dispatch, SetStateAction } from "react";

interface AppProps {
  application: ITeacherApplication;
  setApplication: Dispatch<SetStateAction<ITeacherApplication>>;
  languageList: string[][];
}

const General = ({ application, setApplication, languageList }: AppProps) => {
  const helperTexts = {
    first_name: "Write your first name.",
    last_name: "Write your last name.",
    introduction: "Write a short introduction of you and your teaching style.",
    tags: "Add tags to help people find you.",
    language: "Choose the language you want to teach.",
    software: "Choose the software you want to use for your classroom.",
  };

  console.log(application);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            error={
              application.first_name.length < 1 ||
              application.first_name.length > 50
            }
            required
            id="first_name"
            name="first_name"
            label="First Name"
            fullWidth
            autoComplete="first_name"
            variant="outlined"
            helperText={helperTexts.first_name}
            onChange={(e) =>
              setApplication({ ...application, first_name: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            error={
              application.last_name.length < 1 ||
              application.last_name.length > 50
            }
            required
            id="last_name"
            name="last_name"
            label="Last Name"
            fullWidth
            autoComplete="last_name"
            variant="outlined"
            helperText={helperTexts.last_name}
            onChange={(e) =>
              setApplication({ ...application, last_name: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={
              application.introduction.length < 3 ||
              application.introduction.length > 500
            }
            required
            id="introduction"
            name="introduction"
            label="Introduction"
            fullWidth
            autoComplete="introduction"
            variant="outlined"
            helperText={
              application.introduction.length < 3 ||
              application.introduction.length > 500
                ? helperTexts.introduction
                : `${application.introduction.length}/500`
            }
            multiline
            rows={4}
            onChange={(e) =>
              setApplication({ ...application, introduction: e.target.value })
            }
          />
        </Grid>
        {/* Start Teaching Languages */}
        <Grid item xs={12}>
          <Autocomplete
            multiple
            id="native"
            onChange={(event, newValue) => {
              setApplication({
                ...application,
                teaching_languages: newValue.map((l) => (l ? l[0] : "")),
              });
            }}
            options={languageList}
            getOptionLabel={(option) => (option ? option[1] : "")} // 0: code, 1: display_name
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={option ? option[0] : ""}
                  {...getTagProps({ index })}
                />
              ))
            }
            fullWidth
            // take the values from nativeLanguages and find display name in languageList
            value={application.teaching_languages.map((l) =>
              languageList.find((ll) => ll[0] === l)
            )}
            renderInput={(params) => (
              <TextField
                error={
                  application.teaching_languages.length < 1 ||
                  application.teaching_languages.length > 5
                }
                required
                {...params}
                label="Teaching language(s)"
                placeholder="Choose the language(s) you want to teach (max 5)."
              />
            )}
          />
          <Box sx={{ mb: 3 }} />
        </Grid>
        {/* End Teaching languages */}
        <Grid item xs={12} sm={12}>
          <MuiChipsInput
            value={application.tags}
            onChange={(e) => setApplication({ ...application, tags: e })}
            label="Tags"
            variant="outlined"
            helperText={helperTexts.tags}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default General;
