import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const postStartPauseCycle = createAsyncThunk(
  "classrooms/postStartPauseCycle",
  async (data: { access_token: string; classroom_uuid: string }, thunkApi) => {
    try {
      const response = await fetch(`${url}/startpausecycle/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        body: JSON.stringify({
          classroom_uuid: data.classroom_uuid,
        }),
      });
      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else return thunkApi.rejectWithValue(response.statusText);
    } catch (error) {
      return thunkApi.rejectWithValue("Something went wrong");
    }
  }
);

const postStartPauseCycleSlice = createSlice({
  name: "classrooms/postStartPauseCycle",
  initialState: {
    postStartPauseCycleLoading: false,
    postStartPauseCycleErrors: null,
    postStartPauseCyclePayload: {
      success: false,
      status: "",
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postStartPauseCycle.pending, (state) => {
        state.postStartPauseCycleLoading = true;
      })
      .addCase(postStartPauseCycle.fulfilled, (state, action: any) => {
        state.postStartPauseCycleLoading = false;
        state.postStartPauseCyclePayload = action.payload;
        state.postStartPauseCyclePayload = {
          success: true,
          status: action.payload.classroom_status,
        };
      })
      .addCase(postStartPauseCycle.rejected, (state, action: any) => {
        state.postStartPauseCycleLoading = false;
        state.postStartPauseCycleErrors = action.payload;
      });
  },
});

export default postStartPauseCycleSlice.reducer;
