import React, { useState } from "react";
import { Word } from "../../../../types/linguisticsTypes";

interface WordProps {
  word: Word;
  sentence: string;
  native_languages: string[];
  index: number;
}

const KnowledgeView: React.FC<WordProps> = ({ word, sentence, index }) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleToggle = (): void => {
    setIsHovered(!isHovered);
  };

  const getUnderlineStyle = (): {
    textDecorationStyle: "dotted" | "dashed" | "solid";
    textDecorationThickness: string;
  } => {
    if (word.user_interactions <= 5)
      return { textDecorationStyle: "dotted", textDecorationThickness: "1px" };
    if (word.user_interactions <= 10)
      return { textDecorationStyle: "dashed", textDecorationThickness: "1px" };
    if (word.user_interactions <= 20)
      return { textDecorationStyle: "dashed", textDecorationThickness: "2px" };
    return { textDecorationStyle: "solid", textDecorationThickness: "2px" }; // for values greater than 20
  };

  return (
    <span
      onClick={handleToggle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        cursor: "pointer",
        position: "relative",
        display: "inline-block",
      }}
    >
      <span
        style={{
          textDecoration: "underline",
          ...getUnderlineStyle(),
        }}
      >
        {word.text}
      </span>

      {isHovered && (
        <span
          style={{
            position: "absolute",
            border: "1px solid black",
            padding: "5px",
            backgroundColor: "white",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            top: "100%",
            left: "0",
            marginTop: "5px",
            zIndex: 1000,
            display: "inline-block",
            whiteSpace: "nowrap",
          }}
        >
          Information about the word:
          <span>ID: {word.text}</span>
        </span>
      )}
    </span>
  );
};

export default KnowledgeView;
