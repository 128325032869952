import { Navigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";

enum ROLE {
  ADMIN = "admin",
  TEACHER = "teacher",
  STUDENT = "student",
}

interface Props {
  component: React.ComponentType;
  path?: string;
  roles?: string[];
}

export const PrivateRoute: React.FC<Props> = ({
  component: RouteComponent,
  roles,
}) => {
  const { currentUser, isAuthenticated, isLoading, updateToken } =
    useContext(AuthContext);

  const [isTokenRefreshAttempted, setTokenRefreshAttempted] = useState(false);

  var userHasRequiredRole = true; // Adjust as necessary based on your requirements

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      const tryRefreshToken = async () => {
        try {
          await updateToken();
          console.log("Tried refresh token");
          setTokenRefreshAttempted(true);
        } catch (error) {
          console.error("Error refreshing token:", error);
          setTokenRefreshAttempted(true);
        }
      };
      tryRefreshToken();
    }
  }, [isAuthenticated, isLoading, updateToken]);

  if (isLoading || (!isTokenRefreshAttempted && !isAuthenticated)) {
    return <div>Loading...</div>; // or any placeholder/loading spinner
  }

  if (isAuthenticated && userHasRequiredRole) {
    return <RouteComponent />;
  }

  // If not authenticated or doesn't have the required role
  return <Navigate to="/signin/" />;
};
