import { Box } from "@mui/material";
import Loader from "../../Loaders/Loader";
import UserInfo from "./TeacherNominations/UserInfo/UserInfo";

interface AppProps {
  id: string;
  getClassroomPayload: any;
  getClassroomLoading: boolean;
}

function ClassroomUsers({
  id,
  getClassroomPayload,
  getClassroomLoading,
}: AppProps) {
  return (
    <Box>
      {getClassroomLoading ? (
        <Loader />
      ) : (
        getClassroomPayload.subscribers_details &&
        getClassroomPayload.subscribers_details.length > 0 &&
        getClassroomPayload.subscribers_details.map(
          (subscriber: any, index: number) => (
            <Box sx={{ mb: 2 }} key={index}>
              <UserInfo subscriber={subscriber} loading={false} />
            </Box>
          )
        )
      )}
    </Box>
  );
}

export default ClassroomUsers;
