import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const listBodiesOfText = createAsyncThunk(
  "linguistics/listBodyOfText",
  async (
    data: {
      access_token: string;
    },
    thunkApi
  ) => {
    try {
      const response = await fetch(`${url}/linguistics/bodyoftext/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
      });
      // Check if the response is successful (status code 200)
      if (response.ok) {
        const jsonData = await response.json(); // Parse the response JSON
        return jsonData; // Return the fetched data
      } else {
        // If the response status is not OK, reject with an error message
        return thunkApi.rejectWithValue({ error: "Something went wrong" });
      }
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const listBodiesOfTextSlice = createSlice({
  name: "listBodiesOfTextSlice",
  initialState: {
    listBodiesOfTextLoading: false,
    listBodiesOfTextError: null,
    listBodiesOfTextPayload: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listBodiesOfText.pending, (state) => {
        state.listBodiesOfTextLoading = true;
      })
      .addCase(listBodiesOfText.fulfilled, (state, action: any) => {
        state.listBodiesOfTextLoading = false;
        state.listBodiesOfTextPayload = action.payload;
      })
      .addCase(listBodiesOfText.rejected, (state, action: any) => {
        state.listBodiesOfTextLoading = false;
        state.listBodiesOfTextError = action.payload;
      });
  },
});

export default listBodiesOfTextSlice.reducer;
