import Box from "@mui/material/Box";
import {
  Button,
  Container,
  Divider,
  IconButton,
  Pagination,
  Stack,
} from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import Copyright from "../Copyright/Copyright";
import AuthContext from "../../context/AuthContext";
import { getUserProfile } from "../../features/users/getUserProfileSlice";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListIcon from "@mui/icons-material/List";
import { useAppDispatch, useAppSelector } from "../../hooks/useTypedSelector";
import { useNavigate } from "react-router-dom";
import Scheduler from "../Scheduler/SchedulerMany";
import useMatchMedia from "../../hooks/matchMedia";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { getUsersPaginated } from "../../features/users/getUsersPaginatedSlice";
import UserCard from "./UserCards/UserCard";
import UserCardSkeleton from "../Skeletons/UserCardSkeleton";

const Community: FC = () => {
  const isDesktopResolution = useMatchMedia("(min-width:992px)", true);
  const [isMobile, setIsMobile] = useState(false);

  // Function to check screen size and update state
  const checkScreenSize = () => {
    setIsMobile(window.innerWidth <= 768); // You can adjust the breakpoint as needed
  };

  const dispatch = useAppDispatch();
  const { currentUser, isAuthenticated } = useContext(AuthContext);
  const { getUsersPaginatedLoading, getUsersPaginatedPayload } = useAppSelector(
    (state) => state.getUsersPaginated
  );
  const { getUserProfileLoading, getUserProfilePayload } = useAppSelector(
    (state) => state.getUserProfile
  );

  console.log("getUsersPaginatedPayload", getUsersPaginatedPayload);

  const [page, setPage] = useState(1);

  const handlePaginationChange = (e: any, pageNumber: number) => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      getUsersPaginated({
        access_token: access_token,
        page: pageNumber,
      })
    );
    setPage(pageNumber);
  };

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (!isAuthenticated) return;
    if (!currentUser.profile_uuid) return;
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      getUserProfile({
        access_token: access_token,
        subscriberProfileId: currentUser.profile_uuid,
      })
    );
    dispatch(getUsersPaginated({ access_token: access_token, page: 1 }));

    checkScreenSize(); // Initial check
    window.addEventListener("resize", checkScreenSize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const navigate = useNavigate();

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      {/* Add another navbar inside grid */}
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        <IconButton
          aria-label="New Messages"
          color={"primary"}
          onClick={() => {
            setSelectedTab(0);
          }}
        >
          <ListIcon />
        </IconButton>
        <IconButton
          aria-label="Calendar"
          color={"primary"}
          onClick={() => {
            setSelectedTab(1);
          }}
        >
          <CalendarMonthIcon />
        </IconButton>
        <Button
          aria-label="List"
          color={"primary"}
          onClick={() => {
            navigate("/groups/create");
          }}
        >
          {!isMobile ? (
            <>
              <ControlPointIcon
                sx={{
                  mr: 1,
                }}
              />{" "}
              Create Group
            </>
          ) : (
            <>
              <ControlPointIcon
                sx={{
                  mr: 1,
                }}
              />{" "}
              Create Group
            </>
          )}
        </Button>
      </Stack>
      {/* This is the classroom component */}
      {selectedTab === 0 && (
        <Box>
          {getUsersPaginatedLoading ? (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                marginTop: "20px",
                gap: "16px", // Adjust the gap as needed
              }}
            >
              <UserCardSkeleton />
              <UserCardSkeleton />
              <UserCardSkeleton />
              <UserCardSkeleton />
              <UserCardSkeleton />
            </div>
          ) : (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                marginTop: "20px",
                gap: "16px", // Adjust the gap as needed
              }}
            >
              {getUsersPaginatedPayload.results.length > 0 &&
                getUsersPaginatedPayload.results.map((user, index) => (
                  <Box sx={{ mb: 2 }} key={index}>
                    <UserCard user={user} />
                  </Box>
                ))}
            </div>
          )}

          <Stack alignItems="center">
            <Pagination
              count={
                getUsersPaginatedPayload
                  ? Math.ceil(getUsersPaginatedPayload.count / 20)
                  : 0
              }
              siblingCount={1}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handlePaginationChange}
            />
          </Stack>
        </Box>
      )}
      {/* This is the scheduler component */}
      {selectedTab === 1 && (
        <Box sx={{ mt: 2 }}>
          <Scheduler />
        </Box>
      )}
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
};

export default Community;
