
export function convertTimeToDate15Min(timeArray: string[][]): Date[] {

    const dateArray: Date[] = [];
  
    for (let i = 0; i < timeArray.length; i++) {
      // Each subarray should have two elements - start and end time
      const startTime = new Date();
      const endTime = new Date();
      let start_datetime = timeArray[i][0].split("T")[1];
      let end_datetime = timeArray[i][1].split("T")[1];
      startTime.setHours(parseInt(start_datetime.split(":")[0]));
      startTime.setMinutes(parseInt(end_datetime.split(":")[1]));
      startTime.setSeconds(0);
      endTime.setHours(parseInt(end_datetime.split(":")[0]));
      endTime.setMinutes(parseInt(end_datetime.split(":")[1]));
      endTime.setSeconds(0);
      //console.log(startTime, endTime);
      let currentTime = startTime;
      while (currentTime <= endTime) {
        dateArray.push(currentTime);
        currentTime = new Date(currentTime.getTime() + 15 * 60 * 1000);
      }
    }
  
    return dateArray;
  }
  
  export function convertSimpleTimeToDate15Min(timeArray: string[][]): Date[] {
  
    const dateArray: Date[] = [];
  
    for (let i = 0; i < timeArray.length; i++) {
      // Each subarray should have two elements - start and end time
      const startTime = new Date();
      const endTime = new Date();
      let start_datetime = timeArray[i][0];
      let end_datetime = timeArray[i][1];
      startTime.setHours(parseInt(start_datetime.split(":")[0]));
      startTime.setMinutes(parseInt(end_datetime.split(":")[1]));
      startTime.setSeconds(0);
      endTime.setHours(parseInt(end_datetime.split(":")[0]));
      endTime.setMinutes(parseInt(end_datetime.split(":")[1]));
      endTime.setSeconds(0);
      //console.log(startTime, endTime);
      let currentTime = startTime;
      while (currentTime <= endTime) {
        dateArray.push(currentTime);
        currentTime = new Date(currentTime.getTime() + 15 * 60 * 1000);
      }
    }
  
    return dateArray;
  }
  
  export function convertTimeToDate30Min(timeArray: string[][]): Date[] {
  
    const dateArray: Date[] = [];
  
    for (let i = 0; i < timeArray.length; i++) {
      // Each subarray should have two elements - start and end time
      const startTime = new Date();
      const endTime = new Date();
      let start_datetime = timeArray[i][0].split("T")[1];
      let end_datetime = timeArray[i][1].split("T")[1];
      startTime.setHours(parseInt(start_datetime.split(":")[0]));
      startTime.setMinutes(parseInt(end_datetime.split(":")[1]));
      startTime.setSeconds(0);
      endTime.setHours(parseInt(end_datetime.split(":")[0]));
      endTime.setMinutes(parseInt(end_datetime.split(":")[1]));
      endTime.setSeconds(0);
      //console.log(startTime, endTime);
      let currentTime = startTime;
      while (currentTime <= endTime) {
        dateArray.push(currentTime);
        currentTime = new Date(currentTime.getTime() + 30 * 60 * 1000);
      }
    }
  
    return dateArray;
  }
  
  export function convertSimpleTimeStringToDate30Min(timeArray: string[][]): Date[] {
  
    const dateArray: Date[] = [];
  
    for (let i = 0; i < timeArray.length; i++) {
      // Each subarray should have two elements - start and end time
      const startTime = new Date();
      const endTime = new Date();
      let start_datetime = timeArray[i][0];
      let end_datetime = timeArray[i][1];
      startTime.setHours(parseInt(start_datetime.split(":")[0]));
      startTime.setMinutes(parseInt(end_datetime.split(":")[1]));
      startTime.setSeconds(0);
      endTime.setHours(parseInt(end_datetime.split(":")[0]));
      endTime.setMinutes(parseInt(end_datetime.split(":")[1]));
      endTime.setSeconds(0);
      //console.log(startTime, endTime);
      let currentTime = startTime;
      while (currentTime <= endTime) {
        dateArray.push(currentTime);
        currentTime = new Date(currentTime.getTime() + 30 * 60 * 1000);
      }
    }
  
    return dateArray;
  }

  export function convertDateRangeToDates30Min(timeArray: Date[][]): Date[] {
  
    const dateArray: Date[] = [];
  
    for (let i = 0; i < timeArray.length; i++) {
      // Each subarray should have two elements - start and end time
      const startTime = new Date();
      const endTime = new Date();
      console.log(startTime, endTime);
      let currentTime = startTime;
      while (currentTime <= endTime) {
        dateArray.push(currentTime);
        currentTime = new Date(currentTime.getTime() + 30 * 60 * 1000);
      }
    }
  
    return dateArray;
  }


 export function combine15minDates(dates: Date[]) : Date[][] {
    const combinedDates: Date[][] = []; // array of date ranges
    let startDate: Date;
    let endDate: Date;

    for (let i = 0; i < dates.length; i++) {
        // set start date to current date
        startDate = dates[i];
        // set end date to one minute after start date
        endDate = new Date(startDate.getTime() + (15 * 60 * 1000));
        
        // loop through next dates until a date is not consecutive
        while (i < dates.length - 1 && dates[i+1].getTime() <= endDate.getTime()) {
            i++;
            endDate = new Date(dates[i].getTime() + (15 * 60 * 1000));
        }
        // add from_date and to_date to combinedDates array
        combinedDates.push([startDate, endDate]);
    }
    return combinedDates;
}


// write a function that turns an array of dates into an array of date ranges in typescript
//  const testDates = [
//    new Date('2020-04-01T13:00:00'),
//    new Date('2020-04-01T13:30:00'),
//    new Date('2020-04-01T14:00:00'),
//    new Date('2020-04-01T14:30:00'),
//    new Date('2020-04-01T15:00:00'),
//    new Date('2020-04-01T15:30:00'),
//    new Date('2020-04-01T16:00:00'),
//  ];
// Expected output:
// [
//   [
//     Date('2020-04-01T13:00:00'),
//     Date('2020-04-01T15:30:00')
//   ],
//   [
//     Date('2020-04-01T16:00:00'),
//     Date('2020-04-01T16:30:00'),
//   ]
// ]





  // write a function that turns an array of day time combinations in the next Date type
// input:
// ["monday", "13:00:00"]
// exptected output:
// Date('2020-04-01T13:00:00'),
 

export function getNextDateTime(timeArray: string[][]) {
    let result = [];
    for (let i = 0; i < timeArray.length; i++) {
        let date = new Date();
        date.setDate(date.getDate() + (8 - date.getDay()) % 7);
        let time = timeArray[i][1].split(':');
        date.setHours(parseInt(time[0]));
        date.setMinutes(parseInt(time[1]));
        date.setSeconds(parseInt(time[2]));
        result.push(date);
    }
    return result;
}

//console.log(getNextDateTime([["monday", "13:00:00"], ["monday", "13:30:00"]]));



// write a function that turns a Date in day time arrays, use typescript
// input:
// [Date('2020-04-01T13:00:00'),Date('2020-04-01T13:30:00')]
// exptected output is 
// [["monday", "13:00:00"], ["monday", "13:30:00"]]
export function dateToDayTime(dates: Date[]){
    let result: [string, string][] = [];
    dates.forEach(date => {
        let day = date.getDay();
        let dayName: string;
        switch (day) {
            case 0: dayName = "sunday";
            break;
            case 1: dayName = "monday";
            break;
            case 2: dayName = "tuesday";
            break;
            case 3: dayName = "wednesday";
            break;
            case 4: dayName = "thursday";
            break;
            case 5: dayName = "friday";
            break;
            case 6: dayName = "saturday";
            break;
            default: dayName = "";
            break;
        }
        result.push([dayName, date.toTimeString().slice(0,8)])
    });
    return result;
}

//console.log(dateToDayTime([new Date('2020-04-01T13:00:00'),new Date('2020-04-01T13:30:00')]));























export function arrayOfDatesToRanges(dates: Date[]) {
  const ranges: Date[][] = [];
  let range: Date[] = [ dates[0], dates[1] ];
  for (let i = 1; i < dates.length; i += 1) {
    if (dates[i - 1].getTime() + 30 * 60 * 1000 === dates[i].getTime()) {
      range[1] = dates[i];
    } else {
      ranges.push(range);
      range = [dates[i], dates[i + 1]];
    }
  }
  ranges.push(range);
  return ranges;
}



export function setNextDate(input: Array<Array<string>>): Array<Date> {
    const date = new Date();
    const output = new Array<Date>();

    input.forEach(element => {
        let newDate = new Date(date);
        let weekday = element[0].toLocaleLowerCase();
        switch (weekday) {
            case 'monday':
                newDate.setDate(date.getDate() + (1 + 7 - date.getDay()) % 7);
                break;
            case 'tuesday':
                newDate.setDate(date.getDate() + (2 + 7 - date.getDay()) % 7);
                break;
            case 'wednesday':
                newDate.setDate(date.getDate() + (3 + 7 - date.getDay()) % 7);
                break;
            case 'thursday':
                newDate.setDate(date.getDate() + (4 + 7 - date.getDay()) % 7);
                break;
            case 'friday':
                newDate.setDate(date.getDate() + (5 + 7 - date.getDay()) % 7);
                break;
            case 'saturday':
                newDate.setDate(date.getDate() + (6 + 7 - date.getDay()) % 7);
                break;
            case 'sunday':
                newDate.setDate(date.getDate() + (7 + 7 - date.getDay()) % 7);
                break;
        }
        const time = element[1].split(':');
        newDate.setHours(parseInt(time[0]), parseInt(time[1]), parseInt(time[2]));
        output.push(newDate);
    });

    return output;
}

// turn weekday numbers into strings
export function weekdayNumberToString(weekdayNumber: number): string {
  switch (weekdayNumber) {
    case 0:
      return "Su";
    case 1:
      return "Mo";
    case 2:
      return "Tu";
    case 3:
      return "We";
    case 4:
      return "Th";
    case 5:
      return "Fr";
    case 6:
      return "Sa";
    default:
      return "Su";
  }
}
