import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { ICountry } from "../../types/languageTypes";

const url = process.env.REACT_APP_API_URL;

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", url);
}

export const getCountries = createAsyncThunk(
  "languages/getCountries",
  async (data: { access_token: string }, thunkApi) => {
    try {
      const response = await axios.get<ICountry[]>(`${url}/countries/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        //data: data.subscriberIds
      });
      return response.data;
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const getCountriesSlice = createSlice({
  name: "languages/getCountries",
  initialState: {
    getCountriesLoading: false,
    getCountriesError: null,
    getCountriesPayload: [
      {
        ISO: "US",
        display_name: "United States",
        longitude: 98.5855,
        latitude: 39.8333,
      },
    ],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCountries.pending, (state) => {
        state.getCountriesLoading = true;
      })
      .addCase(
        getCountries.fulfilled,
        (state, action: PayloadAction<ICountry[]>) => {
          state.getCountriesLoading = false;
          state.getCountriesPayload = action.payload;
        }
      )
      .addCase(getCountries.rejected, (state, action: any) => {
        state.getCountriesLoading = false;
        state.getCountriesError = action.payload;
      });
  },
});

export default getCountriesSlice.reducer;
