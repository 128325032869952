import { Button, Container, Divider, IconButton, Stack } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../../features/users/getUserProfileSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../hooks/useTypedSelector";
import Copyright from "../../Copyright/Copyright";
import Box from "@mui/material/Box";
import AuthContext from "../../../context/AuthContext";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListIcon from "@mui/icons-material/List";
import ChatBubble from "@mui/icons-material/ChatBubble";
import Scheduler from "../../Scheduler/SchedulerMany";
import useMatchMedia from "../../../hooks/matchMedia";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import Groups from "./GroupList";

var img_url = "";

if (process.env.STORAGE_TYPE === "S3") {
  img_url = `https://${process.env.AWS_STORAGE_BUCKET_NAME}.s3.amazonaws.com/`;
}

const closeWordOfDayHandler = () => {
  console.log("closeWordOfDayHandler");
};

const closeTeacherHandler = () => {
  console.log("closeTeacherHandler");
};

const GroupMany: FC = () => {
  const isDesktopResolution = useMatchMedia("(min-width:992px)", true);

  const dispatch = useAppDispatch();
  const { currentUser, isAuthenticated } = useContext(AuthContext);
  const { getUserProfileLoading, getUserProfilePayload } = useAppSelector(
    (state) => state.getUserProfile
  );

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (!isAuthenticated) return;
    if (!currentUser.profile_uuid) return;
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      getUserProfile({
        access_token: access_token,
        subscriberProfileId: currentUser.profile_uuid,
      })
    );
  }, []);

  const navigate = useNavigate();

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      {/* Add another navbar inside grid */}
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        <IconButton
          aria-label="New Messages"
          color={"primary"}
          onClick={() => {
            setSelectedTab(0);
          }}
        >
          <ListIcon />
        </IconButton>
        <IconButton
          aria-label="Calendar"
          color={"primary"}
          onClick={() => {
            setSelectedTab(1);
          }}
        >
          <CalendarMonthIcon />
        </IconButton>
        <Button
          aria-label="List"
          color={"primary"}
          onClick={() => {
            navigate("/groups/create");
          }}
        >
          <ControlPointIcon
            sx={{
              mr: 1,
            }}
          />{" "}
          Create Group
        </Button>
      </Stack>
      {/* This is the classroom component */}
      {selectedTab === 0 && (
        <Box sx={{ mt: 2 }}>
          <Groups />
        </Box>
      )}
      {/* This is the scheduler component */}
      {selectedTab === 1 && (
        <Box sx={{ mt: 2 }}>
          <Scheduler />
        </Box>
      )}
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
};

export default GroupMany;
