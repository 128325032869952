import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ICreateClassroom, PaymentTypeEnum } from "../../types/classroomTypes";

const api_url = process.env.REACT_APP_API_URL;
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  console.log("URL: ", api_url);
}

export const postClassroom = createAsyncThunk(
  "userProfile/postClassroom",
  async (data: { access_token: string; classroom: any }, thunkApi) => {
    try {
      const response = await fetch(`${api_url}/classrooms/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.access_token}`,
        },
        body: JSON.stringify(data.classroom),
      });
      // await and if not ok return error
      const json = await response.json();
      if (!response.ok) {
        return thunkApi.rejectWithValue(json);
      }
    } catch (error: any) {
      const message = error.message;
      return thunkApi.rejectWithValue(message);
    }
  }
);

const postclassroomSlice = createSlice({
  name: "postClassroom",
  initialState: {
    postClassroomLoading: false,
    postClassroomError: null,
    postClassroomPayload: {
      uuid: "",
      language: "",
      title: "",
      level: "",
      description: "",
      software: "",
      paymentType: PaymentTypeEnum.PERCLASS,
      n: [],
      paid: false,
      prices: [],
      tags: [],
      frequency: "",
      category: "",
      students_invited: [],
      calendar_events: [],
      start: "",
      duration: 0,
      time_zone: "",
      time_zone_offset: 0,
    } as ICreateClassroom,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postClassroom.pending, (state) => {
        state.postClassroomLoading = true;
      })
      .addCase(postClassroom.fulfilled, (state, action: any) => {
        state.postClassroomLoading = false;
        state.postClassroomPayload = action.payload;
      })
      .addCase(postClassroom.rejected, (state, action: any) => {
        state.postClassroomLoading = false;
        state.postClassroomError = action.payload;
      });
  },
});

export default postclassroomSlice.reducer;
