import ListIcon from "@mui/icons-material/List";
import StoreIcon from "@mui/icons-material/Store";
import Copyright from "../Copyright/Copyright";
import Box from "@mui/material/Box";
import {
  Button,
  Chip,
  Container,
  Divider,
  IconButton,
  Pagination,
  Stack,
} from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { postText } from "../../features/linguistics/postTextSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/useTypedSelector";
import { Vocabulary, VocabularyPaginated } from "../../types/linguisticsTypes";
import { listVocabularyPaginated } from "../../features/linguistics/listVocabularyPaginatedSlice";
import { getLanguagesAndCountries } from "../../features/languages/getLanguagesSlice";
import { WordCards } from "./WordCards/WordCards";
import { getUserProfile } from "../../features/users/getUserProfileSlice";
import AuthContext from "../../context/AuthContext";

function getTodaysDate(): string {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so +1 and pad start with '0' if needed
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

const Dictionary: FC = () => {
  /* 
  Load Dispatch
  */
  const dispatch = useAppDispatch();
  /* 
  GLOBAL STATE:
  */
  const { postTextPayload } = useAppSelector((state) => state.postText);
  const { getUserProfilePayload } = useAppSelector(
    (state) => state.getUserProfile
  );
  const { currentUser } = useContext(AuthContext);
  const { getLanguagesAndCountriesPayload } = useAppSelector(
    (state) => state.getLanguagesAndCountries
  );
  const { listVocabularyPaginatedPayload } = useAppSelector(
    (state) => state.listVocabularyPaginated
  );
  /*
  LOCAL STATE:
  */
  const [selectedTab, setSelectedTab] = useState(0);
  const [languagesLearnedLevel, setLanguagesLearnedLevel] = useState(["A1"]);
  const [languagesLearned, setLanguagesLearned] = useState(["en-US"]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<{
    selectedLanguage: string;
    selectedCategories: string[];
  }>({
    selectedLanguage: "en-US",
    selectedCategories: [],
  });
  const [currentLanguageIndex, setCurrentLanguageIndex] = useState(0);
  /* 
  CONSTANTS:
  */
  const newVocabularyTemplate: Vocabulary = {
    uuid: "",
    word: {
      uuid: "",
      text: "",
      language: "",
      is_deleted: false,
      parts_of_speech: [],
      lexemes: [],
      user_interactions: 0,
      translations: [],
    },
    created_at: getTodaysDate(),
  };

  const newVocabularyTemplatePaginated: VocabularyPaginated = {
    count: 0,
    next: "",
    previous: "",
    results: [newVocabularyTemplate],
  };

  console.log(languagesLearned);

  /* 
  LOCAL STATE 2:
  */
  const [vocabulary, setVocabulary] = useState<VocabularyPaginated>(
    newVocabularyTemplatePaginated
  );
  const [isMobile, setIsMobile] = useState(false);
  const [isInvalid, setIsInvalid] = useState(true);

  /* 
  CLICK HANDLERS:
  */
  const handleLanguageClick = () => {
    // Cycle to the next language or loop back to the first language
    setCurrentLanguageIndex((prevIndex) =>
      prevIndex === languagesLearned.length - 1 ? 0 : prevIndex + 1
    );
  };
  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };
  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };
  // Function to handle changes in the dropdown
  const handleLanguageChange = (event: any) => {
    setFormData({ ...formData, selectedLanguage: event.target.value });
  };
  // Function to check screen size and update state
  const checkScreenSize = () => {
    setIsMobile(window.innerWidth <= 768); // You can adjust the breakpoint as needed
  };
  // Function to handle form submission
  const handleSubmit = async () => {
    closeModal();

    // Update the texts state with the empty template at the beginning
    //setBodyOfText(newTextTemplatePaginated);
    setVocabulary((prevVocabulary) => ({
      ...prevVocabulary,
      results: [newVocabularyTemplate, ...prevVocabulary.results],
    }));

    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      postText({
        access_token: access_token,
        text: "",
        categories: formData.selectedCategories,
        language: languagesLearned[currentLanguageIndex],
        level: languagesLearnedLevel[currentLanguageIndex],
      })
    );
  };
  /* 
  DEBUG: 
  */
  //console.log("postTextPayload", postTextPayload);
  /* 
  useEffect:
  */
  useEffect(() => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      listVocabularyPaginated({
        access_token: access_token,
        page: 1,
        language: languagesLearned[currentLanguageIndex],
        level: ["A1", "A2", "B1", "B2", "C1", "C2"],
      })
    );
    dispatch(
      getUserProfile({
        access_token: access_token,
        subscriberProfileId: currentUser.profile_uuid,
      })
    );
    dispatch(getLanguagesAndCountries({ access_token: access_token }));

    if (listVocabularyPaginatedPayload)
      setVocabulary(listVocabularyPaginatedPayload);

    checkScreenSize(); // Initial check
    window.addEventListener("resize", checkScreenSize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  console.log(
    "getUserProfilePayload.learning_languages_names",
    getUserProfilePayload.learning_languages_names
  );

  useEffect(() => {
    if (getUserProfilePayload.learning_languages_names) {
      // Check if "en-US" is not in the list and add it if necessary
      if (getUserProfilePayload.learning_languages_names.length > 0) {
        setLanguagesLearned(getUserProfilePayload.learning_languages_names);
        setLanguagesLearnedLevel(
          getUserProfilePayload.learning_languages_levels
        );
      }
    }
  }, [getUserProfilePayload]);

  useEffect(() => {
    if (listVocabularyPaginatedPayload)
      setVocabulary(listVocabularyPaginatedPayload);
  }, [listVocabularyPaginatedPayload]);

  useEffect(() => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      listVocabularyPaginated({
        access_token: access_token,
        page: page,
        language: languagesLearned[currentLanguageIndex],
        level: ["A1", "A2", "B1", "B2", "C1", "C2"],
      })
    );
  }, [currentLanguageIndex]);

  useEffect(() => {
    if (postTextPayload) {
      setVocabulary(postTextPayload);
    }
  }, [postTextPayload]);

  const [page, setPage] = useState<number>(1);

  const handlePaginationChange = (e: any, pageNumber: number) => {
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      listVocabularyPaginated({
        access_token: access_token,
        page: pageNumber,
        language: languagesLearned[currentLanguageIndex],
        level: ["A1", "A2", "B1", "B2", "C1", "C2"],
      })
    );
    setPage(pageNumber);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      {/* Add another navbar inside grid */}
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
        alignItems="center" // Center items vertically
      >
        <IconButton
          aria-label="Create Word Entry"
          color={"primary"}
          onClick={() => {
            setSelectedTab(0);
          }}
        >
          <ListIcon />
        </IconButton>

        <Button
          aria-label="List"
          color={"primary"}
          onClick={() => {
            setSelectedTab(1);
          }}
        >
          {!isMobile ? (
            <>
              <StoreIcon
                sx={{
                  mr: 1,
                }}
              />{" "}
              Database
            </>
          ) : (
            <>
              <StoreIcon
                sx={{
                  mr: 0,
                }}
              />{" "}
            </>
          )}
        </Button>

        <Chip
          label={languagesLearned[currentLanguageIndex]}
          clickable
          color="primary"
          onClick={handleLanguageClick}
          size="small"
        />
      </Stack>
      {/* This is the textcards component */}
      {selectedTab === 0 && (
        <Box sx={{ mt: 2 }}>
          {vocabulary.results.length > 0 ? (
            <WordCards
              VocabularyPaginated={vocabulary}
              language={languagesLearned[currentLanguageIndex]}
              languagesPayload={getLanguagesAndCountriesPayload}
            />
          ) : (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                marginTop: "20px",
                gap: "10px", // Adjust the gap as needed
              }}
            >
              No Words found, add some words to this list by clicking on them
              and clicking "add".
            </div>
          )}
        </Box>
      )}

      {selectedTab === 1 && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            marginTop: "20px",
            gap: "10px", // Adjust the gap as needed
          }}
        >
          No Words found in the database, click on the list icon.
        </div>
      )}

      <Stack alignItems="center">
        <Pagination
          count={vocabulary ? Math.ceil(vocabulary.count / 20) : 1}
          siblingCount={5}
          size="large"
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={(event, pageNumber) =>
            handlePaginationChange(event, pageNumber)
          }
        />
      </Stack>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
};

export default Dictionary;
