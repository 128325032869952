import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../hooks/useTypedSelector";
import AuthContext from "../../../context/AuthContext";
import { postVocabulary } from "../../../features/linguistics/postVocabularySlice";

function WordAdd() {
  const location = useLocation();
  console.log("location.state.data ", location.state);
  const state = location.state || {}; // Fallback to empty object if state is undefined

  const [word, setWord] = useState(state.data.word || "");
  const [wordLanguage, setWordLanguage] = useState(
    state.data.word_language || ""
  );
  const [partsOfSpeech, setPartsOfSpeech] = useState(
    state.data.parts_of_speech || ""
  );
  const [description, setDescription] = useState(state.data.description || "");
  const [descriptionLanguage, setDescriptionLanguage] = useState(
    state.data.description_language || ""
  );
  const [translations, setTranslations] = useState(
    state.data.translations || ""
  );
  const [translationsLanguage, settranslationsLanguage] = useState(
    state.data.translations_language || ""
  );
  const [context, setContext] = useState(state.data.context || "");

  const dispatch = useAppDispatch();
  const { currentUser, isAuthenticated } = useContext(AuthContext);
  const { postVocabularyPayload, postVocabularyErrors, postVocabularyLoading } =
    useAppSelector((state) => state.postVocabulary);

  const [translationsError, setTranslationsError] = useState<string | null>(
    null
  );

  const navigate = useNavigate();

  const isTranslationsValid = (translations: string[]): boolean => {
    // Check if translations are not empty
    return translations.every((t) => t !== "");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (!isTranslationsValid(translations)) {
      setTranslationsError("Please ensure translations are not empty.");
      return;
    } else {
      setTranslationsError(null); // clear the error if any
    }
    console.log("Translations before split: ", translations);
    const formData = {
      word,
      wordLanguage,
      partsOfSpeech,
      context,
      description,
      descriptionLanguage,
      translations: translations,
      // Convert translations string to an array
      translationsLanguage,
      // ... other fields?
    };
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      postVocabulary({
        access_token: access_token,
        vocabulary: formData,
      })
    );
  };

  useEffect(() => {
    if (postVocabularyPayload.success) navigate("/dictionary/");
  }, [postVocabularyPayload]);

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "16px",
        }}
      >
        <TextField
          label="Word"
          fullWidth
          margin="normal"
          disabled
          value={word}
          onChange={(e) => setWord(e.target.value)}
        />
        <TextField
          label="Word Target Language"
          fullWidth
          margin="normal"
          disabled
          value={wordLanguage}
          onChange={(e) => setWordLanguage(e.target.value)}
        />
      </div>
      <TextField
        label="Word Context"
        fullWidth
        margin="normal"
        disabled
        value={context}
        onChange={(e) => setContext(e.target.value)}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "16px",
        }}
      >
        <TextField
          label="Description"
          multiline
          rows={4}
          style={{ flex: "1" }} // Adjusts the width to occupy half of the container
          margin="normal"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <TextField
          label="Description Language"
          multiline
          rows={4}
          disabled
          style={{ flex: "1" }} // Adjusts the width to occupy the other half of the container
          margin="normal"
          value={descriptionLanguage}
          onChange={(e) => setDescriptionLanguage(e.target.value)}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "16px",
        }}
      >
        <TextField
          label="Translations"
          fullWidth
          margin="normal"
          value={translations.join(", ")} // Display the list as a comma-separated string in the input
          onChange={(e) => {
            const translationsList = e.target.value
              .split(",")
              .map((item) => item.trim());
            setTranslations(translationsList);
          }}
          helperText={
            translationsError || "Translations should be comma-separated."
          }
          error={Boolean(translationsError)} // if translationsError has a value, mark the field as having an error
        />
        <TextField
          label="Translation Language"
          rows={4} // You can adjust the number of rows as you see fit
          fullWidth
          margin="normal"
          value={translationsLanguage}
          disabled
          onChange={(e) => settranslationsLanguage(e.target.value)} // Also note the corrected state setter
        />
      </div>

      <TextField
        label="Parts of Speech"
        rows={4} // You can adjust the number of rows as you see fit
        fullWidth
        margin="normal"
        value={partsOfSpeech}
        disabled
        onChange={(e) => setPartsOfSpeech(e.target.value)} // Also note the corrected state setter
      />
      <Button variant="contained" color="primary" type="submit">
        Submit
      </Button>
    </form>
  );
}

export default WordAdd;
