interface AppProps {
  level: string;
}

function LanguageLevel({ level }: AppProps) {
  return (
    <>
      {(() => {
        if (level === "A1") {
          return (
            // make span red
            <>
              <span>
                <b> |</b>
              </span>
              <span
                style={{
                  color: "#DCDCDC",
                  width: "1em",
                  height: "1em",
                }}
              >
                <b>|||||</b>
              </span>
            </>
          );
        } else if (level === "A2") {
          return (
            <>
              <span>
                <b> ||</b>
              </span>
              <span
                style={{
                  color: "#DCDCDC",
                  width: "1em",
                  height: "1em",
                }}
              >
                <b>||||</b>
              </span>
            </>
          );
        } else if (level === "B1") {
          return (
            <>
              <span>
                <b> |||</b>
              </span>
              <span
                style={{
                  color: "#DCDCDC",
                  width: "1em",
                  height: "1em",
                }}
              >
                <b>|||</b>
              </span>
            </>
          );
        } else if (level === "B2") {
          return (
            <>
              <span>
                <b> ||||</b>
              </span>
              <span
                style={{
                  color: "#DCDCDC",
                  width: "1em",
                  height: "1em",
                }}
              >
                <b>||</b>
              </span>
            </>
          );
        } else if (level === "C1") {
          return (
            <>
              <span>
                <b> |||||</b>
              </span>
              <span
                style={{
                  color: "#DCDCDC",
                  width: "1em",
                  height: "1em",
                }}
              >
                <b>|</b>
              </span>
            </>
          );
        } else if (level === "C2") {
          return (
            <>
              <span>
                <b> ||||||</b>
              </span>
            </>
          );
        } else {
          return (
            <span
              style={{
                color: "#DCDCDC",
                width: "1em",
                height: "1em",
              }}
            >
              NA
            </span>
          );
        }
      })()}
    </>
  );
}

export default LanguageLevel;
