import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserProfile } from "../../../features/users/getUserProfileSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../hooks/useTypedSelector";
import Copyright from "../../Copyright/Copyright";
import Box from "@mui/material/Box";
import UserInfo from "./UserInfo/UserInfo";
import AuthContext from "../../../context/AuthContext";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListIcon from "@mui/icons-material/List";
import CloseIcon from "@mui/icons-material/Close";
import Scheduler from "../../Scheduler/SchedulerMany";
import useMatchMedia from "../../../hooks/matchMedia";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import UserSkeleton from "../../Skeletons/UserSkeleton";
import Groups from "./Groups/Groups";
import theme from "../../theme/theme";
import { borderColor } from "../../theme/colors";
import PendingRequests from "./PendingRequests/PendingRequests";
import { postTeacherNominationAccept } from "../../../features/classrooms/postTeacherNominationAcceptSlice";
import { postTeacherNominationReject } from "../../../features/classrooms/postTeacherNominationRejectSlice";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const DashboardMany: FC = () => {
  const isDesktopResolution = useMatchMedia("(min-width:992px)", true);

  const dispatch = useAppDispatch();
  const { currentUser, isAuthenticated } = useContext(AuthContext);
  const { getUserProfileLoading, getUserProfilePayload } = useAppSelector(
    (state) => state.getUserProfile
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const [wordOfDay, setWordOfDay] = useState({
    word: "benevolent",
    description: "well meaning and kindly.",
    descriptionLanguage: "en-Us",
    example: '"a benevolent smile"',
    parts_of_speech: ["ADJ"],
  });

  const closeWordOfDayHandler = () => {
    localStorage.setItem("wordOfDay", "closed");
    setWordOfDayStatus("closed");
  };

  const closeTeacherHandler = () => {
    console.log("closeTeacherHandler");
  };

  const [wordOfDayStatus, setWordOfDayStatus] = useState(
    localStorage.getItem("wordOfDay") || "open"
  );

  useEffect(() => {
    if (!isAuthenticated) return;
    if (!currentUser.profile_uuid) return;
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      getUserProfile({
        access_token: access_token,
        subscriberProfileId: currentUser.profile_uuid,
      })
    );
  }, []);

  //debug
  console.log("getUserProfilePayload", getUserProfilePayload);

  const navigate = useNavigate();

  function handleAcceptUser(classroomId: string): void {
    if (!isAuthenticated) return;
    if (!currentUser.profile_uuid) return;
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      postTeacherNominationAccept({
        access_token: access_token,
        classroom_uuid: classroomId,
      })
    ).then((result) => {
      // check if the dispatch was fulfilled
      if (result.meta.requestStatus === "fulfilled") {
        dispatch(
          getUserProfile({
            access_token: access_token,
            subscriberProfileId: currentUser.profile_uuid,
          })
        );
      }
    });
  }

  function handleRejectUser(classroomId: string): void {
    if (!currentUser.profile_uuid) return;
    let authTokens = JSON.parse(localStorage.getItem("authTokens") || "{}");
    let access_token = authTokens.access;
    dispatch(
      postTeacherNominationReject({
        access_token: access_token,
        classroom_uuid: classroomId,
      })
    ).then((result) => {
      // check if the dispatch was fulfilled
      if (result.meta.requestStatus === "fulfilled") {
        dispatch(
          getUserProfile({
            access_token: access_token,
            subscriberProfileId: currentUser.profile_uuid,
          })
        );
      }
    });
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {/* Left Row */}
        <Grid item xs={12} md={4} lg={4}>
          {getUserProfileLoading ? (
            <UserSkeleton />
          ) : (
            <UserInfo
              userProfile={getUserProfilePayload}
              loading={getUserProfileLoading}
              title={""}
              img_url={getUserProfilePayload.profile_image}
            />
          )}
          {/* Start Teacher */}
          {/* Teacher Loading State */}
          {getUserProfileLoading ? (
            <UserSkeleton />
          ) : (
            <>
              {!getUserProfilePayload.is_teacher ? (
                <Card
                  sx={{ mb: 1, borderColor: borderColor.logo, padding: 2 }}
                  variant="outlined"
                >
                  <CardContent>
                    <CloseIcon
                      style={{
                        position: "relative",
                        top: "-30px",
                        left: "-30px",
                      }}
                      color={"primary"}
                      fontSize="small"
                      onMouseEnter={(e) => {
                        e.currentTarget.style.cursor = "pointer";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.cursor = "default";
                      }}
                      onClick={(e) => {
                        closeTeacherHandler();
                      }}
                    />
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Teacher Profile
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      You are currently only registered as a student. If you
                      would like to create a teacher profile, please fill out
                      the form below.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button href={"/teachers/create/"} size="small">
                      Create a teacher profile now!
                    </Button>
                  </CardActions>
                </Card>
              ) : (
                getUserProfilePayload.is_teacher &&
                getUserProfilePayload.invites &&
                getUserProfilePayload.invites.map((invite: any) => (
                  <Card sx={{ mb: 1, mt: 1, borderColor: "red", padding: 2 }}>
                    <CardContent>
                      <CloseIcon
                        style={{
                          position: "relative",
                          top: "-30px",
                          left: "-30px",
                        }}
                        color={"primary"}
                        fontSize="small"
                        onMouseEnter={(e) => {
                          e.currentTarget.style.cursor = "pointer";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.cursor = "default";
                        }}
                        onClick={(e) => {
                          closeTeacherHandler();
                        }}
                      />
                      <Typography
                        sx={{ mb: 1.5 }}
                        color="text.secondary"
                        component="div"
                      >
                        You have been invited to join the classroom{" "}
                        <b>{invite.classroom_title}</b> as a teacher. Please
                        click{" "}
                        <a
                          href={
                            "/teachers/accept-invite/" + invite.classroom_uuid
                          }
                        >
                          here
                        </a>{" "}
                        to accept the invitation.
                      </Typography>
                    </CardContent>
                  </Card>
                ))
              )}
              {getUserProfilePayload.is_teacher &&
                getUserProfilePayload.invites &&
                getUserProfilePayload.invites.length === 0 && (
                  <Card
                    sx={{
                      mb: 1,
                      borderColor: borderColor.logo,
                    }}
                    variant="outlined"
                  >
                    <CardContent>
                      <CloseIcon
                        style={{
                          position: "relative",
                          top: "-30px",
                          left: "-30px",
                        }}
                        color={"primary"}
                        fontSize="small"
                        onMouseEnter={(e) => {
                          e.currentTarget.style.cursor = "pointer";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.cursor = "default";
                        }}
                        onClick={(e) => {
                          closeTeacherHandler();
                        }}
                      />
                      <Typography sx={{ m: 2 }} color="text.secondary">
                        You currently have no new invitations.
                      </Typography>
                    </CardContent>
                  </Card>
                )}
            </>
          )}
          {/* End Teacher */}
          {/* Start Nominations */}
          {getUserProfilePayload &&
            getUserProfilePayload.teacher_profile &&
            getUserProfilePayload.teacher_profile.nomination_details &&
            getUserProfilePayload.teacher_profile.nomination_details.length >
              0 && (
              <PendingRequests
                nominations={
                  getUserProfilePayload.teacher_profile.nomination_details
                }
                loading={false}
                title={"Pending Requests"}
                onAccept={(classroomId) => handleAcceptUser(classroomId)}
                onReject={(classroomId) => handleRejectUser(classroomId)}
              />
            )}
          {/* End Nominations */}

          {/* Start Word of the day */}
          {wordOfDayStatus !== "closed" && isDesktopResolution && (
            <Box sx={{ mt: 3 }}>
              {getUserProfileLoading ? (
                <UserSkeleton /> // Using the same UserSkeleton as a loading state
              ) : (
                <Card
                  sx={{
                    width: "100%",
                    borderColor: borderColor.color4,
                    padding: 2,
                  }}
                  variant="outlined"
                >
                  <CardContent>
                    <CloseIcon
                      style={{
                        position: "relative",
                        top: "-30px",
                        left: "-30px",
                      }}
                      color={"primary"}
                      fontSize="small"
                      onMouseEnter={(e) => {
                        e.currentTarget.style.cursor = "pointer";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.cursor = "default";
                      }}
                      onClick={(e) => {
                        closeWordOfDayHandler();
                      }}
                    />
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                    >
                      Word of the Day
                    </Typography>
                    <Typography variant="h5" component="div">
                      {wordOfDay.word}
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                      {wordOfDay.parts_of_speech[0]}
                    </Typography>
                    <Typography variant="body2">
                      {wordOfDay.description}
                      <br />
                      {wordOfDay.example}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button href={"/dictionary/benevolent/"} size="small">
                      Open the dictionary
                    </Button>
                  </CardActions>
                </Card>
              )}
            </Box>
          )}
          {/* End Word of the day */}
        </Grid>
        {/* Right Row */}
        <Grid item xs={12} md={8} lg={8}>
          {/* Add another navbar inside grid */}
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
          >
            <IconButton
              aria-label="New Messages"
              color={"primary"}
              onClick={() => {
                setSelectedTab(0);
              }}
            >
              <ListIcon />
            </IconButton>
            <IconButton
              aria-label="Calendar"
              color={"primary"}
              onClick={() => {
                setSelectedTab(1);
              }}
            >
              <CalendarMonthIcon />
            </IconButton>
            <Button
              aria-label="List"
              color={"primary"}
              onClick={() => {
                navigate("/groups/create");
              }}
            >
              <ControlPointIcon
                sx={{
                  mr: 1,
                }}
              />{" "}
              Create Group
            </Button>
          </Stack>
          {/* This is the classroom component */}
          {selectedTab === 0 && (
            <Box sx={{ mt: 2 }}>
              <Groups />
            </Box>
          )}
          {/* This is the scheduler component */}
          {selectedTab === 1 && (
            <Box sx={{ mt: 2 }}>
              <Scheduler />
            </Box>
          )}
        </Grid>
      </Grid>
      <Copyright sx={{ pt: 4 }} />
    </Container>
  );
};

export default DashboardMany;
